import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';


export type RowProps = {
  count: number
  total: number
  title: string
  progressColor: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'inherit'
};

export function Row({
  count,
  total,
  title,
  progressColor,
}: RowProps) {
  const totalPercent = total > 0 ? (count / total) * 100 : 0;
  return (
    <Grid
      container
      direction="row"
      spacing={1}
    >
      <Grid item md={3}>
        <Typography variant="h5" color={`${progressColor}.main`}>{title}</Typography>
      </Grid>
      <Grid item md={5} alignSelf="center">
        <Box sx={{ width: '100%', alignSelf: 'center' }}>
          <LinearProgress
            variant="determinate"
            color={progressColor}
            value={totalPercent}
            sx={{ backgroundColor: '#E2E1E5' }}
          />
        </Box>
      </Grid>
      <Grid item md={4}>
        <Grid
          container
          direction="row"
          spacing={1}
        >
          <Grid item md={6}>
            <div><b>{totalPercent.toFixed(1)}%</b></div>
          </Grid>
          <Grid item md={6}>
            <div className="text-muted">{count.shortFormNumberStr()}</div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
