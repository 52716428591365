import {
 BrowserRouter, Navigate, Route, Routes,
} from 'react-router-dom';
import * as CONFIG from 'shared-scope/config';
import Poll from 'products/Poll';
import NewPoll from 'products/Poll/NewPoll';
import Campaign from 'products/Campaign';
import { Details } from 'products/Details';
import NewCampaign from 'products/Campaign/NewCampaign';
import CloneCampaign from 'products/Campaign/NewCampaign/clone';
import EditCampaign from 'products/Campaign/edit';
import ClonePoll from 'products/Poll/NewPoll/clone';
import EditPoll from 'products/Poll/edit';
import Templates from 'products/Templates';
import NewTemplate from 'products/Templates/NewTemplate';
import PhoneBook from 'products/PhoneBook';
import PhoneBookGrid from 'products/PhoneBook/PhoneBookGrid';
import NewFolder from 'products/PhoneBook/NewFolder';
import NewPhoneBook from 'products/PhoneBook/NewPhoneBook';
import ImportToPhoneBook from 'products/PhoneBook/ImportToPhoneBook';
import EditPhoneBook from 'products/PhoneBook/EditPhoneBook';
import EditFolder from 'products/PhoneBook/EditFolder';
import Account from 'products/Account';
import PollReport from 'products/Report/PollReport';
import StopList from 'products/StopList';
import OptIn from 'products/OptIn';
import OptInList from 'products/OptIn/OptInList';
import OptInDetails from 'products/OptIn/OptInDetails';
import CampaignList from 'products/OptIn/CampaignList';
import NewOptIn from 'products/OptIn/NewOptIn';
import { hasPermissionById, PERMISSION_GENERAL } from 'permission';


export default function Router() {
  const hasPermission = hasPermissionById(PERMISSION_GENERAL);

  return <BrowserRouter basename={`${window.BASENAME}`}>
    <Routes>
      <Route path="/poll" element={<Poll />}>
        <Route path=":id/:tab?" element={<Details />} />
      </Route>
      <Route path="/account-settings" element={<Account />} />

      {hasPermission
        ? (
          <>
            {CONFIG.get('poll')
              ? (
                <>
                  <Route path="/poll/new" element={<NewPoll />} />
                  <Route path="/poll/edit/:pollId" element={<EditPoll />} />
                  <Route path="/poll/clone/:cloneId" element={<ClonePoll />} />
                  <Route path="/poll/cloneNew/:cloneId" element={<ClonePoll newPoll />} />
                  <Route path="/poll/cloneNewParticipants/:cloneId" element={<ClonePoll useParticipants />} />
                </>
              )
              : null}

            <Route path="/campaign" element={<Campaign />}>
              <Route path=":id" element={<Details />} />
            </Route>
            <Route path="/campaign/new" element={<NewCampaign />} />
            <Route path="/campaign/edit/:campaignId" element={<EditCampaign />} />
            <Route path="/campaign/clone/:cloneId" element={<CloneCampaign />} />
            <Route path="/campaign/cloneNew/:cloneId" element={<CloneCampaign newCampaign />} />

            <Route path="/templates" element={<Templates />} />
            <Route path="/templates/new" element={<NewTemplate />} />

            <Route path="/phone-book/:folderId?" element={<PhoneBook />}>
              <Route path=":id" element={<PhoneBookGrid />} />
            </Route>
            <Route path="/phone-book/new-folder" element={<NewFolder />} />
            <Route path="/phone-book/new-phone-book/:folderId?" element={<NewPhoneBook />} />
            <Route path="/phone-book/import-phone-book/:bookId" element={<ImportToPhoneBook />} />
            <Route path="/phone-book/edit-phone-book/:id" element={<EditPhoneBook />} />
            <Route path="/phone-book/edit-folder/:id" element={<EditFolder />} />

            <Route path="/report/poll/:id" element={<PollReport />} />

            <Route path="/stop-list" element={<StopList />} />

            {CONFIG.get('opt-in')
              ? (
                <>
                  <Route path="/opt-in" element={<OptIn />}>
                    <Route path="opt-in-list" element={<OptInList />}>
                      <Route path=":id" element={<OptInDetails />} />
                    </Route>
                    <Route path="campaign-list" element={<CampaignList />}>
                      <Route path=":id/:tab?" element={<Details />} />
                    </Route>
                  </Route>
                  <Route path="/opt-in/new" element={<NewOptIn />} />
                </>
              )
              : null}
          </>
        )
        : null}
      {CONFIG.get('poll')
        ? <Route path="/*" element={<Navigate to="/poll" />} />
        : <Route path="/*" element={<Navigate to="/campaign" />} />}
    </Routes>
  </BrowserRouter>;
}
