import React from 'react';
import {
  GridColDef, GridRowParams,
} from '@mui/x-data-grid-pro';
import {} from '@mui/x-data-grid-pro/models';
import { s } from 'i18n';
import { FilterDateRange } from '@xeebi/neru';
import DateTime from 'shared-scope/components/DateTime';
import NewCampaignByType from 'shared-scope/components/GridActions/NewCampaignByType';
import ActionClone from 'shared-scope/components/GridActions/ActionClone';

type StructProps<R> = {
  onActionCreate?: (row: R) => void
  onActionClone?: (row: R) => void
};

export default function Struct<T>({ onActionCreate, onActionClone }: StructProps<T> = {}) {
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: s('ID'),
      type: 'number',
      filterable: true,
      minWidth: 100,
      disableColumnMenu: true,
      flex: 0,
    },
    {
      field: 'name',
      headerName: s('Name'),
      filterable: true,
      type: 'string',
      minWidth: 600,
      disableColumnMenu: true,
      flex: 0,
    },
    {
      field: 'questions',
      headerName: s('Questions'),
      type: 'number',
      filterable: true,
      minWidth: 120,
      disableColumnMenu: true,
      flex: 0,
      renderCell: ({ value }) => (
        <div>{value > 0 ? value : s('-')}</div>
      ),
    },
    {
      field: 'createTs',
      headerName: s('Created'),
      disableColumnMenu: true,
      type: 'custom',
      renderHeaderFilter: (params) => (
        <FilterDateRange
          {...params}
          placeholder={s('Start date')}
        />
      ),
      valueGetter: (value) => (value ? new Date(value) : null),
      renderCell: ({ value }) => <DateTime value={value} direction="row" />,
      minWidth: 150,
      flex: 0,
    },
  ];

  (onActionCreate || onActionClone) && columns.unshift({
    field: 'action',
    type: 'actions',
    headerName: s('Action'),
    getActions: ({ row }: GridRowParams) => {
      const gridActions = [];
      onActionCreate && gridActions.push(
        <NewCampaignByType
          label={s('Create campaign')}
          type={row.type}
          action={(e: React.MouseEvent) => {
            e.stopPropagation();
            onActionCreate(row);
          }}
        />,
      );

      onActionClone && gridActions.push(
        <ActionClone
          label={s('Clone template')}
          action={(e: React.MouseEvent) => {
            e.stopPropagation();
            onActionClone(row);
          }}
          showInMenu={!!gridActions.length}
        />,
      );

      return gridActions;
    },
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    minWidth: 60,
    flex: 0,
  });

  return columns;
}
