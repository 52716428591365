import { styled } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/system';

const ActionsBox = styled(Box)<BoxProps>(({ theme }) => (
  {
    backgroundColor: theme.palette.layout.light,
    borderRadius: '16px',
    padding: '16px 120px',
    width: '100%',
  }
));

export default ActionsBox;
