import { FormProvider, useForm } from 'shared-scope/components/Form';
import { useNavigate, useParams } from 'react-router-dom';
import useAlert from 'shared-scope/hooks/useAlert';
import { useRequest } from '@xeebi/neru';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { every } from 'lodash';
import {
  FormField, request, SchedulerLabel, Sources,
} from 'products/shared/Campaign';
import {
  StepGeneral,
  StepMessage,
  StepScheduler,
  StepSettings,
  StepSettingsReadOnly,
  StepTarget,
  StepTargetReadOnly,
  NewCampaignRow,
} from 'products/shared/Campaign/steps';
import { s } from 'i18n';
import Page from 'layout/Page';
import { Step, Stepper } from 'shared-scope/components/Stepper';
import { CountryCapability } from 'graphql-api';
import { Button, Stack, Typography } from '@mui/material';
import { GetCampaignsQuery, getSdk } from 'products/shared/Campaign/queries.generated';
import { fetcher } from 'graphql-api/fetcher';
import getCampaignData from 'products/Campaign/helpers';
import ActionsBox from 'shared-scope/components/Box/ActionsBox';
import { CampaignStatus } from 'products/CampaignList/types';
import { CAMPAIGN_TYPE_DRIP } from 'products/common/helpers';


const apiCampaign = getSdk(fetcher);
const back = '/campaign';

export default function EditCampaign() {
  const { campaignId = 0 } = useParams();
  const form = useForm<NewCampaignRow>();
  const navigate = useNavigate();

  const [hasError, setHasError] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [editSource, setEditSource] = useState(false);

  const setInitial = useMemo(() => form.setInitial, [form]);

  const [schedulerOn, setSchedulerOn] = useState(false);
  const [scheduleTimes, setScheduleTimes] = useState<string | null>(null);

  const {
    addSuccess,
    addError,
  } = useAlert();

  const {
    isLoading,
    error: errorCreate,
    fetch: createCampaign,
    result,
  } = useRequest(request.createCampaign);

  const {
    isLoading: isLoadingCampaign,
    error: errorCampaign,
    fetch: fetchCampaign,
    result: campaigns,
  } = useRequest<GetCampaignsQuery>(apiCampaign.getCampaigns);

  const {
    result: updateResult,
    isLoading: isUpdating,
    error: errorUpdate,
    fetch: updateCampaign,
  } = useRequest(request.updateCampaign);

  const {
    result: updateStatusResult,
    isLoading: isStatusUpdating,
    error: errorUpdateStatus,
    fetch: updateStatus,
  } = useRequest(request.updateCampaignStatus);

  useEffect(() => {
    if (hasError) {
      const errors = form.getErrors();
      const names = Object.keys(errors);
      if (names.length) {
        const el = document.getElementsByClassName(`formController-${names[0]}`);
        el?.[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      setHasError(false);
    }
  }, [hasError, form]);

  useEffect(() => {
    const error = errorCreate || errorCampaign || errorUpdate || errorUpdateStatus;
    error && addError(error.getMessage());
  }, [errorCreate, errorCampaign, errorUpdate, errorUpdateStatus, addError]);

  useEffect(() => {
    result && addSuccess(s('New campaign added successfully'));
    result && navigate(back);
  }, [result, addSuccess, navigate]);

  useEffect(() => {
    (updateStatusResult || updateResult) && addSuccess(s('Campaign has been successfully edited'));
    (updateStatusResult || updateResult) && navigate(back);
  }, [updateStatusResult, updateResult, navigate, addSuccess]);

  useEffect(() => {
    campaignId && fetchCampaign({
      filter: JSON.stringify({ id: { $eq: +campaignId } }),
    });
  }, [fetchCampaign, campaignId]);

  const campaign = useMemo(
    () => (campaigns?.campaign?.length ? campaigns.campaign[0] : null),
    [campaigns?.campaign],
  );

  const onDraft = useCallback(async () => {
    if (!form.validate()) {
      setHasError(true);
      return;
    }
    const row = form.getRow();
    if (!row || !campaign) {
      return;
    }
    if (editSource) {
      await createCampaign(getCampaignData(row, schedulerOn, true, +campaignId));
    } else {
      await updateCampaign({ campaign, schedulerOn, newCampaign: row });
    }
  }, [campaignId, schedulerOn, createCampaign, form, editSource, campaign, updateCampaign]);

  const onSubmit = useCallback(async () => {
    if (hasChanges) {
      await onDraft();
    }
    await updateStatus({ campaignId, status: CampaignStatus.Stopped });
  }, [campaignId, updateStatus, onDraft, hasChanges]);

  useEffect(() => {
    setSchedulerOn(campaign?.scheduleEnabled || false);
    const useparticipants = campaign?.type === CAMPAIGN_TYPE_DRIP
      ? { send_to_existing_contacts: campaign.params?.send_to_existing_contacts || false }
      : {};

    campaign && (
      setInitial({
        title: campaign.name || '',
        source_type: campaign.params?.campaign_source_type,
        source_name: campaign.params?.campaign_source_name || null,
        message: {
          text: campaign.messageTemplates?.[0]?.text || '',
          attachment: campaign.attachmentId,
        },
        schedule_times: campaign.scheduleTimes || '',
        mapping: campaign.params?.mapping || {},
        type: campaign?.type || undefined,
        ...useparticipants,
      })
    );
  }, [campaign, setInitial]);

  useEffect(() => {
    const st = (form.getValue(FormField.route) as CountryCapability)?.scheduleTimes || null;
    setScheduleTimes(st);
    if (st) {
      setSchedulerOn(true);
    }
  }, [form]);

  useEffect(() => {
    if (!campaign) {
      return;
    }
    const newCampaign = form?.getRow();

    const checkChanges = !every([
      (newCampaign?.title === campaign.name),
      (newCampaign?.route?.route?.id === campaign.route?.id),
      (newCampaign?.message?.attachment === campaign.attachmentId),
      (newCampaign?.message?.text === campaign.messageTemplates[0].text),
      (schedulerOn === campaign.scheduleEnabled),
      (newCampaign?.schedule_times === campaign.scheduleTimes),
    ], Boolean) || editSource;

    setHasChanges(checkChanges);
  }, [form, campaign, schedulerOn, editSource]);

  return (
    <Page
      title={s('Edit text campaign :name', { name: campaign?.name })}
      back={back}
      loading={isLoading || isLoadingCampaign || isUpdating || isStatusUpdating}
    >
      <FormProvider form={form} onSubmit={onSubmit}>
        <Stepper>

          <Step label={s('General')}>
            <StepGeneral
              titleFieldName={s('Campaign name')}
              initial={useMemo(() => (campaign ? {
                route: campaign.route || undefined,
                country: campaign.country || undefined,
              } : undefined), [campaign])}
            />
          </Step>

          <Step label={s('Target Audience')}>
            {editSource
              ? (
                <StepTarget
                  avail={[Sources.excel, Sources.text, Sources.phonebook]}
                  targetType="campaign"
                />
              )
              : (
                <StepTargetReadOnly
                  campaignId={+campaignId}
                  onEdit={() => setEditSource(true)}
                />
              )}
          </Step>

          <Step label={s('Settings')}>
            {editSource
              ? (
                <StepSettings
                  sourceType={form.getValue(FormField.sourceType)}
                  sourceValue={form.getValue(FormField.sourceValue) || null}
                />
              )
              : (
                <StepSettingsReadOnly
                  sourceType={Sources.campaign}
                  sourceValue={form.getValue(FormField.sourceValue) || null}
                />
              )}
          </Step>

          <Step label={s('Compose your message')}>
            <StepMessage
              mapping={form.getValue(FormField.mapping) || {}}
              sourceValue={form.getValue(FormField.sourceValue)}
              maxAttachmentSize={(form.getValue(FormField.route) as CountryCapability)?.maxAttachmentSize || 0}
              templateId={campaign?.conversationTemplateId?.id || undefined}
            />
          </Step>

          <Step label={scheduleTimes ? s('Schedule') : <SchedulerLabel value={schedulerOn} onChange={setSchedulerOn} />}>
            {schedulerOn
              ? <StepScheduler
                  mask={scheduleTimes}
                  initialvalue={form.getValue(FormField.scheduleTimes) || scheduleTimes}
              />
              : <Typography variant="subtitle1">{s('Campaign will be sent now')}</Typography>}
          </Step>
        </Stepper>
        <ActionsBox>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            alignItems="center"
          >
            <Button
              variant="outlined"
              onClick={() => navigate(-1)}
            >{s('Cancel')}</Button>
            <Button
              onClick={onDraft || (() => navigate('/campaign'))}
              variant="outlined"
              disabled={!hasChanges}
            >{s('Save')}</Button>
            <Button
              type="submit"
              variant="contained"
            >{s('Create text campaign')}</Button>
          </Stack>
        </ActionsBox>
      </FormProvider>
    </Page>
  );
}
