import axios from 'axios';
import * as CONFIG from 'shared-scope/config';
import { PhoneBook as PhoneBookObj, PhoneBookFolder } from 'src/graphql-api';


export type PhoneBookSettings = {
    folder: PhoneBookFolder | null
    phoneBookFilter: string | ''
    phoneBook: PhoneBookObj | null
  };


const EXPORT_COLUMN_MAP: { [index: string]: string } = {
    phoneNumber: 'phone_number',
    firstName: 'first_name',
    lastName: 'last_name',
};

function convertFilter(filter: string) {
    let result = filter;
    for (const k of Object.keys(EXPORT_COLUMN_MAP)) {
        const s = `{"${k}":`;
        if (result.indexOf(s) >= 0) {
            result = result.replaceAll(s, `{"${EXPORT_COLUMN_MAP[k]}":`);
        }
    }
    return result;
}


export async function exportPhoneBook(phoneBookId: number, filter: string) {
    const baseUrl = `${CONFIG.get('api')}/export/phone-book`;
    filter = convertFilter(filter);


    try {
        const prepareResp = await axios.post(`${baseUrl}/prepare`);
        const exportId: string = prepareResp?.data?.exportId;
        if (!exportId) {
            throw new Error('Empty export ID');
        }

        const execResp = await axios.get(
            `${baseUrl}/${phoneBookId}`,
            {
                params: {
                    filter,
                    exportId,
                },
            },
        );
        if (execResp?.data?.status !== 'OK') {
            throw new Error(`Wrong status ${execResp?.data?.status}`);
        }

        const exportResp = await axios.get(
            `${baseUrl}/result/`,
            {
            responseType: 'blob',
            params: { exportId },
            },
        );
        const fd = (new Date()).format('dd_mm_yyyy_H2_M2');
        const href = URL.createObjectURL(exportResp?.data);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `phone_book_${phoneBookId}_${fd}.zip`);
        link.click();
        URL.revokeObjectURL(href);
    } catch (e) {
        console.log(e);
    }
}
