import styles from './loading.module.scss';
import { Logo } from './Logo';

type CircularProps = {
  width?: number
  height?: number
  radius?: number
  size?: number
  arc?: number
  className?: string
};

function Circular({
  width = 100,
  height = 100,
  radius = 40,
  size = 3,
  arc = Math.PI,
  className = styles.animate1s,
}: CircularProps) {
  return <svg
    width={width}
    height={height}
    className={`${styles.content} ${className}`}
  >
    <circle
      className={styles.path}
      cx={width / 2}
      cy={height / 2}
      r={radius}
      fill="none"
      strokeWidth={size}
      strokeMiterlimit="10"
      strokeDasharray={`${arc * radius},1000`}
    />
  </svg>;
}

Circular.defaultProps = {
    width: 100,
    height: 100,
    radius: 40,
    size: 3,
    arc: Math.PI,
    className: styles.animate1s,
};

type LoadingProps = {
  size?: number
  className?: string
  logo?: boolean
  levels?: number
  progress?: number | null
};

function Loading({
  size = 100,
  className = 'd-block',
  logo = true,
  levels = 3,
  progress = null,
}: LoadingProps) {
  return <div className={className}>
    <div
      className={styles.loading}
      style={{ width: size + 'px', height: size + 'px' }}
    >
      {levels >= 1 ? <Circular
        width={size}
        height={size}
        radius={size * 0.4}
        size={size * 0.07}
        arc={1.4 * Math.PI}
        className={styles.animate3s}
      /> : null}
      {levels >= 2 ? <Circular
        width={size}
        height={size}
        radius={size * 0.31}
        size={size * 0.05}
        arc={1.2 * Math.PI}
        className={styles.animate2s}
      /> : null}
      {levels >= 3 ? <Circular
        width={size}
        height={size}
        radius={size * 0.23}
        size={size * 0.04}
        arc={0.9 * Math.PI}
        className={styles.animate1s}
      /> : null}
      {progress !== null
    ? <div className="d-flex justify-content-center align-items-center">
      <strong className={styles.progress}>{`${progress}%`}</strong>
    </div>
    : logo
    ? <Logo size={size * 0.3} className={styles.logo} /> : null}
    </div>
  </div>;
}

Loading.defaultProps = {
  size: 100,
  className: 'd-block',
  logo: true,
  levels: 3,
  progress: null,
};

type LoadingSuccessProps = {
  size?: number
  className?: string
};

export function LoadingSuccess({
  size = 100, className = 'd-block',
}: LoadingSuccessProps) {
  return <div className={className}>
    <div className={styles.loading} style={{ width: size + 'px', height: size + 'px' }}>
      {/* <Circular width={size} height={size} radius={size * 0.4} size={size * 0.07} /> */}
      <div
        style={{
        width: Math.round(0.43 * size) + 'px',
        height: Math.round(0.25 * size) + 'px',
      }}
        className={styles.okWrapper}
      >
        <div style={{ width: Math.round(size * 0.07) + 'px' }} className={styles.okLeft} />
        <div style={{ height: Math.round(size * 0.07) + 'px' }} className={styles.okBottom} />
      </div>
    </div>
  </div>;
}


export default Loading;
