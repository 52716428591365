import React from 'react';
import { useFormContext } from 'shared-scope/components/Form';
import { Grid, Typography } from '@mui/material';
import { s } from 'i18n';
import {
 MappingTableReadOnly,
} from '../components';
import {
  ContactListFilter,
  Mapping,
  MappingData,
  MappingFormValue,
  Sources,
  SourceValue,
} from '../types';


export default function StepSettingsReadOnly({ sourceType, sourceValue }: StepSettingsProps) {
  const { form: { setValue }, formRow } = useFormContext<StepSettingsRow>();

  return sourceValue ? (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={2}
    >
      <Grid item xs={12} lg={4} xl={3} />
      <Grid item xs={12} lg={8} xl={9}>
        <MappingTableReadOnly
          value={formRow.mapping || {}}
          onChange={(mapping) => setValue('mapping', mapping)}
          mode={sourceType}
          data={sourceValue.data as MappingData}
          mappingParams={formRow.mapping_param || {}}
        />
      </Grid>
    </Grid>
  ) : <Typography variant="subtitle1">{s('Specify the target audience to customize the settings')}</Typography>;
}

export type StepSettingsRow = {
  mapping?: Mapping
  filter?: ContactListFilter
  mapping_param?: MappingFormValue
};

type StepSettingsProps = {
  sourceType?: Sources
  sourceValue: SourceValue
};
