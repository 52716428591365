import React from 'react';
import { s } from 'i18n';
import {
  Typography,
  Stack,
} from '@mui/material';
import {
 FilterDropdown, FilterDateRange,
} from '@xeebi/neru';
import DateTime from 'shared-scope/components/DateTime';
import { GridColDef, GridHeaderFilterCellProps } from '@mui/x-data-grid-pro';
import { TrxType, Module } from 'graphql-api';


export default function getColumns({
  trxTypes,
  currencySign,
  modules,
}: StructProps) {
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      type: 'number',
      sortable: true,
      filterable: true,
      disableColumnMenu: true,
      minWidth: 80,
      flex: 0,
    },
    {
      field: 'amount',
      type: 'string',
      headerName: s('Amount'),
      disableColumnMenu: true,
      filterable: false,
      sortable: true,
      editable: false,
      minWidth: 150,
      flex: 0,
      renderCell: ({ value, row }) => (
        <Stack justifyContent="center" alignItems="end" sx={{ height: '100%' }}>
          <Typography>
            {`${value} ${currencySign || '$'}`}
          </Typography>
          {row?.bonus
            ? (
              row.bonus.map((b: string) => (
                <Typography
                  color="text.secondary"
                  variant="caption"
                  fontSize={10}
                  lineHeight={1.2}
                >
                  {b}
                </Typography>
              ))
            )
            : null}
        </Stack>
      ),
    },
    {
      field: 'type',
      headerName: s('Type'),
      disableColumnMenu: true,
      type: 'custom',
      valueFormatter: (value: TrxType) => {
        return value?.name || '';
      },
      renderHeaderFilter: (params: GridHeaderFilterCellProps) => (
        <FilterDropdown
          {...params}
          options={trxTypes}
          keyName="id"
          valueName="name"
          placeholder={s('type')}
          minWidth={150}
        />
      ),
      minWidth: 150,
      flex: 0,
    },
    {
      field: 'module',
      headerName: s('Module'),
      disableColumnMenu: true,
      type: 'custom',
      valueFormatter: (value: Module) => {
        return value?.name || '';
      },
      renderHeaderFilter: (params: GridHeaderFilterCellProps) => (
        <FilterDropdown
          {...params}
          options={modules}
          keyName="id"
          valueName="name"
          placeholder={s('module')}
          minWidth={150}
        />
      ),
      minWidth: 150,
      flex: 0,
    },
    {
      field: 'moduleRef',
      type: 'string',
      headerName: s('Module ref'),
      disableColumnMenu: true,
      sortable: true,
      filterable: true,
      editable: false,
      minWidth: 150,
      flex: 0,
    },
    {
      field: 'memo',
      type: 'string',
      headerName: s('Description'),
      disableColumnMenu: true,
      sortable: true,
      filterable: true,
      editable: false,
      minWidth: 150,
      flex: 0,
      renderCell: ({ value }) => (
        <Stack alignItems="start" justifyContent="start" sx={{ height: '100%' }}>
          <Typography variant="caption" whiteSpace="pre-line" lineHeight={1.2}>{value}</Typography>
        </Stack>
      ),
    },
    {
      field: 'trxTs',
      headerName: s('Timestamp'),
      disableColumnMenu: true,
      type: 'custom',
      renderHeaderFilter: (params: GridHeaderFilterCellProps) => (
        <FilterDateRange
          {...params}
          placeholder={s('Timestamp')}
        />
      ),
      valueGetter: (value) => (value ? new Date(value) : null),
      renderCell: ({ value }) => <DateTime value={value} direction="column" />,
      minWidth: 150,
      flex: 0,
    },
  ];
  return columns;
}

type StructProps = {
  trxTypes: TrxType[]
  modules: Module[]
  currencySign: string
};
