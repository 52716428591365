import { Char } from 'shared-scope/types';
import GSM7 from './GSM7';
import { Encod } from './Encoding';


/**
 * GSM7 TR shift chars
 * @type {Char[]}
 */
export const gsm7TrShiftChars: Char[] = [
  null, null, null, null, null, null, null, null,
  null, null, '\f', null, null, null, null, null,
  null, null, null, null, '^', null, null, null,
  null, null, null, null, null, null, null, null,
  null, null, null, null, null, null, null, null,
  '{', '}', null, null, null, null, null, '\\',
  null, null, null, null, null, null, null, null,
  null, null, null, null, '[', '~', ']', null,
  '|', null, null, null, null, null, null, 'Ğ',
  null, 'İ', null, null, null, null, null, null,
  null, null, null, 'Ş', null, null, null, null,
  null, null, null, null, null, null, null, null,
  null, null, null, 'ç', null, '€', null, 'ğ',
  null, 'ı', null, null, null, null, null, null,
  null, null, null, 'ş', null, null, null, null,
  null, null, null, null, null, null, null, null,
];

/**
 * GSM7 tr
 */
export default class GSM7tr extends GSM7 {
  name = Encod.gsm7tr;
  shift_code = 0x01;

  /**
   * Init
   */
  init() {
    super.init();
    this.ext_chars = gsm7TrShiftChars;
  }
}
