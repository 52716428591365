import {
 Button, Icon, Stack, Typography,
} from '@mui/material';
import { s } from 'i18n';
import { useCallback, useEffect, useState } from 'react';
import {
  AnswerType, AttachmentSize, QuestionsTemplate, QuestionType, ValidatorType,
} from 'products/common/types';
import { UID } from 'shared-scope/helpers/functions';
import {
  cloneDeep, find, isEqual,
} from 'lodash';
import { Maybe } from 'shared-scope/types';
import { FormRowsErrors } from 'shared-scope/components/Form/types';
import { getSdk } from 'products/shared/Campaign/queries.generated';
import { fetcher } from 'graphql-api/fetcher';
import Question from './Question';
import { getQuestionsError } from '../helpers';


const api = getSdk(fetcher);


export default function Questions(
  {
    value,
    onChange,
    isResponder = false,
    isPoll = false,
    maxAttachmentSize,
    error,
    hasOptionQuestion = false,
    readOnly = false,
  }: QuestionsProps) {
  /**
   * Normalize value
   */
  useEffect(() => {
    const newValue: QuestionsTemplate = {
      ...{
        initialMessage: '',
        initialAnswer: '',
        initialAttachment: { id: null },
        template: {
          questions: [],
          final_message: '',
          final_attachment_id: null,
          error_message: '',
        },
      },
      ...value,
    };
    if (!newValue.template.questions?.length) {
      newValue.template.questions = [getEmptyQuestion()];
    }
    newValue.template.questions.forEach((q) => {
      if (!q.key) {
        q.key = UID();
      }
    });
    !isEqual(value, newValue) && onChange(newValue);
  }, [value, onChange]);

  const handleQuestions = useCallback((f: Field, v: any) => {
    const newValue: any = cloneDeep(value) || {};
    switch (f) {
      case Field.initialMessage:
      case Field.initialAnswer:
        newValue[f] = v as string;
        break;
      case Field.initialAttachment:
        if (v) {
          newValue[f] = cloneDeep(newValue[f]) || {};
          newValue[f].id = v as string;
        } else {
          newValue[f] = v;
        }
        break;
      case Field.questions:
        newValue.template = newValue.template || {};
        newValue.template.questions = v.map((q: QuestionType, i: number) => {
          return {
            ...q,
            ...{
              position: i + 1,
              field_name: `Question_${i + 1}`,
            },
          };
        });
        break;
      case Field.finalMessage:
      case Field.finalAttachmentId:
      case Field.errorMessage:
        newValue.template[f] = v;
        break;
      default:
        console.error(`Unknown question field ${f}`);
    }
    onChange(newValue as QuestionsTemplate, true);
  }, [value, onChange]);

  let position = 0;
  const getPosition = () => {
    position += 1;
    return position;
  };

  useEffect(() => {
    if (isResponder) {
      handleQuestions(Field.initialMessage, '');
    }
    // eslint-disable-next-line
  }, [isResponder]);

  const [hashtags, setHashtags] = useState<string[]>([]);

  useEffect(() => {
    const loadHashtags = async () => {
      try {
        const { srcHashtag } = await api.getSrcHashtag();
        const hashtagSet = new Set<string>();
        srcHashtag.forEach((h) => {
          if (h.hashtag) {
            hashtagSet.add(h.hashtag);
          }
        });
        setHashtags(Array.from(hashtagSet.keys()).sort());
      } catch (e) {
        console.log(e);
      }
    };
    loadHashtags();
  }, []);

  const questionError = error ? getQuestionsError(error) : null;

  return <Stack spacing={2}>
    {!readOnly && (
      <Stack direction="row" spacing={1} justifyContent="flex-start" alignItems="center">
        <Icon
          className="icon-alert"
          fontSize="small"
          sx={{ transform: 'rotate(180deg)', height: '22px' }}
        />
        <Typography variant="body1">
          {s('If a customer sends back the word that is in the "Reply to start poll" text field, only then the poll will start')}
        </Typography>
      </Stack>
    )}

    <Question
      key="initial_message"
      position={getPosition()}
      title={s('Engagement Question')}
      answerTitle={s('Reply to start poll')}
      question={value?.initialMessage || ''}
      answer={value?.initialAnswer || ''}
      attachment={value?.initialAttachment?.id}
      maxAttachmentSize={maxAttachmentSize}
      onChange={(v) => handleQuestions(Field.initialMessage, v)}
      onAnswerChange={(v) => handleQuestions(Field.initialAnswer, v)}
      onAttachmentChange={(v) => handleQuestions(Field.initialAttachment, v)}
      skipQuestion={isResponder}
      error={questionError?.initial}
      readOnly={readOnly}
    />
    {
      value?.template?.questions && value.template.questions.map((question, i) => (
        <Question
          key={question.key || UID()}
          position={getPosition()}
          title={s('Question')}
          question={question.question}
          answerHashtags={hashtags}
          attachment={question.attachment_id}
          maxAttachmentSize={maxAttachmentSize}
          error={questionError?.questions[i] || undefined}
          onChange={(v) => {
            const questions = cloneDeep(value?.template.questions) || [];
            const q = find(questions, { key: question.key });
            if (q) {
              q.question = v;
            }
            handleQuestions(Field.questions, questions);
          }}
          onAttachmentChange={(v) => {
            const questions = cloneDeep(value?.template.questions) || [];
            const q = find(questions, { key: question.key });
            if (q) {
              q.attachment_id = v;
            }
            handleQuestions(Field.questions, questions);
          }}
          onDelete={((value?.template?.questions?.length || 0) > 1) ? () => {
            const questions = (value?.template.questions || [])
              .filter((q) => q.key !== question.key);
            handleQuestions(Field.questions, questions);
          } : undefined}
          readOnly={readOnly}
        />
      ))
    }

    {!readOnly && (
      <Button
        variant="outlined"
        sx={{ width: '200px' }}
        onClick={() => {
          const questions = cloneDeep(value?.template.questions) || [];
          questions.push(getEmptyQuestion());
          handleQuestions(Field.questions, questions);
        }}
      >
        {s('Add question')}
      </Button>
    )}

    <Question
      key="final_message"
      position={getPosition()}
      title={s('Final message')}
      question={value?.template?.final_message || ''}
      attachment={value?.template?.final_attachment_id || null}
      maxAttachmentSize={maxAttachmentSize}
      onChange={(v) => handleQuestions(Field.finalMessage, v)}
      onAttachmentChange={(v) => handleQuestions(Field.finalAttachmentId, v)}
      error={questionError?.final}
      readOnly={readOnly}
    />
    {
      isPoll && hasOptionQuestion ? (
        <Question
          key="error_message"
          position={getPosition()}
          title={s('Error message')}
          question={value?.template?.error_message || ''}
          onChange={(v) => handleQuestions(Field.errorMessage, v)}
          error={questionError?.final}
          readOnly={readOnly}
        />
      ) : null
    }
  </Stack>;
}

const getEmptyQuestion: () => QuestionType = () => ({
  question: '',
  attachment_id: null,
  key: UID(),
  validator: ValidatorType.AnyText,
  answer_type: AnswerType.Text,
});

type QuestionsProps = {
  value: Maybe<QuestionsTemplate>
  onChange: (v: QuestionsTemplate, templateChanged?: boolean) => void
  isResponder?: boolean
  isPoll?: boolean
  maxAttachmentSize?: AttachmentSize
  error?: FormRowsErrors
  hasOptionQuestion?: boolean
  readOnly?: boolean
};

enum Field {
  initialMessage = 'initialMessage',
  initialAnswer = 'initialAnswer',
  initialAttachment = 'initialAttachment',
  questions = 'questions',
  finalMessage = 'final_message',
  finalAttachmentId = 'final_attachment_id',
  errorMessage = 'error_message',
}
