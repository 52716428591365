import { Import } from 'graphql-api';
import { ImportStatus } from 'products/common/types';
import {
  Grid, IconButton, LinearProgress, Stack, Typography, useTheme,
} from '@mui/material';
import { s } from 'i18n';
import React, { useState } from 'react';
import { downloadImportError } from 'products/common/helpers';
import { Number } from '@xeebi/neru';
import Loading from 'shared-scope/components/Loading';

export default function ImportItem({
 data, onClose, disabled = false,
}: ImportItemProps) {
  const [confirming, setConfirming] = useState(false);

  const {
    id: importId,
    status,
    rowsTotal,
    rowsError,
  } = data;
  const { palette } = useTheme();
  const inProgress = [ImportStatus.Queued, ImportStatus.Importing].includes(status as any);

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      spacing={1}
      sx={{
        paddingRight: '10px',
        paddingBottom: '5px',
        '&:hover': { backgroundColor: '#FEF0EB' },
    }}
    >
      <Grid item width="220px">
        <Stack direction="row" spacing={1} sx={{ width: '100%' }}>
          <Typography fontSize="16px" fontWeight="400">
            {inProgress ? s('Processed rows:') : s('Imported rows:')}
          </Typography>
          <Typography fontSize="16px" fontWeight="600"><Number value={rowsTotal || 0} /></Typography>
        </Stack>
      </Grid>
      <Grid item xs width="100%">
        {
          status === ImportStatus.Error ? (
            <Typography variant="body1" color={(theme) => theme.palette.error.main}>
              {s('Import failed')}
            </Typography>
          ) : (
            <LinearProgress
              variant={inProgress ? 'indeterminate' : 'determinate'}
              value={inProgress ? undefined : 100}
              sx={{ width: '100%' }}
            />
          )
        }
      </Grid>
      <Grid item width="220px" sx={{ ml: 5 }}>
        <Stack direction="row" spacing={1} alignItems="center" justifyContent="start">
          {
            rowsError
              ? (
                <>
                  <Typography
                    variant="body1"
                    color={(theme) => theme.palette.error.main}
                  >
                    {s('Rows with errors:')}
                  </Typography>
                  <Typography
                    fontSize="16px"
                    fontWeight="600"
                    color={(theme) => theme.palette.error.main}
                  >
                    {rowsError}
                  </Typography>
                  {importId && (
                    <IconButton
                      onClick={() => downloadImportError(importId)}
                      sx={{ color: palette.error.main, width: '32px' }}
                    >
                      <i className="icon-import" />
                    </IconButton>
                  )}
                </>
              )
              : (
                <Typography fontSize="16px" fontWeight="400">{s('No errors')}</Typography>
              )
          }
        </Stack>
      </Grid>
      <Grid item width="32px">
        {!confirming ? <IconButton
          title={s('Confirm')}
          disabled={disabled}
          onClick={async () => {
            setConfirming(true);
            onClose && await onClose();
            setConfirming(false);
          }}
          color="inherit"
          sx={{ padding: '2px' }}
        >
          <i className="icon-close" />
        </IconButton>
        : <Loading logo={false} size={32} /> }
      </Grid>
    </Grid>
  );
}

type ImportItemProps = {
  data: Import
  prefix?: string
  onClose?: () => Promise<void>
  disabled?: boolean
};
