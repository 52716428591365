import * as Types from '../../graphql-api';

import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
export type GetOptInListQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sort?: Types.InputMaybe<Types.Scalars['String']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetOptInListQuery = { __typename?: 'Query', optInListCount?: number | null, optInList: Array<{ __typename?: 'OptInList', id?: number | null, phoneNumber?: string | null, operatorName?: string | null, countryName?: string | null, timestamp?: any | null, keyword?: string | null, keywordSecond?: string | null, timestampSecond?: any | null, type?: number | null, status?: number | null, systemNumber?: string | null, systemNumberSecond?: string | null, pdu?: string | null, pduSecond?: string | null, messageId?: string | null, messageIdSecond?: string | null, conversation?: { __typename?: 'Conversation', id?: number | null, phoneNumber?: string | null, chat?: any | null } | null }> };

export type OptInDeleteMutationVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type OptInDeleteMutation = { __typename?: 'Mutation', optInListDelete: Array<{ __typename?: 'OptInList', id?: number | null } | null> };


export const GetOptInListDocument = gql`
    query getOptInList($filter: String = null, $sort: String = null, $limit: Int = null, $offset: Int = 0) {
  optInList(filter: $filter, sort: $sort, limit: $limit, offset: $offset) {
    id
    phoneNumber
    operatorName
    countryName
    timestamp
    keyword
    keywordSecond
    timestampSecond
    type
    status
    conversation {
      id
      phoneNumber
      chat
    }
    systemNumber
    systemNumberSecond
    pdu
    pduSecond
    messageId
    messageIdSecond
  }
  optInListCount(filter: $filter)
}
    `;
export const OptInDeleteDocument = gql`
    mutation optInDelete($filter: String = null) {
  optInListDelete(filter: $filter) {
    id
  }
}
    `;
export type Requester<C = {}> = <R, V>(doc: DocumentNode, vars?: V, options?: C) => Promise<R> | AsyncIterable<R>
export function getSdk<C>(requester: Requester<C>) {
  return {
    getOptInList(variables?: GetOptInListQueryVariables, options?: C): Promise<GetOptInListQuery> {
      return requester<GetOptInListQuery, GetOptInListQueryVariables>(GetOptInListDocument, variables, options) as Promise<GetOptInListQuery>;
    },
    optInDelete(variables?: OptInDeleteMutationVariables, options?: C): Promise<OptInDeleteMutation> {
      return requester<OptInDeleteMutation, OptInDeleteMutationVariables>(OptInDeleteDocument, variables, options) as Promise<OptInDeleteMutation>;
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;