import React from 'react';
import isEmpty from 'lodash/isEmpty';
import upperFirst from 'lodash/upperFirst';
import lowerFirst from 'lodash/lowerFirst';
import { isUpperFirst } from 'shared-scope/helpers/functions';

/**
 * Localization
 * @type {{}}
 */
let localization = {};

/**
 * Localization
 * @param l
 */
export function setLocalization(l) {
  delete l._;
  localization = l;
}

/**
 * Return internationalization string
 * @param str
 * @param params
 * @returns {*}
 */
export function s(str, params = {}) {
  let locString = localization[str]
    || (isUpperFirst(str)
      ? upperFirst(localization[lowerFirst(str)] || '')
      : lowerFirst(localization[upperFirst(str)] || ''))
    || str || '';

  // Log
  if (!isEmpty(localization) && !localization[str]) {
    console.log('No localization for: ' + str);
    // console.trace();
  }

  locString = `${locString}`;
  const template = locString.split('::')[0];
  const parts = template.split(/(:\w+)/g);
  let reactElement = false;

  // Iterate by params
  for (const name in params) {
    if (Object.prototype.hasOwnProperty.call(params, name)) {
      const jsxKey = getJsxKey(name);
      for (let i = 0; i < parts.length; i += 1) {
        if (parts[i] === `:${name}`) {
          if (React.isValidElement(params[name])) {
            parts[i] = React.cloneElement(params[name], { key: jsxKey.next().value });
            reactElement = true;
          } else {
            parts[i] = params[name];
          }
        }
      }
    }
  }

  return reactElement ? <span>{parts}</span> : parts.join('');
}

function* getJsxKey(name) {
  let i = 1;
  while (true) {
    yield i > 1 ? name + i : name;
    i += 1;
  }
}
