import * as CONFIG from 'shared-scope/config';
import axios from 'axios';
import { TZ } from 'shared-scope/const';


export default async function exportOptInList(filter: any) {
    const baseUrl = `${CONFIG.get('api')}/export/opt-in`;

    const execResp = await axios.get(
        `${baseUrl}`,
        {
          responseType: 'blob',
          params: {
            filter: JSON.stringify(filter),
            tz: TZ,
          },
        },
    );

    const fd = (new Date()).format('dd_mm_yyyy_H2_M2');
    const href = URL.createObjectURL(execResp?.data);
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', `opt_in_list_${fd}.xlsx`);
    link.click();
    URL.revokeObjectURL(href);
}
