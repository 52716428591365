import { MessageType } from '../types';

export function createQuestion(text: string, attId: string | null) {
  return {
    type: 'out',
    state: 2,
    message: text,
    position: 1,
    attId,
  } as MessageType;
}

export function createAnswer(text: string | null) {
  return {
    type: 'in',
    state: 2,
    message: text || 'Yes',
    position: 2,
  } as MessageType;
}
