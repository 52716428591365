import { Stack } from '@mui/material';
import { s } from 'i18n';
import { fetcher } from 'graphql-api/fetcher';
import { useEffect, useState, useRef } from 'react';
import { PhoneBook as PhoneBookType, PhoneBookFolder } from 'graphql-api';
import { MangoFilter } from 'shared-scope/types';
import { getSdk } from 'products/common/queries.generated';
import { useRequest, Combobox, useMountedState } from '@xeebi/neru';


const api = getSdk(fetcher);

export default function PhoneBook({ onChange, phoneBookId }: PhoneBookProps) {
  const isMounted = useMountedState();
  const onChangeRef = useRef(onChange);

  const {
    isLoading: loadingFolder,
    error: errorFolder,
    fetch: fetchFolders,
  } = useRequest(api.getPhoneBookFolder);

  const {
    isLoading: loadingPhoneBook,
    error: errorPhoneBook,
    fetch: fetchPhoneBooks,
  } = useRequest(api.getPhoneBook);

  const [folders, setFolders] = useState<PhoneBookFolder[]>([]);
  const [folder, setFolder] = useState<PhoneBookFolder | null>(null);

  const [phonebooks, setPhonebooks] = useState<PhoneBookType[]>([]);
  const [phonebook, setPhonebook] = useState<PhoneBookType | null>(null);

  const [firstLoading, setFirstLoading] = useState(true);

  useEffect(() => {
    onChangeRef.current = onChange;
  }, [onChange]);

  useEffect(() => {
    const loadPhonebooks = async () => {
      const filters: MangoFilter = [];
      filters.push({ 'folder.id': folder ? folder.id : null });
      const queryFilter: MangoFilter = filters.length === 1 ? filters[0] : { $and: filters };

      const { phoneBook } = await fetchPhoneBooks({
        filter: JSON.stringify(queryFilter),
        sort: '[{"name": 1}]',
      });
      if (isMounted()) {
        setPhonebooks(phoneBook);
        if (!phoneBook.find((pb: PhoneBookType) => pb.id === phonebook?.id)) {
          setPhonebook(null);
          onChangeRef.current(null);
        }
      }
    };

    isMounted() && !firstLoading && loadPhonebooks();
  }, [folder, isMounted, phonebook?.id, firstLoading, fetchPhoneBooks]);

  useEffect(() => {
    const init = async () => {
      const { phoneBookFolder } = await fetchFolders();

      if (phoneBookId) {
        const filter = JSON.stringify({ id: phoneBookId });
        const { phoneBook } = await fetchPhoneBooks({
          filter,
          limit: 1,
        });
        const pb = phoneBook[0];
        if (pb.folder?.id) {
          const f = phoneBookFolder.find((fl: PhoneBookFolder) => pb.folder.id === fl.id) || null;
          isMounted() && setFolder(f);
        }

        isMounted() && setPhonebook(pb);
      }

      isMounted() && setFolders(phoneBookFolder);

      setFirstLoading(false);
    };

    isMounted() && firstLoading && init();
  }, [phoneBookId, firstLoading, isMounted, fetchPhoneBooks, fetchFolders]);

  return <Stack spacing={3}>
    <Combobox<PhoneBookFolder>
      title={s('Folder')}
      options={folders}
      value={folder}
      onChange={(e, v) => setFolder(v)}
      optionValue="name"
      optionKey="id"
      loading={loadingFolder}
      disabled={loadingFolder}
      error={!!errorFolder}
      helperText={errorFolder && s('Error loading a folders list')}
      sx={{ width: '600px' }}
    />
    <Combobox<PhoneBookType>
      title={s('Phone book')}
      options={phonebooks}
      value={phonebook}
      onChange={(e, v) => {
        setPhonebook(v);
        onChangeRef.current(v?.id || null);
      }}
      optionValue="name"
      optionKey="id"
      loading={loadingPhoneBook}
      disabled={loadingPhoneBook}
      error={!!errorPhoneBook}
      helperText={(errorPhoneBook && s('Error loading a phone books list'))}
      sx={{ width: '600px' }}
    />
  </Stack>;
}

type PhoneBookProps = {
  phoneBookId: number | null
  onChange: (v: number | null) => void
  disabled?: boolean
};
