import axios, { AxiosRequestConfig } from 'axios';
import { DocumentNode, print } from 'graphql';
import { useCallback } from 'react';
import { GetDataParams, GetDataResult } from '@xeebi/neru';
import * as CONFIG from 'shared-scope/config';

export function fetcher<R, V>(
  doc: DocumentNode,
  vars?: V,
  options?: AxiosRequestConfig,
): Promise<R> {
  const query = print(doc);

  return axios
    .post(
      `${CONFIG.get('graphql') as string}`,
      {
        query,
        variables: vars,
      },
      options || {},
    )
    .then(({ data }) => {
      if (data.errors) {
        const { message } = data.errors[0];
        console.log({
          query,
          variables: vars,
        });
        throw new Error(message);
      }
      return data.data;
    })
    .catch((e) => {
      if (e?.data?.errors) {
        throw new Error(e.data.errors[0]);
      }
      throw e;
    });
}

type AsyncApi<T> = (...p:any) => Promise<T>;

export function useGridFetcher<T>(
  api: AsyncApi<T>,
  resolver: (p: T) => GetDataResult,
) {
  return useCallback<(props: GetDataParams) => Promise<GetDataResult>>(({
    filter, sort, limit, offset, ...other
  }) => (
    api({
      filter: filter ? JSON.stringify(filter) : null,
      sort: sort ? JSON.stringify(sort) : null,
      limit: limit || 0,
      offset: offset || 0,
      ...other,
    })
  )
    // eslint-disable-next-line react-hooks/exhaustive-deps
    .then(resolver), [api]);
}
