import {
  Stack,
  Typography,
} from '@mui/material';
import moment from 'moment-timezone';


const COLUMN = 'column';

export default function DateTime(
  {
    value,
    direction = 'column',
  }: DateTimeProps) {
  return (
    <Stack
      className="text-nowrap"
      direction={direction}
      spacing={direction === COLUMN ? 0 : 1}
      alignItems={direction === COLUMN ? 'start' : 'center'}
      sx={{ height: '100%' }}
    >
      <Typography>
        {value ? moment(value).format('YYYY-MM-DD') : ''}
      </Typography>
      <Typography color="text.secondary">
        {value ? moment(value).format('HH:mm:ss') : ''}
      </Typography>
    </Stack>
  );
}

interface DateTimeProps {
  value: string
  direction?: 'row' | 'column'
}
