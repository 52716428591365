import { Typography } from '@mui/material';
import { s } from 'i18n';
import {
 useEffect,
} from 'react';
import useAlert from 'shared-scope/hooks/useAlert';
import { useRequest } from '@xeebi/neru';
import { RequestErrorCode } from 'shared-scope/RequestError';
import { uploadSource } from '../request';
import { SourceCampaignValue } from '../types';

const SOURCE_TYPE = 'source-campaign';

export default function SourceCampaign({
 onChange, campaignId, useParticipants,
}: SourceCampaignProps) {
  const {
    error,
    fetch,
  } = useRequest(uploadSource);
  const { addError } = useAlert();

  useEffect(() => {
    const uploadCampaign = async () => {
      const result = await fetch({
        source_type: SOURCE_TYPE,
        campaign_id: campaignId,
        useParticipants,
      });
      onChange({
        data: result.data,
        source_type: SOURCE_TYPE,
        campaign_id: campaignId,
        campaign_source_type: SOURCE_TYPE,
      });
    };
    campaignId && uploadCampaign();
  }, [onChange, campaignId, fetch, useParticipants]);

  useEffect(() => {
    error
    && error.getCode() !== RequestErrorCode.cancelled
    && addError(error.getMessage());
  }, [error, addError]);

  return (
    <Typography variant="subtitle1">{s('The target audience is the same as in the original campaign')}</Typography>
  );
}

type SourceCampaignProps = {
  onChange: (v: SourceCampaignValue) => void
  campaignId: number
  useParticipants: boolean
};
