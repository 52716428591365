import { useCallback, useEffect, useState } from 'react';
import { pick, cloneDeep, omit } from 'lodash';
import { z } from 'zod';

import {
  FormErrors, FormRow, FormRowKey, FormValidateRule, FormValidateRules, IForm,
} from './types';

export default function useForm<Row extends FormRow = FormRow>(): IForm<Row> {
  const [row, setRow] = useState<Row>();
  const [initialRow, setInitialRow] = useState<Partial<Row>>({});
  const [
    validateRules,
    setValidateRules,
  ] = useState<FormValidateRules<Row>>();
  const [errors, setErrors] = useState<FormErrors<Row>>({});


  /**
   * Check if field registered
   */
  const isRegistered = useCallback((name: FormRowKey<Row>) => {
    return !!row && row[name] !== undefined;
  }, [row]);


  /**
   * Register new field
   */
  const register = useCallback(<T = string>(name: FormRowKey<Row>, initial?: T) => {
    const newItem = initialRow[name] !== undefined
      ? initialRow[name] as T
      : initial !== undefined
        ? initial : undefined;

    setRow((r) => ({ ...r, [name]: newItem } as Row));

    return true;
  }, [initialRow]);

  const unregister = useCallback((name: FormRowKey<Row>) => {
    setRow((r) => (r ? omit(r, name) : {}) as Row);
    setValidateRules((r) => (r ? omit(r, name) : {}) as FormValidateRules<Row>);
    setErrors((r) => (r ? omit(r, name) : {}) as FormErrors<Row>);
  }, []);

  /**
   * Set fields value
   */
  const setValue = useCallback(<T = string>(name: FormRowKey<Row>, value: T) => {
    setRow((r) => {
      let newValue = value;
      if (typeof value === 'function') {
        newValue = value(r?.[name]);
      }
      return { ...r, [name]: newValue } as Row;
    });
  }, []);

  /**
   * Set fields validate rule
   */
  const setValidateRule = useCallback(
    (
      name: FormRowKey<Row>,
      validateRule: FormValidateRule<Row>,
    ) => {
      const formRow = cloneDeep(row || {});
      const rule: z.ZodSchema = (validateRule instanceof Function) ? validateRule(formRow as Row, name) : validateRule;
      setValidateRules(
        (r) => ({ ...r, [name]: rule } as FormValidateRules<Row>),
      );
    },
    [row],
  );

  /**
   * Get fields value
   */
  const getValue = useCallback(<T = string>(name: FormRowKey<Row>) => {
    return isRegistered(name) && row ? cloneDeep(row[name]) as T : undefined;
  }, [row, isRegistered]);


  /**
   * Get all form values
   */
  const getRow = useCallback(() => {
    return cloneDeep(row);
  }, [row]);


  /**
   * Get field error
   */
  const getError = useCallback((name: FormRowKey<Row>) => errors[name as keyof FormErrors] || [], [errors]);
  const getErrors = useCallback(() => cloneDeep(errors), [errors]);

  /**
   * Set field error
   */
  const setError = useCallback((name: FormRowKey<Row>, message: string) => {
    setErrors((e) => ({ ...e, [name]: message }));
  }, []);

  const clearError = useCallback(
    (name?: FormRowKey<Row> | FormRowKey<Row>[]) => {
      setErrors((e) => (name ? omit(e, name) : {}));
    }, []);

  /**
   * Set initial form values
   */
  const setInitial = useCallback((initial: Partial<Row>) => {
    setInitialRow(cloneDeep(initial));
  }, []);

  useEffect(() => {
    Object.keys(initialRow).forEach((name) => {
      setValue(name, initialRow[name]);
    });
  }, [initialRow, setValue]);

  /**
   * Validate all forms fields
   */
  const validate = useCallback(() => {
    let newErrors: FormErrors<Row> = {};

    validateRules && Object
      .keys(validateRules || {})
      .forEach((name: FormRowKey<Row>) => {
        const parseResult = validateRules[name].safeParse(row?.[name]);
        if (!parseResult.success) {
          newErrors = { ...newErrors, [name]: parseResult.error.issues };
        }
      });
    setErrors(newErrors);
    return !(newErrors && Object.keys(newErrors).length);
  }, [validateRules, row]);


  /**
   * Validate some forms fields
   */
  const validateFields = useCallback(
    (names: FormRowKey<Row> | FormRowKey<Row>[], validateBy?: FormValidateRules<Row>) => {
      const validateNames = Array.isArray(names) ? names : [names];
      const rules = validateBy || pick(validateRules, validateNames);

      clearError(names);
      let newErrors: FormErrors<Row> = {};
      Object
        .keys(rules || {})
        .forEach((name: FormRowKey<Row>) => {
          const parseResult = rules[name]?.safeParse(row?.[name]);
          if (parseResult && !parseResult.success) {
            newErrors = { ...newErrors, [name]: parseResult.error.issues };
          }
        });
      setErrors((e) => ({ ...e, ...newErrors }));
      return !(newErrors && Object.keys(newErrors).length);
    }, [validateRules, row, clearError]);

  return {
    isRegistered,
    register,
    unregister,
    setValue,
    getValue,
    getRow,
    getError,
    getErrors,
    setError,
    clearError,
    setInitial,
    setValidateRule,
    validate,
    validateFields,
  };
}

