import { QuestionsTemplate } from 'products/common/types';
import validateQuestions from 'products/common/CampaignShared/helpers/validateQuestions';
import validateOptInTemplate from 'products/common/CampaignShared/helpers/validateOptInTemplate';
import TemplateType from 'products/Templates/types';


export default function validateTemplate(templateType: TemplateType, questions: QuestionsTemplate) {
  const e: FormErrors = {};

  let result;
  if (templateType === TemplateType.Poll) {
    result = validateQuestions(questions);
  } else {
    result = validateOptInTemplate(questions);
  }
  const {
    questions: validQuestions,
    error: questionsError,
  } = result;

  questionsError && Object.assign(e, { questions: questionsError });

  return {
    error: e,
    validQuestions,
  };
}

type FormErrors = {
  templateName?: string
  initialMessage?: string
  result?: string
  questions?: string[]
};
