import Page from 'layout/Page';
import { s } from 'i18n';
import { Stepper, Step } from 'shared-scope/components/Stepper';
import { useNavigate } from 'react-router-dom';
import React, { useCallback, useEffect } from 'react';
import { fetcher } from 'graphql-api/fetcher';
import { FormProvider, useForm } from 'shared-scope/components/Form';
import useAlert from 'shared-scope/hooks/useAlert';
import { useRequest } from '@xeebi/neru';
import { Actions, StepGeneral, StepGeneralRow } from 'products/shared/Campaign/steps';
import { getSdk } from '../query.generated';

const api = getSdk(fetcher);
const back = '/phone-book';

export default function NewFolder() {
  const form = useForm<NewFolderRow>();
  const navigate = useNavigate();
  const { addSuccess, addError } = useAlert();
  const {
    isLoading, error, fetch: createFolder, result,
  } = useRequest(api.phoneBookFolderSave);

  const onSubmit = useCallback(async (row: NewFolderRow) => {
    await createFolder({ input: { name: row.title } });
  }, [createFolder]);

  useEffect(() => {
    error && addError(s('Error creating a new folder'));
  }, [error, addError]);

  useEffect(() => {
    result && addSuccess(s('New folder added successfully'));
    result && navigate(`/phone-book/${result?.phoneBookFolderSave?.[0]?.id}`);
  }, [result, addSuccess, navigate]);

  return (
    <Page title={s('Create folder')} back={back} loading={isLoading}>
      <FormProvider form={form} onSubmit={onSubmit}>
        <Stepper>
          <Step label={s('General')}>
            <StepGeneral titleFieldName={s('Folder name')} noRoute />
          </Step>
        </Stepper>
        <Actions
          submitText={s('Create folder')}
          onCancel={() => navigate(back)}
        />
      </FormProvider>
    </Page>
  );
}

type NewFolderRow = StepGeneralRow;
