import { memoize } from 'lodash';
import { fetcher } from 'graphql-api/fetcher';
import { getSdk } from '../queries.generated';

const api = getSdk(fetcher);

async function getAttachments() {
  return (await api.getAttachment({
    filter: JSON.stringify({ size: { $ne: null } }),
    sort: '[{"size": 1}, {"name": 1}]',
  })).attachment;
}

export default memoize(getAttachments);
