import React, { useState, useEffect, useCallback } from 'react';
import { s } from 'i18n';
import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Switch,
} from '@mui/material';
import { Role, User } from 'graphql-api';
import Loading from 'shared-scope/components/Loading';
import { Combobox } from '@xeebi/neru';
import { createOrModifyUser } from '../helper';

const AVAILABLE_ROLES = [
  { id: 'agent', name: 'Agent' },
  { id: 'admin', name: 'Admin' },
] as Role[];

type CreateProps = {
  open: boolean
  onClose: () => void
  onComplete: () => void
  roles: Role[]
  user?: User | null
};

export default function UserForm({
  open,
  onClose,
  onComplete,
  user = null,
}: CreateProps) {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState<Role | null>(null);
  const [enabled, setEnabled] = useState(true);
  const [save, setSave] = useState(false);
  const [error, setError] = useState('');

  let isValid = !!(email && username && password && role?.id);
  if (user) {
    isValid = !!(email && username && role?.id);
  }

  const cleanUp = useCallback(() => {
    setUsername('');
    setEmail('');
    setPassword('');
    setRole(null);
    onClose();
  }, [onClose]);

  useEffect(() => {
    if (user) {
      setUsername(user.name || '');
      setEmail(user.email || '');
      setRole(user?.role?.length ? user.role[0] : null);
      setEnabled(user.enabled || false);
    }
  }, [user]);

  useEffect(() => {
    let active = true;
    const process = async () => {
      try {
        if (!save) {
          return;
        }
        setLoading(true);
        if (isValid && role?.id) {
          const userId = user ? user.id : null;
          await createOrModifyUser(username, email, enabled, password, role.id, userId);
        }
        if (active) {
          cleanUp();
          onComplete();
        }
      } catch (e: any) {
        console.error(e);
        if (e?.response?.data?.errors) {
          const errorList: string[] = [];
          e.response.data.errors.map((err: any) => errorList.push(err?.message || ''));
          if (errorList.length > 0) {
            setError(errorList.join('\n'));
          }
        }
      } finally {
        setLoading(false);
        setSave(false);
      }
    };

    save && process();

    return () => {
      active = false;
    };
  }, [save, cleanUp, email, enabled, isValid, onComplete, password, role?.id, user, username]);

  return (
    <Dialog
      open={open}
      onClose={cleanUp}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle id="alert-dialog-title">
        {user ? s('Edit') : s('Create')}
      </DialogTitle>
      <DialogContent sx={{ paddingTop: '24px !important' }}>
        <Stack spacing={4}>
          {error && <Alert severity="error" onClose={() => setError('')}>{error}</Alert>}
          <TextField
            type="text"
            label="Username"
            fullWidth
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
          <TextField
            type="email"
            label="Email"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <TextField
            type="password"
            label="Password"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <Combobox<Role>
            title={s('Role')}
            options={AVAILABLE_ROLES}
            value={role}
            optionKey="id"
            optionValue="name"
            onChange={(event, value) => setRole(value)}
            // isOptionEqualToValue={(option, value) => option.id === value.id}
            inputParams={{
              InputProps: {
                autoComplete: 'new-password',
              },
            }}
          />
          <Stack direction="row" spacing={2}>
            <Typography>{s('Enabled')}</Typography>
            <Switch
              checked={enabled}
              onChange={() => setEnabled(!enabled)}
              disabled={false}
            />
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Box sx={{ width: '100%', p: 2 }}>
          <Stack direction="row" justifyContent="end" spacing={2}>
            <Button
              variant="outlined"
              onClick={cleanUp}
              size="medium"
            >
              {s('Close')}
            </Button>
            {loading
              ? <Loading logo={false} size={40} />
              : (
                <Button
                  variant="contained"
                  disabled={!isValid}
                  onClick={() => setSave(true)}
                  size="medium"
                >
                  {s('Save')}
                </Button>
              )}
          </Stack>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
