import Cookies, { CookieAttributes } from 'js-cookie';
import axios from 'axios';
import * as CONFIG from 'shared-scope/config';

export function getSSIDName() {
  if (CONFIG.get('EUID')) {
    return `ssid-${CONFIG.get('EUID')}`;
  }
  return 'ssid';
}

export const cookieLifeTime = 25920000;

export function setAjaxAuthorization(auth: string): void {
  axios.defaults.headers.common.Authorization = auth;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function initSSID(ssid: string, cookieParams: CookieAttributes) {
  if (ssid) {
    localStorage.setItem(getSSIDName(), ssid);
    setAjaxAuthorization(`Bearer ${ssid}`);
  } else {
    console.log('Reset SSID, stack: ', (new Error())?.stack?.substring(0, 300) || '');
    localStorage.removeItem(getSSIDName());
    Cookies.remove(getSSIDName());
    setAjaxAuthorization('');
  }
}

/**
 * Set SSID
 * @param ssid
 * @param cookieParams
 */
export function setSSID(ssid: string, cookieParams: CookieAttributes): void {
  initSSID(ssid, cookieParams);
}

/**
 * Get SSID
 * @return {*|string}
 */
export function getSSID(): string {
  return localStorage.getItem(getSSIDName()) || '';
}
