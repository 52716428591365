import { Alert, useAppContext } from 'shared-scope/AppContext';
import { useCallback } from 'react';

const useAlert = () => {
  const { alerts, setAlerts } = useAppContext();

  const addAlerts = useCallback((alert: Alert | Alert[]) => {
    const newAlerts = Array.isArray(alert) ? alert : [alert];
    setAlerts((a) => a.concat(newAlerts));
  }, [setAlerts]);

  const addInfo = useCallback((message: string) => {
    addAlerts({ message, type: 'info' });
  }, [addAlerts]);
  const addError = useCallback((message: string) => {
    addAlerts({ message, type: 'error' });
  }, [addAlerts]);
  const addSuccess = useCallback((message: string) => {
    addAlerts({ message, type: 'success' });
  }, [addAlerts]);
  const addWarning = useCallback((message: string) => {
    addAlerts({ message, type: 'warning' });
  }, [addAlerts]);
  const removeAlert = useCallback((id: string) => {
    const newAlerts = alerts.filter((alert) => alert.id !== id);
    setAlerts(newAlerts);
  }, [alerts, setAlerts]);

  return {
    alerts, setAlerts, addAlerts, addInfo, addWarning, addError, addSuccess, removeAlert,
  };
};

export default useAlert;
