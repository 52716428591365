import {
  Chip,
  Icon,
  Stack,
  Typography, Box,
} from '@mui/material';
import React, {
  useEffect,
  useState,
} from 'react';
import moment from 'moment-timezone';
import { Message } from 'products/CampaignList/components/Message';
import { UID } from 'shared-scope/helpers/functions';
import { MessageType } from '../types';

export default function Phone({
  chat,
  height = 600,
}: PhoneProps) {
  const [time, setTime] = useState(getTime(new Date()));
  const [msgDates, setMsgDates] = useState<string[]>([]);

  useEffect(() => {
    const timerId = setInterval(() => {
      setTime(getTime(new Date()));
    }, 10_000);
    return () => clearInterval(timerId);
  }, []);

  useEffect(() => {
    const dates = chat.map((m) => {
      const d = m.timestamp ? moment(m.timestamp).format('MMMM DD') : '';
      return d;
    });
    setMsgDates(dates.filter((d, index, array) => d && array.indexOf(d) === index));
  }, [chat]);

  return (
    <Box
      sx={{
        borderRadius: '32px 32px 0px 0px',
        border: '12px solid black',
        borderBottom: 'none',
        maxWidth: '375px',
        minWidth: '300px',
        width: '100%',
        pl: 2,
        pr: 2,
      }}
    >
      <Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ mb: 2 }}
        >
          <Box
            sx={{
              width: 75,
              alignSelf: 'center',
            }}
          >
            <Typography
              fontSize={12}
              fontWeight={600}
            >
              {time}
            </Typography>
          </Box>
          <Box
            sx={{
              width: 176,
              backgroundColor: 'black',
              borderRadius: '0px 0px 32px 32px',
            }}
          />
          <Stack direction="row" alignItems="center" justifyContent="center" sx={{ width: 75 }}>
            <Icon className="material-icons" fontSize="small">signal_cellular_alt</Icon>
            <Icon className="material-icons" fontSize="small">wifi</Icon>
            <Icon className="material-icons" sx={{ transform: 'rotate(90deg)' }}>battery_5_bar</Icon>
          </Stack>
        </Stack>
      </Box>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: `${height}px`,
          overflow: 'auto',
        }}
      >
        <Stack
          sx={{
            minHeight: '250px',
            height: '100%',
            overflow: 'auto',
          }}
          flexGrow={1}
        >
          {msgDates.length > 0
            ? (
              msgDates.map((date) => (
                <React.Fragment key={date}>
                  <Chip
                    key={UID()}
                    variant="outlined"
                    label={<Typography color="text.secondary" variant="subtitle1">{date}</Typography>}
                    sx={{
                      width: 'auto',
                      alignSelf: 'center',
                    }}
                  />
                  {chat
                    .filter((m) => {
                      const msgDate = m.timestamp ? moment(m?.timestamp).format('MMMM DD') : '';
                      return msgDate === date;
                    })
                    .map((m) => (
                      <Message key={UID()} message={m} />
                    ))}
                </React.Fragment>
              ))
            )
            : (
              chat.map((m) => (
                <Message key={UID()} message={m} />
              ))
            )}
        </Stack>
      </Box>
    </Box>
  );
}

type PhoneProps = {
  chat: MessageType[]
  height?: number
};

const getTime = (d: Date) => `${String(d.getHours()).padStart(2, '0')}:${String(d.getMinutes()).padStart(2, '0')}`;

