import {
  Box,
  Typography,
} from '@mui/material';
import React, {
} from 'react';


export type KeywordProps = {
  keyword: string
};

export function Keyword({ keyword }: KeywordProps) {
  return (
    <Box
      sx={({ palette }) => (
        {
          borderRadius: '100px',
          border: `1px solid ${palette.muted.main}`,
          pl: 1,
          pr: 1,
        }
      )}
    >
      <Typography variant="subtitle1">
        {keyword}
      </Typography>
    </Box>
  );
}
