import {
 Button, Dialog, DialogActions, DialogContent, DialogTitle, ImageList, Stack, Typography,
} from '@mui/material';
import { s } from 'i18n';
import Loading from 'shared-scope/components/Loading';
import { formatFileSize } from 'products/common/helpers';
import React, { useState } from 'react';
import { Attachment } from 'graphql-api';
import { isUndefined } from 'lodash';
import AttachmentItem from './AttachmentItem';

export default function AttachDialog(
  {
    open,
    attachmentList,
    selected,
    onSelect,
    onDelete,
    onUpload,
    onClose,
    loadingList,
    loadingFile,
    maxFileSize,
  }: AttachDialogProps) {
  const [selectedAttachment, setSelectedAttachment] = useState<string | null>(selected);
  const checkSize = !isUndefined(maxFileSize);

  return <Dialog
    disableEscapeKeyDown
    open={open}
    fullWidth
    maxWidth="lg"
  >
    <DialogTitle>{s('Attach file')}</DialogTitle>
    <DialogContent dividers>
      <Stack spacing={2} width="100%">
        <Stack direction="row" justifyContent="space-between" height="48px" alignItems="center">
          <Typography variant="h3">{s('Select file to attach')}</Typography>
        </Stack>
        {loadingList ? <Loading logo={false} size={40} /> : (
          <ImageList cols={4}>
            {attachmentList.map((attachmentItem) => (
              <AttachmentItem
                key={attachmentItem.id}
                attachment={attachmentItem}
                selected={selectedAttachment === attachmentItem.id}
                onClick={() => (selectedAttachment === attachmentItem.id
                  ? onSelect(selectedAttachment)
                  : setSelectedAttachment(attachmentItem.id || null)
                )}
                onDelete={() => {
                  attachmentItem.id && onDelete(attachmentItem.id);
                  selectedAttachment === attachmentItem.id && setSelectedAttachment(null);
                }}
                disabled={checkSize && (!attachmentItem.size || (attachmentItem.size > maxFileSize))}
                error={checkSize
                  ? s('The file size should be no larger than :size for this route',
                    { size: formatFileSize(maxFileSize || 0) })
                  : ''}
              />
            ))}
          </ImageList>
        )}
      </Stack>
    </DialogContent>
    <DialogActions sx={{ padding: '8px 24px' }}>
      <Stack direction="row" sx={{ width: '100%' }} justifyContent="space-between">
        <Stack direction="row" spacing={2} justifyContent="flex-start" textAlign="center">
          {
            loadingFile ? <Loading logo={false} size={40} /> : (
              <Button
                component="label"
                variant="contained"
                color="primary"
              >
                {s('Upload file...')}
                <input
                  accept="*/*"
                  hidden
                  type="file"
                  onInput={(e: any) => {
                    if (e.target.files && e.target.files[0]) {
                      onUpload(e.target.files[0]);
                    }
                  }}
                />
              </Button>
            )
          }
          {
            checkSize ? <Typography variant="body1" sx={{ alignSelf: 'center' }}>
              {s('Max file size: :filesize', { filesize: formatFileSize(maxFileSize || 0) })}
            </Typography> : null
          }

        </Stack>

        <Stack direction="row" spacing={2} justifyContent="flex-end" alignItems="center">
          <Button
            autoFocus
            variant="outlined"
            onClick={() => onClose()}
          >
            {s('Close')}
          </Button>
          <Button
            variant="contained"
            disabled={!selectedAttachment}
            onClick={() => onSelect(selectedAttachment)}
          >
            {s('Attach file')}
          </Button>
        </Stack>
      </Stack>
    </DialogActions>
  </Dialog>;
}

type AttachDialogProps = {
  open: boolean
  attachmentList: Attachment[]
  selected: string | null
  onSelect: (v: string | null) => void
  onDelete: (v: string | null) => void
  onUpload: (v: File) => void
  onClose: () => void
  loadingList: boolean
  loadingFile: boolean
  maxFileSize?: number
};
