import { FormProvider, useForm } from 'shared-scope/components/Form';
import { useNavigate, useParams } from 'react-router-dom';
import useAlert from 'shared-scope/hooks/useAlert';
import { useRequest } from '@xeebi/neru';
import React, {
 useCallback, useEffect, useMemo, useState,
} from 'react';
import {
 FormField, request, SchedulerLabel, Sources,
} from 'products/shared/Campaign';
import {
  Actions,
  StepGeneral,
  StepMessage,
  StepScheduler,
  StepSettingsReadOnly,
  StepTarget,
  NewCampaignRow,
  StepSettings,
} from 'products/shared/Campaign/steps';
import { s } from 'i18n';
import Page from 'layout/Page';
import { Step, Stepper } from 'shared-scope/components/Stepper';
import { CountryCapability } from 'graphql-api';
import { Typography } from '@mui/material';
import { GetCampaignsQuery, getSdk } from 'products/shared/Campaign/queries.generated';
import { fetcher } from 'graphql-api/fetcher';
import getCampaignData from 'products/Campaign/helpers';

const apiCampaign = getSdk(fetcher);
const back = '/campaign';

export default function CloneCampaign({ newCampaign }: { newCampaign?: boolean }) {
  const { cloneId = 0 } = useParams();
  const form = useForm<NewCampaignRow>();
  const navigate = useNavigate();
  const {
    addSuccess,
    addError,
  } = useAlert();

  const {
    isLoading,
    error,
    fetch: createCampaign,
    result,
  } = useRequest(request.createCampaign);
  const {
    isLoading: isLoadingCampaign,
    error: errorCampaign,
    fetch: fetchCampaign,
    result: campaigns,
  } = useRequest<GetCampaignsQuery>(apiCampaign.getCampaigns);

  const setInitial = useMemo(() => form.setInitial, [form]);

  const [schedulerOn, setSchedulerOn] = useState(false);
  const [scheduleTimes, setScheduleTimes] = useState<string | null>(null);

  const onSubmit = useCallback(async (row: NewCampaignRow) => {
    await createCampaign(getCampaignData(row, schedulerOn));
  }, [schedulerOn, createCampaign]);

  const onDraft = useCallback(async () => {
    const row = form.getRow();
    if (row) {
      await createCampaign(getCampaignData(row, schedulerOn, true));
    }
  }, [schedulerOn, createCampaign, form]);

  useEffect(() => {
    error && addError(s('Error creating a new campaign'));
    errorCampaign && addError(errorCampaign.getMessage());
  }, [error, errorCampaign, addError]);

  useEffect(() => {
    result && addSuccess(s('New campaign added successfully'));
    result && navigate(back);
  }, [result, addSuccess, navigate]);

  useEffect(() => {
    cloneId && fetchCampaign({
      filter: JSON.stringify({ id: { $eq: +cloneId } }),
    });
  }, [fetchCampaign, cloneId]);

  const clonedCampaign = useMemo(
    () => (campaigns?.campaign?.length ? campaigns.campaign[0] : null),
    [campaigns?.campaign],
  );

  /**
   * Set form initial for simple fields
   */
  useEffect(() => {
      setSchedulerOn(clonedCampaign?.scheduleEnabled || false);
      clonedCampaign
        && setInitial({
          title: 'COPY ' + (clonedCampaign.name || ''),
          source_type: newCampaign ? Sources.excel : Sources.campaign,
          message: {
            text: clonedCampaign.messageTemplates?.[0]?.text || '',
            attachment: clonedCampaign.attachmentId,
          },
          schedule_times: clonedCampaign.scheduleTimes || '',
          mapping: clonedCampaign.params?.mapping || {},
        });
  }, [clonedCampaign, newCampaign, setInitial]);

  useEffect(() => {
    const st = (form.getValue(FormField.route) as CountryCapability)?.scheduleTimes || null;
    setScheduleTimes(st);
    if (st) {
      setSchedulerOn(true);
    }
  }, [form]);

  return (
    <Page
      title={s('Clone text campaign from :name', { name: clonedCampaign?.name })}
      back={back}
      loading={isLoading || isLoadingCampaign}
    >
      <FormProvider form={form} onSubmit={onSubmit}>
        <Stepper>
          <Step label={s('General')}>
            <StepGeneral
              titleFieldName={s('Campaign name')}
              initial={useMemo(() => (clonedCampaign ? {
                route: clonedCampaign.route || undefined,
                country: clonedCampaign.country || undefined,
              } : undefined), [clonedCampaign])}
            />
          </Step>
          <Step label={s('Target Audience')}>
            <StepTarget
              avail={newCampaign ? [Sources.excel, Sources.text, Sources.phonebook] : [Sources.campaign]}
              campaignId={+cloneId}
              targetType="campaign"
            />
          </Step>
          <Step label={s('Settings')}>
            {newCampaign
              ? (
                <StepSettings
                  sourceType={form.getValue(FormField.sourceType)}
                  sourceValue={form.getValue(FormField.sourceValue) || null}
                />
              )
              : (
                <StepSettingsReadOnly
                  sourceType={form.getValue(FormField.sourceType)}
                  sourceValue={form.getValue(FormField.sourceValue) || null}
                />
              )}
          </Step>
          <Step label={s('Compose your message')}>
            <StepMessage
              mapping={form.getValue(FormField.mapping) || {}}
              sourceValue={form.getValue(FormField.sourceValue)}
              maxAttachmentSize={(form.getValue(FormField.route) as CountryCapability)?.maxAttachmentSize || 0}
              templateId={clonedCampaign?.conversationTemplateId?.id || undefined}
            />
          </Step>
          <Step label={scheduleTimes ? s('Schedule') : <SchedulerLabel value={schedulerOn} onChange={setSchedulerOn} />}>
            {schedulerOn
              ? <StepScheduler
                  mask={scheduleTimes}
                  initialvalue={form.getValue(FormField.scheduleTimes) || scheduleTimes}
              />
              : <Typography variant="subtitle1">{s('Campaign will be sent now')}</Typography>}
          </Step>
        </Stepper>
        <Actions
          submitText={s('Clone text campaign')}
          onDraft={onDraft}
          onCancel={() => navigate(back)}
        />
      </FormProvider>
    </Page>
  );
}
