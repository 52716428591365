import axios from 'axios';
import * as CONFIG from 'shared-scope/config';

export default async function downloadImportError(importId: number) {
  try {
    const resp = await axios.get(`${CONFIG.get('api')}/import/${importId}/errors`);
    const href = URL.createObjectURL(new Blob([resp.data]));
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', `import_${importId}.errors.txt`);
    link.click();
    URL.revokeObjectURL(href);
  } catch (e) {
    console.log(e);
  }
}
