import React, { useCallback, useState } from 'react';
import axios from 'axios';
import * as CONFIG from 'shared-scope/config';
import {
  Box,
  Stack,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
} from '@mui/material';
import Loading from 'shared-scope/components/Loading';
import pp from 'media/images/paypal.svg';
import ppbg from './images/ppbg.svg';


type PayPalProps = {
  open: boolean
  onClose: () => void
};

type QuickButtonProps = {
  amount: number
  disabled: boolean
  setDisabled: Function
};

export const sendQuery = async (amount: number) => {
  try {
    await axios.post(
      `${CONFIG.get('api')}/paypal`,
      { amount },
    );
  } catch (e) {
    console.log(e);
  }
};

function QuickButton({
  amount,
  disabled,
  setDisabled,
}: QuickButtonProps) {
  const [loading, setLoading] = useState(false);
  const send = useCallback(async () => {
    setLoading(true);
    setDisabled(true);
    try {
      await sendQuery(amount);
    } finally {
      setLoading(false);
      setDisabled(false);
    }
  }, [setLoading, amount, setDisabled]);

  return loading
    ? <Loading logo={false} size={40} />
    : (
      <Button
        variant="contained"
        size="small"
        onClick={send}
        disabled={disabled}
      >
        {`$${amount}`}
      </Button>
    );
}

export default function PayPal({ open, onClose }: PayPalProps) {
  const [amount, setAmount] = useState<number>(1);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const send = useCallback(async () => {
    setLoading(true);
    setDisabled(true);
    try {
      await sendQuery(amount);
    } finally {
      setLoading(false);
      setDisabled(false);
    }
  }, [setLoading, amount, setDisabled]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Stack direction="row" spacing={2} alignItems="center">
          <img
            alt="pp"
            src={pp}
            height={28}
          />
          <Typography>PayPal</Typography>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
          <Stack spacing={2}>
            <Stack direction="row" spacing={2}>
              <QuickButton amount={10} disabled={disabled} setDisabled={setDisabled} />
              <QuickButton amount={20} disabled={disabled} setDisabled={setDisabled} />
              <QuickButton amount={50} disabled={disabled} setDisabled={setDisabled} />
            </Stack>
            <Stack direction="row" spacing={2}>
              <QuickButton amount={100} disabled={disabled} setDisabled={setDisabled} />
              <QuickButton amount={200} disabled={disabled} setDisabled={setDisabled} />
              <QuickButton amount={500} disabled={disabled} setDisabled={setDisabled} />
            </Stack>
          </Stack>
          <img
            alt="PP background"
            src={ppbg}
            height={140}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Box sx={{ width: '100%', p: 2 }}>
          <Stack direction="row" justifyContent="space-between" spacing={2}>
            <TextField
              type="number"
              label="Amount"
              sx={{ width: '200px' }}
              value={amount}
              inputProps={{ min: 1 }}
              onChange={(e) => setAmount(e.target?.value ? Number(e.target.value) : 1)}
            />
            {loading
              ? <Loading logo={false} size={40} />
              : (
                <Button
                  variant="contained"
                  disabled={disabled}
                  onClick={send}
                >
                  Submit
                </Button>
              )}
          </Stack>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
