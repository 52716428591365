import { GridActionsCellItem } from '@mui/x-data-grid-pro';
import { Icon } from '@mui/material';
import React from 'react';
import TemplateType from 'products/Templates/types';

export default function NewCampaignByType({
 label, type, action, showInMenu = false,
}: NewCampaignByTypeProps) {
  let iconClass = '';
  switch (type) {
    case TemplateType.Text:
      iconClass = 'icon-send-text';
      break;
    case TemplateType.Poll:
      iconClass = 'icon-send-poll';
      break;
    case TemplateType.OptIn:
      iconClass = 'icon-send-opt-in';
      break;
    default:
      iconClass = '';
  }

  return <GridActionsCellItem
    label={label}
    icon={<Icon color="primary"><i className={iconClass} /></Icon>}
    onClick={action}
    showInMenu={showInMenu}
  />;
}

type NewCampaignByTypeProps = {
  label: string
  type: TemplateType
  action: (e: React.MouseEvent) => void
  showInMenu?: boolean
};
