import {
  Box,
  IconButton,
  Typography,
  Stack, useTheme,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { UID } from 'shared-scope/helpers/functions';
import { useMountedState } from '@xeebi/neru';
import ReactDOM from 'react-dom';
import TagButton from 'shared-scope/components/TagButton';

function Expand({ expand, onClick }: ExpandProps){
  return (
    <IconButton
      size="small"
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
        onClick();
      }}
      sx={{
        borderRadius: 10,
        transform: expand ? 'rotate(180deg)' : 'rotate(360deg)',
        backgroundColor: 'rgba(0, 125, 215, 0)',
        paddingTop: 0,
        paddingBottom: 0,
      }}
    >
      <i className="icon-select" />
    </IconButton>
  );
}

const TEXT_LENGTH = 50;

export default function QuestionButton({
    text,
    onClick,
    isActive,
}: QuestionButtonProps) {
  const [expand, setExpand] = useState(false);
  const [parent, setParent] = useState<HTMLElement | null>(null);
  const [root] = useState<HTMLElement | null>(document.getElementById('root'));
  const [questionId] = useState(UID());

  const isMounted = useMountedState();
  const { palette } = useTheme();

  useEffect(() => {
    if (isMounted()) {
      const parentEl = document.getElementById(questionId) || null;
      setParent(parentEl);
    }
  }, [isMounted, questionId]);

  return (
    <Box id={questionId}>
      <TagButton
        isActive={isActive}
        onClick={onClick}
      >
        <Stack
          spacing={1}
          direction="row"
        >
          <Box sx={{ overflow: 'hidden' }}>
            <Typography variant="caption" whiteSpace="pre-line">
              {`${text.replace('\n', ' ').slice(0, TEXT_LENGTH)}${text.length > TEXT_LENGTH ? ' ...' : ''}`}
            </Typography>
          </Box>
          <Expand expand={expand} onClick={() => setExpand(!expand)} />
        </Stack>

      </TagButton>
      {(expand && parent && root)
        ? (
          ReactDOM.createPortal(
            <Box
              sx={{
                position: 'absolute',
                zIndex: 2000,
                height: 'auto',
                top: parent.getBoundingClientRect().top + window.scrollY,
                left: parent.getBoundingClientRect().left + window.scrollX,
              }}
            >
              <TagButton
                isActive={isActive}
                onClick={onClick}
                border={`1px solid ${palette.muted.main}`}
              >
                <Stack
                  spacing={1}
                  direction="row"
                  alignItems="stretch"
                >
                  <Box>
                    <Typography variant="caption" whiteSpace="pre-line">{text}</Typography>
                  </Box>
                  <Expand expand={expand} onClick={() => setExpand(!expand)} />
                </Stack>

              </TagButton>
            </Box>,
            root,
        ))
        : null}
    </Box>
  );
}

interface QuestionButtonProps {
  text: string
  onClick: () => void
  isActive: boolean
}

type ExpandProps = {
  expand: boolean
  onClick: () => void
};
