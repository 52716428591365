import * as Types from '../graphql-api';

import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
export type GetCountriesQueryVariables = Types.Exact<{
  available?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  filter?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type GetCountriesQuery = { __typename?: 'Query', countryCount?: number | null, country: Array<{ __typename?: 'Country', id?: number | null, name?: string | null, iso2?: string | null }> };

export type GetRoutesQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type GetRoutesQuery = { __typename?: 'Query', routeCount?: number | null, route: Array<{ __typename?: 'Route', id?: number | null, name?: string | null, ratePlan?: { __typename?: 'RatePlan', id?: number | null, name?: string | null } | null }> };

export type GetRouteCountQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type GetRouteCountQuery = { __typename?: 'Query', routeCount?: number | null };

export type GetCampaignStatusesQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type GetCampaignStatusesQuery = { __typename?: 'Query', campaignStatusCount?: number | null, campaignStatus: Array<{ __typename?: 'CampaignStatus', id?: number | null, name?: string | null }> };

export type GetPhoneBooksQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type GetPhoneBooksQuery = { __typename?: 'Query', phoneBookCount?: number | null, phoneBook: Array<{ __typename?: 'PhoneBook', id?: number | null, name?: string | null, folder?: { __typename?: 'PhoneBookFolder', id?: number | null, name?: string | null } | null, country?: { __typename?: 'Country', id?: number | null, name?: string | null } | null }> };

export type GetPhoneBookFoldersQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type GetPhoneBookFoldersQuery = { __typename?: 'Query', phoneBookFolderCount?: number | null, phoneBookFolder: Array<{ __typename?: 'PhoneBookFolder', id?: number | null, name?: string | null }> };

export type SavePhoneBookMutationVariables = Types.Exact<{
  input: Types.PhoneBookInput;
}>;


export type SavePhoneBookMutation = { __typename?: 'Mutation', phoneBookSave: Array<{ __typename?: 'PhoneBook', id?: number | null, name?: string | null } | null> };

export type GetTemplatesQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sort?: Types.InputMaybe<Types.Scalars['String']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetTemplatesQuery = { __typename?: 'Query', conversationTemplateCount?: number | null, conversationTemplate: Array<{ __typename?: 'ConversationTemplate', id?: number | null, name?: string | null, template?: any | null, initialMessage?: string | null, initialAnswer?: string | null, initialAttachment?: { __typename?: 'Attachment', id?: string | null } | null }> };

export type GetAttachmentQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sort?: Types.InputMaybe<Types.Scalars['String']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetAttachmentQuery = { __typename?: 'Query', attachmentCount?: number | null, attachment: Array<{ __typename?: 'Attachment', id?: string | null, name?: string | null, createTs?: any | null, lastUseTs?: any | null, contentType?: string | null, size?: number | null }> };


export const GetCountriesDocument = gql`
    query getCountries($available: Boolean = false, $filter: String = null) {
  country(available: $available, filter: $filter) {
    id
    name
    iso2
  }
  countryCount(available: $available, filter: $filter)
}
    `;
export const GetRoutesDocument = gql`
    query getRoutes($filter: String = null) {
  route(filter: $filter) {
    id
    name
    ratePlan {
      id
      name
    }
  }
  routeCount(filter: $filter)
}
    `;
export const GetRouteCountDocument = gql`
    query getRouteCount($filter: String = null) {
  routeCount(filter: $filter)
}
    `;
export const GetCampaignStatusesDocument = gql`
    query getCampaignStatuses($filter: String = null) {
  campaignStatus(filter: $filter) {
    id
    name
  }
  campaignStatusCount(filter: $filter)
}
    `;
export const GetPhoneBooksDocument = gql`
    query getPhoneBooks($filter: String = null) {
  phoneBook(filter: $filter) {
    id
    name
    folder {
      id
      name
    }
    country {
      id
      name
    }
  }
  phoneBookCount(filter: $filter)
}
    `;
export const GetPhoneBookFoldersDocument = gql`
    query getPhoneBookFolders($filter: String = null) {
  phoneBookFolder(filter: $filter) {
    id
    name
  }
  phoneBookFolderCount(filter: $filter)
}
    `;
export const SavePhoneBookDocument = gql`
    mutation savePhoneBook($input: PhoneBookInput!) {
  phoneBookSave(input: $input) {
    id
    name
  }
}
    `;
export const GetTemplatesDocument = gql`
    query getTemplates($filter: String = null, $sort: String = null, $limit: Int = null, $offset: Int = 0) {
  conversationTemplate(
    filter: $filter
    sort: $sort
    limit: $limit
    offset: $offset
  ) {
    id
    name
    template
    initialMessage
    initialAnswer
    initialAttachment {
      id
    }
  }
  conversationTemplateCount(filter: $filter)
}
    `;
export const GetAttachmentDocument = gql`
    query getAttachment($filter: String = null, $sort: String = null, $limit: Int = null, $offset: Int = 0) {
  attachment(filter: $filter, sort: $sort, limit: $limit, offset: $offset) {
    id
    name
    createTs
    lastUseTs
    contentType
    size
  }
  attachmentCount(filter: $filter)
}
    `;
export type Requester<C = {}> = <R, V>(doc: DocumentNode, vars?: V, options?: C) => Promise<R> | AsyncIterable<R>
export function getSdk<C>(requester: Requester<C>) {
  return {
    getCountries(variables?: GetCountriesQueryVariables, options?: C): Promise<GetCountriesQuery> {
      return requester<GetCountriesQuery, GetCountriesQueryVariables>(GetCountriesDocument, variables, options) as Promise<GetCountriesQuery>;
    },
    getRoutes(variables?: GetRoutesQueryVariables, options?: C): Promise<GetRoutesQuery> {
      return requester<GetRoutesQuery, GetRoutesQueryVariables>(GetRoutesDocument, variables, options) as Promise<GetRoutesQuery>;
    },
    getRouteCount(variables?: GetRouteCountQueryVariables, options?: C): Promise<GetRouteCountQuery> {
      return requester<GetRouteCountQuery, GetRouteCountQueryVariables>(GetRouteCountDocument, variables, options) as Promise<GetRouteCountQuery>;
    },
    getCampaignStatuses(variables?: GetCampaignStatusesQueryVariables, options?: C): Promise<GetCampaignStatusesQuery> {
      return requester<GetCampaignStatusesQuery, GetCampaignStatusesQueryVariables>(GetCampaignStatusesDocument, variables, options) as Promise<GetCampaignStatusesQuery>;
    },
    getPhoneBooks(variables?: GetPhoneBooksQueryVariables, options?: C): Promise<GetPhoneBooksQuery> {
      return requester<GetPhoneBooksQuery, GetPhoneBooksQueryVariables>(GetPhoneBooksDocument, variables, options) as Promise<GetPhoneBooksQuery>;
    },
    getPhoneBookFolders(variables?: GetPhoneBookFoldersQueryVariables, options?: C): Promise<GetPhoneBookFoldersQuery> {
      return requester<GetPhoneBookFoldersQuery, GetPhoneBookFoldersQueryVariables>(GetPhoneBookFoldersDocument, variables, options) as Promise<GetPhoneBookFoldersQuery>;
    },
    savePhoneBook(variables: SavePhoneBookMutationVariables, options?: C): Promise<SavePhoneBookMutation> {
      return requester<SavePhoneBookMutation, SavePhoneBookMutationVariables>(SavePhoneBookDocument, variables, options) as Promise<SavePhoneBookMutation>;
    },
    getTemplates(variables?: GetTemplatesQueryVariables, options?: C): Promise<GetTemplatesQuery> {
      return requester<GetTemplatesQuery, GetTemplatesQueryVariables>(GetTemplatesDocument, variables, options) as Promise<GetTemplatesQuery>;
    },
    getAttachment(variables?: GetAttachmentQueryVariables, options?: C): Promise<GetAttachmentQuery> {
      return requester<GetAttachmentQuery, GetAttachmentQueryVariables>(GetAttachmentDocument, variables, options) as Promise<GetAttachmentQuery>;
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;