import Header from 'layout/Header';
import Body from 'layout/Body';
import {
  Snackbar, Box, Alert, Slide,
} from '@mui/material';
import useAlert from 'shared-scope/hooks/useAlert';
import { SlideProps } from '@mui/material/Slide';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Alert as AlertType, useAppContext } from 'shared-scope/AppContext';
import { fetcher } from 'graphql-api/fetcher';
import { TermsConditions } from 'graphql-api';
import { getSdk } from 'products/common/queries.generated';
import Terms from 'products/TermsConditions';


const api = getSdk(fetcher);

export default function Layout({ children }: PropsWithChildren<LayoutProps>) {
  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState<AlertType | null>(null);
  const [tc, setTc] = useState<TermsConditions | null>(null);
  const appContext = useAppContext();

  const {
    alerts,
    removeAlert,
  } = useAlert();

  useEffect(() => {
    if (!alert && alerts.length && alerts[0].id) {
      setAlert(alerts[0]);
      setOpen(true);
    }
  }, [alerts, alert]);

  useEffect(() => {
    let active = true;
    const loadTc = async () => {
      const { termsConditions } = await api.getTermsConditions({
        filter: JSON.stringify({
          $and: [
            { active: true },
            { acceptTs: null },
          ],
        }),
        sort: JSON.stringify([{ effectiveDate: -1 }]),
      });

      if (active && termsConditions.length > 0) {
        setTc(termsConditions[0]);
      }
    };
    loadTc();

    return () => {
      active = false;
    };
  }, [appContext?.user?.id]);

  return (<>
    <Box sx={{ minWidth: '850px' }}>
      <Header />
      <Body>{children}</Body>
    </Box>
    {
        alert && <Snackbar
          open={open}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          onClose={(event, reason) => {
            if (reason === 'clickaway') {
              return;
            }
            setOpen(false);
            alert?.id && removeAlert(alert.id);
            setAlert(null);
          }}
          autoHideDuration={3_000}
          TransitionComponent={Transition}
        >
          <Alert severity={alert.type}>{alert.message}</Alert>
        </Snackbar>
      }
    {tc
        ? <Terms tcUser={null} tc={tc} />
        : null}
  </>
  );
}

function Transition(props: TransitionProps) {
  return <Slide {...props} direction="down" />;
}

export interface LayoutProps {
}

type TransitionProps = Omit<SlideProps, 'direction'>;

