import {
  Box,
  Stack,
  Grid,
  MobileStepper,
  Typography,
  Button,
} from '@mui/material';
import { s } from 'i18n';
import { useNavigate } from 'react-router-dom';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import {
  useRequest,
  runWithInterval,
  ApiQueryParams,
  ApiQueryResult,
} from '@xeebi/neru';
import { Campaign, GroupQuestion } from 'src/graphql-api';
import { isEqual } from 'lodash';
import { apiCampaign } from 'products/CampaignList/Grid';
import { CampaignStatus } from 'products/CampaignList/types';
import Loading from 'shared-scope/components/Loading';
import { Controller } from 'swiper/modules';
import { Swiper, SwiperSlide, SwiperClass } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { loadGroupVoteStat } from 'products/Details/Poll/GroupAnswer';
import { UID } from 'shared-scope/helpers/functions';
import PieQuestionChart from 'shared-scope/components/PieQuestionChart';
import useAlert from 'shared-scope/hooks/useAlert';
import QuestionButton from './QuestionButton';


const loadPolls = async (params: ApiQueryParams) => {
  params.filter = params.filter === 'null'
    ? '{"$and":[{"isChat":true}, {"type": {"$in": [0, 1, 2, 3, 4, 6]}}]}'
    : `{"$and":[{"isChat":true}, {"type": {"$in": [0, 1, 2, 3, 4, 6]}}, ${params.filter}]}`;
  const { campaignCount, campaign } = await apiCampaign.getVoteSmsCampaigns(params);
  return { count: campaignCount, rows: campaign } as ApiQueryResult;
};

export default function Charts() {
  const navigate = useNavigate();
  const [pollIds, setPollIds] = useState<number[]>([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [item, setItem] = useState<Item | null>(null);
  const [question, setQuestion] = useState<GroupQuestion | null>(null);
  const runnerPoll = useRef({ stop: () => {} });
  const runnerStat = useRef({ stop: () => {} });
  const itemsRef = useRef<{ [key: number]: Item }>({});
  const [swiper, setSwiper] = useState<SwiperClass | null>(null);

  const [needUpdate, setNeedUpdate] = useState(false);

  const { error: pollError, fetch: fetchPolls } = useRequest(loadPolls);
  const { error: statError, fetch: fetchStat } = useRequest(loadGroupVoteStat);
  const { addError } = useAlert();

  const [firstLoading, setFirstLoading] = useState(true);

  useEffect(() => {
    if (pollError) {
      addError(pollError.getMessage());
      console.error(pollError.getError());
    }
    if (statError) {
      addError(statError.getMessage());
      console.error(statError.getError());
    }
  }, [pollError, statError, addError]);

  const getPolls = useCallback(async () => {
    const result = await fetchPolls({
      filter: JSON.stringify({ status: CampaignStatus.Complete }),
      limit: 10,
      sort: JSON.stringify([{ id: -1 }]),
    });
    if (result?.rows) {
      const ids = result.rows.map((r: Campaign) => r.id);
      if (!isEqual(ids, pollIds)) {
        setPollIds(ids);
        setNeedUpdate(true);
      }
      for (const row of result.rows) {
        if (!itemsRef.current[row.id]) {
          itemsRef.current[row.id] = {
            poll: row as Campaign,
            questions: [],
          };
        }
      }
    }
  }, [fetchPolls, pollIds]);

  const getStats = useCallback(async () => {
    if (!pollIds.length) {
      setFirstLoading(false);
      return;
    }

    try {
      /* eslint-disable no-await-in-loop */
      for (const id of pollIds) {
        const statQuestions = await fetchStat(id);
        itemsRef.current[id].questions = statQuestions;
      }
      /* eslint-disable no-await-in-loop */
    } finally {
      setFirstLoading(false);
    }
  }, [fetchStat, itemsRef, pollIds]);

  useEffect(() => {
    if (needUpdate && pollIds.length) {
      const defaultItem = itemsRef.current[pollIds[0]] || null;
      setItem((prevItem) => {
        if (!prevItem) {
          return defaultItem;
        }
        return prevItem;
      });
      setQuestion((prevQuestion) => {
        if (!prevQuestion) {
          return defaultItem.questions?.[0] || null;
        }
        return prevQuestion;
      });
      setNeedUpdate(false);
    }
  }, [needUpdate, itemsRef, pollIds]);

  useEffect(() => {
    const init = async () => {
      try {
        const id = pollIds[0];
        const statQuestions = await fetchStat(id);
        itemsRef.current[id].questions = statQuestions;
        setItem(itemsRef.current[id]);
        setQuestion(itemsRef.current[id].questions?.[0] || null);
      } finally {
        setFirstLoading(false);
      }
    };
    if (firstLoading && pollIds.length) {
      init();
    }
  }, [firstLoading, pollIds, fetchStat]);

  useEffect(() => {
    runnerPoll.current.stop();
    runnerPoll.current = runWithInterval(getPolls, 30_000);
    return () => runnerPoll.current.stop();
  }, [getPolls, runnerPoll]);

  useEffect(() => {
    runnerStat.current.stop();
    runnerStat.current = runWithInterval(getStats, 60_000);
    return () => runnerStat.current.stop();
  }, [getStats, runnerStat]);

  useEffect(() => {
    const index = pollIds.indexOf(item?.poll?.id || 0);
    setQuestion(item?.questions?.[0] || null);
    setActiveIndex(index);
    swiper?.slideTo(index);
  }, [item, pollIds, swiper]);

  return (
    <Stack spacing={2}>
      <Stack direction="row" justifyContent="space-between" spacing={2}>
        <Button
          variant="text"
          onClick={() => navigate(`/poll/${item?.poll.id}/results`)}
        >
          <Typography
            variant="h3"
            sx={{ textDecoration: 'underline' }}
          >
            {item?.poll?.name || ''}
          </Typography>
        </Button>
        <MobileStepper
          variant="dots"
          steps={pollIds.length}
          position="static"
          activeStep={activeIndex}
          sx={{ maxWidth: 300, flexGrow: 1 }}
          nextButton={
            <Button
              size="small"
              onClick={() => {
                const id = pollIds[activeIndex + 1];
                const newItem = itemsRef.current[id];
                setItem(newItem);
              }}
              disabled={activeIndex === (pollIds.length - 1)}
            >
              <KeyboardArrowRight />
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={() => {
                const id = pollIds[activeIndex - 1];
                const newItem = itemsRef.current[id];
                setItem(newItem);
              }}
              disabled={activeIndex < 1}
            >
              <KeyboardArrowLeft />
            </Button>
          }
        />
      </Stack>
      {firstLoading
        ? <Box sx={{ alignSelf: 'center' }}><Loading logo={false} size={160} /></Box>
        : (pollIds.length
          ? (
            <Swiper
              className="mySwiper"
              onSlideChange={({ activeIndex: index }: any) => {
                const id = pollIds[index];
                const newItem = itemsRef.current[id];
                setItem(newItem);
              }}
              modules={[Controller]}
              onSwiper={setSwiper}
              controller={{ control: swiper }}
            >
              {pollIds.map((id) => {
                const poolQuestions = itemsRef.current[id]?.questions || [];
                return (
                  <SwiperSlide key={id}>
                    <Grid container spacing={2} alignItems="stretch" justifyContent="stretch">
                      <Grid item sm={12} lg={5}>
                        <Stack
                          spacing={1}
                          direction="row"
                          flexWrap="wrap"
                          useFlexGap
                          alignItems="start"
                          flexShrink={1}
                        >
                          {poolQuestions.slice(0, 3).map((q) => (
                            <QuestionButton
                              key={UID()}
                              isActive={q.question === question?.question}
                              onClick={() => setQuestion(q)}
                              text={q?.question || ''}
                            />
                          ))}
                        </Stack>
                      </Grid>
                      <Grid item sm={12} lg={7}>
                        {question
                          ? (
                            <PieQuestionChart size={160} question={question} useOther />
                          )
                          : null}
                      </Grid>
                    </Grid>
                  </SwiperSlide>
                );
              })}
            </Swiper>)
          : <Typography variant="h4" align="center">{s('No data')}</Typography>
        )}
    </Stack>
  );
}

type Item = {
  poll: Campaign
  questions: GroupQuestion[]
};
