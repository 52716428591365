import { getSdk } from 'products/Account/query.generated';
import { fetcher } from 'graphql-api/fetcher';
import { ApiQueryParams, ApiQueryResult } from '@xeebi/neru';

const api = getSdk(fetcher);

export const getUsers = async (params: ApiQueryParams) => {
  const { user, userCount } = await api.getUsers(params);
  return { count: userCount, rows: user } as ApiQueryResult;
};

export const getRoles = async () => {
  const response = await api.getRoles();
  return response;
};

export const createOrModifyUser = async (
  name: string,
  email: string,
  enabled: boolean,
  password: string,
  roleId: string,
  userId: number | null = null,
) => {
  const filter = userId ? JSON.stringify({ id: userId }) : null;
  const passwd = password ? { password } : {};

  const response = await api.saveUser({
    filter,
    input: {
      name,
      email,
      enabled,
      role: [roleId],
      ...passwd,
    },
  });
  return response;
};

export const deleteUser = async (userId: number) => {
  const filter = JSON.stringify({ id: userId });
  const response = await api.deleteUser({ filter });
  return response;
};
