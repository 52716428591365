import React, {
  useEffect,
  useState,
} from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import {
  Button,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { s } from 'i18n';
import Page from 'layout/Page';
import { useMountedState } from '@xeebi/neru';


const CAMPAIGN = 'Campaign';
const OPT_IN = 'Opt-in';

export default function OptIn() {
  const location = useLocation();
  const navigate = useNavigate();
  const isMounted = useMountedState();

  const [tab, setTab] = useState<String | null>(null);

  useEffect(() => {
    if (!isMounted()) {
      return;
    }

    if (location.pathname.includes('opt-in-list')) {
      setTab(OPT_IN);
    } else if (location.pathname.includes('campaign-list')) {
       setTab(CAMPAIGN);
    } else {
      navigate('/opt-in/campaign-list');
      setTab(CAMPAIGN);
    }
  }, [navigate, location.pathname, tab, isMounted]);

  return (
    <Page
      title={s('Opt-in')}
      headTools={[
        <Button
          key="create"
          variant="contained"
          onClick={() => {
            navigate('/opt-in/new');
          }}
        >
          {s('Add opt-in keyword')}
        </Button>,
      ]}
    >
      <Stack
        sx={{
          display: 'flex',
          height: '100%',
          width: '100%',
        }}
        spacing={2}
      >
        <ToggleButtonGroup
          value={tab}
          onChange={(e, v) => {
            if (v === OPT_IN) {
              navigate('/opt-in/opt-in-list');
              setTab(v);
            } else if (v === CAMPAIGN) {
              navigate('/opt-in/campaign-list');
              setTab(v);
            }
          }}
          exclusive
          aria-label="charts-interval"
        >
          <ToggleButton key={CAMPAIGN} value={CAMPAIGN}>{s('Keywords')}</ToggleButton>
          <ToggleButton key={OPT_IN} value={OPT_IN}>{s('Opt-ins')}</ToggleButton>
        </ToggleButtonGroup>

        <Outlet />

      </Stack>
    </Page>
  );
}

