import {
  runWithInterval,
  Runner,
  useMountedState,
  GridRefType,
} from '@xeebi/neru';
import {
  useCallback,
  useEffect,
  useState,
  MutableRefObject,
} from 'react';
import { isEqual } from 'lodash';
import { fetcher } from 'graphql-api/fetcher';
import { Import } from 'graphql-api';
import { Box, Stack, Typography } from '@mui/material';
import { ImportStatus } from 'products/common/types';
import ImportItem from 'products/common/CampaignShared/components/ImportItem';
import dayjs from 'dayjs';
import { MangoFilter } from 'shared-scope/types';
import { getSdk } from '../queries.generated';

const api = getSdk(fetcher);
const ACTIVE_INTERVAL = 10_000;
const DEFAULT_INTERVAL = 60_000;

export default function Imports({
  type,
  dstId,
  title = '',
  statusFilter = true,
  last3days = true,
  gridRef = undefined,
}: ImportsProps) {
  const [imports, setImports] = useState<Import[]>([]);
  const [interval, setInterval] = useState(DEFAULT_INTERVAL);
  const [runner, setRunner] = useState<Runner | null>(null);
  const [filteredImports, setFilteredImports] = useState<Import[]>([]);

  const isMounted = useMountedState();

  const loadImports = useCallback(async () => {
    try {
      const defaultStatusFilter = [ImportStatus.Queued, ImportStatus.Importing, ImportStatus.Error, ImportStatus.Complete];
      const statuses = statusFilter === true ? defaultStatusFilter : statusFilter;

      const filter: MangoFilter = [{ type }, { confirmedTs: null }];
      dstId && filter.push({ dstId });
      statuses && filter.push({ status: { $in: statuses } });
      last3days && filter.push({ createTs: { $gte: dayjs().subtract(3, 'days').format('YYYY-MM-DDTHH:mm:ss') } });

      const newImports = await api.getImport({
        filter: JSON.stringify({
          $and: filter,
        }),
        sort: '[{"id": -1}]',
      });

      isMounted() && setImports((prev) => {
        if (!isEqual(prev, newImports.import) && gridRef) {
          gridRef.current.refresh();
        }
        return newImports.import;
      });
    } catch (e) {
      console.error(e);
      setImports([]);
    }
  }, [type, dstId, statusFilter, last3days, isMounted, gridRef]);

  const confirmImport = useCallback(async (id: number) => {
    try {
      id && await api.confirmImport({ id });
    } catch (e) {
      console.error(e);
    } finally {
      loadImports();
    }
  }, [loadImports]);

  //Set interval
  useEffect(() => {
    const hasActiveImports = imports.some(
      (imp: Import) => imp.status && [ImportStatus.Queued, ImportStatus.Importing].includes(imp.status),
    );
    setInterval(hasActiveImports ? ACTIVE_INTERVAL : DEFAULT_INTERVAL);
  }, [imports]);

  //Auto update
  useEffect(() => {
    runner?.stop();
    setRunner(runWithInterval(loadImports, interval));

    return () => {
      runner?.stop();
    };

    // don't need runner in deps list
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interval, loadImports]);

  useEffect(() => {
    setFilteredImports(imports.filter((i) => (i?.rowsError || 0) > 0 || [ImportStatus.Queued, ImportStatus.Importing].includes(i?.status || -1)));
  }, [imports]);

  return filteredImports.length
    ? (
      <Box
        sx={{
          width: '100%',
          border: 1,
          borderRadius: '8px',
          padding: 2,
        }}
      >
        <Stack spacing={1}>
          <Typography variant="h4">{title}</Typography>
          {filteredImports.map((imp) => (
            imp.id && <ImportItem
              data={imp}
              onClose={() => confirmImport(imp.id || 0)}
              key={imp.id}
            />
          ))}
        </Stack>
      </Box>
    )
    : null;
}

type ImportsProps = {
  title?: string
  type: number
  dstId?: number
  statusFilter?: boolean | ImportStatus[]
  last3days?: boolean
  gridRef?: MutableRefObject<GridRefType>
};
