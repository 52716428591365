declare global {
  interface Window {
    BASENAME: string
  }
  interface String {
    format(...args: (string | number | boolean)[]): string
    padZero(length: number): string
    capitalizeFirstLetter(): string
  }
  interface Date {
    format(date_format: string): string
  }
  interface Number {
    indexOfComma(delimiter: number): 1 | 2 | false
    shortFormNumberStr(): number | strign
  }
  const PUBLIC_PATH: string;
}

// eslint-disable-next-line no-extend-native,func-names
Date.prototype.format = function (date_format) {
  date_format = date_format || 'mm/dd/yyyy H2:M2';
  const map = { // 1 Dec 2014: new Date(2014, 11, 1).format("yyyy yy MMM Mmm MM Mm mm DDD Ddd DD Dd dd d")
    yyyy: this.getFullYear(), // 2014
    yy: String(this.getFullYear()).substr(2, 2), // 14
    mm: ('0' + (this.getMonth() + 1)).slice(-2), // 12
    dd: ('0' + this.getDate()).slice(-2), // 01
    d: this.getDate(), // 1
    H2: ('0' + this.getHours()).slice(-2),
    H: this.getHours(),
    M2: ('0' + this.getMinutes()).slice(-2),
    M: this.getMinutes(),
    SS: this.getSeconds(),
  };
  const re = new RegExp(Object.keys(map).join('|'), 'gi');
  date_format = date_format.replace(re, (matched) => {
    return map[matched];
  });
  return date_format;
};

// eslint-disable-next-line no-extend-native,func-names
String.prototype.format = function (...args: (string | number | boolean)[]) {
  let index = 0;
  if (args.length) {
    return this.replace(/(%s)/g, (str, p1) => {
      // eslint-disable-next-line no-plusplus
      return str.replace(p1, args[index++]);
    });
  }
  return this;
};

String.prototype.capitalizeFirstLetter = function capitalizeFirstLetter() {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

// eslint-disable-next-line no-extend-native,func-names
String.prototype.padZero = function (length: number) {
  let d = String(this);
  while (d.length < length) {
    d = '0' + d;
  }
  return d;
};

Number.prototype.indexOfComma = function indexOfComma(delimiter = 1) {
  const numberLength = (Math.ceil(this / delimiter)).toString().length;
  if (numberLength === 5) {
    return 2;
  }
  if (numberLength === 4) {
    return 1;
  }
  return false;
};

Number.prototype.shortFormNumberStr = function shortFormNumberStr() {
  if (this < 1000) {
    return this;
  }
  const isMillion = this >= 1_000_000;
  const index = this.indexOfComma(isMillion ? 1000 : 1);
  const shortNumber = this.toString().slice(0, 3);
  const numberWithComma = index ? `${shortNumber.slice(0, index)}.${shortNumber.slice(index)}` : shortNumber;
  return `${numberWithComma}${isMillion ? 'M' : 'K'}`;
};

// console.log('hi'.padZero(5));
// console.log(String.prototype.format.toString());
// console.log(('hi %s').format('Tom'));

export {};
