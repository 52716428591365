import {
  Box, Grid, Stack, Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { s } from 'i18n';
import { PieChart as MuiPieChart } from '@mui/x-charts/PieChart';


export default function PieChart({
    data,
    size,
    report = false,
    skipAnimation = false,
}: PieChartProps) {
  const [active, setActive] = useState<number>(0);

  useEffect(() => {
    setActive(data.reduce((acc, row) => acc + row.value, 0));
  }, [data]);

  const lineHeight = data.length > 6 ? 1.2 : 1.2;
  let fontSize = 14;
  if (data.length <= 6) {
    fontSize = 14;
  } else if (data.length < 10) {
    fontSize = 12;
  } else if (data.length <= 20) {
    fontSize = 11;
  } else {
    fontSize = 10;
  }
  return (
    <Stack
      spacing={2}
      direction="row"
      justifyContent="center"
      alignItems="center"
      flexGrow={1}
      sx={{ width: '100%' }}
    >
      <Box sx={{ height: size, width: size }}>
        <MuiPieChart
          series={[
            {
              data,
              highlightScope: { faded: 'global', highlighted: 'item' },
              startAngle: -270,
              endAngle: 90,
              outerRadius: (size / 2) - 5,
              cx: size / 2,
              cy: size / 2,
              valueFormatter: ({ value }) => {
                return `${((value / active) * 100).toFixed(0)}%`;
              },
            },
          ]}
          slotProps={{
            legend: {
              hidden: true,
            },
            noDataOverlay: {
              message: 'No data',
            },
          }}
          height={size}
          width={size}
          skipAnimation={skipAnimation}
        />
      </Box>
      <Stack
        spacing={1}
        sx={{
          width: '100%',
          overflow: 'hidden',
        }}
      >
        {data.map((row) => {
          const settings = report
            ? { lg: 2 }
            : { lg: 2, xl: 1 };
          return (
            <Stack
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: '100%',
              }}
              key={row.id}
            >
              <Grid
                container
                direction="row"
                columnSpacing={1}
                alignItems="center"
              >
                <Grid item xl={1}>
                  <Box
                    sx={{
                      width: 12,
                      height: 12,
                      backgroundColor: row.color,
                    }}
                  />
                </Grid>
                <Grid item {...settings}>
                  <Typography id={row.label} fontSize={fontSize} fontWeight={400} variant="h5" lineHeight={lineHeight}>
                    {`${((row.value / active) * 100).toFixed(0)}%`}
                  </Typography>
                </Grid>
                <Grid item {...settings}>
                  <Typography fontSize={fontSize} fontWeight={600} variant="subtitle1" lineHeight={lineHeight}>
                    {row.value.toLocaleString('en-US')}
                  </Typography>
                </Grid>
                <Grid item lg xl>
                  <Typography id={s(row.label)} fontSize={fontSize} fontWeight={400} variant="h5" lineHeight={lineHeight}>
                    {s(row.label)}
                  </Typography>
                </Grid>
              </Grid>

              {row.answers
                ? (
                  <Typography
                    color="text.secondary"
                    variant="subtitle1"
                    noWrap
                    sx={{ paddingLeft: '20px' }}
                    lineHeight={lineHeight}
                    fontSize={fontSize - 2}
                  >
                    {row.answers}
                  </Typography>
                )
                : null}
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
}

export type PieValue = {
  id: any
  value: number
  label: string
  color: string
  answers?: string
};

type PieChartProps = {
  size: number
  data: PieValue[]
  report?: boolean
  skipAnimation?: boolean
};
