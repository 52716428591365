import { s } from 'i18n';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';
import React, {
  useEffect,
  useState,
} from 'react';
import { fetcher } from 'graphql-api/fetcher';
import { Campaign, GroupQuestion } from 'graphql-api';
import WhiteBox from 'shared-scope/components/Box/WhiteBox';
import Loading from 'shared-scope/components/Loading';
import { Question } from 'products/Details/Poll/GroupAnswer/Question';
import { getSdk } from 'products/Details/Poll/query.generated';


const api = getSdk(fetcher);

export const loadGroupVoteStat = async (campaignId: number) => {
  const { groupVoteStat } = await api.getGroupVoteStat({ campaignId });
  return (groupVoteStat?.questions || []) as GroupQuestion[];
};

export type GroupAnswerProps = {
  data: Campaign | null
};

export function GroupAnswer({ data }: GroupAnswerProps) {
  const [questions, setQuestions] = useState<GroupQuestion[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getQuestions = async () => {
      if (!data?.id) {
        return;
      }
      setLoading(true);
      try {
        const result = await loadGroupVoteStat(data.id);
        setQuestions(result);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };
    getQuestions();
  }, [data?.id]);

  return (
    <WhiteBox sx={{ height: '100%' }}>
      <Stack
        spacing={1}
        direction="column"
      >
        <Typography variant="h3">{s('Results')}</Typography>

        {loading
          ? <Loading logo={false} size={150} />
          : null}

        {questions.map((q) => <Question {...q} key={q.position} />)}

      </Stack>
    </WhiteBox>
  );
}
