import { FormRowsErrors } from 'shared-scope/components/Form/types';
import { QuestionError } from 'products/common/types';

const getMessageError = (formErr: FormRowsErrors): QuestionError => {
  const qError: QuestionError = {
    question: '',
    attachment: '',
    reply: '',
  };

  formErr.forEach((e) => {
    if (!e.path.length || e.path.includes('text')) {
      qError.question = e.message;
    }
    if (e.path.includes('attachment') && !qError.attachment) {
      qError.attachment = e.message;
    }
    if (e.path.includes('answer')) {
      qError.reply = e.message;
    }
  });

  return qError;
};

export default getMessageError;
