import React, {
  useCallback, useEffect, useState,
} from 'react';
import { AxiosRequestConfig } from 'axios';
import { s } from 'i18n';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { Campaign } from 'graphql-api';
import WhiteBox from 'shared-scope/components/Box/WhiteBox';
import { GridColDef, GridHeaderFilterCellProps } from '@mui/x-data-grid-pro';
import {
  IN_PROGRESS,
  COMPLETED,
  OPTED_OUT,
  ERROR,
  EXPORTED,
  OPT_IN_CHART_STATUSES,
} from 'products/Details/Poll/helpers';
import { Phone } from 'products/shared/Campaign/components';
import { MessageType } from 'products/shared/Campaign/types';
import {
  useGridRef,
  FilterDateRange,
  FilterDropdown,
  DataGrid,
  ApiQueryParams,
  ApiQueryResult, useRequest, runWithInterval,
} from '@xeebi/neru';
import { phoneNumberFormat } from 'shared-scope/helpers/functions';
import { fetcher } from 'graphql-api/fetcher';
import { getSdk } from 'products/OptIn/query.generated';
import DateTime from 'shared-scope/components/DateTime';
import Export from './Export';


const api = getSdk(fetcher);

const columns: GridColDef[] = [
  {
    field: 'phoneNumber',
    headerName: 'Phone number',
    type: 'string',
    sortable: true,
    filterable: true,
    disableColumnMenu: true,
    minWidth: 160,
    valueFormatter: (value) => phoneNumberFormat(value),
  },
  {
    field: 'status',
    headerName: s('Status'),
    disableColumnMenu: true,
    type: 'custom',
    renderHeaderFilter: (params: GridHeaderFilterCellProps) => (
      <FilterDropdown
        {...params}
        options={[
          {
            id: IN_PROGRESS,
            name: 'In progress',
          },
          {
            id: COMPLETED,
            name: 'Completed',
          },
          {
            id: OPTED_OUT,
            name: 'Opted out',
          },
          {
            id: ERROR,
            name: 'Error',
          },
          {
            id: EXPORTED,
            name: 'Completed (Exported)',
          },
        ]}
        keyName="id"
        valueName="name"
        placeholder={s('Status')}
        minWidth={150}
      />
    ),
    renderCell: ({ value }) => (
      <Stack justifyContent="center" sx={{ height: '100%' }}>
        <Typography>{OPT_IN_CHART_STATUSES[value].label}</Typography>
      </Stack>

    ),
    minWidth: 180,
    maxWidth: 250,
  },
  {
    field: 'timestamp',
    headerName: s('Timestamp'),
    disableColumnMenu: true,
    type: 'custom',
    renderHeaderFilter: (params: GridHeaderFilterCellProps) => (
      <FilterDateRange
        {...params}
        placeholder={s('Start date')}
      />
    ),
    valueGetter: (value) => (value ? new Date(value) : null),
    renderCell: ({ value }) => <DateTime value={value} direction="row" />,
    minWidth: 180,
    maxWidth: 250,
  },
];

export type MessagesProps = {
  data: Campaign | null
};

export function Messages({ data }: MessagesProps) {
  const gridRef = useGridRef();
  const [chat, setChat] = useState<MessageType[]>([]);

  const [rowId, setRowId] = useState<null | number>(null);

  const { fetch } = useRequest(api.getOptInList);

  const getChat = useCallback(async () => {
    if (!rowId) {
      return;
    }
    const { optInList } = await fetch({
      filter: JSON.stringify({ id: rowId }),
    });
    const conversation = optInList?.[0]?.conversation?.chat?.conversation || null;
    setChat(conversation);
  }, [fetch, rowId]);

  useEffect(() => {
    if (!rowId) {
      return () => {};
    }
    const runner = runWithInterval(getChat, 10_000);
    return () => runner.stop();
  }, [getChat]);

  const getRecords = useCallback(async (params: ApiQueryParams, config: AxiosRequestConfig) => {
    const gridFilter = params.filter === 'null' ? '' : `, ${params.filter}`;
    params.filter = `{"$and":[{"campaign.id": ${data?.id}}${gridFilter}]}`;
    const { optInList, optInListCount } = await api.getOptInList(params, config);
    return { count: optInListCount, rows: optInList } as ApiQueryResult;
  }, [data?.id]);

  const ExportTool = useCallback(() => (
    <Export
      campaignId={data?.id || -1}
      gridRef={gridRef}
    />
  ), [gridRef, data?.id]);

  return (
    <WhiteBox>
      <Stack direction="row" justifyContent="space-between" spacing={2} useFlexGap flexWrap="wrap">
        <Box
          sx={{
            maxWidth: 700,
            display: 'flex',
          }}
        >
          <DataGrid
            primaryKey="id"
            storageId="OptInMessagesGrid"
            refreshTimeout={15_000}
            pageSize={10}
            apiRef={gridRef}
            getRows={getRecords}
            columns={columns}
            onRowClick={({ row }) => {
              setRowId(row.id || null);
            }}
            customTools={ExportTool}
          />
        </Box>
        <Box>
          <Phone chat={chat} height={400} />
        </Box>
      </Stack>
    </WhiteBox>
  );
}
