import { s } from 'i18n';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useState,
} from 'react';
import * as Tool from 'products/CampaignList/components/Tools';
import {
  getCampaignStatus,
  getStatusStyle,
  getToolKeys,
} from 'products/CampaignList/helpers';
import { CAMPAIGN_TYPE_DRIP } from 'products/common/helpers';
import { Keyword } from 'products/common/CampaignShared/components/Keyword';
import { ConfirmDlg, useConfirmation } from 'shared-scope/components/ConfirmDlg';
import { BLOCK_STYLE } from 'products/Details/helpers';
import Source from 'products/CampaignList/components/Source';
import Loading from 'shared-scope/components/Loading';
import { CampaignStatus as CampaignStatusTypes, GridType } from 'products/CampaignList/types';
import { hasPermissionById, PERMISSION_GENERAL } from 'permission';
import {
  Confirmation,
  ToolKey,
} from 'products/CampaignList/components/Tools';
import { Campaign, TermsConditions } from 'graphql-api';
import Terms from 'products/TermsConditions';
import getActiveTermsConditions from 'products/shared/Campaign/helpers/getActiveTermsConditions';
import { ActionTool } from './ActionTool';
import { Export } from './Export';


type TCActionParams = {
  tc: TermsConditions
  action: Function
};

export type HeaderProps = {
  campaign: Campaign
  gridRef: MutableRefObject<GridApiPro>
  onError: Function
  campaignType: GridType.Poll | GridType.TextCampaign | GridType.AgentPoll | GridType.OptIn
  onChange: ({
    id,
    startTs,
    stopTs,
    status,
  }: Campaign) => void
};

export function Header({
  campaign,
  gridRef,
  onChange,
  onError,
  campaignType,
}: HeaderProps) {
  const {
    type,
    id: campaignId,
    keywords,
    conversationStartReplied: participants,
    conversationTemplate,
  } = campaign;

  const [loading, setLoading] = useState(false);
  const [dlgArchive, setDlgArchive] = useState<Function | null>(null);
  const [showConfirm, hideConfirm, confirmParams] = useConfirmation();
  const [mainTool, setMainTool] = useState<Tool.ToolType | null>(null);
  const [toolKeys, setToolKeys] = useState<Tool.ToolKeys[]>([]);
  const [statusStyle, setStatusStyle] = useState<string>('');
  const [tc, setTc] = useState<TCActionParams | null>(null);

  const hasPermission = hasPermissionById(PERMISSION_GENERAL);

  const confirmation = useCallback((text: string, action: Function) => {
    setDlgArchive(() => action);
    showConfirm(<div>{text}</div>, hideConfirm);
  }, [showConfirm, setDlgArchive, hideConfirm]);

  const action = async (toolAction: Function) => {
    if (campaignId) {
      setLoading(true);
      try {
        const { data } = await toolAction(campaignId);
        if (data?.campaign_id) {
          const newStatus = getCampaignStatus(data.campaign_status, campaignType);
          const updates = {
            id: data.campaign_id,
            startTs: data.start_ts,
            stopTs: data.stop_ts,
            status: newStatus,
          };
          gridRef.current.updateRows([updates]);
          onChange(updates);
        }
      } catch (e: any) {
        console.log(e);
        onError(e?.message || '');
      }
    }
  };

  useEffect(() => {
    if (campaign.status?.id === CampaignStatusTypes.Stopping || campaign.status?.id === CampaignStatusTypes.Starting) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [campaign.status?.id]);

  useEffect(() => {
    if (campaign.status?.id === undefined) {
      return;
    }
    const keys = getToolKeys(campaign.status.id, type, !!conversationTemplate?.questions?.length);
    if (keys.length > 0 && keys[0] === Tool.ToolKey.Start) {
      setMainTool(Tool.START);
      keys.splice(0, 1);
    } else if (keys.length > 0 && keys[0] === Tool.ToolKey.Stop) {
      setMainTool(Tool.STOP);
      keys.splice(0, 1);
    } else {
      setMainTool(null);
    }
    setToolKeys(keys);
    setStatusStyle(getStatusStyle(campaign.status, campaign?.statusString || null));
  }, [campaign.status, type, campaign?.statusString, conversationTemplate?.questions?.length]);

  const loadTc = async (tcAction: Function) => {
    const t = await getActiveTermsConditions(campaign);
    if (t) {
      setTc({ tc: t, action: tcAction });
    } else {
      action(tcAction);
    }
  };


  let statusLabel = getCampaignStatus(campaign?.status?.id, campaignType)?.name || '';
  statusLabel += campaign.statusString ? ` (${campaign.statusString})` : '';

  return (
    <Box
      sx={BLOCK_STYLE}
      width="100%"
    >
      <Stack spacing={1}>
        <Stack
          direction="row"
          justifyContent="space-between"
        >
          {(campaignType === GridType.OptIn && keywords)
            ? (
              <Stack direction="row" spacing={1} whiteSpace="nowrap" justifyContent="center" alignItems="center">
                <h3>{campaign?.name || ''}</h3>
                {keywords.split(' ').map((keyword: string) => (
                  <Keyword key={keyword} keyword={keyword} />
                ))}
              </Stack>
            )
            : <h3>{campaign?.name || ''}</h3>}

          {campaign && <Source campaign={campaign} />}
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
        >
          <Stack
            direction="row"
            spacing={1}
          >
            {loading && <Loading logo={false} size={24} />}
            {(mainTool && !loading && hasPermission) && (
              <ActionTool
                campaignId={campaignId || -1}
                tool={mainTool}
                onAction={(toolAction: Function, confirm: Confirmation | null, tKey) => {
                  if (tKey === ToolKey.Start) {
                    loadTc(toolAction);
                  } else if (confirm && confirmation) {
                      confirmation(confirm(campaign.name || ''), () => { action(toolAction); });
                    } else {
                      action(toolAction);
                    }
                }}
              />
            )}
            <Box
              className={statusStyle}
              sx={{
                fontSize: '1rem',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {statusLabel}
            </Box>
            {type === CAMPAIGN_TYPE_DRIP
              ? (
                <Box
                  className="badge bg-light text-dark"
                  sx={{
                    fontSize: '1rem',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  Drip
                </Box>
              )
              : null}
          </Stack>
          <Stack
            direction="row"
            spacing={1}
          >
            {campaignType !== GridType.OptIn
              ? <Export data={campaign} onError={onError} campaignType={campaignType} />
              : null}

            {hasPermission
              ? (
                toolKeys
                  .filter((key) => {
                    if (key === ToolKey.ClonePollNewParticipants) {
                      return participants && participants > 0;
                    }
                    return true;
                  })
                  .map((key) => (
                    <ActionTool
                      key={key}
                      campaignId={campaign.id}
                      tool={Tool.toolMap[key]}
                      onAction={(toolAction: Function, confirm: Confirmation | null) => {
                        (confirm && confirmation)
                          ? confirmation(confirm(campaign.name || ''), () => { action(toolAction); })
                          : action(toolAction);
                      }}
                    />
                ))
              )
              : null}
          </Stack>
        </Stack>
      </Stack>
      {dlgArchive
        ? <ConfirmDlg
            title={s('Are you sure?')}
            confirmParams={{
              ...confirmParams,
              onClose: (result: boolean) => {
                result && dlgArchive();
                setDlgArchive(null);
              },
            }}
            maxWidth="lg"
        />
        : null}
      {tc
        ? <Terms
            tcUser={null}
            tc={tc.tc}
            tcCampaign={campaign}
            onClose={() => {
              setTc(null);
            }}
            onAccept={() => {
              action(tc.action);
              setTc(null);
            }}
        />
        : null}
    </Box>
  );
}
