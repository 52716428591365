import { QuestionsTemplate } from 'products/common/types';
import cloneDeep from 'lodash/cloneDeep';
import { s } from 'i18n';
import { Attachment } from 'graphql-api';
import validateAttachment from 'products/common/CampaignShared/helpers/validateAttachment';


export default function validateOptInTemplate(
  questions: QuestionsTemplate | null,
  attachmentList?: Attachment[],
  maxAttachmentSize?: number,
) {
  const validQuestions = cloneDeep(questions);
  let questionsError = '';

  const validAttachment = (attachmentId: string): string => validateAttachment({
    attachmentId,
    maxAttachmentSize,
    attachmentList,
  });

  if (validQuestions) {
    validQuestions.initialError = {
      question: !questions?.initialAnswer?.trim() ? s('Keywords can\'t be empty. ') : '',
    };

    questionsError = validQuestions.initialError?.question
      ? s('Incorrectly filled in questions') : questionsError;

    if (validQuestions.template.questions?.length) {
      validQuestions.template.questions[0].error = {
        question: !questions?.template?.questions?.[0].question ? s('Message can\'t be empty. ') : '',
        reply: !questions?.template?.questions?.[0].keywords?.length ? s('Confirmation keywords can\'t be empty. ') : '',
        attachment: questions?.template?.questions?.[0].attachment_id ? validAttachment(questions?.template?.questions?.[0].attachment_id) : '',
      };
      questionsError = (validQuestions.template.questions[0].error.question || validQuestions.template.questions[0].error.reply)
        ? s('Incorrectly filled in questions')
        : questionsError;
    }

    validQuestions.template.final_error = {
      question: !questions?.template?.final_message ? s('You must set a text for confirmation message') : '',
      attachment: questions?.template.final_attachment_id ? validAttachment(questions?.template.final_attachment_id) : '',
    };
    questionsError = validQuestions.template.final_error.question
      ? s('Incorrectly filled in questions') : questionsError;
  }

  return {
    questions: validQuestions,
    error: questionsError,
  };
}
