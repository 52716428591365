import React, { ReactElement } from 'react';
import {
  SOURCE_PHONE_BOOK,
  SOURCE_NUMBER_LOOKUP,
  SOURCE_CAMPAIGN,
  SOURCE_FILE,
  SOURCE_TEXT,
} from 'products/CampaignList/helpers';
import { useNavigate } from 'react-router-dom';
import { Campaign } from 'graphql-api';
import { CampaignStatus } from 'products/CampaignList/types';
import styles from './style.module.scss';

type SourceProps = {
    campaign: Campaign
};

function Source({
    campaign,
}: SourceProps) {
  let sourceName: ReactElement | string = '';
  let icon: ReactElement | null = null;
  let cls = '';

  if (
    campaign?.status?.id === CampaignStatus.Archived || campaign?.status?.id === CampaignStatus.Complete
  ) {
    cls = 'text-muted';
  }

  const params = campaign?.params;
  const navigate = useNavigate();

  if (params) {
    if (params?.campaign_source_type === SOURCE_PHONE_BOOK) {
      const folderId = params.folder_id || null;
      sourceName = <span
        style={{ textDecoration: 'underline' }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          navigate(`/phone-book/${folderId}/${campaign.addressBookId}`);
        }}
      >
        {campaign.params.campaign_source_name}
      </span>;
      icon = <i className="icon-c-phone-book me-1" />;
    } else if (campaign.params.campaign_source_type === SOURCE_NUMBER_LOOKUP) {
      sourceName = campaign.params.campaign_source_name;
      icon = <i className="icon-c-number-lookup me-1" />;
    } else if (campaign.params.campaign_source_type === SOURCE_CAMPAIGN) {
      sourceName = campaign.params.campaign_source_name;
      icon = <i className="icon-c-clone me-1" />;
    } else if (campaign.params.campaign_source_type === SOURCE_FILE) {
      sourceName = campaign.params.campaign_source_name;
      icon = <i className="icon-c-file-check me-1" />;
    } else if (campaign.params.campaign_source_type === SOURCE_TEXT) {
      icon = <i className="icon-c-text me-1" />;
    }
  }

  return <div
    className={`${styles.textSourceName} ${styles.truncate} ${cls}`}
  >
    {icon}
    {sourceName}
  </div>;
}

export default Source;
