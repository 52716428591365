import * as Types from '../../graphql-api';

import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
export type GetConversationTemplateQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sort?: Types.InputMaybe<Types.Scalars['String']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetConversationTemplateQuery = { __typename?: 'Query', conversationTemplateCount?: number | null, conversationTemplate: Array<{ __typename?: 'ConversationTemplate', id?: number | null, name?: string | null, createTs?: any | null, template?: any | null, initialMessage?: string | null, initialAnswer?: string | null, questions?: number | null, type?: number | null, initialAttachment?: { __typename?: 'Attachment', id?: string | null } | null }> };

export type ConversationTemplateSaveMutationVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.Scalars['String']['input']>;
  input: Types.ConversationTemplateInput;
}>;


export type ConversationTemplateSaveMutation = { __typename?: 'Mutation', conversationTemplateSave: Array<{ __typename?: 'ConversationTemplate', id?: number | null } | null> };

export type ConversationTemplateDeleteMutationVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type ConversationTemplateDeleteMutation = { __typename?: 'Mutation', conversationTemplateDelete: Array<{ __typename?: 'ConversationTemplate', id?: number | null } | null> };

export type GetAttachmentQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sort?: Types.InputMaybe<Types.Scalars['String']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetAttachmentQuery = { __typename?: 'Query', attachmentCount?: number | null, attachment: Array<{ __typename?: 'Attachment', id?: string | null, name?: string | null, createTs?: any | null, lastUseTs?: any | null, contentType?: string | null, size?: number | null }> };


export const GetConversationTemplateDocument = gql`
    query getConversationTemplate($filter: String = null, $sort: String = null, $limit: Int = null, $offset: Int = 0) {
  conversationTemplate(
    filter: $filter
    sort: $sort
    limit: $limit
    offset: $offset
  ) {
    id
    name
    createTs
    template
    initialMessage
    initialAnswer
    initialAttachment {
      id
    }
    questions
    type
  }
  conversationTemplateCount(filter: $filter)
}
    `;
export const ConversationTemplateSaveDocument = gql`
    mutation conversationTemplateSave($filter: String = null, $input: ConversationTemplateInput!) {
  conversationTemplateSave(filter: $filter, input: $input) {
    id
  }
}
    `;
export const ConversationTemplateDeleteDocument = gql`
    mutation conversationTemplateDelete($filter: String = null) {
  conversationTemplateDelete(filter: $filter) {
    id
  }
}
    `;
export const GetAttachmentDocument = gql`
    query getAttachment($filter: String = null, $sort: String = null, $limit: Int = null, $offset: Int = 0) {
  attachment(filter: $filter, sort: $sort, limit: $limit, offset: $offset) {
    id
    name
    createTs
    lastUseTs
    contentType
    size
  }
  attachmentCount(filter: $filter)
}
    `;
export type Requester<C = {}> = <R, V>(doc: DocumentNode, vars?: V, options?: C) => Promise<R> | AsyncIterable<R>
export function getSdk<C>(requester: Requester<C>) {
  return {
    getConversationTemplate(variables?: GetConversationTemplateQueryVariables, options?: C): Promise<GetConversationTemplateQuery> {
      return requester<GetConversationTemplateQuery, GetConversationTemplateQueryVariables>(GetConversationTemplateDocument, variables, options) as Promise<GetConversationTemplateQuery>;
    },
    conversationTemplateSave(variables: ConversationTemplateSaveMutationVariables, options?: C): Promise<ConversationTemplateSaveMutation> {
      return requester<ConversationTemplateSaveMutation, ConversationTemplateSaveMutationVariables>(ConversationTemplateSaveDocument, variables, options) as Promise<ConversationTemplateSaveMutation>;
    },
    conversationTemplateDelete(variables?: ConversationTemplateDeleteMutationVariables, options?: C): Promise<ConversationTemplateDeleteMutation> {
      return requester<ConversationTemplateDeleteMutation, ConversationTemplateDeleteMutationVariables>(ConversationTemplateDeleteDocument, variables, options) as Promise<ConversationTemplateDeleteMutation>;
    },
    getAttachment(variables?: GetAttachmentQueryVariables, options?: C): Promise<GetAttachmentQuery> {
      return requester<GetAttachmentQuery, GetAttachmentQueryVariables>(GetAttachmentDocument, variables, options) as Promise<GetAttachmentQuery>;
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;