import React, {
  useState,
  useEffect,
} from 'react';
import { s } from 'i18n';
import {
  Box,
  Stack,
  Typography,
  Button,
  Alert,
} from '@mui/material';
import GrayBox from 'shared-scope/components/Box/GrayBox';
import Loading from 'shared-scope/components/Loading';
import { useAppContext } from 'shared-scope/AppContext';
import { fetcher } from 'graphql-api/fetcher';
import { TermsConditions as TermsConditionsType } from 'graphql-api';
import { getSdk } from 'products/common/queries.generated';
import Terms from 'products/TermsConditions';
import moment from 'moment-timezone';


const api = getSdk(fetcher);

export default function TermsConditions() {
  const appContext = useAppContext();
  const [tc, setTc] = useState<TermsConditionsType[] | null>(null);
  const [openTc, setOpenTc] = useState<TermsConditionsType | null>(null);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    let active = true;
    const loadTc = async () => {
      setLoading(true);
      try {
        const { termsConditions } = await api.getTermsConditions({
          filter: JSON.stringify({ upcoming: false }),
          sort: JSON.stringify([{ effectiveDate: -1 }]),
        });

        if (active && termsConditions.length > 0) {
          setTc(termsConditions);
        }
      } catch ({ message }) {
        console.log(message);
        setError(message as string);
      } finally {
        setLoading(false);
        setRefresh(false);
      }
    };

    refresh && loadTc();

    return () => {
      active = false;
    };
  }, [refresh, appContext?.user?.id]);

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        width: '100%',
      }}
    >
      {error && <Alert severity="error" onClose={() => setError('')}>{error}</Alert>}
      {loading
        ? <Loading logo={false} size={150} />
        : (
          <Stack spacing={2}>
            {tc?.map((t) => {
              const accepted = t.acceptTs ? t : null;
              const acceptedTc = accepted?.acceptTs
                ? moment(accepted.acceptTs).local()?.format('MMMM D, YYYY, HH:mm ZZ')
                : null;
              return (
                <GrayBox key={t.id}>
                  <Stack direction="row" spacing={1} sx={{ mb: 2 }}>
                    {accepted
                      ? (
                        <Typography
                          variant="h4"
                          color={(theme) => theme.palette.success.main}
                        >
                          {s('Accepted')}
                        </Typography>
                      )
                      : (
                        <Typography
                          variant="h4"
                          color={(theme) => theme.palette.error.main}
                        >
                          {s('Not accepted')}
                        </Typography>
                      )}
                    {acceptedTc && (
                      <Typography>
                        {acceptedTc}
                      </Typography>
                    )}
                  </Stack>
                  {
                    t?.version ? (
                      <Typography sx={{ mb: 2 }}>
                        {s('Terms and Conditions version :ver effective since :date', { ver: t.version, date: moment(t.effectiveDate).local()?.format('MMMM D, YYYY, HH:mm ZZ') })}
                      </Typography>
                    ) : null
                  }
                  {
                    t?.open ? (
                      <Button
                        size="medium"
                        variant="outlined"
                        onClick={() => setOpenTc(t)}
                      >
                        {accepted ? s('Read') : s('Read and accept')}
                      </Button>
                    ) : null
                  }
                </GrayBox>
              );
            }) || null}
          </Stack>
        )}
      {(openTc && tc)
        ? (
          <Terms
            tcUser={null}
            tc={openTc}
            onClose={() => setOpenTc(null)}
            onAccept={() => setRefresh(true)}
          />
        )
        : null}
    </Box>
  );
}
