import React from 'react';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';


export type TotalItemProps = {
  title: string
  titleColor: 'primary' | 'secondary'
  value: number
  total: number
  error?: number
};

export function TotalItem({
  title,
  value,
  total,
  titleColor,
  error = 0,
}: TotalItemProps) {
  const padding = titleColor === 'primary' ? {} : { pl: 1 };
  return (
    <Stack direction="row" justifyContent="space-between" sx={padding}>
      <Typography color={`text.${titleColor}`}>{title}</Typography>
      <Stack direction="row">
        <Typography color="success.main">{value.shortFormNumberStr()}</Typography>
        <Typography color="text.primary">{`/${total.shortFormNumberStr()}`}</Typography>
        {!!error && <Typography color="error">{`/${error.shortFormNumberStr()}`}</Typography>}
      </Stack>
    </Stack>
  );
}
