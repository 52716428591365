
let Glob: Record<string, any> = {};

export function save(params: Record<string, any> = {}) {
  Glob = {
    ...Glob,
    ...params,
  };
}

export function get() {
  return Glob;
}

export function getMobiusAppAccount() {
  return get().mobiusApp?.account || null;
}
