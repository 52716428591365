import {
  Button,
  Typography,
  IconButton,
  Stack,
  Menu,
  MenuItem,
} from '@mui/material';
import React, {
  useRef,
  useState,
} from 'react';
import {
  PhoneBook,
} from 'graphql-api';
import { s } from 'i18n';
import { useNavigate } from 'react-router-dom';
import { exportPhoneBook } from 'products/PhoneBook/helpers';


type HeaderProps = {
  phoneBook: PhoneBook | null
  onPhoneBookDelete: () => void
  count: number
};

export default function Header({
    phoneBook,
    onPhoneBookDelete,
    count,
  }: HeaderProps) {
  const navigate = useNavigate();
  const contactFilter = useRef<string>('null');
  const [openExportMenu, setOpenExportMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  return (
    <Stack spacing={2} direction="row" justifyContent="space-between">
      <Stack spacing={1}>
        <Stack direction="row" spacing={1}>
          {phoneBook
            ? (
              <>
                <Typography variant="h3">{`${phoneBook?.name}`}</Typography>
                <Typography variant="h3" fontWeight="500">{`(ID:${phoneBook?.id})`}</Typography>
              </>
            )
            : null}
        </Stack>
        <Typography variant="h5">{phoneBook?.country?.name} {`contacts: ${count.toLocaleString('en-US')}`}</Typography>
      </Stack>
      <Stack spacing={2} direction="row" justifyContent="space-between" height="43px">
        <Button
          disabled={!phoneBook}
          key="contact-import"
          variant="outlined"
          color="inherit"
          onClick={() => navigate(`/phone-book/import-phone-book/${phoneBook?.id}`)}
        >
          {s('Import')}
        </Button>
        <Button
          disabled={!phoneBook}
          key="contact-export"
          variant="outlined"
          color="inherit"
          endIcon={<i className="icon-select" />}
          onClick={(e) => {
            setAnchorEl(e.currentTarget);
            setOpenExportMenu(true);
          }}
        >
          {s('Export')}
        </Button>
        <Menu
          open={openExportMenu}
          onClose={() => setOpenExportMenu(false)}
          anchorEl={anchorEl}
        >
          <MenuItem
            key="all_contacts"
            onClick={() => exportPhoneBook(phoneBook?.id || -1, contactFilter.current)}
          >
            {s('All contacts')}
          </MenuItem>
          <MenuItem
            key="new_contacts"
            onClick={() => {
              let filter = contactFilter.current;
              if (!filter || filter === 'null') {
                filter = '{"last_campaign_id":null}';
              } else {
                filter = '{"$and":[{"last_campaign_id":null},' + filter + ']}';
              }
              exportPhoneBook(phoneBook?.id || -1, filter);
            }}
          >
            {s('New contacts')}
          </MenuItem>
        </Menu>


        <IconButton
          disabled={!phoneBook}
          aria-label="Edit phone book"
          sx={{
            border: '2px solid',
            borderRadius: '50%',
          }}
          color="inherit"
          onClick={() => navigate(`/phone-book/edit-phone-book/${phoneBook?.id}`)}
        >
          <div><i className="icon-edit" /></div>
        </IconButton>
        <IconButton
          disabled={!phoneBook}
          aria-label="Delete phone book"
          sx={{
            border: '2px solid',
            borderRadius: '50%',
          }}
          color="error"
          onClick={() => onPhoneBookDelete()}
        >
          <div><i className="icon-delete" /></div>
        </IconButton>
      </Stack>
    </Stack>
  );
}
