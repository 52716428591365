import { PropsWithChildren, ReactElement } from 'react';
import { useAppContext } from 'shared-scope/AppContext';


export const PERMISSION_GENERAL = 'general';
export const PERMISSION_CHATBOT = 'chatbot';

//todo: remake using hooks
export function hasPermissionById(pid: string) {
  // eslint-disable-next-line
  const appContext = useAppContext();
  const permissions = appContext?.user?.permission?.map((p) => p.id) || [];
  return permissions.includes(pid);
}


type PermissionsGateProps = {
  scopes: string[]
};

export function PermissionWrapper({
  children,
  scopes = [],
}: PropsWithChildren<PermissionsGateProps>) {
  const appContext = useAppContext();
  const permissions = appContext?.user?.permission?.map((p) => p.id) || [];

  const permissionGranted = scopes
    .filter((componentPermission) => permissions.includes(componentPermission))
    .length > 0;

  return permissionGranted ? children as ReactElement : null;
}
