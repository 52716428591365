export default function parseAnswerRow(str: string): [string, string, string, string, string] {
  let code = '';
  let delimiter = '';
  let option = '';
  let hashtag = '';
  let tail = '';
  const rowHasTags = Array.from(str.matchAll(/^(\d+)([^\p{L}|\p{N}]+)(.*)( #[\p{L}|\p{N}]+)([^\p{L}|\p{N}]*)$/gu));
  const rowNoTags = Array.from(str.matchAll(/^(\d+)([^\p{L}|\p{N}]+)(.*)/gu));

  if (rowHasTags?.[0]?.length) {
    [[,code, delimiter, option, hashtag, tail]] = rowHasTags;
  } else if (rowNoTags?.[0]?.length) {
    [[,code, delimiter, option]] = rowNoTags;
  }

  return [code, delimiter, option, hashtag, tail];
}
