import { s } from 'i18n';
import Stack from '@mui/material/Stack';
import {
  ToggleButtonGroup,
  ToggleButton,
  Typography, Grid,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import React, {
  useEffect,
  useState,
} from 'react';
import { Campaign, RawQuestion, RawAnswer as RawAnswerType } from 'graphql-api';
import WhiteBox from 'shared-scope/components/Box/WhiteBox';
import GrayBox from 'shared-scope/components/Box/GrayBox';
import Loading from 'shared-scope/components/Loading';
import { fetcher } from 'graphql-api/fetcher';
import { getSdk } from 'products/Details/Poll/query.generated';
import { ERROR_GROUP } from 'products/Details/Poll/helpers';
import { get } from 'lodash';


const api = getSdk(fetcher);

const loadRawVoteStat = async (campaignId: number) => {
  const { rawVoteStat } = await api.getRawVoteStat({ campaignId });
  return (rawVoteStat?.questions || []) as RawQuestion[];
};

export type GroupAnswerProps = {
  data: Campaign | null
};
export function RawAnswer({ data }: GroupAnswerProps) {
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up('md'));

  const [questions, setQuestions] = useState<RawQuestion[]>([]);
  const [loading, setLoading] = useState(false);
  const [question, setQuestion] = useState<RawQuestion>({});
  const [allCount, setAllCount] = useState(0);

  useEffect(() => {
    const getQuestions = async () => {
      if (!data?.id) {
        return;
      }
      setLoading(true);
      try {
        const result = await loadRawVoteStat(data.id);
        setQuestions(result);
        if (!question.question && result.length > 0) {
          setQuestion(result[0]);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };
    getQuestions();
    // eslint-disable-next-line
  }, [data?.id]);

  useEffect(() => {
    if (question) {
      const count = (question?.answers?.map((a) => a?.count || 0).reduce((a, b) => a + b, 0) || 0);
      setAllCount(count);
    }
  }, [question]);

  return (
    <WhiteBox sx={{ height: '100%' }}>
      <Stack
        spacing={2}
        direction="column"
      >
        <Typography variant="h3">{s('Raw answers')}</Typography>

        <ToggleButtonGroup
          exclusive
          size="small"
          aria-label="questions"
          orientation={largeScreen ? 'horizontal' : 'vertical'}
          value={question}
          onChange={(e, v) => v && setQuestion(v)}
          sx={{
            width: '100%',
            overflow: 'auto',
          }}
        >
          {questions.map((q) => {
            const text = q?.question || '';
            const shortText = `${text.replace('\n', ' ').slice(0, 50)}${text.length > 50 ? ' ...' : ''}`;
            return (
              <ToggleButton
                key={q.position}
                title={text}
                value={q}
                sx={{ minWidth: '150px' }}
              >
                {shortText}
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>

        {loading
          ? <Loading logo={false} size={150} />
          : null}

        <GrayBox>
          <Stack spacing={2}>
            <Stack direction="row" justifyContent="space-between">
              <Typography color="text.primary" variant="h5" whiteSpace="pre-line">{question.question}</Typography>
              <Typography color="text.secondary" variant="subtitle1">{`Total answers: ${allCount.toLocaleString('en-US')}`}</Typography>
            </Stack>
            <Stack spacing={1}>
              {question.answers?.map((a) => {
                const answerObj = (a || {}) as RawAnswerType;
                const count = answerObj.count || 0;
                let answer = answerObj.answer || '';
                const errorGroup = get(ERROR_GROUP, answer || '');
                answer = errorGroup?.label || answer;
                return (
                  <Grid
                    container
                    key={answer}
                    direction="row"
                    columnSpacing={2}
                    alignItems="center"
                  >
                    <Grid item md={1}>
                      <Typography variant="h5">{count.toLocaleString('en-US')}</Typography>
                    </Grid>
                    <Grid item md>
                      <Typography variant="subtitle1" fontWeight={answerObj.keyword ? 600 : 400}>{answer}</Typography>
                    </Grid>
                  </Grid>
                );
              })}
            </Stack>
          </Stack>
        </GrayBox>
      </Stack>
    </WhiteBox>
  );
}
