import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import { s } from 'i18n';
import { runWithInterval, useRequest, useMountedState } from '@xeebi/neru';
import { PieChart } from '@mui/x-charts/PieChart';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { CAMPAIGN_TYPE_OPT_IN } from 'products/common/helpers';
import { getSdk } from 'products/Details/Poll/query.generated';
import { fetcher } from 'graphql-api/fetcher';
import { Campaign, OptInChart } from 'graphql-api';
import { calcCampaignCounters } from 'products/CampaignList/helpers';
import WhiteBox from 'shared-scope/components/Box/WhiteBox';
import { OPT_IN_CHART_STATUSES } from 'products/Details/Poll/helpers';
import useAlert from 'shared-scope/hooks/useAlert';
import { Row } from './Row';


const api = getSdk(fetcher);

export type StatisticProps = {
  data: Campaign | null
};

export function Statistic({ data }: StatisticProps) {
  const isMounted = useMountedState();
  const { addError } = useAlert();
  const [charts, setCharts] = useState<OptInChart[]>([]);
  const [
    sent,
    delivered,
    total,
    error,
    unroutable,
  ] = data ? calcCampaignCounters(data) : [0, 0, 0, 0, 0];

  const isOptIn = (data?.type || -1) === CAMPAIGN_TYPE_OPT_IN;

  const {
    error: optInError,
    fetch,
  } = useRequest(api.getOptInChart);

  const getCharts = useCallback(async () => {
    if (data?.id) {
      const { optInChart } = await fetch({
        campaignId: data.id,
      });
      isMounted() && setCharts(optInChart);
    }
  }, [fetch, isMounted, data?.id]);

  useEffect(() => {
    if (optInError) {
      addError(optInError.getMessage());
      console.error(optInError.getError());
    }
  }, [optInError, addError]);

  useEffect(() => {
    if (!isOptIn) {
      return () => {};
    }
    const runner = runWithInterval(getCharts, 10_000);
    return () => runner.stop();
  }, [isOptIn, getCharts]);

  return (
    <WhiteBox sx={{ height: '100%' }}>
      <Stack
        spacing={1}
        direction="column"
      >
        <Typography variant="h3">{s('Statistics')}</Typography>
        {!isOptIn
          ? (
            <>
              <Row
                count={sent}
                total={total}
                title={s('Sent')}
                progressColor="success"
              />
              <Row
                count={error - unroutable}
                total={total}
                title={s('Errors')}
                progressColor="error"
              />
              <Row
                count={delivered}
                total={sent}
                title={s('Delivered')}
                progressColor="secondary"
              />
              <Row
                count={unroutable}
                total={total}
                title={s('Unroutable')}
                progressColor="warning"
              />
            </>
          )
          : (charts.length > 0 && (
            <Stack direction="row" justifyContent="center" alignItems="center">
              <Box>
                <PieChart
                  series={[
                    {
                      data: charts.map((chart) => {
                        const status = OPT_IN_CHART_STATUSES[chart.status];
                        return {
                          id: chart.status,
                          value: chart.pct,
                          label: status.label,
                          color: status.color,
                        };
                      }),
                      startAngle: -270,
                      endAngle: 90,
                      outerRadius: 70,
                      cx: 80,
                    },
                  ]}
                  slotProps={{
                    legend: {
                      hidden: true,
                    },
                  }}
                  height={150}
                  width={200}
                />
              </Box>
              <Stack spacing={1} width="100%">
                {charts.map((chart) => {
                  const status = OPT_IN_CHART_STATUSES[chart.status];
                  return (
                    <Stack
                      key={chart.status}
                      direction="row"
                      spacing={1}
                      alignItems="center"
                    >
                      <Box
                        sx={{
                          width: 16,
                          height: 16,
                          backgroundColor: status.color,
                        }}
                      />
                      <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                        <Typography id={status.label} fontSize={16} fontWeight={400}>{status.label}</Typography>
                        <Typography fontSize={16} fontWeight={600}>{chart.count.toLocaleString('en-US')}</Typography>
                      </Stack>
                    </Stack>
                  );
                })}
              </Stack>
            </Stack>
            )
          )}
      </Stack>
    </WhiteBox>
  );
}
