import {
 ReactNode, createContext, useContext, Dispatch, SetStateAction,
} from 'react';
import * as Glob from 'shared-scope/globals';
import { RefreshAccountQuery } from 'products/common/queries.generated';
import { User } from 'graphql-api';
import { CookieAttributes } from 'js-cookie';
import { AlertColor } from '@mui/material';

export interface AppContextIf {
  token: string
  setToken: (t: string, p: CookieAttributes) => void
  user: User | null
  setUser: (u: Partial<User> | null) => void
  loading: boolean
  setLoading: (v: boolean) => void
  onTCAccepted: () => void
  account: RefreshAccountQuery | null
  refreshAccount: () => void
  notificationReadId: number | undefined | null
  setNotificationReadId: (v: number) => void
  alerts: Alert[]
  setAlerts: Dispatch<SetStateAction<Alert[]>>
}

export class AppContext implements AppContextIf {
  constructor(props: AppContextIf) {
    Object.assign(this, props);
  }
  token!: string;
  setToken!: (t: string, p?: CookieAttributes) => void;
  user!: User;
  setUser!: (u: Partial<User> | null) => void;
  loading!: boolean;
  setLoading!: (v: boolean) => void;
  onTCAccepted!: () => void;
  account!: RefreshAccountQuery | null;
  refreshAccount!: () => void;
  notificationReadId!: number | undefined | null;
  setNotificationReadId!: (v: number) => void;
  alerts!: Alert[];
  setAlerts!: Dispatch<SetStateAction<Alert[]>>;

  // eslint-disable-next-line class-methods-use-this
  get currencySign() {
    // get it from AppContext::user object in the future
    return Glob.get().mobiusApp?.account?.user?.bill_account?.currency?.currency_sign || '';
  }
}

export const Context = createContext<AppContext | null>(null);

export interface AppContextProviderProps {
  value: AppContext
  children?: ReactNode
}

export function AppContextProvider({ value, children = null }: AppContextProviderProps) {
  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export const useAppContext = () => {
  const store = useContext(Context);
  if (!store) {
    throw new Error('useAppContext must be used within a AppContextProvider');
  }
  return store;
};

export type Alert = {
  id?: string
  message: string
  type: AlertColor
};
