import React, { useState, useEffect } from 'react';
import { s } from 'i18n';
import {
  Box,
  Button,
  Stack,
  Typography,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { User } from 'graphql-api';
import Loading from 'shared-scope/components/Loading';
import { deleteUser } from '../helper';

type DeleteProps = {
  user: User
  open: boolean
  onClose: () => void
  onComplete: () => void
};

export default function Delete({
  user,
  open,
  onClose,
  onComplete,
}: DeleteProps) {
  const [deleteAction, setDeleteAction] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    let active = true;
    const process = async () => {
      try {
        if (!deleteAction) {
          return;
        }
        setLoading(true);
        const userId = user ? user.id : null;
        userId && await deleteUser(userId);

        if (active) {
          onComplete();
          onClose();
        }
      } catch (e: any) {
        console.error(e);
        if (e?.response?.data?.errors) {
          const errorList: string[] = [];
          e.response.data.errors.map((err: any) => errorList.push(err?.message || ''));
          if (errorList.length > 0) {
            setError(errorList.join('\n'));
          }
        }
      } finally {
        setLoading(false);
        setDeleteAction(false);
      }
    };

    deleteAction && process();

    return () => {
      active = false;
    };
  }, [deleteAction, onClose, onComplete, user]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle id="alert-dialog-title">
        {s('Delete')}
      </DialogTitle>
      <DialogContent
        sx={{
          paddingTop: '24px !important',
          textAlign: 'center',
        }}
      >
        {error && <Alert severity="error" onClose={() => setError('')}>{error}</Alert>}
        <Box>
          <Typography component="span" variant="h5">{s('Do you really want to delete')}</Typography>
          <Typography component="span" variant="h4">{` ${user?.name}` || ''}</Typography>
          <Typography component="span" variant="h5">?</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box sx={{ width: '100%', p: 2 }}>
          <Stack direction="row" justifyContent="end" spacing={2}>
            <Button
              variant="outlined"
              onClick={onClose}
              size="small"
            >
              {s('No')}
            </Button>
            {loading
              ? <Loading logo={false} size={40} />
              : (
                <Button
                  variant="contained"
                  onClick={() => setDeleteAction(true)}
                  size="small"
                >
                  {s('Yes')}
                </Button>
              )}
          </Stack>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
