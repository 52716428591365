import { get } from 'lodash';
import { GroupQuestion } from 'graphql-api';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import PieQuestionChart from 'shared-scope/components/PieQuestionChart';
import LinearProgress from '@mui/material/LinearProgress';
import { Typography } from '@mui/material';
import React, {
  useRef,
} from 'react';
import GrayBox from 'shared-scope/components/Box/GrayBox';
import {
  PROGRESS_COLORS,
  ERROR_GROUP,
} from 'products/Details/Poll/helpers';
import { roundRobin } from 'shared-scope/helpers/functions';
import { s } from 'i18n';


export const MAX_PIE_CHART_SIZE = 9;

export function Question({ question, position, groups }: GroupQuestion) {
  const allCount = (groups?.map((g) => g?.count || 0).reduce((sum, a) => sum + a, 0) || 0);
  const pieChart = groups ? groups.length <= MAX_PIE_CHART_SIZE : false;
  const colors = useRef<{ [key: string]: string }>({});
  const colorGen = roundRobin(PROGRESS_COLORS);
  const maxPct = Math.max(...groups?.map((g) => g?.pct || 0) as number[]);

  return (
    <GrayBox>
      <Stack spacing={2}>
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <Typography color="text.secondary" variant="subtitle1">{(position || 0) < 0 ? s('Engagement question') : s('Question') + ' ' + position}</Typography>
          <Typography color="text.secondary" variant="subtitle1">{`Total answers: ${allCount.toLocaleString('en-US')}`}</Typography>
        </Stack>

        <Stack direction="row" spacing={1} justifyContent="space-between">
          <Box sx={{ width: '40%' }}>
            <Typography variant="h5" whiteSpace="pre-line">{question}</Typography>
          </Box>
          <Grid
            container
            direction="row"
            spacing={pieChart ? 1 : 0}
            alignItems="center"
            sx={{ width: '60%' }}
          >
            {!pieChart
              ? (
                <Grid
                  item
                  md={12}
                >
                  <Stack spacing={0}>
                    {groups?.map((g) => {
                      const answers = (g?.answers || []).join('; ');
                      const group = (g?.keyword ? 'Keyword: ' : '') + (g?.group || '');
                      const pct = g?.pct || 0;
                      const count = g?.count || 0;
                      const errorGroup = get(ERROR_GROUP, group || '');
                      let color = get(colors.current, group, '');
                      if (!color) {
                        color = errorGroup?.color || colorGen.next().value;
                        colors.current[group] = color;
                      }
                      return (
                        <Grid
                          container
                          direction="row"
                          spacing={1}
                          key={group}
                        >
                          <Grid
                            item
                            md={6}
                          >
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems="center"
                            >
                              <Box
                                sx={{
                                  width: 8,
                                  height: 8,
                                  backgroundColor: color,
                                }}
                              />
                              <Stack
                                sx={{
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  width: '100%',
                                }}
                              >
                                <Typography variant="caption">{errorGroup?.label || group}</Typography>
                                <Typography color="text.secondary" variant="subtitle1" noWrap>{answers}</Typography>
                              </Stack>
                            </Stack>
                          </Grid>
                          <Grid
                            item
                            md={6}
                          >
                            <Stack>
                              <Stack direction="row" justifyContent="space-between">
                                <Typography variant="h4" id={group}>{`${pct.toFixed(1)} %`}</Typography>
                                <Typography color="text.secondary" variant="subtitle1">{count}</Typography>
                              </Stack>
                              <LinearProgress
                                variant="determinate"
                                value={(pct / maxPct) * 100}
                                sx={{
                                  backgroundColor: 'white',
                                  '& .MuiLinearProgress-bar': {
                                    backgroundColor: color,
                                  },
                                }}
                              />
                            </Stack>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Stack>
                </Grid>
              )
              : null}

            {pieChart
              ? (
                <Grid
                  item
                  md={12}
                >
                  <PieQuestionChart size={200} question={{ question, position, groups }} showAnswers />
                </Grid>
              )
              : null}
          </Grid>
        </Stack>
      </Stack>
    </GrayBox>
  );
}
