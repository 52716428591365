import React from 'react';
import {
  Stack,
  Typography,
} from '@mui/material';
import {
  GridColDef,
  GridHeaderFilterCellProps,
} from '@mui/x-data-grid-pro';
import { s } from 'i18n';
import { Keyword } from 'products/common/CampaignShared/components/Keyword';
import {
  FilterDropdown,
  FilterDateRange,
} from '@xeebi/neru';
import DateTime from 'shared-scope/components/DateTime';
import { phoneNumberFormat } from 'shared-scope/helpers/functions';


const columns: GridColDef[] = [
  {
    field: 'phoneNumber',
    headerName: 'Phone number',
    type: 'string',
    sortable: true,
    filterable: true,
    disableColumnMenu: true,
    minWidth: 140,
    flex: 0,
    valueFormatter: (value) => phoneNumberFormat(value),
  },
  {
    field: 'countryName',
    headerName: 'Country',
    type: 'string',
    sortable: true,
    filterable: true,
    disableColumnMenu: true,
    minWidth: 140,
    flex: 0,
  },
  {
    field: 'operatorName',
    headerName: 'Operator',
    type: 'string',
    sortable: true,
    filterable: true,
    disableColumnMenu: true,
    minWidth: 140,
    flex: 0,
  },
  {
    field: 'keyword',
    headerName: 'First keyword',
    type: 'string',
    sortable: true,
    filterable: true,
    disableColumnMenu: true,
    minWidth: 200,
    flex: 1,
    renderCell: ({ row }) => (
      <Stack direction="row" justifyContent="start" alignItems="center" sx={{ height: '100%' }} spacing={1}>
        <Keyword keyword={row.keyword} />
      </Stack>
    ),
  },
  {
    field: 'timestamp',
    headerName: s('First timestamp'),
    disableColumnMenu: true,
    type: 'custom',
    renderHeaderFilter: (params: GridHeaderFilterCellProps) => (
      <FilterDateRange
        {...params}
        placeholder={s('First timestamp')}
      />
    ),
    valueGetter: (value) => (value ? new Date(value) : null),
    renderCell: ({ value }) => <DateTime value={value} direction="row" />,
    minWidth: 190,
    flex: 0,
  },
  {
    field: 'keywordSecond',
    headerName: 'Second keyword',
    type: 'string',
    sortable: true,
    filterable: true,
    disableColumnMenu: true,
    minWidth: 120,
    flex: 1,
    renderCell: ({ row }) => (
      <Stack direction="row" justifyContent="start" alignItems="center" sx={{ height: '100%' }} spacing={1}>
        <Keyword keyword={row.keywordSecond} />
      </Stack>
    ),
  },
  {
    field: 'timestampSecond',
    headerName: s('Second timestamp'),
    disableColumnMenu: true,
    type: 'custom',
    renderHeaderFilter: (params: GridHeaderFilterCellProps) => (
      <FilterDateRange
        {...params}
        placeholder={s('Second timestamp')}
      />
    ),
    valueGetter: (value) => (value ? new Date(value) : null),
    renderCell: ({ value }) => <DateTime value={value} direction="row" />,
    minWidth: 190,
    flex: 0,
  },
  {
    field: 'type',
    headerName: 'Verification',
    type: 'custom',
    renderHeaderFilter: (params: GridHeaderFilterCellProps) => (
      <FilterDropdown
        {...params}
        options={[
          { id: 0, name: 'Single' },
          { id: 1, name: 'Double' },
        ]}
        keyName="id"
        valueName="name"
        placeholder={s('Status')}
        minWidth={150}
      />
    ),
    renderCell: ({ value }) => (
      <Typography>{value === 0 ? 'Single' : 'Double'} </Typography>
    ),
    disableColumnMenu: true,
    minWidth: 200,
    flex: 1,
  },
];

export default columns;
