import * as Types from '../../../graphql-api';

import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
export type GetCampaignsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sort?: Types.InputMaybe<Types.Scalars['String']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetCampaignsQuery = { __typename?: 'Query', campaignCount?: number | null, campaign: Array<{ __typename?: 'Campaign', type?: number | null, id?: number | null, name?: string | null, messageTemplates?: any | null, params?: any | null, conversationTemplate?: any | null, scheduleEnabled?: boolean | null, scheduleTz?: string | null, scheduleTimes?: string | null, attachmentId?: string | null, conversationTemplateId?: { __typename?: 'ConversationTemplate', name?: string | null, id?: number | null } | null, route?: { __typename?: 'Route', id?: number | null, name?: string | null } | null, country?: { __typename?: 'Country', id?: number | null, name?: string | null } | null }> };

export type GetCountryCapabilityQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sort?: Types.InputMaybe<Types.Scalars['String']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetCountryCapabilityQuery = { __typename?: 'Query', countryCapabilityCount?: number | null, countryCapability: Array<{ __typename?: 'CountryCapability', id?: number | null, maxAttachmentSize?: number | null, termsConditions?: boolean | null, scheduleTimes?: string | null, preapprovedCampaigns?: boolean | null, country?: { __typename?: 'Country', id?: number | null, name?: string | null } | null, route?: { __typename?: 'Route', id?: number | null, name?: string | null } | null }> };

export type GetSrcHashtagQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sort?: Types.InputMaybe<Types.Scalars['String']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetSrcHashtagQuery = { __typename?: 'Query', srcHashtag: Array<{ __typename?: 'SrcHashtag', id?: number | null, hashtag?: string | null, country?: { __typename?: 'Country', id?: number | null, name?: string | null } | null }> };


export const GetCampaignsDocument = gql`
    query getCampaigns($filter: String = null, $sort: String = null, $limit: Int = null, $offset: Int = 0) {
  campaign(filter: $filter, sort: $sort, limit: $limit, offset: $offset) {
    type
    id
    name
    messageTemplates
    params
    conversationTemplate
    conversationTemplateId {
      name
      id
    }
    scheduleEnabled
    scheduleTz
    scheduleTimes
    attachmentId
    route {
      id
      name
    }
    country {
      id
      name
    }
  }
  campaignCount(filter: $filter)
}
    `;
export const GetCountryCapabilityDocument = gql`
    query getCountryCapability($filter: String = null, $sort: String = null, $limit: Int = null, $offset: Int = null) {
  countryCapability(filter: $filter, sort: $sort, offset: $offset, limit: $limit) {
    id
    maxAttachmentSize
    termsConditions
    country {
      id
      name
    }
    route {
      id
      name
    }
    scheduleTimes
    preapprovedCampaigns
  }
  countryCapabilityCount(filter: $filter)
}
    `;
export const GetSrcHashtagDocument = gql`
    query getSrcHashtag($filter: String = null, $sort: String = null, $limit: Int = null, $offset: Int = null) {
  srcHashtag(filter: $filter, sort: $sort, offset: $offset, limit: $limit) {
    id
    hashtag
    country {
      id
      name
    }
  }
}
    `;
export type Requester<C = {}> = <R, V>(doc: DocumentNode, vars?: V, options?: C) => Promise<R> | AsyncIterable<R>
export function getSdk<C>(requester: Requester<C>) {
  return {
    getCampaigns(variables?: GetCampaignsQueryVariables, options?: C): Promise<GetCampaignsQuery> {
      return requester<GetCampaignsQuery, GetCampaignsQueryVariables>(GetCampaignsDocument, variables, options) as Promise<GetCampaignsQuery>;
    },
    getCountryCapability(variables?: GetCountryCapabilityQueryVariables, options?: C): Promise<GetCountryCapabilityQuery> {
      return requester<GetCountryCapabilityQuery, GetCountryCapabilityQueryVariables>(GetCountryCapabilityDocument, variables, options) as Promise<GetCountryCapabilityQuery>;
    },
    getSrcHashtag(variables?: GetSrcHashtagQueryVariables, options?: C): Promise<GetSrcHashtagQuery> {
      return requester<GetSrcHashtagQuery, GetSrcHashtagQueryVariables>(GetSrcHashtagDocument, variables, options) as Promise<GetSrcHashtagQuery>;
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;