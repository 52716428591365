import { AnswerOption, QuestionType } from 'products/common/types';
import parseAnswerRow from './parseAnswerRow';

export default function getQuestionFull(question: QuestionType): QuestionType {
  const options = question.options || [];
  if (options.length) {
    const fullText: string[] = [];
    question.question.split('\n').forEach((line) => {
      const [code, delimiter, option] = parseAnswerRow(line);
      if (code) {
        const fullOption: AnswerOption | undefined = options.find(
          (opt) => (opt.code === code && opt.option === option),
        );
        let newLine = fullOption ? `${fullOption.code}${delimiter}${fullOption.option}` : `${code}${delimiter}${option}`;
        if (fullOption?.hashtag) {
          newLine += ` #${fullOption.hashtag}`;
        }
        fullText.push(newLine);
      } else {
        fullText.push(line);
      }
    });
    return {
      ...question,
      question: fullText.join('\n'),
    };
  }
    return { ...question };
}
