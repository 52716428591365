import Page from 'layout/Page';
import { Step, Stepper } from 'shared-scope/components/Stepper';
import { s } from 'i18n';
import { FormProvider, useForm } from 'shared-scope/components/Form';
import React, {
  useCallback, useEffect, useState,
} from 'react';
import {
  FormField,
  request,
  SchedulerLabel,
} from 'products/shared/Campaign';
import {
  Actions,
  StepGeneral,
  StepMessage,
  StepScheduler,
  StepSettings,
  StepTarget,
  NewCampaignRow,
} from 'products/shared/Campaign/steps';
import { Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import useAlert from 'shared-scope/hooks/useAlert';
import { useRequest } from '@xeebi/neru';
import { CountryCapability } from 'graphql-api';
import getCampaignData from 'products/Campaign/helpers';


const back = '/campaign';

export default function NewCampaign() {
  const form = useForm<NewCampaignRow>();
  const navigate = useNavigate();
  const location = useLocation();
  const { addSuccess, addError } = useAlert();
  const {
   isLoading, error, fetch: createCampaign, result,
  } = useRequest(request.createCampaign);

  const [schedulerOn, setSchedulerOn] = useState(false);
  const [scheduleTimes, setScheduleTimes] = useState<string | null>(null);

  const templateId = new URLSearchParams(location.search).get('templateId');

  const onSubmit = useCallback(async (row: NewCampaignRow) => {
      await createCampaign(getCampaignData(row, schedulerOn));
  }, [schedulerOn, createCampaign]);

  const onDraft = useCallback(async () => {
    const row = form.getRow();
    if (row) {
      await createCampaign(getCampaignData(row, schedulerOn, true));
    }
  }, [schedulerOn, createCampaign, form]);

  useEffect(() => {
    error && addError(s('Error creating a new campaign'));
  }, [error, addError]);

  useEffect(() => {
    result && addSuccess(s('New campaign added successfully'));
    result && navigate(back);
  }, [result, addSuccess, navigate]);

  useEffect(() => {
    const st = (form.getValue(FormField.route) as CountryCapability)?.scheduleTimes || null;
    setScheduleTimes(st);
    if (st) {
      setSchedulerOn(true);
    }
  }, [form]);

  return (
    <Page title={s('Create new campaign')} back={back} loading={isLoading}>
      <FormProvider form={form} onSubmit={onSubmit}>
        <Stepper>
          <Step label={s('General')}>
            <StepGeneral titleFieldName={s('Campaign name')} />
          </Step>
          <Step label={s('Target Audience')}>
            <StepTarget targetType="campaign" />
          </Step>
          <Step label={s('Settings')}>
            <StepSettings
              sourceType={form.getValue(FormField.sourceType)}
              sourceValue={form.getValue(FormField.sourceValue) || null}
            />
          </Step>
          <Step label={s('Compose your message')}>
            <StepMessage
              mapping={form.getValue(FormField.mapping) || {}}
              mappingParams={form.getValue(FormField.mappingParam) || {}}
              sourceValue={form.getValue(FormField.sourceValue)}
              maxAttachmentSize={(form.getValue(FormField.route) as CountryCapability)?.maxAttachmentSize || 0}
              templateId={templateId ? +templateId : null}
            />
          </Step>
          <Step label={scheduleTimes ? s('Schedule') : <SchedulerLabel value={schedulerOn} onChange={setSchedulerOn} />}>
            {schedulerOn
              ? <StepScheduler
                  mask={scheduleTimes}
              />
              : <Typography variant="subtitle1">{s('Campaign will be sent now')}</Typography>}
          </Step>
        </Stepper>
        <Actions
          submitText={s('Create text campaign')}
          onDraft={onDraft}
          onCancel={() => navigate(back)}
        />
      </FormProvider>
    </Page>
  );
}
