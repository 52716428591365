import { GridActionsCellItem } from '@mui/x-data-grid-pro';
import { Icon } from '@mui/material';
import React from 'react';

export default function ActionClone({ label, action, showInMenu = false }: ActionCloneProps) {
  return <GridActionsCellItem
    label={label}
    icon={<Icon color="inherit"><i className="icon-copy" /></Icon>}
    onClick={action}
    showInMenu={showInMenu}
  />;
}

type ActionCloneProps = {
  label: string
  action: (e: React.MouseEvent) => void
  showInMenu?: boolean
};
