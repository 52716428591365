import { s } from 'i18n';
import * as CONFIG from 'shared-scope/config';
import axios from 'axios';
import { TZ } from 'shared-scope/const';
import {
  Campaign,
} from 'src/graphql-api';
import { CAMPAIGN_TYPE_DEFAULT, CAMPAIGN_TYPE_DRIP } from 'products/common/helpers';
import moment from 'moment-timezone';


export const BLOCK_STYLE = {
  backgroundColor: 'white',
  p: 2,
  borderRadius: 4,
  height: '100%',
};

export async function exportCampaign(campaignId: number, filter: any, reply: boolean) {
  const prefix = reply ? 'replies' : 'messages';
  const baseUrl = reply ? `${CONFIG.get('api')}/export/campaign_replies` : `${CONFIG.get('api')}/export/messages`;
  const statusFilter = reply ? null : JSON.stringify(filter);

  try {
    const prepareResp = await axios.post(`${baseUrl}/prepare`);
    const exportId: string = prepareResp?.data?.exportId;
    if (!exportId) {
      throw new Error('Empty export ID');
    }

    const execResp = await axios.get(
        `${baseUrl}/${campaignId}`,
        {
          responseType: 'blob',
          params: {
            filter: statusFilter,
            exportId,
            tz: TZ,
          },
        },
    );

    if (execResp?.status !== 200) {
      throw new Error(`Wrong status ${execResp?.status}`);
    }

    const fd = (new Date()).format('dd_mm_yyyy_H2_M2');
    const href = URL.createObjectURL(execResp?.data);
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', `${prefix}_${campaignId}_${fd}.xlsx`);
    link.click();
    URL.revokeObjectURL(href);
  } catch (error) {
    console.log('++export failed++ ', error);
  }
}

export type ReportStatus = {
  success: boolean
  fileName?: string
  error?: string
  serverStatus?: string
};

export async function downloadReport(campaign: Campaign): Promise<ReportStatus> {
  try {
    const execResp = await axios.post(
      `${CONFIG.get('graphql_host')}${process.env.REACT_APP_BASE_PATH}/vote/v1/report/download`,
      {
        url: window.location.href,
      },
      { responseType: 'blob' },
    );

    if (execResp?.status !== 200) {
      throw new Error(s('Server error :error', { error: execResp?.status }));
    }

    if (!execResp?.data) {
      throw new Error(s('Empty report'));
    }

    const href = URL.createObjectURL(execResp.data);
    const link = document.createElement('a');
    const fileName = `campaign_${campaign.id}_${(new Date()).format('dd_mm_yyyy_H2_M2')}.pdf`;
    link.href = href;
    link.setAttribute('download', fileName);
    link.click();
    URL.revokeObjectURL(execResp.data);
    return { success: true, fileName };
  } catch (error: any) {
    console.log('PDF report download failed', error);
    return { success: false, error: error?.message || 'Connection error' };
  }
}


export async function sendEmailReport(campaign: Campaign, email: string): Promise<ReportStatus> {
  try {
    const fileName = `campaign_${campaign.id}_${(new Date()).format('dd_mm_yyyy_H2_M2')}.pdf`;
    const subject = s('Report for campaign :campaign_id ":campaign_name"', { campaign_id: campaign.id, campaign_name: campaign.name });
    const messageText = s('Report for campaign :campaign_id ":campaign_name"', { campaign_id: campaign.id, campaign_name: campaign.name });
    const execResp = await axios.post(
      `${CONFIG.get('graphql_host')}${process.env.REACT_APP_BASE_PATH}/vote/v1/report/send`,
      {
        url: window.location.href,
        email,
        subject,
        plain_text: messageText,
        attachment_file_name: fileName,
      },
    );

    if (execResp?.status !== 200) {
      throw new Error(s('Server error :error', { error: execResp?.status }));
    }

    return { success: true, fileName, serverStatus: execResp?.data?.email_status };
  } catch (error: any) {
    console.log('PDF report email failed', error);
    return { success: false, error: error?.message || 'Connection error' };
  }
}

export function validateEmail(email: string | null | undefined): boolean {
  if (!email || email.length === 0) {
    return false;
  }
  const re = /\S+@\S+\.\S+/;
  for (const e of email.split(/;|,/)) {
    if (!re.test(e)) {
      return false;
    }
  }
  return true;
}

const DATE_RANGES = [
  { unit: [s('wk'), s('wks')], value: 86400 * 7 },
  { unit: [s('day'), s('days')], value: 86400 },
  { unit: [s('hr'), s('hrs')], value: 3600 },
  { unit: [s('min'), s('mins')], value: 60 },
  { unit: [s('sec'), s('secs')], value: 1 },
];

export function formatDuration(duration: number) {
  const result = [];
  for (const range of DATE_RANGES) {
    if (duration >= range.value) {
      const n = Math.floor(duration / range.value);
      result.push(n + ' ' + range.unit[n > 1 ? 1 : 0]);
      duration -= n * range.value;
    } else if (result.length === 1) {
      break;
    }
    if (result.length > 1 || duration === 0) {
      break;
    }
  }
  return result.join(' ');
}

export function getPollDuration(campaign: Campaign | null | undefined): string | null {
  if (campaign && (campaign.type === CAMPAIGN_TYPE_DRIP || (campaign.type === CAMPAIGN_TYPE_DEFAULT && campaign.isChat)) && campaign.startTs) {
    let maxTs = moment(campaign.startTs);
    if (campaign.stopTs) {
      maxTs = moment(campaign.stopTs);
    } else {
      maxTs = moment();
    }
    if (campaign.lastReplyTs && moment(campaign.lastReplyTs).isAfter(maxTs)) {
      maxTs = moment(campaign.lastReplyTs);
    }
    return formatDuration(maxTs.diff(moment(campaign.startTs)) / 1000);
  }
  return null;
}


export const ALL = 'all';
export const NEW = 'new';

export async function exportLeads(campaignId: number, leadType: 'all' | 'new') {
  const filter = leadType === NEW
    ? JSON.stringify({ state: 1 })
    : JSON.stringify({ state: { $in: [1, 2] } });

  try {
    const execResp = await axios.get(
      `${CONFIG.get('api')}/export/conversation/${campaignId}`,
      {
        responseType: 'blob',
        params: {
          filter,
          tz: TZ,
        },
      },
    );

    if (execResp?.status !== 200) {
      throw new Error(`Wrong status ${execResp?.status}`);
    }

    if (!execResp?.data) {
      throw new Error('Empty report');
    }

    const fd = (new Date()).format('dd_mm_yyyy_H2_M2');
    const href = URL.createObjectURL(execResp.data);
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', `export_${campaignId}_${leadType}_${fd}.xlsx`);
    link.click();
    URL.revokeObjectURL(execResp.data);
  } catch (error) {
    console.log('++export LEADS failed++ ', error);
  }
}
