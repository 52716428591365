import React, {
  useCallback,
  useState,
  useEffect,
} from 'react';
import {
  Button,
  Typography,
  Stack,
  Menu,
  MenuItem,
} from '@mui/material';
import {
  GridRowSelectionModel,
} from '@mui/x-data-grid-pro';
import { s } from 'i18n';
import { COMPLETED } from 'products/Details/Poll/helpers';
import useAlert from 'shared-scope/hooks/useAlert';
import { useRequest, GridRefType } from '@xeebi/neru';
import exportOptInList from './helpers';


type ExportProps = {
  gridRef: React.MutableRefObject<GridRefType>
  count: number
  selected: GridRowSelectionModel
};

export default function Export({ count, selected, gridRef }: ExportProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);

  const {
    isLoading,
    error,
    fetch: fetchExport,
    cancel,
  } = useRequest(exportOptInList);

  const { addError } = useAlert();

  useEffect(() => {
    if (error) {
      addError(error.getMessage());
      console.error(error.getError());
    }
  }, [error, addError]);

  const getExportFiltered = useCallback(async () => {
    if (isLoading) {
      cancel(s('Duplicate request'));
    }
    const filter = { $and: [{ status: { $in: [1, 4] } }] };
    const gridFilter = gridRef.current.mangoFilter?.$and || [];
    filter.$and.push(...gridFilter);

    await fetchExport(filter, {});
  }, [isLoading, fetchExport, cancel, gridRef]);

  const getExportNew = useCallback(async () => {
    if (isLoading) {
      cancel(s('Duplicate request'));
    }
    const filter = { status: COMPLETED };
    await fetchExport(filter, {});
  }, [isLoading, fetchExport, cancel]);

  const getExportAll = useCallback(async () => {
    if (isLoading) {
      cancel(s('Duplicate request'));
    }
    const filter = { status: { $in: [1, 4] } };
    await fetchExport(filter, {});
  }, [isLoading, fetchExport, cancel]);

  const getExportSelected = useCallback(async () => {
    if (isLoading) {
      cancel(s('Duplicate request'));
    }
    const filter = { id: { $in: selected } };
    await fetchExport(filter, {});
  }, [isLoading, fetchExport, cancel, selected]);

  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{ marginLeft: 'auto', p: 1 }}
      justifyContent="center"
      alignItems="center"
    >
      <Typography>{`${s('Contacts')}: ${count.toLocaleString('en-US')}`}</Typography>
      <Button
        variant="outlined"
        color="inherit"
        size="small"
        sx={{ fontSize: '1rem' }}
        endIcon={<i className="icon-select" />}
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
          setOpen(true);
        }}
      >
        {s('Export')}
      </Button>
      <Menu
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={anchorEl}
      >
        {selected.length > 0
          ? (
            <MenuItem
              key="selected"
              onClick={getExportSelected}
            >
              {s('Selected contacts')}
            </MenuItem>
          )
          : null}
        <MenuItem
          key="filtered_contacts"
          onClick={getExportFiltered}
        >
          {s('Filtered contacts')}
        </MenuItem>
        <MenuItem
          key="new_contacts"
          onClick={getExportNew}
        >
          {s('New contacts')}
        </MenuItem>
        <MenuItem
          key="all_contacts"
          onClick={getExportAll}
        >
          {s('All contacts')}
        </MenuItem>
      </Menu>
    </Stack>
  );
}
