import React, { useEffect, useState } from 'react';
import { Combobox, CustomCheckbox } from '@xeebi/neru';
import {
  Stack, Grid, Chip, Typography,
} from '@mui/material';
import { s } from 'i18n';

const UNCHECKED = 0;
const CHECKED = 1;
const INDETERMINATE = 2;

export default function HashtagFilter({ value, onChange, options }: HashtagFilterProps) {
  const [internalOptions, setInternalOptions] = useState<{ k: string, v: string, state: 0 | 1 | 2 }[]>([]);

  useEffect(() => {
    setInternalOptions(options.map((o) => ({
      k: o,
      v: o,
      state: value?.$in?.includes(o)
        ? CHECKED
        : value?.$nin?.includes(o)
          ? INDETERMINATE
          : UNCHECKED,
    })));
  }, [options, value]);

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      rowSpacing={1}
    >
      <Grid item xs={12} lg={4}>
        <Typography>{s('Hashtags')}</Typography>
      </Grid>
      <Grid item xs={12} lg={8}>
        <Combobox<{ k: string, v: string, state: 0 | 1 | 2 }, true, true>
          title=""
          placeholder=""
          value={internalOptions.length
            ? internalOptions.filter((o) => value?.$in?.includes(o.k) || value?.$nin?.includes(o.k))
            : []}
          onChange={(e, values, reason) => {
            if (reason === 'removeOption' || reason === 'clear') {
              const filter: any = {};
              const existsKeys = values.map((o) => o.k);
              filter.$nin = (value?.$nin || []).filter((opt: string) => existsKeys.includes(opt));
              filter.$in = (value?.$in || []).filter((opt: string) => existsKeys.includes(opt));
              if (!filter?.$nin?.length) {
                delete filter.$nin;
              }
              if (!filter?.$in?.length) {
                delete filter.$in;
              }
              onChange(filter);
            }
          }}
          options={internalOptions}
          optionKey="k"
          optionValue="v"
          multiple
          limitTags={2}
          disableCloseOnSelect
          renderTags={(tagValue, getTagProps) => tagValue.map((o, index) => {
            const option = internalOptions.find((opt) => opt.k === o.k);
            return (
              <Chip
                {...getTagProps({ index })}
                size="small"
                label={`#${option?.v}`}
                color={option?.state === INDETERMINATE ? 'error' : 'default'}
              />
            );
          })}
          renderOption={(props, option) => (
            <Stack
              key={props.key}
              direction="row"
              justifyContent="start"
              alignItems="center"
              spacing={1}
              sx={{
               width: '100%',
              }}
              onClick={(e: any) => {
                const newOption = { ...option };
                newOption.state = newOption.state === UNCHECKED
                  ? CHECKED
                  : newOption.state === CHECKED
                    ? INDETERMINATE
                    : UNCHECKED;

                const filter: any = {};
                if (newOption?.state === CHECKED) {
                  filter.$in = [...(value?.$in || []), newOption.k];
                  filter.$nin = (value?.$nin || []).filter((opt: string) => opt !== newOption.k);
                } else if (newOption?.state === INDETERMINATE) {
                  filter.$nin = [...(value?.$nin || []), newOption.k];
                  filter.$in = (value?.$in || []).filter((opt: string) => opt !== newOption.k);
                } else {
                  filter.$nin = (value?.$nin || []).filter((opt: string) => opt !== option.k);
                  filter.$in = (value?.$in || []).filter((opt: string) => opt !== option.k);
                }
                if (!filter?.$nin?.length) {
                  delete filter.$nin;
                }
                if (!filter?.$in?.length) {
                  delete filter.$in;
                }
                onChange(filter);

                if (newOption?.state === INDETERMINATE) {
                  e.preventDefault();
                  e.stopPropagation();
                  return;
                }
                (props as any)?.onClick(e);
              }}
            >
              <CustomCheckbox state={option.state} />
              <Typography>{option.v}</Typography>
            </Stack>
        )}
          sx={{
            width: 180,
          }}
        />
      </Grid>
    </Grid>
  );
}

type HashtagFilterProps = {
  value: any
  options: string[]
  onChange: (value: any) => void
};
