import { useContext, useMemo } from 'react';
import { s } from 'i18n';
import { FormRowsValues } from './types';
import FormContext from './FormContext';

export default function useFormContext<T extends FormRowsValues = FormRowsValues>() {
  const form = useContext(FormContext);

  if (!form) {
    throw new Error(s('Form provider is not found'));
  }

  const { getRow } = form;
  const formRow = useMemo<T>(() => (getRow() || {}) as T, [getRow]);

  return { form, formRow };
}

