import { s } from 'i18n';
import {
  Box, Button, Stack, ToggleButtonGroup,
  ToggleButton, Typography, Divider, TextField, Chip,
} from '@mui/material';
import React, {
  useCallback,
  useEffect, useMemo,
  useState,
} from 'react';
import moment from 'moment-timezone';
import { Campaign } from 'graphql-api';
import WhiteBox from 'shared-scope/components/Box/WhiteBox';
import { GridColDef, DataGridPro } from '@mui/x-data-grid-pro';
import {
 DataGrid, ApiQueryParams, ApiQueryResult, useGridRef,
} from '@xeebi/neru';
import {
  stateIcons,
  STATE_TAKEN_OVER,
  STATE_DISCARDED,
  STATE_COMPLETED,
  NEEDING_ATTENTION_STATES,
} from 'products/CampaignList/helpers';
import { phoneNumberFormat, UID } from 'shared-scope/helpers/functions';
import Loading from 'shared-scope/components/Loading';
import { ConfirmDlg, useConfirmation } from 'shared-scope/components/ConfirmDlg';
import { MessageType } from 'products/shared/Campaign/types';
import { Message } from '../../CampaignList/components/Message';
import {
  ALL,
  ATTENTION,
  loadConversation,
  sendMessage,
  submit,
} from './helpers';


export type ConversationProps = {
  data: Campaign
};

export type Result = {
  answer: string
  position: 1 | 2
  question: string
  field_name: string
  validator: string
};

export type ConversationType = {
  id: number
  phoneNumber: string
  state: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9
  chat: { conversation: MessageType[] }
  result: { result: Result[] }
  campaign_id: number
};

const phoneColumns: GridColDef[] = [
  {
    field: 'state',
    renderCell: ({ row }) => {
      const { state } = row as ConversationType;
      return <i className={stateIcons[state]} />;
    },
    renderHeader: () => null,
    sortable: false,
    filterable: false,
    minWidth: 30,
  },
  {
    field: 'phoneNumber',
    type: 'string',
    renderHeader: () => null,
    sortable: false,
    filterable: false,
    minWidth: 160,
    valueFormatter: (value) => phoneNumberFormat(value),
  },
];

function getQuestionColumns(state: number) {
  return [
    {
      field: 'question',
      headerName: 'Question',
      type: 'string',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      minWidth: 260,
    },
    {
      field: 'answer',
      headerName: 'Answer',
      type: 'string',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      minWidth: 150,
      editable: state === STATE_TAKEN_OVER,
    },
  ] as GridColDef[];
}

export function Conversation({ data }: ConversationProps) {
  const [numberFilter, setNumberFilter] = useState<'all' | 'attention'>(ALL);
  const [chat, setChat] = useState<MessageType[]>([]);
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [conversationId, setConversationId] = useState<number>(0);
  const [state, setState] = useState<number>(-1);
  const [results, setResults] = useState<Result[]>(
    data?.conversationTemplate?.questions.map((q: Result) => ({
      ...q,
      answer: '',
  })) as Result[]);
  const [allCount, setAllCount] = useState(0);
  const [attentionCount, setAttentionCount] = useState(0);
  const convGridRef = useGridRef();
  const [newMessage, setNewMessage] = useState('');

  const [showConfirm, hideConfirm, confirmParams] = useConfirmation();

  const [sending, setSending] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [newState, setNewState] = useState(-1);
  const [error, setError] = useState('');
  const [rows, setRows] = useState<ConversationType[]>([]);
  const [msgDates, setMsgDates] = useState<string[]>([]);

  const getData = useCallback(async (params: ApiQueryParams) => {
    const result = await loadConversation(params, data.id, numberFilter);
    setAllCount(result.stat?.total || 0);
    setAttentionCount(result.stat?.attention || 0);
    setRows(result?.rows || []);
    return result as ApiQueryResult;
  }, [data.id, numberFilter]);

  const sendSms = useCallback(async () => {
    setSending(true);
    if (data.id && newMessage) {
      const success: boolean = await sendMessage(data.id, newMessage, phoneNumber, conversationId);
      if (success) {
        setNewMessage('');
        convGridRef.current.refresh();
      }
    }
    setSending(false);
  }, [data.id, newMessage, phoneNumber, conversationId, convGridRef]);

  const sendState = useCallback(async () => {
    setSubmitting(true);
    if (data.id && conversationId && state === STATE_TAKEN_OVER && newState > -1) {
      const success: boolean = await submit(data.id, newState, phoneNumber, conversationId);
      if (success) {
        setState(newState);
        convGridRef.current.refresh();
      }
    }
    setSubmitting(false);
  }, [data.id, state, phoneNumber, conversationId, newState, convGridRef]);

  const questionColumns = useMemo(() => getQuestionColumns(state), [state]);

  useEffect(() => {
    const row = rows.find((r: ConversationType) => r.id === conversationId);
    const newChat = row?.chat?.conversation || [];
    setChat(newChat);

    const dates = newChat.map((m) => {
      const d = m.timestamp ? moment(m.timestamp).format('MMMM DD') : '';
      return d;
    });
    setMsgDates(dates.filter((d, index, array) => d && array.indexOf(d) === index));

    if (results.length > 0) {
      setResults((res) => res.map((r) => {
        if (!row) {
          r.answer = '';
        } else if (!r.answer) {
          r.answer = row?.result?.result?.find((newR: Result) => newR.position === r.position)?.answer || '';
        }
        return r;
      }));
    }
    if (row) {
      setState(row.state);
    }
  }, [conversationId, rows, results.length]);

  // Checking answers
  useEffect(() => {
    if (state === STATE_TAKEN_OVER) {
      let isValid = true;
      for (const r of results) {
        if (!r.answer) {
          isValid = false;
        }
      }
      if (isValid) {
        setError('');
      } else {
        setError(s('The "Answer" field is required'));
      }
    }
  }, [results, state]);

  return (
    <WhiteBox>
      <Stack
        spacing={2}
        direction="row"
        alignItems="stretch"
      >
        <Stack direction="column" alignItems="center" spacing={2}>
          <ToggleButtonGroup
            value={numberFilter}
            onChange={(e, v) => setNumberFilter(v)}
            exclusive
            size="small"
            aria-label="conversation-filter"
          >
            <ToggleButton
              value={ALL}
            >
              {`${s('All')}•${allCount}`}
            </ToggleButton>
            <ToggleButton
              value={ATTENTION}
            >
              {`${s('Attention')}•${attentionCount}`}
            </ToggleButton>
          </ToggleButtonGroup>
          <DataGrid<ConversationType>
            storageId="ConversationGrid"
            pageSize={10}
            getRows={getData}
            columns={phoneColumns}
            onRowClick={({ row }) => {
              setChat(row.chat.conversation);
              setPhoneNumber(row.phoneNumber);
              setConversationId(row.id);
              setState(row.state);
              if (row?.result?.result) {
                setResults(results.map((r) => {
                  r.answer = row.result.result.find((newR: Result) => newR.position === r.position)?.answer || '';
                  return r;
                }));
              }
            }}
            slots={{
              columnHeaders: () => null,
              toolbar: () => null,
            }}
            autoHeight
            apiRef={convGridRef}
            refreshTimeout={10_000}
          />
        </Stack>
        <Divider orientation="vertical" flexItem color="grey" />
        <Stack direction="column" spacing={2}>
          <DataGridPro
            sx={{
              '& .MuiDataGrid-cell:focus': {
                outline: 'none !important',
              },
            }}
            columns={questionColumns}
            rows={results}
            getRowId={(row) => row.field_name}
            processRowUpdate={(newRow) => {
              const newResults = [...results];
              const index = newResults.findIndex((r) => r.question === newRow.question);
              if (index > -1) {
                newResults[index].answer = newRow.answer;
                setResults(newResults);
              }
              return newRow;
            }}
            hideFooter
          />
          {(state === STATE_TAKEN_OVER && !!error)
            ? <Typography variant="caption" color="error">{error}</Typography>
            : null}
          <Stack direction="row" spacing={2}>
            {submitting
              ? <Loading logo={false} size={52} />
              : (
                <>
                  <Button
                    variant="contained"
                    disabled={state !== STATE_TAKEN_OVER || !!error}
                    onClick={() => {
                      setNewState(STATE_COMPLETED);
                      showConfirm(<div>{s('Are you sure you want to complete the conversation?')}</div>, hideConfirm);
                    }}
                  >
                    {s('Submit lead')}
                  </Button>
                  <Button
                    variant="outlined"
                    disabled={state !== STATE_TAKEN_OVER}
                    onClick={() => {
                      setNewState(STATE_DISCARDED);
                      showConfirm(<div>{s('Are you sure you want to discard the conversation?')}</div>, hideConfirm);
                    }}
                  >
                    {s('Discard')}
                  </Button>
                </>
              )}
          </Stack>
        </Stack>
        <Box sx={{
          borderRadius: '16px',
          border: '2px solid black',
          width: '100%',
          height: '700px',
          overflow: 'auto',
          p: 2,
        }}
        >
          <Stack
            spacing={2}
            justifyContent="space-between"
            divider={<Divider flexItem color="grey" />}
            sx={{
              height: '100%',
            }}
          >
            <Stack
              sx={{
                minHeight: '250px',
                height: '100%',
                overflow: 'auto',
              }}
              flexGrow={1}
            >
              {msgDates.length > 0
                ? (
                  msgDates.map((date) => (
                    <>
                      <Chip
                        key={date}
                        variant="outlined"
                        label={<Typography color="text.secondary" variant="subtitle1">{date}</Typography>}
                        sx={{
                          width: 'auto',
                          alignSelf: 'center',
                        }}
                      />
                      {chat
                        .filter((m) => {
                          const msgDate = m.timestamp ? moment(m?.timestamp).format('MMMM DD') : '';
                          return msgDate === date;
                        })
                        .map((m) => (
                          <Message key={UID()} message={m} />
                        ))}
                    </>
                  ))

                )
                : (
                  chat.map((m) => (
                    <Message key={UID()} message={m} />
                  ))
                )}
            </Stack>
            <Stack spacing={2} flexGrow={0}>
              <Typography>New message</Typography>
              <TextField
                rows={4}
                size="medium"
                multiline
                placeholder="Type your message here"
                onChange={(e) => setNewMessage(e.target.value)}
                value={newMessage}
              />
              {sending
                ? <Loading logo={false} size={52} />
                : (
                  <Button
                    variant="outlined"
                    onClick={sendSms}
                    disabled={!(NEEDING_ATTENTION_STATES.includes(state))}
                    sx={{ width: 150 }}
                  >
                    {s('Send')}
                  </Button>
                )}
            </Stack>
          </Stack>
        </Box>
      </Stack>
      <ConfirmDlg
        title={s('Are you sure?')}
        confirmParams={{
          ...confirmParams,
          onOk: () => {
            sendState();
            hideConfirm();
          },
        }}
        maxWidth="lg"
      />
    </WhiteBox>
  );
}
