import { styled } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/system';

const WhiteBox = styled(Box)<BoxProps>(({ theme }) => (
  {
    backgroundColor: theme.palette.layout.main,
    borderRadius: '16px',
    padding: '16px',
  }
));

export default WhiteBox;
