import { NewCampaignRow } from 'products/shared/Campaign/steps';
import { get, isEmpty, omit } from 'lodash';
import { DEFAULTS } from 'products/shared/Campaign';
import { SourcePhoneBookValue } from 'products/shared/Campaign/types';


export default function getCampaignData(
  row: NewCampaignRow,
  schedulerOn: boolean,
  isDraft: boolean = false,
  campaignId: number | null = null,
) {
  let phoneBookFilter = get(row?.source_value, 'phone_book_filter', null);
  phoneBookFilter = isEmpty(phoneBookFilter) ? null : phoneBookFilter;

  const draft = isDraft ? { is_draft: true } : {};
  const edit = campaignId ? { current_campaign_id: campaignId } : {};

  const data = {
    ...DEFAULTS,
    ...omit(row.source_value?.data, ['data_lines', 'data_rowset', 'data_sheets', 'file']),
    ...omit(row.source_value, ['data']),
    ...{
      mapping: row.mapping,
      skip: row.mapping_param?.skipTopRows,
      phone_add: row.mapping_param?.addPrefix,
      phone_cut: row.mapping_param?.cutDigits,
      delimiter: row.mapping_param?.delimiter,
      enclosure: row.mapping_param?.quoteCharacter,
      sheets: row.mapping_param?.sheet,
      random: row.mapping_param?.random,
      randomEnabled: !!row.mapping_param?.random,
      msg_templates: [{ text: row.message?.text, enabled: true }],
      campaign_name: row.title,
      country_id: row.route?.country?.id,
      connection_id: row.route?.route?.id,
      conversation_template_id: row.template?.id,
      attachment: row.route?.maxAttachmentSize ? row.message?.attachment : null,
      src_address: row?.number?.number || null,
      src_address_id: row?.number?.number_id || null,
      type: row.type,
      address_book_id: (row.source_value as SourcePhoneBookValue)?.phone_book_id || null,
      phone_book_filter: phoneBookFilter,
      send_to_existing_contacts: row?.send_to_existing_contacts,
      ...draft,
      ...edit,
    },
  };
  schedulerOn && Object.assign(data, {
    schedule_times: row.schedule_times,
    schedule_enabled: true,
    timezone_based_schedule: true,
  });
  return data;
}
