import * as Types from '../../../../graphql-api';

import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
export type GetTrxTypeQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sort?: Types.InputMaybe<Types.Scalars['String']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetTrxTypeQuery = { __typename?: 'Query', trxTypeCount?: number | null, trxType: Array<{ __typename?: 'TrxType', id?: string | null, name?: string | null, sign?: string | null }> };

export type GetTrxQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sort?: Types.InputMaybe<Types.Scalars['String']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetTrxQuery = { __typename?: 'Query', trxCount?: number | null, trx: Array<{ __typename?: 'Trx', id?: number | null, trxTs?: any | null, postingTs?: any | null, amount?: any | null, moduleRef?: string | null, memo?: string | null, bonusAmount?: any | null, bonus?: Array<string> | null, type?: { __typename?: 'TrxType', id?: string | null, name?: string | null, sign?: string | null } | null, module?: { __typename?: 'Module', id?: string | null, name?: string | null } | null }> };

export type GetInvoicesQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sort?: Types.InputMaybe<Types.Scalars['String']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetInvoicesQuery = { __typename?: 'Query', invoiceCount?: number | null, invoice: Array<{ __typename?: 'Invoice', id?: number | null, name?: string | null, refNumber?: string | null, billPeriod?: string | null, firstDate?: any | null, lastDate?: any | null, amount?: any | null, bonusAmount?: any | null, fileSize?: number | null }> };

export type GetModulesQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sort?: Types.InputMaybe<Types.Scalars['String']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetModulesQuery = { __typename?: 'Query', moduleCount?: number | null, module: Array<{ __typename?: 'Module', id?: string | null, name?: string | null }> };


export const GetTrxTypeDocument = gql`
    query getTrxType($filter: String = null, $sort: String = null, $limit: Int = null, $offset: Int = 0) {
  trxType(filter: $filter, sort: $sort, offset: $offset, limit: $limit) {
    id
    name
    sign
  }
  trxTypeCount(filter: $filter)
}
    `;
export const GetTrxDocument = gql`
    query getTrx($filter: String = null, $sort: String = null, $limit: Int = null, $offset: Int = 0) {
  trx(filter: $filter, sort: $sort, offset: $offset, limit: $limit) {
    id
    trxTs
    postingTs
    amount
    type {
      id
      name
      sign
    }
    module {
      id
      name
    }
    moduleRef
    memo
    bonusAmount
    bonus
  }
  trxCount(filter: $filter)
}
    `;
export const GetInvoicesDocument = gql`
    query getInvoices($filter: String = null, $sort: String = null, $limit: Int = null, $offset: Int = 0) {
  invoice(filter: $filter, sort: $sort, offset: $offset, limit: $limit) {
    id
    name
    refNumber
    billPeriod
    firstDate
    lastDate
    amount
    bonusAmount
    fileSize
  }
  invoiceCount(filter: $filter)
}
    `;
export const GetModulesDocument = gql`
    query getModules($filter: String = null, $sort: String = null, $limit: Int = null, $offset: Int = 0) {
  module(filter: $filter, sort: $sort, offset: $offset, limit: $limit) {
    id
    name
  }
  moduleCount(filter: $filter)
}
    `;
export type Requester<C = {}> = <R, V>(doc: DocumentNode, vars?: V, options?: C) => Promise<R> | AsyncIterable<R>
export function getSdk<C>(requester: Requester<C>) {
  return {
    getTrxType(variables?: GetTrxTypeQueryVariables, options?: C): Promise<GetTrxTypeQuery> {
      return requester<GetTrxTypeQuery, GetTrxTypeQueryVariables>(GetTrxTypeDocument, variables, options) as Promise<GetTrxTypeQuery>;
    },
    getTrx(variables?: GetTrxQueryVariables, options?: C): Promise<GetTrxQuery> {
      return requester<GetTrxQuery, GetTrxQueryVariables>(GetTrxDocument, variables, options) as Promise<GetTrxQuery>;
    },
    getInvoices(variables?: GetInvoicesQueryVariables, options?: C): Promise<GetInvoicesQuery> {
      return requester<GetInvoicesQuery, GetInvoicesQueryVariables>(GetInvoicesDocument, variables, options) as Promise<GetInvoicesQuery>;
    },
    getModules(variables?: GetModulesQueryVariables, options?: C): Promise<GetModulesQuery> {
      return requester<GetModulesQuery, GetModulesQueryVariables>(GetModulesDocument, variables, options) as Promise<GetModulesQuery>;
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;