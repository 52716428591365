import { AnswerOption, AnswerType, QuestionType } from 'products/common/types';
import parseAnswerRow from './parseAnswerRow';

export default function parseQuestion(question: QuestionType, needAttachment: boolean = true): QuestionType {
  const attId = needAttachment ? question.attachment_id : null;
  delete question.error;

  const answerOptions: AnswerOption[] = [];
  const clearText: string[] = [];
  question.question.split('\n').forEach((line) => {
    const [code, delimiter, option, hashtag] = parseAnswerRow(line);
    code && answerOptions.push({
      code,
      option,
      hashtag: hashtag.replace(/ #/g, '') || undefined,
    });
    clearText.push(code ? code + delimiter + option : line);
  });

  return {
    ...question,
    attachment_id: attId,
    question: clearText.join('\n'),
    options: answerOptions,
    answer_type: answerOptions.length ? AnswerType.Poll : AnswerType.Text,
  };
}
