/* eslint-disable */
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import padStart from 'lodash/padStart';
import cloneDeep from 'lodash/cloneDeep';
import { s } from 'i18n';
import styles from './scheduler.module.scss';

/**
 * Scheduler class
 */
export default class Scheduler extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            cellDown: null,
            cellOver: null,
            action: true,
            map: {},
            mask: {},
        };
    }

    /**
     * Receive props
     * @param nextProps
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const map = this._valueToMap(nextProps.value);
        const mask = this._valueToMap(nextProps.mask);
        if (!nextProps.mask) {
            for (let d = 0; d < 7; ++d) {
                for (let h = 0; h < 24; ++h) {
                    mask[d][h] = true;
                }
            }
        }
        this.setState({map: map, mask: mask});
    }

    /**
     * Value to map
     * @private
     */
    _valueToMap(val) {
        // Init new map
        let map = {};
        for (let d = 0; d < 7; ++d) {
            for (let h = 0; h < 24; ++h) {
                map[d] = map[d] || {};
                map[d][h] = false;
            }
        }

        // Fill map
        let value = (val || '').split(',');
        for (let v of value) {
            let d = parseInt(v.substr(0, 1));
            let start = parseInt(v.substr(1, 2));
            let end = parseInt(v.substr(3, 2));

            if (d >= 0 && start >= 0 && end >= 0) {
                d = this._dayToMap(d);
                for (let h = start; h <= end && h < 24; ++h) {
                    map[d][h] = true;
                }
            }
        }

        return map;
    }

    /**
     * Get day value
     * @param d
     * @returns {any}
     * @private
     */
    _getWeekSchedule(d) {
        let value = [];
        let start = null;
        for (let h = 0; h < 24; ++h) {
            // First bound
            if (this.state.map[d][h] && this.state.mask[d][h]) {
                if (start === null) {
                    start = h;
                }
            }

            // Second bound
            if (
                start !== null
                && (
                    !(this.state.map[d][h] && this.state.mask[d][h]) || h === 23
                )
            ) {
                let _d = this._mapToDay(d);
                let _s = padStart(start, 2, '0');
                let _e = padStart(!(this.state.map[d][h] && this.state.mask[d][h]) ? h - 1 : h, 2, '0');

                value.push(`${_d}${_s}${_e}`);
                start = null;
            }
        }
        return value.length ? value.join(',') : null;
    }

    /**
     * Get value
     * @returns {any}
     * @private
     */
    _getValue() {
        let value = [];
        for (let d = 0; d < 7; ++d) {
            let v = this._getWeekSchedule(d);
            if (v) value.push(v);
        }
        return value.length ? value.join(',') : null;
    }

    /**
     * Map to day
     * @param d
     * @private
     */
    _mapToDay(d) {
        return d + 1;
    }

    /**
     * Day to map
     */
    _dayToMap(d) {
        let map = d - 1;
        if (map <= 0) return 0;
        else if (map >= 6) return 6;
        else return map;
    }

    /**
     * Init map
     */
    UNSAFE_componentWillMount() {
        this.setState({
            map: this._valueToMap(this.props.value),
            mask: this._valueToMap(this.props.mask)
        });
    }

    /**
     * Init map
     */
    componentDidMount() {
        this.cells = [];

        for (let d = 0; d < 7; ++d) {
            for (let h = 0; h < 24; ++h) {
                let element = ReactDOM.findDOMNode(this.refs[`cell-${d}-${h}`]);
                element.addEventListener('touchstart', this.downListener(d, h));
                element.addEventListener('touchmove', this.moveListener(d, h));
                element.addEventListener('touchend', this.upListener(d, h));
                this.cells.push([element, d, h]);
            }
        }
    }

    /**
     * Apply mouse move to map
     * @param map
     * @param down
     * @param over
     * @param action
     * @returns {any}
     * @private
     */
    _applyMouseMoveToMap(map, down = null, over = null, action = true) {
        let m = cloneDeep(map);
        if (down) {
            let [d1, d2] = [down[0], over[0]].sort((a, b) => a - b);
            let [h1, h2] = [down[1], over[1]].sort((a, b) => a - b);
            for (let d = d1; d <= d2; ++d) {
                for (let h = h1; h <= h2; ++h) {
                    m[d][h] = action;
                }
            }
        }
        return m;
    }

    /**
     * Down listener
     * @param d
     * @param h
     * @return {Function}
     */
    downListener = (d, h) => {
        return e => {
            if (!this.state.cellDown && !this.props.disabled && this.state.mask[d][h]) {
                this.setState({
                    action: !this.state.map[d][h],
                    cellDown: [d, h],
                    cellOver: [d, h],
                });
                e.stopPropagation();
                e.preventDefault();
            }
        };
    };

    /**
     * Up listener
     * @param d
     * @param h
     * @return {Function}
     */
    upListener = (d, h) => {
        return e => {
            if (this.state.cellDown && !this.props.disabled) {
                this.setState(
                    {
                        cellDown: null,
                        cellOver: null,
                        map: this._applyMouseMoveToMap(
                            this.state.map,
                            this.state.cellDown,
                            this.state.cellOver,
                            this.state.action
                        )
                    },
                    () => {
                        this.props.onChange(this._getValue())
                    }
                );
            }
        }
    };

    /**
     * Move listener
     * @param d
     * @param h
     * @return {Function}
     */
    moveListener = (d, h) => {
        return e => {
            if (this.state.cellDown && !this.props.disabled) {
                // Pointers
                let x = 0;
                let y = 0;
                if (window.TouchEvent && e instanceof TouchEvent) {
                    x = e.touches[0].clientX;
                    y = e.touches[0].clientY;
                } else {
                    x = e.clientX;
                    y = e.clientY;
                }
                document.elementFromPoint(x, y);

                // Check dom node
                let element = document.elementFromPoint(x, y);

                // Find element
                for (let cell of this.cells) {
                    if (cell[0] === element) {
                        this.setState({cellOver: [cell[1], cell[2]]});
                    }
                }

                // Stop events
                e.stopPropagation();
                e.preventDefault();
            }
        }
    };

    /**
     * Render
     * @returns {*}
     */
    render() {
        // Map
        let map = this._applyMouseMoveToMap(this.state.map, this.state.cellDown, this.state.cellOver, this.state.action);

        // Check cross
        let day = {};
        let hour = {};
        for (let d = 0; d < 7; ++d) {
            for (let h = 0; h < 24; ++h) {
                if (map[d][h]) {
                    day[d] = true;
                    hour[h] = true;
                }
            }
        }

        // Het header
        let header = [<div key="blank" className={styles.cell}/>];
        for (let h = 0; h < 24; ++h) {
            header.push(
                <div
                    key={`hour-${h}`}
                    className={`text-center ${styles.cell} ${styles.header} ${hour[h] ? styles.headerActive : ''}`}
                >
                    <div className={styles.compactSmall}>
                        {padStart(h, 2, '0')}
                    </div>
                </div>
            );
        }

        // Make rows
        const week = [s('Mon'), s('Tue'), s('Wed'), s('Thu'), s('Fri'), s('Sat'), s('Sun')];
        let rows = [];
        for (let d = 0; d < 7; ++d) {
            let row = [];
            row.push(<div key={`week-name-${d}`}
                          className={`text-center ${styles.cell} ${styles.header} ${day[d] ? styles.headerActive : ''}`}>
                <span className={`${styles.fullDisplay} d-none d-md-inline`}>{week[d]}</span>
                <span
                    className={`${styles.compactDisplay} ${styles.compactSmall} d-inline d-md-none`}>{week[d][0]}</span>
            </div>);

            // Iterate cells
            for (let h = 0; h < 24; ++h) {
                if (this.state.mask[d][h]) {
                    row.push(<div
                        key={`hour-${h}`}
                        className={`${styles.cell} ${map[d][h] ? styles.cellActive : ''}`}
                        ref={`cell-${d}-${h}`}

                        // Mouse
                        onMouseDown={this.downListener(d, h)}
                        onMouseMove={this.moveListener(d, h)}
                        onMouseUp={this.upListener(d, h)}
                    />);
                } else {
                    row.push(<div
                        key={`hour-${h}`}
                        className={`${styles.cell} ${styles.cellDisabled}`}
                        ref={`cell-${d}-${h}`}
                    />);
                }
            }
            rows.push(<div className={styles.row} key={`week-${d}`}>{row}</div>);
        }
        return <div ref="element"
                    className={`zopa ${this.props.disabled ? (this.props.disabledActive ? styles.disabledActive : styles.disabled) : ''} ${this.props.compact ? styles.compact : ''}`}>
            <div className={styles.row}>{header}</div>
            {rows}
        </div>;
    }
}

Scheduler.propTypes = {
    value: PropTypes.string,
    mask: PropTypes.string,
    disabled: PropTypes.bool,
    disabledActive: PropTypes.bool,
    onChange: PropTypes.func,
    compact: PropTypes.bool,
};

Scheduler.defaultProps = {
    value: null,
    mask: null,
    disabled: false,
    disabledActive: false,
    onChange: () => {
    },
    compact: false,
};