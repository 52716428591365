import React, {
  useCallback,
  useState,
  useEffect,
} from 'react';
import {
  Button,
  Stack,
  Menu,
  MenuItem,
} from '@mui/material';
import { s } from 'i18n';
import { COMPLETED, EXPORTED } from 'products/Details/Poll/helpers';
import { useRequest, GridRefType } from '@xeebi/neru';
import useAlert from 'shared-scope/hooks/useAlert';
import exportOptInList from 'products/OptIn/OptInList/helpers';


type ExportProps = {
  gridRef: React.MutableRefObject<GridRefType>
  campaignId: number
};

export default function Export({ campaignId, gridRef }: ExportProps) {
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);

  const {
    isLoading,
    error,
    fetch: fetchExport,
    cancel,
  } = useRequest(exportOptInList);

  const { addError } = useAlert();

  useEffect(() => {
    if (error) {
      addError(error.getMessage());
      console.error(error.getError());
    }
  }, [error, addError]);

  const getExportFiltered = useCallback(async () => {
    if (isLoading) {
      cancel(s('Duplicate request'));
    }
    const filter = { $and: [{ campaign_id: campaignId }] };
    const gridFilter = gridRef.current.mangoFilter?.$and || [];
    filter.$and.push(...gridFilter);

    await fetchExport(filter, {});
  }, [isLoading, fetchExport, cancel, gridRef, campaignId]);

  const getExportNew = useCallback(async () => {
    if (isLoading) {
      cancel(s('Duplicate request'));
    }
    const filter = { $and: [{ campaign_id: campaignId }, { status: COMPLETED }] };
    await fetchExport(filter, {});
  }, [isLoading, fetchExport, cancel, campaignId]);

  const getExportAll = useCallback(async () => {
    if (isLoading) {
      cancel(s('Duplicate request'));
    }
    const filter = { $and: [{ campaign_id: campaignId }, { status: { $in: [COMPLETED, EXPORTED] } }] };
    await fetchExport(filter, {});
  }, [isLoading, fetchExport, cancel, campaignId]);

  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{ marginLeft: 'auto', p: 1 }}
      justifyContent="center"
      alignItems="center"
    >
      <Button
        variant="outlined"
        color="inherit"
        size="small"
        sx={{ fontSize: '1rem' }}
        endIcon={<i className="icon-select" />}
        onClick={(e) => {
          setAnchor(e.currentTarget);
          setOpen(true);
        }}
      >
        {s('Export')}
      </Button>
      <Menu
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={anchor}
      >
        <MenuItem
          key="filtered_contacts"
          onClick={getExportFiltered}
        >
          {s('Filtered opt-ins')}
        </MenuItem>
        <MenuItem
          key="new_contacts"
          onClick={getExportNew}
        >
          {s('New opt-ins')}
        </MenuItem>
        <MenuItem
          key="all_contacts"
          onClick={getExportAll}
        >
          {s('All opt-ins')}
        </MenuItem>
      </Menu>
    </Stack>
  );
}
