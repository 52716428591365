import difference from 'lodash/difference';
import { Segment } from 'shared-scope/types';
import isEmpty from 'lodash/isEmpty';
import Encoding, { defaultEncodings, Encod } from './Encoding';
import GSM7, { gsm7BasicChars } from './GSM7';
import GSM7tr from './GSM7tr';
import GSM7es from './GSM7es';
import GSM7pt from './GSM7pt';
import ASCII from './ASCII';
import UCS2 from './UCS2';

/**
 * Select encoding
 * @param text
 * @param encodings
 * @return {Encoding}
 */
export function selectEncoding(
  text: string,
  encodings: Encod[] = defaultEncodings,
): Encoding | null {
  // Encodings
  const enc = [new GSM7(), new GSM7tr(), new GSM7es(), new GSM7pt(), new ASCII(), new UCS2()]
    .filter((v) => encodings.indexOf(v.name) >= 0);

  // Chars
  const chars = text.split('');
  for (const encoding of enc) {
    if (encoding.can_represent(chars)) {
      return encoding;
    }
  }
  return null;
}

/**
 * Is gsm base
 * @param text
 * @return {*}
 */
export function isGsmBase(text: string = ''): boolean {
  const chars = text.split('');
  return difference(chars, gsm7BasicChars).length === 0;
}

/**
 * Split segments
 * @param text
 * @param encodings
 * @return Segment
 */
export function splitSegments(text: string, encodings?: Encod[]): Segment | null {
  const encoding = selectEncoding(text, isEmpty(encodings) ? defaultEncodings : encodings);

  let result = null;
  if (encoding) {
    result = encoding.split_segments(text) as Segment;
    result.name = encoding.toString();
  }

  return result;
}
