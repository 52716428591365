import {
  Components, createTheme, Palette, PaletteOptions,
  ThemeOptions, PaletteColor, PaletteColorOptions, Theme,
} from '@mui/material';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import merge from 'lodash/merge';

type PaletteOptVal = string | number | PaletteColorOptions;

declare module '@mui/material/styles' {
  interface Palette {
    [key: string]: PaletteOptVal | Record<string, PaletteOptVal>
    layout: PaletteColor
    normal: PaletteColor
    muted: PaletteColor
    header: PaletteColor
    other: PaletteColor
  }
  interface PaletteOptions {
    [key: string]: PaletteOptVal | Record<string, PaletteOptVal>
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    button: true
    buttonMajor: true
    buttonDlg: true
    buttonDlgFilled: true
  }
}

type MakeThemeProps = {
  palette: PaletteOptions
  typography?: TypographyOptions | ((palette: Palette) => TypographyOptions)
  components?: Components | ((theme: Theme) => Components)
  themeOptions?: Omit<ThemeOptions, 'palette' | 'typography' | 'components'>
};

const getDefaultTypography = (palette: Palette): TypographyOptions => ({
    fontFamily: '"Rubik", sans-serif',
    h1: {
      color: palette.header.main,
      fontSize: '40px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '56px',
    },
    h2: {
      color: palette.header.main,
      fontSize: '24px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '32px',
    },
    h3: {
      color: palette.header.main,
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '28px',
    },
    h4: {
      color: palette.header.main,
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '24px',
    },
    h5: {
      color: palette.header.main,
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '20px',
    },
    subtitle1: {
      color: palette.muted.main,
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '20px',
    },
    subtitle2: {
      color: palette.muted.main,
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '16px',
    },
    body1: {
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '24px',
    },
    body2: {
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '19.2px',
    },
  });

const getDefaultComponents = ({ palette, transitions }: Theme): Components<Theme> => ({
  MuiIconButton: {
    styleOverrides: {
      root: ({ ownerState }: any) => {
        const style: any = {};
        if (ownerState.color === 'error') {
          style.backgroundColor = 'transparent';
          style.color = palette.error.main;
          style['&:hover'] = {
            backgroundColor: palette.error.light,
          };
        } else if (ownerState.color === 'warning') {
          style.backgroundColor = 'transparent';
          style.color = palette.warning.main;
          style['&:hover'] = {
            backgroundColor: palette.primary.dark,
          };
        } else if (ownerState.color === 'inherit' || ownerState.color === 'secondary') {
          style.backgroundColor = 'transparent';
          style.color = palette.normal.main;
          style['&:hover'] = {
            backgroundColor: palette.layout.light,
          };
        } else if (ownerState.color === 'success') {
          style.backgroundColor = 'transparent';
          style.color = palette.success.main;
          style['&:hover'] = {
            backgroundColor: palette.success.light,
          };
        } else if (ownerState.color === 'primary') {
          style.backgroundColor = 'transparent';
          style.color = palette.primary.main;
          style['&:hover'] = {
            backgroundColor: palette.primary.light,
          };
        }
        return {
          ...style,
          '&.Mui-disabled': {
            backgroundColor: style.backgroundColor,
            color: style.color,
            opacity: 0.5,
          },
        };
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: '100px',
        fontSize: '16px',
        fontWeight: '600',
        lineHeight: '24px',
        textTransform: 'none',
        padding: '12px 24px',
      },
      outlined: {
        borderWidth: '2px',
        '&:hover': {
          borderWidth: '2px',
        },
      },
      sizeSmall: {
        fontSize: '12px',
        padding: '8px 16px',
        borderRadius: '60px',
        lineHeight: '18px',
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        whiteSpace: 'nowrap',
      },
    },
  },
  MuiDialogTitle: {
    defaultProps: {
      color: 'white',
      sx: {
        backgroundColor: palette.normal.main,
        padding: '0.3em 0.8em',
        fontSize: '1.22rem',
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        maxWidth: '600px',
        width: '100%',
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        backgroundColor: palette.layout.main,
        '&.MuiInputBase-multiline': {
          padding: '12px',
          fontSize: '16px',
        },
        input: {
          padding: '12px',
          fontSize: '16px',
        },
      },
      sizeSmall: {
        borderRadius: '8px',
        input: {
          padding: '7px 8px',
          fontSize: '12px',
          minWidth: '170px',
          '&::placeholder': {
            fontSize: '14px',
          },
        },
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        top: '-5px',
        '&.Mui-focused': {
          top: 0,
        },
      },
      sizeSmall: {
        fontSize: '16px',
      },
      filled: {
        top: 0,
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      select: {
        padding: '12px',
      },
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      inputRoot: {
        padding: '4px',
      },
    },
  },
  MuiSwitch: {
    styleOverrides: {
      root: {
        width: 42,
        height: 26,
        padding: 0,
        marginRight: 5,
        '& .MuiSwitch-switchBase': {
          padding: 0,
          margin: 2,
          transitionDuration: '300ms',
          '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              backgroundColor: palette.mode === 'dark' ? palette.primary.dark : palette.primary.main,
              opacity: 1,
              border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.5,
            },
          },
          '&.Mui-disabled .MuiSwitch-thumb': {
            color:
              palette.mode === 'light'
                ? palette.grey[100]
                : palette.grey[600],
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: palette.mode === 'light' ? 0.7 : 0.3,
          },
        },
        '& .MuiSwitch-thumb': {
          boxSizing: 'border-box',
          width: 22,
          height: 22,
        },
        '& .MuiSwitch-track': {
          borderRadius: 26 / 2,
          backgroundColor: palette.mode === 'light' ? '#E9E9EA' : '#39393D',
          opacity: 1,
          transition: transitions.create(['background-color'], {
            duration: 500,
          }),
        },
      },
    },
  },
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        scrollbarColor: palette.layout.main + ' ' + palette.normal.light,
        scrollbarWidth: '0.4em',
        '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
          width: '0.3em',
          height: '0.4em',
          backgroundColor: palette.layout.main,
          cursor: 'pointer',
        },
        '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
          borderRadius: 8,
          minHeight: 24,
          backgroundColor: palette.normal.light,
        },
        '&::-webkit-scrollbar-track, & *::-webkit-scrollbar-track': {
          WebkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
      },
    },
  },
  MuiToggleButtonGroup: {
    styleOverrides: {
      root: {
        border: `1px solid ${palette.normal.light}`,
        borderRadius: '12px',
        padding: '4px',
        width: 'fit-content',
      },
    },
    variants: [
      {
        props: { size: 'small' },
        style: {
          borderRadius: '8px',
          padding: '2px',
        },
      },
    ],
  },
  MuiToggleButton: {
    styleOverrides: {
      root: {
        padding: '4px 12px',
        borderRadius: '6px !important',
        border: 0,
        '&:not(:last-of-type)': {
          marginRight: '1px',
        },
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '24px',
        color: palette.muted.main,
        textTransform: 'none',
        '&:hover': {
          backgroundColor: palette.layout.light,
        },
        '&.Mui-selected': {
          backgroundColor: palette.layout.light,
          color: palette.primary.main,
          cursor: 'default',
          '&:hover': {
            backgroundColor: palette.layout.light,
          },
        },
      },
      sizeSmall: {
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '20px',
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '24px',
        minHeight: 'auto',
        minWidth: 'auto',
        textTransform: 'none',
        color: palette.normal.light,
        '&:hover': {
          color: palette.primary.main,
        },
        '&.Mui-selected': {
          color: palette.primary.main,
        },
      },
    },
  },
});

export default function makeTheme({
 palette, typography = {}, components = {}, themeOptions = {},
}: MakeThemeProps) {
  let theme = createTheme({
    palette,
    typography: getDefaultTypography,
  });

  const typo = (typography instanceof Function) ? typography(theme.palette) : typography;
  const customComponents = (components instanceof Function) ? components(theme) : components;
  const defaultComponents = getDefaultComponents(theme);
  theme = createTheme(theme, {
    typography: typo,
    components: merge(defaultComponents, customComponents),
    mixins: {
      MuiDataGrid: {
        containerBackground: '#F2F2F6',
      },
    },
    ...themeOptions,
  });

  return theme;
}
