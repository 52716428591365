import React, {
  useCallback,
  useEffect,
} from 'react';
import {
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import moment from 'moment-timezone';
import useAlert from 'shared-scope/hooks/useAlert';
import { phoneNumberFormat, copyTextToClipboard } from 'shared-scope/helpers/functions';
import { useRequest } from '@xeebi/neru';
import WhiteBox from 'shared-scope/components/Box/WhiteBox';
import GrayBox from 'shared-scope/components/Box/GrayBox';


export default function Message({
  message,
  pdu,
  systemNumber,
  ts,
}: MessageProps) {
  const { addError, addSuccess } = useAlert();
  const { error, fetch } = useRequest(copyTextToClipboard);

  const copy = useCallback(async () => {
    await fetch(pdu);
    addSuccess('Copied');
  }, [pdu, addSuccess, fetch]);

  useEffect(() => {
    if (error) {
      addError(error.getMessage());
      console.error(error.getError());
    }
  }, [error, addError]);

  return (
    <GrayBox sx={{ width: 500 }}>
      <Stack
        spacing={2}
        direction="column"
        sx={{ width: '100%' }}
        flexGrow={1}
      >
        <Typography variant="h3">{message}</Typography>
        <Stack direction="row" justifyContent="space-between" spacing={2} alignItems="center">
          <Typography color="text.primary">Service number</Typography>
          <Typography color="text.secondary" variant="subtitle1">{phoneNumberFormat(systemNumber)}</Typography>
        </Stack>

        {ts
          ? (
            <Stack direction="row" justifyContent="space-between" spacing={2} alignItems="center">
              <Typography color="text.primary">Timestamp</Typography>
              <Stack
                className="text-nowrap"
                direction="row"
                spacing={1}
                alignItems="center"
              >
                <Typography color="text.secondary" variant="subtitle1">
                  {moment(ts).format('YYYY-MM-DD')}
                </Typography>
                <Typography color="text.secondary" variant="subtitle1">
                  {moment(ts).format('HH:mm:ss')}
                </Typography>
              </Stack>
            </Stack>
          )
          : null}

        {pdu
          ? (
            <Stack spacing={1}>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography color="text.primary">PDU</Typography>
                <IconButton
                  sx={{ fontSize: 24 }}
                  onClick={copy}
                >
                  <i className="icon-copy" />
                </IconButton>
              </Stack>
              <WhiteBox
                sx={{
                  overflow: 'auto',
                  maxHeight: '300px',
                }}
              >
                <Typography
                  color="text.secondary"
                  variant="subtitle1"
                  sx={{
                    wordWrap: 'break-word',
                    fontFamily: 'monospace',
                }}
                >
                  {pdu}
                </Typography>
              </WhiteBox>
            </Stack>
          )
          : null}
      </Stack>
    </GrayBox>
  );
}

type MessageProps = {
  message: string
  systemNumber: string
  pdu: string
  ts: string
};
