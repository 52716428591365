import { Char } from 'shared-scope/types';
import GSM7 from './GSM7';
import { Encod } from './Encoding';

/**
 * GSM7 PT shift chars
 * @type {*[]}
 */
export const gsm7PtShiftChars: Char[] = [
  null, null, null, null, null, 'ê', null, null,
  null, 'ç', '\f', 'Ô', 'ô', null, 'Á', 'á',
  null, null, 'Φ', 'Γ', '^', 'Ω', 'Π', 'Ψ',
  'Σ', 'Θ', null, null, null, null, null, 'Ê',
  null, null, null, null, null, null, null, null,
  '{', '}', null, null, null, null, null, '\\',
  null, null, null, null, null, null, null, null,
  null, null, null, null, '[', '~', ']', null,
  '|', 'À', null, null, null, null, null, null,
  null, 'Í', null, null, null, null, null, 'Ó',
  null, null, null, null, null, 'Ú', null, null,
  null, null, null, 'Ã', 'Õ', null, null, null,
  null, 'Â', null, null, null, '€', null, null,
  null, 'í', null, null, null, null, null, 'ó',
  null, null, null, null, null, 'ú', null, null,
  null, null, null, 'ã', 'õ', null, null, 'â',
];

/**
 * GSM7 pt
 */
export default class GSM7pt extends GSM7 {
  name = Encod.gsm7pt;
  shift_code = 0x03;

  /**
   * Init
   */
  init() {
    super.init();
    this.ext_chars = gsm7PtShiftChars;
  }
}
