import {
 memo, ReactNode, useCallback, useEffect, useMemo, useRef,
} from 'react';
import { Box } from '@mui/material';
import { isEqual } from 'lodash';
import useFormContext from './useFormContext';
import { FormRow, FormRowsErrors, FormValidateRule } from './types';

function FormController<T = string, R extends FormRow = FormRow>(
  {
    name, render, children, initial, validateRule, className, renderParams,
  }: FormControllerProps<T, R>) {
  const {
    form: {
      register, unregister, isRegistered, getValue, setValue, getError,
      setValidateRule, clearError, validateFields,
    },
  } = useFormContext<R>();

  const valueRef = useRef(getValue(name));
  const errorRef = useRef(getError(name));
  const validateRef = useRef(validateFields);


  if (!isEqual(valueRef.current, getValue(name))) {
    valueRef.current = getValue(name) as T;
    validateRef.current = validateFields;
  }
  if (!isEqual(errorRef.current, getError(name))) {
    errorRef.current = getError(name);
  }

  const registered = useMemo(() => isRegistered(name), [name, isRegistered]);

  /**
   * Init new control
   */
  useEffect(() => {
    !registered && register<T>(name, initial);
  }, [registered, name, initial, register]);

  /**
   * Set validation rules
   */
  useEffect(() => {
    validateRule && setValidateRule(name, validateRule as FormValidateRule);
  }, [name, validateRule, setValidateRule]);

  useEffect(() => {
    return () => {
      unregister(name);
    };
  }, [name, unregister]);

  const handleChange = useCallback((v: T) => {
      clearError(name);
      setValue<T>(name, v);
    }, [name, setValue, clearError]);

  const handleValidate = useCallback(
    () => {
      clearError(name);
      validateRef.current(name);
    },
    //Disable warning for Ref Deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [name, clearError, validateRef.current],
  );

  const renderFunc = useMemo(
    () => {
      const func = children ?? render ?? null;
      const e = Array.isArray(errorRef.current) ? errorRef.current : [{ message: errorRef.current }];
      return typeof func === 'function'
        ? func({
          value: valueRef.current,
          onChange: handleChange,
          error: e.length ? e.map((err) => err.message) : undefined,
          fullError: e.length ? e : undefined,
          validate: handleValidate,
          renderParams,
        })
        : func;
    },
    //Disable warning for Ref Deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [children, render, handleChange, handleValidate, renderParams, valueRef.current, errorRef.current],
  );

  return <Box className={`${className || ''} formController-${name}`}>
    {renderFunc}
  </Box>;
}

export default memo(FormController,
  (prevProps, nextProps) => {
    return prevProps.name === nextProps.name
      && prevProps.className === nextProps.className
      && isEqual(prevProps.initial, nextProps.initial)
      && isEqual(prevProps.renderParams, nextProps.renderParams)
      && prevProps.validateRule === nextProps.validateRule
      && prevProps.render?.toString() === nextProps.render?.toString()
      && prevProps.children?.toString() === nextProps.children?.toString();
  },
) as typeof FormController;

type FormControllerProps<T, R extends FormRow = FormRow> = {
  name: string
  initial?: T
  validateRule?: FormValidateRule<R>
  renderParams?: Record<string, any>
  render?: (v: FormControllerRenderProps<T>) => ReactNode | null
  children?: ReactNode | ((v: FormControllerRenderProps<T>) => ReactNode | null) | null
  className?: string
};

type FormControllerRenderProps<T> = {
  value?: T
  onChange: (v: T | null) => void
  error?: string[]
  fullError?: FormRowsErrors
  validate: () => void
  renderParams?: Record<string, any>
};

