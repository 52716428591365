import { MangoFilter, Maybe } from 'shared-scope/types';
import {
  CAMPAIGN_TYPE_DEFAULT,
  CAMPAIGN_TYPE_DRIP,
  CAMPAIGN_TYPE_OPT_IN,
  CAMPAIGN_TYPE_RESPONDER,
} from 'products/common/helpers';

export type Mapping = Record<string, number>;
export type MappingTitle = Record<string, string>;

export type MappingData = {
  file_type: 'csv' | 'excel'
  sheets?: string[]
  data_lines?: string
  data_sheets?: any[]
  data_rowset?: any[]
  data_count?: number
  delimiter?: string
  mapping: Mapping
  birthday_format?: string
};

export type ContactListFilter = {
  birthday?: string | null
  gender?: string | null
  state?: string[] | null
  tz?: string[] | null
  campaign?: string | null
  src_hashtags?: HashtagFilter | null
};

type HashtagFilter = {
  $in?: string[]
  $nin?: string[]
};

export type SourceFileValue = {
  data: MappingData | null
  source_type: string
  delimiter?: string
};

export type SourceTextValue = {
  data: MappingData | null
  source_type: string
  text: string
  delimiter?: string
};

export type SourcePhoneBookValue = {
  data: MappingData | null
  source_type: string
  campaign_source_type: string
  campaign_source_name: string
  folder_id: number | null
  phone_book_id: number
  phone_book_filter: MangoFilter
  src_hashtags: string[]
};

export type SourceCampaignValue = {
  data: MappingData | null
  source_type: string
  campaign_id: number
  campaign_source_type: string
};

export type SourceValue = SourceFileValue | SourceTextValue | SourcePhoneBookValue | SourceCampaignValue | null;

export enum Sources {
  excel = 'source-file',
  text = 'source-text',
  phonebook = 'source-phone-book',
  responder = 'responder',
  campaign = 'campaign',
}

export enum FormField {
  title = 'title',
  route = 'route',
  country = 'country',
  folder = 'folder',
  sourceType = 'source_type',
  sourceValue = 'source_value',
  filter = 'filter',
  mappingParam = 'mapping_param',
  mapping = 'mapping',
  template = 'template',
  message = 'message',
  questions = 'questions',
  optIn = 'optIn',
  schedulerOn = 'schedulerOn',
  scheduleTz = 'schedule_tz',
  scheduleTimes = 'schedule_times',
  type = 'type',
  number = 'number',
  sendToExistingContacts = 'send_to_existing_contacts',
  resendQuestionIfNoAnswer = 'second_stage_resend_question_if_no_answer_min',
  gptWrongAnswerCheckEnabled = 'gpt_wrong_answer_check_enabled',
  srcHashtags = 'src_hashtags',
}

export enum CampaignType {
  default = CAMPAIGN_TYPE_DEFAULT,
  responder = CAMPAIGN_TYPE_RESPONDER,
  drip = CAMPAIGN_TYPE_DRIP,
  optIn = CAMPAIGN_TYPE_OPT_IN,
}

export enum MappingFormField {
  cutDigits = 'cutDigits',
  addPrefix = 'addPrefix',
  sheets = 'sheet',
  csvDelimiter = 'delimiter',
  csvQuote = 'quoteCharacter',
  skipTop = 'skipTopRows',
  // filters = 'filters',
  random = 'random',
  birthdayFormat = 'birthdayFormat',
}

export type MappingFormValue = {
  [MappingFormField.cutDigits]?: number
  [MappingFormField.addPrefix]?: string
  [MappingFormField.sheets]?: number
  [MappingFormField.csvDelimiter]?: string
  [MappingFormField.csvQuote]?: string
  [MappingFormField.skipTop]?: number
  [MappingFormField.random]?: number
  [MappingFormField.birthdayFormat]?: string
  data_rowset?: string[][]
};

export enum CustomError {
  MESSAGE_IS_NOT_EMPTY = 'MESSAGE_IS_NOT_EMPTY',
  ANSWER_IS_NOT_EMPTY = 'ANSWER_IS_NOT_EMPTY',
  ATT_IS_NOT_ALLOWED = 'ATT_IS_NOT_ALLOWED',
  ATT_BROKEN = 'ATT_BROKEN',
  ATT_MAX_SIZE = 'ATT_MAX_SIZE',
}

export type QuestionValue = {
  text: string
  attachment?: Maybe<string>
  answer?: string
};

export enum TemplateType {
  Text = 0,
  Poll = 1,
  OptIn = 2,
}

export type MessageType = {
  type: 'out' | 'in'
  state: 0 | 1 | 2 | 3
  message: string
  position: number
  mobius_message_id?: string
  attId?: string
  timestamp?: string
};

