import React, {
  useEffect,
  useState,
} from 'react';
import {
  Box,
  Stack,
  Typography,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { fetcher } from 'graphql-api/fetcher';
import { OptInList } from 'graphql-api';
import { AxiosRequestConfig } from 'axios';
import Drawer from 'shared-scope/components/Drawer';
import useAlert from 'shared-scope/hooks/useAlert';
import { phoneNumberFormat } from 'shared-scope/helpers/functions';
import { useMountedState, useRequest } from '@xeebi/neru';
import WhiteBox from 'shared-scope/components/Box/WhiteBox';
import { MessageType } from 'products/shared/Campaign/types';
import { Phone } from 'products/shared/Campaign/components';
import Message from 'products/OptIn/OptInDetails/Message';
import { getSdk } from '../query.generated';


const api = getSdk(fetcher);

export const getRecord = async (id: number, config?: AxiosRequestConfig) => {
  const { optInList } = await api.getOptInList({
    filter: JSON.stringify({ id }),
    limit: 1,
  }, config);
  return optInList?.[0] || null;
};

export default function OptInDetails() {
  const { addError } = useAlert();
  const isMounted = useMountedState();
  const { id } = useParams();
  const [chat, setChat] = useState<MessageType[]>([]);
  const [optIn, setOptIn] = useState<OptInList | null>(null);
  const [first, setFirst] = useState('');
  const [second, setSecond] = useState('');

  const navigate = useNavigate();

  const {
    isLoading,
    error,
    fetch,
  } = useRequest(getRecord);

  useEffect(() => {
    const loadChat = async () => {
      const data: OptInList = await fetch(Number(id));
      if (isMounted() && data.conversation?.chat?.conversation) {
        setChat(data.conversation?.chat?.conversation);
        setOptIn(data);
        const messages = data.conversation?.chat?.conversation.filter((m: any) => m.type === 'in');
        const firstMsg = messages?.[0]?.message || '';
        const secondMsg = messages?.[1]?.message || '';
        setFirst(firstMsg);
        setSecond(secondMsg);
      }
    };
    isMounted() && id && loadChat();
  }, [isMounted, fetch, id]);

  useEffect(() => {
    if (error) {
      addError(error.getMessage());
      console.error(error.getError());
    }
  }, [error, addError]);

  const pn = optIn?.phoneNumber ? phoneNumberFormat(optIn.phoneNumber) : '';

  return (
    <Drawer
      open
      loading={isLoading}
      disabled={isLoading}
      onClose={() => navigate('/opt-in/opt-in-list')}
      autoWidth
    >
      <Stack spacing={1}>
        <WhiteBox>
          <Typography variant="h3">{pn}</Typography>
        </WhiteBox>
        <WhiteBox sx={{ height: '100%' }}>
          <Stack spacing={2} direction="row" flexWrap="wrap" useFlexGap>
            <Stack spacing={2}>
              {first
                ? (
                  <Message
                    message={first}
                    systemNumber={optIn?.systemNumber || ''}
                    pdu={optIn?.pdu || ''}
                    ts={optIn?.timestamp || ''}
                  />
                )
                : null}
              {second
                ? (
                  <Message
                    message={second}
                    systemNumber={optIn?.systemNumberSecond || ''}
                    pdu={optIn?.pduSecond || ''}
                    ts={optIn?.timestampSecond || ''}
                  />
                )
                : null}
            </Stack>
            <Box>
              <Phone chat={chat} height={400} />
            </Box>
          </Stack>
        </WhiteBox>
      </Stack>
    </Drawer>
  );
}
