/* eslint-disable */
import { Char, Segment } from 'shared-scope/types';

export enum Encod {
  gsm7 = 'gsm7',
  gsm7tr = 'gsm7_tr',
  gsm7es = 'gsm7_es',
  gsm7pt = 'gsm7_pt',
  ascii = 'ascii',
  ucs2 = 'ucs2',
}

export const defaultEncodings = [Encod.gsm7, Encod.gsm7tr, Encod.gsm7es, Encod.gsm7pt, Encod.ascii, Encod.ucs2];

/**
 * Default max octets
 * @type {number}
 */
export const DEFAULT_MAX_OCTETS: number = 140;

/**
 * Encoding
 */
export default class Encoding {
  name: Encod | null = null;
  shift_code: number | null = null;
  supported_chars: Char[] = [];

  /**
   * Initialize
   */
  constructor() {
    this.init();
    this.initChars();
  }

  init() {}
  initChars() {}
  can_represent(chars: Char[]): boolean {
    return false;
  }
  split_segments(text: string, msg_ref_num?: number, force_concat?: boolean, max_octets?: number): Segment | null {
    return null;
  }

  toString(): string {
    return this.name?.toString() || '';
  }
}
