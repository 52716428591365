import React, { useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { s } from 'i18n';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { IconButton, Typography } from '@mui/material';
import { Campaign } from 'graphql-api';
import { BLOCK_STYLE } from 'products/Details/helpers';
import WhiteBox from 'shared-scope/components/Box/WhiteBox';
import { GridType } from 'products/CampaignList/types';
import { improve } from 'products/CampaignList/helpers';
import { TotalItem } from './TotalItem';


export type PriceProps = {
  data: Campaign
  type: GridType
};
export function Price({ data, type }: PriceProps) {
  const [expand, setExpand] = useState<boolean>(false);
  const messagesReplied = data?.messagesReplied || 0;
  const smsReplied = data?.smsReplied || 0;
  const billedAmountPlatform = data?.billedAmountPlatform || 0;

  const ic = improve(data);

  const totalCosts = useMemo(() => {
    if (data?.billedAmountRouter) {
      const costs = data.billedAmountRouter;
      const costsKeys = Object.keys(costs);

      return costsKeys.map((key) => (
        <Stack key={key} direction="row" justifyContent="space-between" sx={{ pl: 1 }}>
          <Typography color="text.secondary">{`${key} route cost`}</Typography>
          <Typography color="error">$ {Number(costs[key]).toFixed(4)}</Typography>
        </Stack>
      ));
    }
    return [];
  }, [data?.billedAmountRouter]);

  const parentBlock = document.getElementById('price-container');

  return (
    <WhiteBox id="price-container" sx={{ height: '100%' }}>
      <Stack
        direction="row"
        spacing={1}
        sx={{ height: '100%' }}
      >
        <Stack
          spacing={2}
          direction="column"
          sx={{ width: '100%' }}
        >
          <Typography variant="h3">{s('Amounts')}</Typography>
          <Stack
            spacing={2}
            direction="column"
            justifyContent="center"
            flexGrow={1}
          >
            <TotalItem
              title={s('Total messages')}
              titleColor="primary"
              value={ic.successCount}
              total={ic.messagesTotal}
              error={ic.errorCount}
            />
            <TotalItem
              title={s('Total SMS')}
              titleColor="primary"
              value={ic.smsSuccessCount}
              total={ic.smsTotal}
              error={ic.smsErrorCount}
            />
            <Stack direction="row" justifyContent="space-between">
              <Typography color="text.primary">{s('Total cost')}</Typography>
              <Typography color="error">$ {Number(data?.billedAmount || 0).toFixed(4)}</Typography>
            </Stack>
          </Stack>
        </Stack>
        <IconButton
          key="expand-price"
          size="medium"
          onClick={() => setExpand(!expand)}
          color="inherit"
          sx={{ borderRadius: 10 }}
        >
          <i className="icon-select" />
        </IconButton>
      </Stack>
      {(expand && parentBlock)
        ? ReactDOM.createPortal(
          <Box
            sx={{
              ...BLOCK_STYLE,
              position: 'absolute',
              width: 'calc(100% - 8px)',
              zIndex: 2000,
              height: 'auto',
              top: '8px',
              left: '8px',
              boxShadow: '0 0 3px 0 rgba(0, 0, 0, 0.25)',
            }}
          >
            <Stack
              spacing={1}
              direction="row"
              alignItems="stretch"
            >
              <Stack
                spacing={1}
                direction="column"
                sx={{ width: '100%' }}
              >
                <Typography variant="h3">{s('Amounts')}</Typography>

                {/* Total messages */}
                <TotalItem
                  title={s('Total messages')}
                  titleColor="primary"
                  value={ic.successCount}
                  total={ic.messagesTotal}
                  error={ic.errorCount}
                />
                <TotalItem
                  title={type === GridType.Poll ? s('Engagement') : s('Sent')}
                  titleColor="secondary"
                  value={ic.cSuccessCount}
                  total={ic.cMessagesTotal}
                  error={ic.cErrorCount}
                />
                <Stack direction="row" justifyContent="space-between" sx={{ pl: 1 }}>
                  <Typography color="text.secondary">{s('Incoming messages')}</Typography>
                  <Typography color="text.primary">{messagesReplied.shortFormNumberStr()}</Typography>
                </Stack>
                {type === GridType.Poll
                  ? (
                    <TotalItem
                      title={s('Conversation')}
                      titleColor="secondary"
                      value={ic.ssSuccessCount}
                      total={ic.ssMessagesTotal}
                      error={ic.ssErrorCount}
                    />
                  )
                  : null}

                {/* Total SMS */}
                <TotalItem
                  title={s('Total SMS')}
                  titleColor="primary"
                  value={ic.smsSuccessCount}
                  total={ic.smsTotal}
                  error={ic.smsErrorCount}
                />
                <TotalItem
                  title={type === GridType.Poll ? s('Engagement') : s('Sent')}
                  titleColor="secondary"
                  value={ic.cSmsSuccessCount}
                  total={ic.cSmsTotal}
                  error={ic.cSmsErrorCount}
                />
                <Stack direction="row" justifyContent="space-between" sx={{ pl: 1 }}>
                  <Typography color="text.secondary">{s('Incoming messages')}</Typography>
                  <Typography color="text.primary">{smsReplied.shortFormNumberStr()}</Typography>
                </Stack>
                {type === GridType.Poll
                  ? (
                    <TotalItem
                      title={s('Conversation')}
                      titleColor="secondary"
                      value={ic.ssSmsSuccessCount}
                      total={ic.ssSmsTotal}
                      error={ic.ssSmsErrorCount}
                    />
                  )
                  : null}

                {/* Total costs */}
                <Stack direction="row" justifyContent="space-between">
                  <Typography color="text.primary">{s('Total cost')}</Typography>
                  <Typography color="error">$ {Number(data?.billedAmount || 0).toFixed(4)}</Typography>
                </Stack>
                {totalCosts}
                <Stack direction="row" justifyContent="space-between" sx={{ pl: 1 }}>
                  <Typography color="text.secondary">{s('Platform fee')}</Typography>
                  <Typography color="error">$ {Number(billedAmountPlatform).toFixed(4)}</Typography>
                </Stack>
              </Stack>

              <IconButton
                key="expand-price"
                size="medium"
                onClick={() => setExpand(!expand)}
                color="inherit"
                sx={{
                  borderRadius: 10,
                  transform: 'rotate(180deg)',
                }}
              >
                <i className="icon-select" />
              </IconButton>
            </Stack>
          </Box>,
          parentBlock,
        )
        : null}
    </WhiteBox>
  );
}
