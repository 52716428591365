import {
  Autocomplete, Chip, TextField,
} from '@mui/material';
import { OutlinedTextFieldProps } from '@mui/material/TextField/TextField';
import { useMemo } from 'react';
import { omit, words } from 'lodash';

export default function TagBox(
  {
    value,
    onChange,
    textField,
    returnArray,
  }: TagBoxProps) {
  const tagsValue = useMemo(() => {
    return Array.isArray(value) ? value : words(value);
  }, [value]);

  return <Autocomplete
    multiple
    options={[]}
    value={tagsValue}
    onChange={(e, v) => {
      const clearValue = v.map((vv) => vv.replace(/^\+\s/, ''));
      onChange(returnArray ? clearValue : clearValue.join(' '));
    }}
    freeSolo
    selectOnFocus
    clearOnBlur
    handleHomeEndKeys
    renderTags={
      (v: readonly string[], getTagProps) => (
        v.map((option: string, index: number) => (
          <Chip variant="outlined" label={option} {...getTagProps({ index })} />
        ))
      )
    }
    renderInput={(params) => (
      <TextField
        {...omit(textField, ['value', 'onChange'])}
        {...params}
      />
    )}
    filterOptions={(options, params) => {
      const { inputValue } = params;
      const newValues = [];
      // Suggest the creation of a new value
      if (inputValue !== '') {
        newValues.push(`+ ${inputValue}`);
      }
      return newValues;
    }}
  />;
}

interface TagBoxProps {
  value: string | string[]
  onChange: (v: string | string[]) => void
  returnArray?: boolean
  label?: string
  placeholder?: string
  textField?: OutlinedTextFieldProps
}
