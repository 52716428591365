import {
  Button, Icon, Stack, Typography,
} from '@mui/material';
import { s } from 'i18n';
import React, { useCallback, useState } from 'react';
import axios from 'axios';
import * as CONFIG from 'shared-scope/config';
import Loading from 'shared-scope/components/Loading';

export default function AiRephrase({
 value, onChange, onClick, onError, disabled,
}: AiRephraseProps) {
  const [loading, setLoading] = useState(false);

  const rephrase = useCallback(async (type: RephrasePrompt) => {
    try {
      setLoading(true);
      onError && onError(s(''));
      const { data } = await axios.post(`${CONFIG.get('api')}/aiPrompt/execute`,
        { text: value, prompt: type },
      );
      onChange(data.answer);
    } catch (e) {
      onError && onError(s('AI rephrase error'));
    } finally {
      setLoading(false);
    }
  }, [value, onChange, onError]);

  return <Stack direction="row" spacing={1} alignItems="center">
    <Icon className="icon-ai" fontSize="small" />
    <Typography variant="subtitle1" color={(theme) => theme.palette.normal.main}>
      {s('Rephrase')}:
    </Typography>
    <Stack direction="row" spacing={0} alignItems="center">
      {loading ? <Loading logo={false} size={20} />
        : rephraseTypes.map((t) => (
          <Button
            key={t.k}
            variant="text"
            size="small"
            disabled={disabled}
            onClick={() => {
              onClick && onClick(t.k);
              rephrase(t.k);
            }}
            sx={{
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '20px',
            padding: '2px 4px',
          }}
          >{t.v}</Button>
        ))}
    </Stack>
  </Stack>;
}

/**
 * It's prompts aliases from mobius/ai_prompt
 */
enum RephrasePrompt {
  neutral = 'vote_rewriter_neutral',
  formal = 'vote_rewriter_formal',
  friendly = 'vote_rewriter_friendly',
}

const rephraseTypes = [
  { k: RephrasePrompt.neutral, v: 'Neutral' },
  { k: RephrasePrompt.formal, v: 'Formal' },
  { k: RephrasePrompt.friendly, v: 'Friendly' },
];

type AiRephraseProps = {
  value: string
  onChange: (v: string) => void
  onClick?: (v?: string) => void
  onError?: (v: string) => void
  disabled?: boolean
};
