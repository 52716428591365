import range from 'lodash/range';
import { Char, Segment } from 'shared-scope/types';
import difference from 'lodash/difference';
import Encoding, { DEFAULT_MAX_OCTETS, Encod } from './Encoding';


/**
 * ASC II
 */
export default class ASCII extends Encoding {
  name = Encod.ascii;

  initChars() {
    this.supported_chars = range(0, 128)
      .map((v) => String.fromCharCode(v));
  }

  can_represent(chars: Char[]): boolean {
    return difference(chars, this.supported_chars).length === 0;
  }

  split_segments(
    text: string,
    msg_ref_num = 1,
    force_concat = false,
    max_octets = DEFAULT_MAX_OCTETS,
  ): Segment {
    let segments = [''];
    let perSegment = DEFAULT_MAX_OCTETS;

    const lenText = text.length;
    if (force_concat || lenText > max_octets) {
      let payloadOctets = max_octets;
      if (msg_ref_num <= 0xff) {
        payloadOctets -= 5;
      } else {
        payloadOctets -= 6;
      }
      const segmentChars = payloadOctets;
      segments = range(0, lenText, segmentChars)
        .map((i) => text.substring(i, i + segmentChars));
      perSegment = segmentChars;
    } else {
      segments = [text];
    }
    return {
      segments,
      per_segment: perSegment,
    };
  }
}
