import {
 Box, FormControlLabel, Stack, Switch,
} from '@mui/material';
import { s } from 'i18n';
import React from 'react';

export default function SchedulerLabel(
  {
    value,
    onChange,
  }: {
    value: boolean
    onChange: (v: boolean) => void
  }) {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      textAlign="center"
      maxWidth="600px"
      width="100%"
      whiteSpace="nowrap"
    >
      <Box>{s('Schedule')}</Box>
      <FormControlLabel
        control={<Switch
          checked={value}
          onChange={(e) => {
            onChange(e.target.checked);
          }}
          inputProps={{ 'aria-label': 'controlled' }}
        />}
        label={value ? s('Enable') : s('Disable')}
      />
    </Stack>
  );
}
