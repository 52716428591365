import { Char, Segment } from 'shared-scope/types';
import difference from 'lodash/difference';
import reduce from 'lodash/reduce';
import forEach from 'lodash/forEach';
import Encoding, { DEFAULT_MAX_OCTETS, Encod } from './Encoding';

/**
 * GSM7 basic chars
 * @type {Char[]}
 */
export const gsm7BasicChars: Char[] = [
  '@', '£', '$', '¥', 'è', 'é', 'ù', 'ì',
  'ò', 'Ç', '\n', 'Ø', 'ø', '\r', 'Å', 'å',
  'Δ', '_', 'Φ', 'Γ', 'Λ', 'Ω', 'Π', 'Ψ',
  'Σ', 'Θ', 'Ξ', ' ', 'Æ', 'æ', 'ß', 'É',
  ' ', '!', '"', '#', '¤', '%', '&', "'",
  '(', ')', '*', '+', ',', '-', '.', '/',
  '0', '1', '2', '3', '4', '5', '6', '7',
  '8', '9', ':', ';', '<', '=', '>', '?',
  '¡', 'A', 'B', 'C', 'D', 'E', 'F', 'G',
  'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O',
  'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W',
  'X', 'Y', 'Z', 'Ä', 'Ö', 'Ñ', 'Ü', '§',
  '¿', 'a', 'b', 'c', 'd', 'e', 'f', 'g',
  'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o',
  'p', 'q', 'r', 's', 't', 'u', 'v', 'w',
  'x', 'y', 'z', 'ä', 'ö', 'ñ', 'ü', 'à',
];

/**
 * GSM7 basic ext chars
 * @type {Char[]}
 */
export const gsm7BasicExtChars: Char[] = [
  null, null, null, null, null, null, null, null,
  null, null, '\f', null, null, null, null, null,
  null, null, null, null, '^', null, null, null,
  null, null, null, null, null, null, null, null,
  null, null, null, null, null, null, null, null,
  '{', '}', null, null, null, null, null, '\\',
  null, null, null, null, null, null, null, null,
  null, null, null, null, '[', '~', ']', null,
  '|', null, null, null, null, null, null, null,
  null, null, null, null, null, null, null, null,
  null, null, null, null, null, null, null, null,
  null, null, null, null, null, null, null, null,
  null, null, null, null, null, '€', null, null,
  null, null, null, null, null, null, null, null,
  null, null, null, null, null, null, null, null,
  null, null, null, null, null, null, null, null,
];

/**
 * GSM7 class
 */
export default class GSM7 extends Encoding {
  name: Encod = Encod.gsm7;
  shift_code: number | null = null;
  basic_chars: Char[] = [];
  ext_chars: Char[] = [];
  ext_chars_set: Char[] = [];

  /**
   * Constructor
   */
  init() {
    this.basic_chars = gsm7BasicChars;
    this.ext_chars = gsm7BasicExtChars;
  }

  initChars() {
    this.ext_chars_set = this.ext_chars.filter((v) => v !== null);
    this.supported_chars = ([] as Char[]).concat(this.basic_chars, this.ext_chars)
      .filter((v) => v !== null);
  }

  can_represent(chars: Char[]): boolean {
    return difference(chars, this.supported_chars).length === 0;
  }

  getSeptets(octets: number): number {
    return Math.floor((octets * 8) / 7);
  }

  split_segments(
    text: string,
    msg_ref_num = 1,
    force_concat = false,
    max_octets = DEFAULT_MAX_OCTETS,
  ): Segment {
    if (!text) {
      return {
        segments: [text],
        per_segment: this.getSeptets(DEFAULT_MAX_OCTETS),
      };
    }

    let udhi = false;
    let concat = false;
    let payloadOctets = max_octets;

    if (this.shift_code !== null) {
      if (!udhi) {
        udhi = true;
        payloadOctets -= 1;
      }
      payloadOctets -= 3;
    }

    if (force_concat) {
      concat = true;
    } else {
      const payloadSeptets = this.getSeptets(payloadOctets);
      const encodedTextSeptets = reduce(
        text.split(''),
        (sum, char) => sum + (this.ext_chars_set.indexOf(char) >= 0 ? 2 : 1),
        0,
      );
      if (encodedTextSeptets > payloadSeptets) {
        concat = true;
      }
    }

    if (concat) {
      if (!udhi) {
        // udhi = True -- Does not matter anymore
        payloadOctets -= 1;
      }
      if (msg_ref_num <= 0xff) {
        payloadOctets -= 5;
      } else {
        payloadOctets -= 6;
      }
    }

    const payloadSeptets = this.getSeptets(payloadOctets);
    const segments = [];
    let segmentSeptets = 0;

    let total = 0;
    let i = 0;
    forEach(
      text.split(''),
      (c, j) => {
        const chunkSeptets = this.ext_chars_set.indexOf(c) >= 0 ? 2 : 1;
        segmentSeptets += chunkSeptets;
        total += chunkSeptets;
        if (segmentSeptets > payloadSeptets) {
          segments.push(text.substring(i, j));
          segmentSeptets = chunkSeptets;
          i = j;
        }
      },
    );

    if (segmentSeptets > 0) {
      segments.push(text.substring(i));
    }

    return {
      segments,
      per_segment: payloadSeptets,
      total,
    };
  }
}
