import * as CONFIG from 'shared-scope/config';
import axios from 'axios';
import { Maybe } from 'src/graphql-api';
import { ApiQueryParams, ApiQueryResult } from '@xeebi/neru';
import {
  STATE_ABANDONED,
  STATE_ERROR,
  STATE_TAKEN_OVER,
} from 'products/CampaignList/helpers';
import { fetcher } from 'graphql-api/fetcher';
import { getSdk } from './query.generated';


const api = getSdk(fetcher);

export const ALL = 'all';
export const ATTENTION = 'attention';

export const loadConversationStat = async (campaignId: number) => {
  // eslint-disable-next-line object-shorthand
  const { conversationStat } = await api.getConversationStat({ campaignId: campaignId });
  return conversationStat;
};

export const needAttentionStates = [STATE_ERROR, STATE_ABANDONED, STATE_TAKEN_OVER];
export const loadConversation = async (params: ApiQueryParams, campaignId: Maybe<number> | undefined, type: 'all' | 'attention') => {
  const filter = type === ATTENTION
    ? { $and: [{ 'campaign.id': campaignId }, { state: { $in: needAttentionStates } }] }
    : { 'campaign.id': campaignId };

  params.filter = JSON.stringify(filter);
  params.sort = JSON.stringify([{ createTs: 1 }]);

  if (!campaignId) {
    return { count: 0, rows: [] } as ApiQueryResult;
  }
  const stat = await loadConversationStat(campaignId);
  const count = type === ATTENTION
    ? stat?.attention || 0
    : stat?.total || 0;

  const { conversation } = await api.getConversation(params);

  return { count, rows: conversation, stat } as any;
};

export const sendMessage = async (campaignId: number, message: string, phoneNumber: string, conversationId: number) => {
  try {
    const { data }: any = await axios.post(`${CONFIG.get('api')}/conversation/${campaignId}/send-message`,
      {
        message,
        phoneNumber,
        conversationId,
      },
    );
    return (data?.success || false) as boolean;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const submit = async (
  campaignId: number,
  state: number,
  phoneNumber: string,
  conversationId: number,
) => {
  try {
    const { data }: any = await axios.post(`${CONFIG.get('api')}/conversation/${campaignId}/set-state`, {
      state,
      phoneNumber,
      conversationId,
    });
    return (data?.success || false) as boolean;
  } catch (e) {
    console.log(e);
  }
  return false;
};

export const PROGRESS_COLORS = [
  '#F94144',
  '#F9C74F',
  '#90BE6D',
  '#5E35B1',
  '#D81B60',
  '#FF6F00',
  '#1E88E5',
  '#00897B',
  '#8E24AA',
];

// OPT IN CHART STATUSES
export const IN_PROGRESS = 0;
export const COMPLETED = 1;
export const OPTED_OUT = 2;
export const ERROR = 3;
export const EXPORTED = 4;

export const OPT_IN_CHART_STATUSES: { [index: number]: any } = {
  [IN_PROGRESS]: {
    label: 'In progress',
    color: '#FF6F00',
  },
  [COMPLETED]: {
    label: 'Completed',
    color: '#00897B',
  },
  [OPTED_OUT]: {
    label: 'Opted out',
    color: '#1E88E5',
  },
  [ERROR]: {
    label: 'Error',
    color: '#D81B60',
  },
  [EXPORTED]: {
    label: 'Completed (Exported)',
    color: '#F9C74F',
  },
};

export const ERROR_GROUP = {
  __INVALID__: {
    label: 'INVALID',
    color: '#4A4658',
  },
  __NO_ANSWER__: {
    label: 'NO ANSWER',
    color: '#7C788A',
  },
};
