import { AxiosError } from 'axios';

export default class RequestError {
  private readonly error: AxiosError;

  constructor(error: AxiosError) {
    this.error = error;
  }

  getCode(): RequestErrorCode | null {
    return this.error.code as RequestErrorCode || null;
  }

  getMessage(): string {
    let message = '';
    if (this.error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      const resp = this.error.response.data as ErrorResponseData;
      message = resp.message || resp.error || this.error.message || '';
    } else if (this.error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
    } else {
      // Something happened in setting up the request that triggered an Error
      message = this.error.message;
    }

    return message;
  }

  getError(): AxiosError {
    return this.error;
  }
}

type ErrorResponseData = {
  message?: string
  event?: string
  error?: string
};

/**
 * Add additional statuses if necessary
 */
export enum RequestErrorCode {
  cancelled = 'ERR_CANCELED',
  badResponse = 'ERR_BAD_RESPONSE',
}
