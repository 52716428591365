import React from 'react';
import { s } from 'i18n';
import { Button } from '@mui/material';
import { GridType } from 'products/CampaignList/types';
import { useNavigate } from 'react-router-dom';
import DataGrid from 'products/CampaignList/Grid';
import Page from 'layout/Page';


export default function Campaign() {
  const navigate = useNavigate();

  return (
    <Page
      title={s('Campaigns')}
      headTools={[
        <Button key="create" variant="contained" onClick={() => navigate('/campaign/new')}>
          {s('Create text campaign')}
        </Button>,
      ]}
    >
      <DataGrid gridType={GridType.TextCampaign} />
    </Page>
  );
}
