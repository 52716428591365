import {
  Box, Stack, Switch, TextField,
} from '@mui/material';
import { s } from 'i18n';
import { useEffect, useState } from 'react';

export default function ResendQuestion(
  {
    value,
    error = false,
    helperText = [],
    onChange,
  }: {
    value: string
    error: boolean
    helperText: string[] | undefined
    onChange: (v: string) => void
  }) {
  const [enabled, setEnabled] = useState(false);
  const [minutes, setMinutes] = useState<string>('10');

  useEffect(() => {
    setEnabled(value !== '0');
    setMinutes(value === '0' ? '10' : value);
  }, [value]);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      textAlign="center"
      maxWidth="600px"
      width="100%"
      whiteSpace="nowrap"
      alignItems="start"
      spacing={2}
    >
      <Switch
        checked={enabled}
        onChange={(e) => {
          setEnabled(e.target.checked);
          if (e.target.checked) {
            onChange(minutes);
          } else {
            onChange('0');
          }
        }}
        inputProps={{ 'aria-label': 'controlled' }}
        sx={{ marginTop: '12px !important' }}
      />
      <Box sx={{ marginTop: '12px !important' }}>{s('Resend question if no answer received in')}</Box>
      <TextField
        value={minutes}
        disabled={!enabled}
        onChange={(e) => {
          setMinutes(e.target.value);
          onChange(e.target.value);
        }}
        error={error}
        helperText={helperText}
        type="number"
      />
      <Box sx={{ marginTop: '12px !important' }}>{s('minutes')}</Box>
    </Stack>
  );
}
