import { USA } from 'shared-scope/const';
import { s } from 'i18n';
import { CustomError, Mapping, MappingTitle } from '../types';

export const DEFAULTS = {
  boost_mode: false,
  schedule_times: '10819,20819,30819,40819,50819',
  schedule_tz: 'PST',
  country_id: USA,
  remove_duplicated_numbers: true,
};

export const US_TIME_ZONES = [
  {
    v: 'Atlantic',
    k: ['America/Anguilla', 'America/Antigua', 'America/Barbados', 'America/Blanc-Sablon', 'America/Dominica', 'America/Grenada', 'America/Lower_Princes', 'America/Montserrat', 'America/Port_of_Spain', 'America/Puerto_Rico', 'America/Santo_Domingo', 'America/St_Kitts', 'America/St_Lucia', 'America/St_Thomas', 'America/St_Vincent', 'America/Tortola'],
  },
  {
    v: 'Eastern',
    k: ['America/Detroit', 'America/Grand_Turk', 'America/Indiana/Indianapolis', 'America/Indiana/Marengo', 'America/Indiana/Petersburg', 'America/Indiana/Vevay', 'America/Indiana/Vincennes', 'America/Indiana/Winamac', 'America/Iqaluit', 'America/Kentucky/Louisville', 'America/Kentucky/Monticello', 'America/Nassau', 'America/New_York', 'America/Toronto'],
  },
  {
    v: 'Central',
    k: ['America/Chicago', 'America/Indiana/Knox', 'America/Indiana/Tell_City', 'America/Menominee', 'America/North_Dakota/Beulah', 'America/North_Dakota/Center', 'America/North_Dakota/New_Salem', 'America/Rankin_Inlet', 'America/Resolute', 'America/Winnipeg'],
  },
  {
    v: 'Mountain',
    k: ['America/Boise', 'America/Cambridge_Bay', 'America/Denver', 'America/Edmonton', 'America/Inuvik', 'America/Yellowknife'],
  },
  {
    v: 'Pacific',
    k: ['America/Los_Angeles', 'America/Vancouver'],
  },
  {
    v: 'Alaska',
    k: ['America/Anchorage', 'America/Juneau', 'America/Metlakatla', 'America/Nome', 'America/Sitka', 'America/Yakutat'],
  },
  {
    v: 'Hawaii–Aleutian',
    k: ['America/Adak', 'Pacific/Honolulu'],
  },
  {
    v: 'American Samoa',
    k: ['Pacific/Pago_Pago'],
  },
  {
    v: 'Chamorro',
    k: ['Pacific/Guam', 'Pacific/Saipan'],
  },
  {
    v: 'Newfoundland',
    k: ['America/St_Johns'],
  },
  {
    v: 'Atlantic/Bermuda',
    k: ['America/Glace_Bay', 'America/Goose_Bay', 'America/Halifax', 'America/Moncton', 'Atlantic/Bermuda'],
  },
  {
    v: 'Jamaica',
    k: ['America/Atikokan,America/Cayman,America/Jamaica'],
  },
  {
    v: 'Swift Current',
    k: ['America/Regina', 'America/Swift_Current'],
  },
  {
    v: 'Phoenix',
    k: ['America/Creston', 'America/Dawson', 'America/Dawson_Creek', 'America/Fort_Nelson', 'America/Hermosillo', 'America/Phoenix', 'America/Whitehorse'],
  },
];

export const GENDERS = [
  { k: 'F', v: s('Female') },
  { k: 'M', v: s('Male') },
];

export enum MappingColumns {
  COLUMN_ID = 'address_id',
  COLUMN_PHONE = 'phone_number',
  COLUMN_FIRST_NAME = 'first_name',
  COLUMN_LAST_NAME = 'last_name',
  COLUMN_GENDER = 'gender',
  COLUMN_STATE = 'state',
  COLUMN_COUNTRY = 'country',
  COLUMN_TIMEZONE = 'tz',
  COLUMN_CUSTOM_1 = 'custom_1',
  COLUMN_CUSTOM_2 = 'custom_2',
  COLUMN_CUSTOM_3 = 'custom_3',
  COLUMN_CUSTOM_4 = 'custom_4',
  COLUMN_CUSTOM_5 = 'custom_5',
  COLUMN_CUSTOM_6 = 'custom_6',
  COLUMN_CUSTOM_7 = 'custom_7',
  COLUMN_CUSTOM_8 = 'custom_8',
  COLUMN_CUSTOM_9 = 'custom_9',
  COLUMN_CUSTOM_10 = 'custom_10',
  COLUMN_CLIENT_MESSAGE_ID = 'client_message_id',
  COLUMN_TRACKING_ID = 'tracking_id',
  COLUMN_BIRTHDAY = 'birthday',
}

export const OPT_IN_DATA = 'opt_in_data';

export const OPT_IN_DATA_FIELDS = [
  MappingColumns.COLUMN_TRACKING_ID,
];

const dontImport: MappingTitle = { do_not_import: s('Skip') };
const basedMappingColumns: MappingTitle = {
  [MappingColumns.COLUMN_PHONE]: s('Phone number'),
  [MappingColumns.COLUMN_FIRST_NAME]: s('First name'),
  [MappingColumns.COLUMN_LAST_NAME]: s('Last name'),
  [MappingColumns.COLUMN_GENDER]: s('Gender'),
  [MappingColumns.COLUMN_STATE]: s('State'),
  [MappingColumns.COLUMN_TIMEZONE]: s('Time zone'),
  [MappingColumns.COLUMN_TRACKING_ID]: s('Tracking ID'),
  [MappingColumns.COLUMN_BIRTHDAY]: s('Birthday'),
};
const customMappingColumns: MappingTitle = {
  [MappingColumns.COLUMN_CUSTOM_1]: 'Custom 1',
  [MappingColumns.COLUMN_CUSTOM_2]: 'Custom 2',
  [MappingColumns.COLUMN_CUSTOM_3]: 'Custom 3',
  [MappingColumns.COLUMN_CUSTOM_4]: 'Custom 4',
  [MappingColumns.COLUMN_CUSTOM_5]: 'Custom 5',
  [MappingColumns.COLUMN_CUSTOM_6]: 'Custom 6',
  [MappingColumns.COLUMN_CUSTOM_7]: 'Custom 7',
  [MappingColumns.COLUMN_CUSTOM_8]: 'Custom 8',
  [MappingColumns.COLUMN_CUSTOM_9]: 'Custom 9',
  [MappingColumns.COLUMN_CUSTOM_10]: 'Custom 10',
  [MappingColumns.COLUMN_CLIENT_MESSAGE_ID]: 'Client message ID',
};

export const ImportColumns: MappingTitle = {
  ...dontImport,
  ...basedMappingColumns,
};

export const MappingColumnsTitles: MappingTitle = {
  ...basedMappingColumns,
  ...customMappingColumns,
};

export const MAPPING_CAMPAIGN: Mapping = {
  [MappingColumns.COLUMN_ID]: 0,
  [MappingColumns.COLUMN_PHONE]: 1,
  [MappingColumns.COLUMN_COUNTRY]: 2,
  [MappingColumns.COLUMN_STATE]: 3,
  [MappingColumns.COLUMN_TIMEZONE]: 4,
  [MappingColumns.COLUMN_FIRST_NAME]: 5,
  [MappingColumns.COLUMN_LAST_NAME]: 6,
  [MappingColumns.COLUMN_GENDER]: 7,
  [MappingColumns.COLUMN_CUSTOM_1]: 8,
  [MappingColumns.COLUMN_CUSTOM_2]: 9,
  [MappingColumns.COLUMN_CUSTOM_3]: 10,
  [MappingColumns.COLUMN_CUSTOM_4]: 11,
  [MappingColumns.COLUMN_CUSTOM_5]: 12,
  [MappingColumns.COLUMN_CUSTOM_6]: 13,
  [MappingColumns.COLUMN_CUSTOM_7]: 14,
  [MappingColumns.COLUMN_CUSTOM_8]: 15,
  [MappingColumns.COLUMN_CUSTOM_9]: 16,
  [MappingColumns.COLUMN_CUSTOM_10]: 17,
  [MappingColumns.COLUMN_CLIENT_MESSAGE_ID]: 18,
};

export const MAPPING_PHONE_BOOK: Mapping = {
  [MappingColumns.COLUMN_ID]: 0,
  [MappingColumns.COLUMN_PHONE]: 1,
  [MappingColumns.COLUMN_STATE]: 2,
  [MappingColumns.COLUMN_TIMEZONE]: 3,
  [MappingColumns.COLUMN_FIRST_NAME]: 4,
  [MappingColumns.COLUMN_LAST_NAME]: 5,
  [MappingColumns.COLUMN_GENDER]: 6,
  [MappingColumns.COLUMN_CUSTOM_1]: 7,
  [MappingColumns.COLUMN_CUSTOM_2]: 8,
  [MappingColumns.COLUMN_CUSTOM_3]: 9,
  [MappingColumns.COLUMN_BIRTHDAY]: 10,
  [MappingColumns.COLUMN_TRACKING_ID]: 11,
};

export const errorMapMessage: Record<CustomError, string> = {
  [CustomError.MESSAGE_IS_NOT_EMPTY]: s('Message can\'t be empty'),
  [CustomError.ANSWER_IS_NOT_EMPTY]: s('Answer can\'t be empty'),
  [CustomError.ATT_IS_NOT_ALLOWED]: s('Attachments are not allowed on your route'),
  [CustomError.ATT_BROKEN]: s('The file is broken and cannot be attached to the message'),
  [CustomError.ATT_MAX_SIZE]: s('The file is too big. It will be deleted when the campaign is created'),
};
