import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import React, {
} from 'react';
import * as Tool from 'products/CampaignList/components/Tools';
import { Confirmation, ToolType } from 'products/CampaignList/components/Tools';
import { Maybe } from 'graphql-api';


export type ActionToolProps = {
  campaignId: Maybe<number | undefined>
  tool: Tool.ToolType
  onAction: (action: Function, confirm: Confirmation | null, key: string) => any
  loading?: boolean
};
export function ActionTool({
  campaignId,
  tool,
  onAction,
}: ActionToolProps) {
  const navigate = useNavigate();
  const actionHandler = (t: ToolType) => {
    t.handler && onAction && onAction(t.handler, t.confirmation || null, t.key);

    if (t.redirect && campaignId) {
      const path = (typeof t.redirect === 'string') ? t.redirect : t.redirect(campaignId);
      navigate(path);
    }
  };
  return (
    <IconButton
      key={`${campaignId}_${tool.key}`}
      size="small"
      onClick={(e) => {
        e.stopPropagation();
        actionHandler(tool);
      }}
      aria-label={tool.caption}
      sx={{
        border: '2px solid',
        borderRadius: '50%',
        width: 38,
        height: 38,
      }}
      color={tool?.color}
    >
      {tool.icon}
    </IconButton>
  );
}
