import { FormProvider, useForm } from 'shared-scope/components/Form';
import { useNavigate, useParams } from 'react-router-dom';
import useAlert from 'shared-scope/hooks/useAlert';
import { useRequest } from '@xeebi/neru';
import React, {
 useCallback, useEffect, useMemo, useState,
} from 'react';
import {
 FormField, request, SchedulerLabel, Sources,
} from 'products/shared/Campaign';
import {
  Actions,
  StepGeneral,
  StepQuestions,
  StepScheduler,
  StepSettings,
  StepTarget,
  NewPollRow,
  StepSettingsReadOnly,
} from 'products/shared/Campaign/steps';
import { s } from 'i18n';
import Page from 'layout/Page';
import { getCampaignData } from 'products/Poll/helpers';
import { Step, Stepper } from 'shared-scope/components/Stepper';
import { CountryCapability } from 'graphql-api';
import { Typography } from '@mui/material';
import { GetCampaignsQuery, getSdk } from 'products/shared/Campaign/queries.generated';
import { fetcher } from 'graphql-api/fetcher';
import { getQuestionFull } from 'products/shared/Campaign/helpers';
import { omit } from 'lodash';
import { QuestionType } from 'products/common/types';


const apiCampaign = getSdk(fetcher);
const back = '/poll';

export default function ClonePoll({ newPoll, useParticipants = false }: { newPoll?: boolean, useParticipants?: boolean }) {
  const { cloneId = 0 } = useParams();
  const form = useForm<NewPollRow>();
  const navigate = useNavigate();
  const {
    addSuccess,
    addError,
  } = useAlert();

  const {
    isLoading,
    error,
    fetch: createPoll,
    result,
  } = useRequest(request.createCampaign);
  const {
    isLoading: isLoadingCampaign,
    error: errorCampaign,
    fetch: fetchCampaign,
    result: campaigns,
  } = useRequest<GetCampaignsQuery>(apiCampaign.getCampaigns);

  const setInitial = useMemo(() => form.setInitial, [form]);

  const [schedulerOn, setSchedulerOn] = useState(false);
  const [scheduleTimes, setScheduleTimes] = useState<string | null>(null);

  const onSubmit = useCallback(async (row: NewPollRow) => {
    await createPoll(getCampaignData(row, schedulerOn));
  }, [schedulerOn, createPoll]);

  const onDraft = useCallback(async () => {
    const row = form.getRow();
    if (row) {
      await createPoll(getCampaignData(row, schedulerOn, true));
    }
  }, [schedulerOn, createPoll, form]);

  useEffect(() => {
    error && addError(s('Error creating a new poll'));
    errorCampaign && addError(errorCampaign.getMessage());
  }, [error, errorCampaign, addError]);

  useEffect(() => {
    result && addSuccess(s('New poll added successfully'));
    result && navigate(back);
  }, [result, addSuccess, navigate]);

  useEffect(() => {
    cloneId && fetchCampaign({
      filter: JSON.stringify({ id: { $eq: +cloneId } }),
    });
  }, [fetchCampaign, cloneId]);

  const clonedCampaign = useMemo(
    () => (campaigns?.campaign?.length ? campaigns.campaign[0] : null),
    [campaigns?.campaign],
  );

  /**
   * Set form initial for simple fields
   */
  useEffect(() => {
    setSchedulerOn(clonedCampaign?.scheduleEnabled || false);
    clonedCampaign
      && setInitial({
        title: 'COPY ' + (clonedCampaign.name || ''),
        source_type: newPoll ? Sources.excel : Sources.campaign,
        questions: {
          initialMessage: clonedCampaign.messageTemplates?.[0]?.text || '',
          initialAnswer: clonedCampaign.params.reply_actions?.[0]?.keywords || '',
          initialAttachment: { id: clonedCampaign.attachmentId || null },
          template: {
            ...omit(clonedCampaign.conversationTemplate, 'questions'),
            questions: clonedCampaign.conversationTemplate?.questions?.map((q: QuestionType) => getQuestionFull(q)) || [],
          },
        },
        schedule_times: clonedCampaign.scheduleTimes || '',
        mapping: clonedCampaign.params?.mapping || {},
        useParticipants,
        second_stage_resend_question_if_no_answer_min: clonedCampaign.params?.second_stage_resend_question_if_no_answer_min || '0',
        gpt_wrong_answer_check_enabled: clonedCampaign.params?.gpt_wrong_answer_check_enabled || false,
      });
  }, [clonedCampaign, newPoll, setInitial, useParticipants]);

  useEffect(() => {
    const st = (form.getValue(FormField.route) as CountryCapability)?.scheduleTimes || null;
    setScheduleTimes(st);
    if (st) {
      setSchedulerOn(true);
    }
  }, [form]);

  return (
    <Page
      title={s('Clone poll from :name', { name: clonedCampaign?.name })}
      back={back}
      loading={isLoading || isLoadingCampaign}
    >
      <FormProvider form={form} onSubmit={onSubmit}>
        <Stepper>
          <Step label={s('General')}>
            <StepGeneral
              titleFieldName={s('Poll name')}
              initial={useMemo(() => (clonedCampaign ? {
                route: clonedCampaign.route || undefined,
                country: clonedCampaign.country || undefined,
              } : undefined), [clonedCampaign])}
            />
          </Step>
          <Step label={s('Target Audience')}>
            <StepTarget
              avail={newPoll ? [Sources.excel, Sources.text, Sources.phonebook] : [Sources.campaign]}
              campaignId={+cloneId}
              useParticipants={useParticipants}
              targetType="poll"
            />
          </Step>
          <Step label={s('Settings')}>
            {newPoll
              ? (
                <StepSettings
                  sourceType={form.getValue(FormField.sourceType)}
                  sourceValue={form.getValue(FormField.sourceValue) || null}
                />
              )
              : (
                <StepSettingsReadOnly
                  sourceType={form.getValue(FormField.sourceType)}
                  sourceValue={form.getValue(FormField.sourceValue) || null}
                />
              )}
          </Step>
          <Step label={s('Questions')}>
            <StepQuestions
              sourceType={form.getValue(FormField.sourceType)}
              maxAttachmentSize={(form.getValue(FormField.route) as CountryCapability)?.maxAttachmentSize || 0}
              templateId={clonedCampaign?.conversationTemplateId?.id || undefined}
            />
          </Step>
          <Step label={scheduleTimes ? s('Schedule') : <SchedulerLabel value={schedulerOn} onChange={setSchedulerOn} />}>
            {schedulerOn
              ? <StepScheduler
                  mask={scheduleTimes}
                  initialvalue={form.getValue(FormField.scheduleTimes) || scheduleTimes}
              />
              : <Typography variant="subtitle1">{s('Poll will be sent now')}</Typography>}
          </Step>
        </Stepper>
        <Actions
          submitText={s('Clone poll')}
          onDraft={onDraft}
          onCancel={() => navigate(back)}
        />
      </FormProvider>
    </Page>
  );
}
