import * as Types from '../../graphql-api';

import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
export type GetUsersQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sort?: Types.InputMaybe<Types.Scalars['String']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetUsersQuery = { __typename?: 'Query', userCount?: number | null, user: Array<{ __typename?: 'User', id?: number | null, name?: string | null, email?: string | null, enabled?: boolean | null, createTs?: any | null, role?: Array<{ __typename?: 'Role', id?: string | null, name?: string | null }> | null, permission?: Array<{ __typename?: 'Permission', id?: string | null, name?: string | null }> | null }> };

export type GetRolesQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type GetRolesQuery = { __typename?: 'Query', roleCount?: number | null, role: Array<{ __typename?: 'Role', id?: string | null, name?: string | null }> };

export type SaveUserMutationVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.Scalars['String']['input']>;
  input: Types.UserInput;
}>;


export type SaveUserMutation = { __typename?: 'Mutation', userSave: Array<{ __typename?: 'User', id?: number | null } | null> };

export type DeleteUserMutationVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', userDelete: Array<{ __typename?: 'User', id?: number | null } | null> };

export type GetRatesQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sort?: Types.InputMaybe<Types.Scalars['String']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetRatesQuery = { __typename?: 'Query', rateCount?: number | null, rate: Array<{ __typename?: 'Rate', id?: number | null, fees?: any | null, country?: { __typename?: 'Country', name?: string | null } | null, operator?: { __typename?: 'Operator', name?: string | null } | null }> };


export const GetUsersDocument = gql`
    query getUsers($filter: String = null, $sort: String = null, $limit: Int = null, $offset: Int = 0) {
  user(filter: $filter, sort: $sort, limit: $limit, offset: $offset) {
    id
    name
    email
    enabled
    role {
      id
      name
    }
    permission {
      id
      name
    }
    createTs
  }
  userCount(filter: $filter)
}
    `;
export const GetRolesDocument = gql`
    query getRoles($filter: String = null) {
  role(filter: $filter) {
    id
    name
  }
  roleCount(filter: $filter)
}
    `;
export const SaveUserDocument = gql`
    mutation saveUser($filter: String, $input: UserInput!) {
  userSave(filter: $filter, input: $input) {
    id
  }
}
    `;
export const DeleteUserDocument = gql`
    mutation deleteUser($filter: String) {
  userDelete(filter: $filter) {
    id
  }
}
    `;
export const GetRatesDocument = gql`
    query getRates($filter: String = null, $sort: String = null, $limit: Int = null, $offset: Int = 0) {
  rate(filter: $filter, sort: $sort, limit: $limit, offset: $offset) {
    id
    country {
      name
    }
    operator {
      name
    }
    fees
  }
  rateCount(filter: $filter)
}
    `;
export type Requester<C = {}> = <R, V>(doc: DocumentNode, vars?: V, options?: C) => Promise<R> | AsyncIterable<R>
export function getSdk<C>(requester: Requester<C>) {
  return {
    getUsers(variables?: GetUsersQueryVariables, options?: C): Promise<GetUsersQuery> {
      return requester<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, variables, options) as Promise<GetUsersQuery>;
    },
    getRoles(variables?: GetRolesQueryVariables, options?: C): Promise<GetRolesQuery> {
      return requester<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, variables, options) as Promise<GetRolesQuery>;
    },
    saveUser(variables: SaveUserMutationVariables, options?: C): Promise<SaveUserMutation> {
      return requester<SaveUserMutation, SaveUserMutationVariables>(SaveUserDocument, variables, options) as Promise<SaveUserMutation>;
    },
    deleteUser(variables?: DeleteUserMutationVariables, options?: C): Promise<DeleteUserMutation> {
      return requester<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, variables, options) as Promise<DeleteUserMutation>;
    },
    getRates(variables?: GetRatesQueryVariables, options?: C): Promise<GetRatesQuery> {
      return requester<GetRatesQuery, GetRatesQueryVariables>(GetRatesDocument, variables, options) as Promise<GetRatesQuery>;
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;