import * as Types from '../../graphql-api';

import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
export type GetStopListQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sort?: Types.InputMaybe<Types.Scalars['String']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetStopListQuery = { __typename?: 'Query', stopListCount?: number | null, stopList: Array<{ __typename?: 'StopList', id?: number | null, phoneNumber?: string | null, operatorName?: string | null, countryName?: string | null, timestamp?: any | null, replyText?: string | null, campaignName?: string | null, campaign?: { __typename?: 'Campaign', id?: number | null, name?: string | null, type?: number | null, conversationTemplate?: any | null } | null }> };

export type StopListDeleteMutationVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type StopListDeleteMutation = { __typename?: 'Mutation', stopListDelete: Array<{ __typename?: 'StopList', id?: number | null } | null> };


export const GetStopListDocument = gql`
    query getStopList($filter: String = null, $sort: String = null, $limit: Int = null, $offset: Int = 0) {
  stopList(filter: $filter, sort: $sort, limit: $limit, offset: $offset) {
    id
    phoneNumber
    operatorName
    countryName
    timestamp
    replyText
    campaign {
      id
      name
      type
      conversationTemplate
    }
    campaignName
  }
  stopListCount(filter: $filter)
}
    `;
export const StopListDeleteDocument = gql`
    mutation stopListDelete($filter: String = null) {
  stopListDelete(filter: $filter) {
    id
  }
}
    `;
export type Requester<C = {}> = <R, V>(doc: DocumentNode, vars?: V, options?: C) => Promise<R> | AsyncIterable<R>
export function getSdk<C>(requester: Requester<C>) {
  return {
    getStopList(variables?: GetStopListQueryVariables, options?: C): Promise<GetStopListQuery> {
      return requester<GetStopListQuery, GetStopListQueryVariables>(GetStopListDocument, variables, options) as Promise<GetStopListQuery>;
    },
    stopListDelete(variables?: StopListDeleteMutationVariables, options?: C): Promise<StopListDeleteMutation> {
      return requester<StopListDeleteMutation, StopListDeleteMutationVariables>(StopListDeleteDocument, variables, options) as Promise<StopListDeleteMutation>;
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;