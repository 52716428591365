import makeTheme from './MuiThemeGenerator';

const theme = makeTheme({
  palette: {
    primary: {
      main: '#EF3F08',
      light: '#FFF2F5',
      dark: '#BE1E1E',
    },
    error: {
      main: '#FF0D2A',
      light: '#fbd6da',
    },
    warning: { main: '#FFBA34' },
    info: { main: '#094AF0' },
    success: {
      main: '#01A76F',
      light: '#d9f9ee',
    },
    normal: {
      main: '#000020',
      light: '#7C788A',
    },
    muted: { main: '#7C788A' },
    other: { main: '#7200B1' },
    layout: {
      main: '#FFFFFF',
      light: '#F6F8FA',
      dark: '#fef8f6',
    },
    header: {
      main: '#120047',
      light: '#323252',
      contrastText: '#FFFFFF',
    },
  },
});

export default theme;
