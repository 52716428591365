import React, { useEffect, useState } from 'react';
import { s } from 'i18n';
import { GroupQuestion } from 'graphql-api';
import { roundRobin } from 'shared-scope/helpers/functions';
import { ERROR_GROUP, PROGRESS_COLORS } from 'products/Details/Poll/helpers';
import PieChart, { PieValue } from 'shared-scope/components/PieChart';
import { get } from 'lodash';

const colorGenerator = roundRobin(PROGRESS_COLORS);
const colors: { [key: string]: string } = {};

const PIE_GROUP_COUNT = 5;
const OTHER_COLOR = '#b1afbb';

export default function PieQuestionChart({
    question,
    size,
    useOther = false,
    showAnswers = false,
}: PieQuestionChartProps) {
  const [data, setData] = useState<PieValue[]>([]);

  useEffect(() => {
    if (question) {
      const groups = useOther
        ? question?.groups?.slice(0, PIE_GROUP_COUNT) || []
        : question?.groups || [];

      const mainGroups = groups.map((g, id) => {
        let group: string = g?.group || '';
        const pct = g?.count || 0;
        const answers = showAnswers ? { answers: (g?.answers || []).join('; ') } : {};
        const errorGroup = get(ERROR_GROUP, group || '');
        let color = errorGroup?.color || get(colors, id, '');
        if (!color) {
          color = colorGenerator.next().value;
          colors[id] = color;
        }
        if (g?.keyword) {
          group = `Keyword: ${group}`;
        }
        return {
          id,
          value: pct,
          label: errorGroup?.label || group,
          color,
          ...answers,
        } as PieValue;
      }) || [];

      if (useOther) {
        const otherGroups = question.groups?.slice(PIE_GROUP_COUNT, question.groups?.length || 0) || [];
        if (otherGroups.length > 0) {
          const otherValue = {
            id: 'other',
            value: otherGroups.reduce((acc, row) => acc + (row?.count || 0), 0),
            label: s('OTHER'),
            color: OTHER_COLOR,
          } as PieValue;
          mainGroups.push(otherValue);
        }
      }
      setData(mainGroups);
    } else {
      setData([]);
    }
  }, [question, useOther, showAnswers]);

  return (
    <PieChart size={size} data={data} />
  );
}

type PieQuestionChartProps = {
  size: number
  question: GroupQuestion
  useOther?: boolean
  showAnswers?: boolean
};
