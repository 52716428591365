import {
  TermsConditions,
  Campaign,
} from 'graphql-api';
import { getSdk } from 'products/common/queries.generated';
import { getSdk as getCampaignSdk } from 'products/shared/Campaign/queries.generated';
import { fetcher } from 'graphql-api/fetcher';

const api = getSdk(fetcher);
const campaignApi = getCampaignSdk(fetcher);

const getActiveTermsConditions = async (campaign: Campaign): Promise<TermsConditions | null> => {
  const { countryCapability } = await campaignApi.getCountryCapability({
    filter: JSON.stringify({
      $or: [
        {
          $and: [
            { 'route.id': campaign.route?.id || null },
            { 'country.id': campaign.country?.id || null },
          ],
        },
        {
          $and: [
            { 'route.id': campaign.replyRoute?.id || null },
            { 'country.id': campaign.country?.id || null },
          ],
        },
        {
          $and: [
            { 'route.id': null },
            { 'country.id': campaign.country?.id || null },
          ],
        },
      ],
    }),
  });

  let tcEn = false;
  let count = 0;
  countryCapability.forEach((cc) => {
    if (cc.route) {
      count += 1;
      if (cc.termsConditions) {
        tcEn = true;
      }
    }
  });
  if (count === 0 && !tcEn) {
    countryCapability.forEach((cc) => {
      if (!cc.route && cc.termsConditions) {
        tcEn = true;
      }
    });
  }

  if (tcEn) {
    const { termsConditions } = await api.getTermsConditions({
      filter: JSON.stringify({ active: true }),
      sort: JSON.stringify([{ effectiveDate: -1 }]),
    });
    return termsConditions.length > 0 ? termsConditions[0] : null;
  }
  return null;
};

export default getActiveTermsConditions;
