import { Button, Stack } from '@mui/material';
import React from 'react';
import { MappingColumnsTitles } from '../helpers/const';
import { Mapping } from '../types';

export default function MessageShortcuts({ mapping, onShortcut }: ColumnsShortcutsProps) {
  return <Stack
    spacing={1}
    direction="row"
    flexWrap="wrap"
    useFlexGap
  >
    {Object.entries(mapping).map(([k, i]) => {
      if (!MappingColumnsTitles[k]) {
        return null;
      }
      return (
        <Button
          key={i}
          variant="outlined"
          size="small"
          color="inherit"
          onClick={() => onShortcut(k)}
          sx={{
            backgroundColor: (theme) => theme.palette.layout.light,
            border: 'none',
            height: '30px',
            fontWeight: 400,
          }}
        >
          {MappingColumnsTitles[k]}
        </Button>
      );
    })}
  </Stack>;
}

type ColumnsShortcutsProps = {
  mapping: Mapping
  onShortcut: (v: string) => void
};
