import Page from 'layout/Page';
import { s } from 'i18n';
import {
  Button,
  Box,
  Stack,
  Autocomplete,
  List,
  ListItem,
  ListItemButton,
  TextField,
  IconButton,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { useRequest } from '@xeebi/neru';
import { getSdk } from 'products/PhoneBook/query.generated';
import { getSdk as commonSdk } from 'products/common/queries.generated';
import { fetcher } from 'graphql-api/fetcher';
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Region, PhoneBook as PhoneBookObj, PhoneBookFolder } from 'src/graphql-api';
import { PhoneBookGridProps } from 'products/PhoneBook/PhoneBookGrid';
import {
  useNavigate,
  Outlet,
  useParams,
} from 'react-router-dom';
import { ConfirmDlg } from 'shared-scope/components/ConfirmDlg';
import useAlert from 'shared-scope/hooks/useAlert';
import { MangoFilter } from 'shared-scope/types';
import { USA } from 'shared-scope/const';


const api = getSdk(fetcher);
const commonApi = commonSdk(fetcher);

export default function PhoneBook() {
  const navigate = useNavigate();
  const { folderId } = useParams();

  const { addError } = useAlert();
  const [folders, setFolders] = useState<PhoneBookFolder[] | null>(null);
  const [phoneBooks, setPhoneBooks] = useState<PhoneBookObj[]>([]);
  const [folderToDelete, setFolderToDelete] = useState<PhoneBookFolder | null>(null);
  const [phoneBookToDelete, setPhoneBookToDelete] = useState<PhoneBookObj | null>(null);
  const [regions, setRegions] = useState<Region[]>([]);

  const [phoneBookFilter, setPhoneBookFilter] = useState('');
  const [filterInput, setFilterInput] = useState('');
  const [folder, setFolder] = useState<PhoneBookFolder | null>(null);
  const [phoneBook, setPhoneBook] = useState<PhoneBookObj | null>(null);

  const inputDelay = useRef<NodeJS.Timeout>();

  const fId = folderId === 'null' ? null : Number(folderId);

  const { error: regionError, fetch: fetchRegion } = useRequest(api.getRegion);
  const { error: folderError, fetch: fetchFolders } = useRequest(commonApi.getPhoneBookFolder);
  const { error: phonebookError, fetch: fetchPhonebooks } = useRequest(commonApi.getPhoneBook);
  const { error: folderDeleteError, fetch: deleteFolderRequest } = useRequest(api.phoneBookFolderDelete);
  const { error: phonebookDeleteError, fetch: deletePhoneBookRequest } = useRequest(api.phoneBookDelete);

  useEffect(() => {
    const error = regionError || folderError || phonebookError || folderDeleteError || phonebookDeleteError;
    if (error?.getMessage()) {
      addError(error.getMessage());
      console.error(error.getError());
    }
  }, [addError, regionError, folderError, phonebookError, folderDeleteError, phonebookDeleteError]);

  useEffect(() => {
    if (folders) {
      const fol = folders.find((f) => f.id === fId) || null;
      setFolder(folders.find((f) => f.id === fId) || null);
      if (fId && !fol) {
        navigate('/phone-book/null');
      }
    }
  }, [fId, folders, navigate]);

  const loadRegions = useCallback(async () => {
      const { region } = await fetchRegion({
        filter: `{"country.id": ${USA}}`,
        sort: '[{"code": 1}]',
      });
      //region.forEach((r) => {
      //  r.name = r.code + ' ' + r.name;
      //});
      setRegions(region);
  }, [fetchRegion]);

  const loadFolders = useCallback(async () => {
      const data = await fetchFolders();
      setFolders(data?.phoneBookFolder || []);
  }, [fetchFolders]);

  const loadPhoneBooks = useCallback(async () => {
    const filters: MangoFilter = [];
    filters.push({ 'folder.id': fId });
    if (phoneBookFilter) {
      filters.push({ name: { $like: '%' + phoneBookFilter + '%' } });
    }
    const filter: any = filters.length === 1 ? filters[0] : { $and: filters };
    const data = await fetchPhonebooks({
        filter: JSON.stringify(filter),
        sort: '[{"name": 1}]',
      });
    setPhoneBooks(data?.phoneBook || []);
  }, [fId, phoneBookFilter, fetchPhonebooks]);

  const cleanUp = useCallback(() => {
    setPhoneBook(null);
    setPhoneBookFilter('');
    setFilterInput('');
    setFolder(null);
    navigate('/phone-book/null');
  }, [navigate]);

  const deleteFolder = useCallback(async () => {
    if (folderToDelete) {
      await deleteFolderRequest({
        filter: JSON.stringify({ id: folderToDelete.id }),
      });
      if (folderToDelete.id === folder?.id) {
        cleanUp();
      }
      loadFolders();
      setFolderToDelete(null);
    }
  }, [deleteFolderRequest, folderToDelete, cleanUp, folder?.id, loadFolders]);

  const deletePhoneBook = useCallback(async () => {
    if (phoneBookToDelete) {
      await deletePhoneBookRequest({
        filter: JSON.stringify({ id: phoneBookToDelete.id }),
      });
      loadPhoneBooks();
      setPhoneBookToDelete(null);
      setPhoneBook(null);
      navigate(`/phone-book/${folder?.id || null}`);
    }
  }, [deletePhoneBookRequest, folder?.id, loadPhoneBooks, phoneBookToDelete, navigate]);

  useEffect(() => {
    if (phoneBook && !phoneBooks.map((pb) => pb.id).includes(phoneBook?.id)) {
      setPhoneBook(null);
      navigate(`/phone-book/${fId}`);
    }
  }, [phoneBooks, phoneBook, fId, navigate]);

  useEffect(() => {
    loadFolders();
    loadRegions();
  }, [loadFolders, loadRegions]);

  useEffect(() => {
    loadPhoneBooks();
  }, [loadPhoneBooks]);

  useEffect(() => {
    clearTimeout(inputDelay.current);
    inputDelay.current = setTimeout(() => setPhoneBookFilter(filterInput), 500);
  }, [filterInput]);

  return (
    <Page
      title={s('Phone books')}
      headTools={[
        <Button
          key="create"
          variant="contained"
          onClick={() => navigate(`/phone-book/new-phone-book/${fId}`)}
        >
          {s('Add new phone book')}
        </Button>,
        <Button
          key="create-folder"
          variant="outlined"
          onClick={() => navigate('/phone-book/new-folder')}
        >
          {s('Create folder')}
        </Button>,
      ]}
    >
      <Grid
        container
        direction="row"
        spacing={2}
      >
        <Grid
          item
          md={3}
        >
          <Box
            sx={{
              backgroundColor: '#F2F2F6',
              p: 2,
              height: '100%',
              borderRadius: 4,
            }}
          >
            <Stack spacing={2}>
              <Autocomplete
                value={folder}
                options={folders || []}
                getOptionLabel={(option) => option.name || ''}
                renderOption={(props, option) => (
                  <Box component="li" {...props} key={option.id} sx={{ width: '100%', display: 'flex' }}>
                    <Grid
                      container
                      spacing={2}
                    >
                      <Grid item xs={12}>
                        <Stack spacing={2} direction="row" justifyContent="space-between" alignItems="center">
                          <Typography>{option?.name}</Typography>
                          <Stack spacing={2} direction="row" height="7px" alignItems="center">
                            <IconButton
                              aria-label="Edit folder"
                              color="primary"
                              sx={{ borderRadius: '50' }}
                              onClick={(event) => {
                                event.stopPropagation();
                                event.preventDefault();
                                navigate(`/phone-book/edit-folder/${option?.id}`);
                              }}
                            >
                              <div><i className="icon-edit" /></div>
                            </IconButton>
                            <IconButton
                              aria-label="Delete folder"
                              color="primary"
                              sx={{ borderRadius: '50' }}
                              onClick={() => {
                                setFolderToDelete(option);
                              }}
                            >
                              <div><i className="icon-delete" /></div>
                            </IconButton>
                          </Stack>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Folder"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
                onChange={(event, value) => {
                  setFolder(value);
                  navigate(`/phone-book/${value?.id || null}`);
                }}
              />
              <TextField
                value={filterInput}
                placeholder="Name"
                onChange={(event) => setFilterInput(event.target.value)}
              />
              <List
                sx={{
                  backgroundColor: 'white',
                  maxHeight: '600px',
                  borderRadius: 4,
                  overflow: 'auto',
                }}
              >
                {phoneBooks.map((pb) => {
                  return (
                    <ListItemButton
                      selected={pb.id === phoneBook?.id}
                      key={pb.id}
                      onClick={() => {
                        setPhoneBook(pb);
                        navigate(`/phone-book/${folder?.id || null}/${pb.id}`);
                      }}
                    >
                      <ListItem>{pb.name}</ListItem>
                    </ListItemButton>
                  );
                })}
              </List>
            </Stack>
          </Box>
        </Grid>
        <Grid
          item
          md={9}
        >
          <Outlet
            context={{
              regions,
              onPhoneBookDelete: () => setPhoneBookToDelete(phoneBook),
            } as PhoneBookGridProps}
          />
          {!phoneBook
            ? (
              <Stack direction="row" justifyContent="center" height="100%">
                <Stack justifyContent="center" height="100%">
                  <Typography fontSize="18px" fontWeight="500" color="#808B95">{s('Select phone book')}</Typography>
                </Stack>
              </Stack>

            )
            : null}
        </Grid>
      </Grid>
      {
        folderToDelete == null
        ? null
        : <ConfirmDlg
            disabled={false}
            confirmParams={{
                content: <div>Are you sure you want to delete phone book folder <b>{folderToDelete.name}</b>?</div>,
                show: true,
                onOk: () => deleteFolder(),
                onClose: () => setFolderToDelete(null),
            }}
            title={s('Are you sure?')}
            maxWidth="md"
            fullWidth
        />
      }
      {
        phoneBookToDelete == null
        ? null
        : <ConfirmDlg
            disabled={false}
            confirmParams={{
                content: <div>{s('Are you sure you want to delete phone book')} <b>{phoneBookToDelete.name}</b>?</div>,
                show: true,
                onOk: () => deletePhoneBook(),
                onClose: () => setPhoneBookToDelete(null),
            }}
            title={s('Are you sure?')}
            maxWidth="md"
            fullWidth
        />
      }
    </Page>
  );
}
