import { s } from 'i18n';
import {
  Button,
  Box,
  Stack,
  Typography,
  TextField,
} from '@mui/material';
import { fetcher } from 'graphql-api/fetcher';
import React, {
  useEffect,
  useState,
  useMemo,
} from 'react';
import reportStar from 'media/images/report_star.svg';
import reportCorner1 from 'media/images/report_corner_1.svg';
import reportCorner2 from 'media/images/report_corner_2.svg';
import {
  Campaign,
  RawQuestion,
  GroupQuestion,
  GroupAnswer,
} from 'src/graphql-api';
import { getSdk as voteStatGetSdk } from 'products/Details/Poll/query.generated';
import dayjs from 'dayjs';
import moment from 'moment-timezone';
import {
  getCampaignStatus,
  getStatusStyle,
  calcCampaignCounters,
} from 'products/CampaignList/helpers';
import {
  PROGRESS_COLORS,
  ERROR_GROUP,
} from 'products/Details/Poll/helpers';
import { GridType } from 'products/CampaignList/types';
import {
  downloadReport,
  sendEmailReport,
  validateEmail,
  getPollDuration,
} from 'products/Details/helpers';
import PieChart from 'shared-scope/components/PieChart';
import { get } from 'lodash';
import { useNavigate, useParams, NavLink } from 'react-router-dom';
import { getSdk as campaignGetSdk } from 'products/CampaignList/query.generated';
import * as CONFIG from 'shared-scope/config';
import { BrandFiles, getBrandImg } from 'shared-scope/helpers/functions';
import Alert from '@mui/material/Alert';
import Loading from 'shared-scope/components/Loading';


const MAX_PAGE_GROUP_ANSWERS = 11;
const MAX_PIE_SIZE = 9;
const MAX_FIRST_PAGE_QUESTIONS = 6;
const MAX_SECOND_PAGE_QUESTIONS = 12;

const apiVoteStat = voteStatGetSdk(fetcher);
const apiCampaign = campaignGetSdk(fetcher);

const loadRawVoteStat = async (campaign: Campaign) => {
  const { rawVoteStat } = await apiVoteStat.getRawVoteStat({ campaignId: campaign.id || 0 });
  return (rawVoteStat?.questions || []) as RawQuestion[];
};

const loadGroupVoteStat = async (campaign: Campaign) => {
  const { groupVoteStat } = await apiVoteStat.getGroupVoteStat({ campaignId: campaign.id || 0 });
  return (groupVoteStat?.questions || []) as GroupQuestion[];
};

function getGroupColor(groupAnswer: GroupAnswer, pageIndex: number, groupIndex: number) {
  const errorGroup = get(ERROR_GROUP, groupAnswer.group || '');
  if (errorGroup) {
    return errorGroup.color;
  }
  return PROGRESS_COLORS[(pageIndex * MAX_PAGE_GROUP_ANSWERS + groupIndex) % PROGRESS_COLORS.length];
}

const loadCampaign = async (campaignId: number) => {
  const params = {
    filter: JSON.stringify({ $and: [{ isChat: true }, { id: { $eq: campaignId } }] }),
    limit: 1,
    offset: 0,
    sort: null,
  };
  const { campaign } = await apiCampaign.getVoteSmsCampaigns(params);
  return campaign[0] as Campaign;
};


type RowProps = {
  count: number
  total: number
  title: string
  progressColor: string
};

function Row({
  count,
  total,
  title,
  progressColor,
}: RowProps) {
  const totalPercent = total > 0 ? (count / total) * 100 : 0;
  const barSize = 86;
  const progressSize = Math.round((barSize * totalPercent) / 100.0);

  return (
    <Stack direction="row" width="260px" alignItems="center">
      <Typography
        width="64px"
        fontSize="14px"
        fontWeight="500"
        color={progressColor}
      >
        {s(title)}
      </Typography>
      <Box
        width={barSize + 'px'}
        height="4px"
        marginLeft="6px"
        position="relative"
        sx={{
          backgroundColor: '#FFFFFF',
          borderRadius: '100px',
        }}
      >
        <Box
          width={progressSize + 'px'}
          height="4px"
          left={0}
          position="absolute"
          sx={{
            backgroundColor: progressColor,
            borderRadius: '100px',
          }}
        />
      </Box>
      <Typography
        width="48px"
        fontSize="14px"
        fontWeight="500"
        color="#000020"
        align="right"
      >
        {Math.round(totalPercent)}%
      </Typography>
      <Typography
        width="60px"
        fontSize="14px"
        fontWeight="400"
        color="#7C788A"
        align="right"
      >
        {count}
      </Typography>
    </Stack>
  );
}

type ReportPageProps = {
  title?: string
  pageNumber?: number
  questions?: boolean
  children: React.ReactNode
};

function ReportPage({
  title,
  pageNumber,
  questions,
  children,
}: ReportPageProps) {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{
        '@media print': {
          pageBreakBefore: title ? 'always' : '',
          breakBefore: title ? 'always' : '',
          zoom: '120%',
          margin: 0,
          padding: 0,
        },
      }}
    >
      <Box
        width="612px"
        height="792px"
        sx={{
          position: 'relative',
          border: 1,
          borderColor: '#7C788A',
          backgroundColor: title ? '#FFFFFF' : '#120047',
        }}
      >
        {
          title ? (
            <Stack direction="row" width="564px" left="24px" top="32px" justifyContent="space-between" position="absolute">
              <Typography
                fontSize="24px"
                fontWeight="600"
                color="#120047"
              >
                {s(title)}
              </Typography>
              <img
                alt={CONFIG.get('app_name', 'Logo')}
                src={getBrandImg(BrandFiles.LogoDark)}
                height={38}
              />
            </Stack>
          ) : null
        }
        {
          questions ? (
            <Box
              left="24px"
              top="82px"
              width="564px"
              padding="16px"
              sx={{
                position: 'absolute',
                backgroundColor: '#F6F8FA',
                borderRadius: '8px',
              }}
            >
              {
                children
              }
            </Box>
          ) : children
        }
        {
          pageNumber ? (
            <Typography
              position="absolute"
              left="565px"
              top="737px"
              fontSize="12px"
              fontWeight="400"
              color="#120047"
            >
              {pageNumber}
            </Typography>
          ) : null
        }
      </Box>
    </Box>
  );
}

type PollReportDataProps = {
  campaign: Campaign
};

function PollReportData({
  campaign,
}: PollReportDataProps) {
  const [campaignSent, campaignDelivered, campaignTotal, campaignError] = calcCampaignCounters(campaign);
  const [rawQuestions, setRawQuestions] = useState<RawQuestion[][]>([]);
  const [groupQuestions, setGroupQuestions] = useState<GroupQuestion[]>([]);
  const [campaignTotalAnswers, setCampaignTotalAnswers] = useState(0);
  const duration = getPollDuration(campaign);
  let pageNumber = 1;
  let questionColorIndex = -1;


  const now = dayjs();

  useEffect(() => {
    const load = async () => {
      try {
        const rawVoteStatData = await loadRawVoteStat(campaign);
        const rawQuestionPages = [] as RawQuestion[][];
        let rawQuestionPage = [] as RawQuestion[];

        for (const question of rawVoteStatData) {
          const maxPageSize = rawQuestionPages.length > 0 ? MAX_SECOND_PAGE_QUESTIONS : MAX_FIRST_PAGE_QUESTIONS;
          rawQuestionPage.push(question);
          if (rawQuestionPage.length >= maxPageSize) {
            rawQuestionPages.push(rawQuestionPage);
            rawQuestionPage = [] as RawQuestion[];
          }
        }
        if (rawQuestionPage.length > 0) {
          rawQuestionPages.push(rawQuestionPage);
        }

        setRawQuestions(rawQuestionPages);
        const groupVoteStatData = await loadGroupVoteStat(campaign);
        setGroupQuestions(groupVoteStatData);
        setCampaignTotalAnswers(rawVoteStatData.reduce((acc, q) => acc + (q.answers?.reduce((acc1, a) => acc1 + (a?.count || 0), 0) || 0), 0));
      } catch (e: any) {
        setRawQuestions([]);
        setGroupQuestions([]);
        console.log(e);
      }
    };
    load();
  }, [campaign]);

  return (
    <Stack
      spacing="1px"
      width="100vw"
      alignItems="center"
      sx={{
        '@media print': {
          size: 'letter',
          margin: 0,
          padding: 0,
          },
      }}
    >

      {/** page 1 */}

      <ReportPage>
        <Box
          sx={{
            position: 'absolute',
            left: '40px',
            top: '43px',
          }}
        >
          <img
            alt={CONFIG.get('app_name', 'Logo')}
            src={getBrandImg(BrandFiles.LogoFull)}
            height={38}
          />
        </Box>
        <Typography
          position="absolute"
          left="484px"
          top="53px"
          fontSize="14px"
          fontWeight="400"
          color="#EF3F08"
        >
          <NavLink to="/">{CONFIG.get('app_name', '')}</NavLink>
        </Typography>

        <Typography
          position="absolute"
          left="40px"
          top="362px"
          fontSize="24px"
          fontWeight="700"
          color="#FFFFFF"
        >
          {campaign?.name}
        </Typography>

        <Box
          sx={{
            position: 'absolute',
            left: '574px',
            top: '94px',
          }}
        >
          <img alt="" src={reportStar} height="14px" />
        </Box>

        <Box
          sx={{
            position: 'absolute',
            left: '598px',
            top: '222px',
          }}
        >
          <img alt="" src={reportStar} height="11px" />
        </Box>

        <Box
          sx={{
            position: 'absolute',
            left: '102px',
            top: '687px',
          }}
        >
          <img alt="" src={reportStar} height="14px" />
        </Box>

        <Box
          sx={{
            position: 'absolute',
            right: '0px',
            top: '0px',
          }}
        >
          <img alt="" src={reportCorner1} height="300px" />
        </Box>

        <Box
          sx={{
            position: 'absolute',
            left: '0px',
            bottom: '0px',
          }}
        >
          <img alt="" src={reportCorner2} height="440px" />
        </Box>

        <Typography
          position="absolute"
          left="40px"
          top="732px"
          fontSize="14px"
          fontWeight="400"
          color="#73C9FA"
        >
          {s('Poll report')}
        </Typography>

        <Typography
          position="absolute"
          left="457px"
          top="712px"
          width="120px"
          align="right"
          fontSize="14px"
          fontWeight="400"
          color="#73C9FA"
        >
          {s('Generated:')}
        </Typography>

        <Typography
          position="absolute"
          left="437px"
          top="732px"
          width="140px"
          align="right"
          fontSize="14px"
          fontWeight="400"
          color="#73C9FA"
        >
          {now.format('MM/DD/YYYY HH:MM')}
        </Typography>

      </ReportPage>


      {/** page 2 */}

      {
        rawQuestions.map((questions, pageIndex) => {
          pageNumber += 1;
          return (
            <ReportPage
              title={s('Poll info')}
              pageNumber={pageNumber}
              key={pageNumber}
            >
              <Stack width="564px" left="24px" top="112px" position="absolute" spacing={1}>
                { pageIndex === 0 ? (
                  <Box
                    height="104px"
                  >
                    <Stack direction="row" marginTop="12px" marginLeft="12px" marginRight="12px">
                      <Stack spacing="8px" width="100%">
                        <Typography
                          fontSize="12px"
                          fontWeight="500"
                          color="#7C788A"
                        >
                          {s('Name')}
                        </Typography>
                        <Typography
                          fontSize="16px"
                          fontWeight="700"
                          color="#120047"
                        >
                          {campaign?.name}
                        </Typography>
                      </Stack>
                      <Stack spacing="8px" width="100px">
                        <Typography
                          fontSize="12px"
                          fontWeight="500"
                          color="#7C788A"
                          align="right"
                        >
                          {s('Status')}
                        </Typography>
                        <Box
                          width="100px"
                          height="24px"
                          alignContent="center"
                          justifyContent="center"
                          className={getStatusStyle(campaign.status || null, campaign.statusString || null)}
                          sx={{
                            fontSize: '12px',
                            fontWeight: 400,
                            display: 'flex',
                          }}
                        >
                          {(getCampaignStatus(campaign?.status?.id, GridType.Poll)?.name || '') + (campaign.statusString ? ` (${campaign.statusString})` : '')}
                        </Box>
                      </Stack>
                    </Stack>
                  </Box>) : null }
                { pageIndex === 0 ? (
                  <Box
                    height="168px"
                    sx={{
                      backgroundColor: '#F6F8FA',
                      borderRadius: '8px',
                    }}
                  >
                    <Stack direction="row" marginTop="12px" marginLeft="12px" spacing="16px">
                      <Stack marginLeft="6px" spacing="8px">
                        <Typography
                          fontSize="14px"
                          fontWeight="500"
                          color="#000020"
                        >
                          {s('Statistics')}
                        </Typography>
                        <Row
                          count={campaignSent}
                          total={campaignTotal}
                          title={s('Sent')}
                          progressColor="#01A76F"
                        />
                        <Row
                          count={campaignError}
                          total={campaignTotal}
                          title={s('Errors')}
                          progressColor="#E3001B"
                        />
                        <Row
                          count={campaignDelivered}
                          total={campaignSent}
                          title={s('Delivered')}
                          progressColor="#2C67FD"
                        />
                        <Stack direction="row" justifyContent="space-between">
                          <Typography
                            fontSize="14px"
                            fontWeight="500"
                            color="#000020"
                          >
                            {s('Total answers')}
                          </Typography>
                          <Typography
                            fontSize="14px"
                            fontWeight="400"
                            color="#7C788A"
                          >
                            {campaignTotalAnswers}
                          </Typography>
                        </Stack>
                      </Stack>

                      <Box
                        width="1px"
                        height="146px"
                        sx={{
                          backgroundColor: '#E2E1E5',
                          borderRadius: 2,
                        }}
                      />

                      <Stack>
                        <Typography
                          fontSize="14px"
                          fontWeight="500"
                          color="#000020"
                        >
                          {s('Date')}
                        </Typography>
                        <Stack direction="row" marginTop="6px">
                          <Stack width="101px" spacing="8px">
                            <Typography
                              fontSize="14px"
                              fontWeight="400"
                              color="#7C788A"
                            >
                              {s('Start')}
                            </Typography>

                            <Typography
                              fontSize="16px"
                              fontWeight="500"
                              color="#000020"
                            >
                              {campaign?.startTs ? moment(campaign.startTs).format('YYYY-MM-DD') : ''}
                            </Typography>

                            <Typography
                              fontSize="14px"
                              fontWeight="400"
                              color="#7C788A"
                            >
                              {campaign?.startTs ? moment(campaign.startTs).format('HH:mm:ss') : ''}
                            </Typography>

                          </Stack>

                          <Stack marginLeft="12px" spacing="8px">
                            <Typography
                              fontSize="14px"
                              fontWeight="400"
                              color="#7C788A"
                            >
                              {s('Duration')}
                            </Typography>

                            <Typography
                              fontSize={(duration?.length || 0) > 18 ? '12px' : (duration?.length || 0) > 15 ? '14px' : '16px'}
                              fontWeight="500"
                              color="#000020"
                            >
                              {duration}
                            </Typography>

                            <Typography
                              fontSize="14px"
                              fontWeight="400"
                              color="#7C788A"
                            >
                              &nbsp;
                            </Typography>

                          </Stack>

                        </Stack>
                      </Stack>

                    </Stack>
                  </Box>) : null }

                <Box
                  sx={{
                    backgroundColor: '#F6F8FA',
                    borderRadius: '8px',
                  }}
                >
                  <Stack marginLeft="12px" marginTop="12px" marginBottom="12px" spacing="8px">
                    <Typography
                      fontSize="14px"
                      fontWeight="400"
                      color="#000020"
                    >
                      {s('How many answered?')}
                    </Typography>

                    {
                      questions.map((question) => {
                        const repliedTotal = (question?.answers?.map((a) => a?.count || 0).reduce((a, b) => a + b, 0) || 0);
                        const repliedPercent = campaign?.cMessagesSentTotal ? (repliedTotal * 100.0) / campaign.cMessagesSentTotal : 0;
                        const barSize = 420;
                        const progressSize = Math.round((barSize * repliedPercent) / 100.0);
                        questionColorIndex += 1;

                        return (
                          <Stack direction="row" height="37px" spacing="20px" alignItems="center" key={question.position}>
                            <Box
                              width="8px"
                              height="8px"
                              sx={{
                                backgroundColor: PROGRESS_COLORS[questionColorIndex % PROGRESS_COLORS.length],
                                borderRadius: '2px',
                              }}
                            />
                            <Typography
                              width="78px"
                              fontSize="14px"
                              fontWeight="400"
                              color="#7C788A"
                            >
                              {(question.position || 0) < 0 ? s('Engagement question') : s('Question') + ' ' + question.position}
                            </Typography>
                            <Stack width="420px" spacing="8px">
                              <Stack direction="row" justifyContent="space-between">
                                <Typography
                                  fontSize="14px"
                                  fontWeight="500"
                                  color="#000020"
                                >
                                  {repliedPercent.toFixed(1)}%
                                </Typography>

                                <Typography
                                  fontSize="12px"
                                  fontWeight="500"
                                  color="#7C788A"
                                >
                                  {repliedTotal}
                                </Typography>
                              </Stack>

                              <Box
                                width={barSize + 'px'}
                                height="6px"
                                position="relative"
                                sx={{
                                  backgroundColor: '#FFFFFF',
                                  borderRadius: '100px',
                                }}
                              >
                                <Box
                                  width={progressSize + 'px'}
                                  height="6px"
                                  left={0}
                                  position="absolute"
                                  sx={{
                                    backgroundColor: PROGRESS_COLORS[questionColorIndex % PROGRESS_COLORS.length],
                                    borderRadius: '100px',
                                  }}
                                />
                              </Box>

                            </Stack>
                          </Stack>
                        );
                      })
                    }

                  </Stack>
                </Box>
              </Stack>
            </ReportPage>
          );
        })
      }

      {/** page 3 */}

      {
        groupQuestions.map((question) => {
          const questionGroups = question?.groups || [];
          let answerPos = 0;
          let totalAnswers = 0;
          const questionPages = questionGroups.reduce((pages, currentGroup, groupIndex) => {
              const pageIndex = Math.floor(groupIndex / MAX_PAGE_GROUP_ANSWERS);
              if (currentGroup) {
                if (pages.length <= pageIndex) {
                  pages.push([]);
                }
                totalAnswers += currentGroup.count || 0;
                pages[pageIndex].push(currentGroup);
              }
              return pages;
            }, [[]] as GroupAnswer[][]);
          return questionPages.map((pageGroups, pageIndex) => {
              const pieCharts = pageGroups ? pageGroups.length <= MAX_PIE_SIZE : false;
              pageNumber += 1;
              const linesCount = (question.question?.split('\n') || []).length || 1;
              let qFontSize = '14px';
              if (linesCount <= 8) {
                qFontSize = '14px';
              } else if (linesCount <= 15) {
                qFontSize = '12px';
              } else {
                qFontSize = '10px';
              }

              return (
                <ReportPage
                  title={s('Results')}
                  pageNumber={pageNumber}
                  questions
                  key={pageNumber}
                >
                  <Stack spacing="8px">
                    <Typography
                      fontSize="12px"
                      fontWeight="500"
                      color="#7C788A"
                    >
                      {(question.position || 0) < 0 ? s('Engagement question') : s('Question') + ' ' + question.position}
                    </Typography>
                    <Stack direction="row" width="532px" justifyContent="space-between">
                      <Typography
                        fontSize={qFontSize}
                        fontWeight="500"
                        color="#000020"
                        whiteSpace="pre-line"
                        lineHeight={1.5}
                      >
                        {question.question}
                      </Typography>
                      <Typography
                        fontSize="12px"
                        fontWeight="500"
                        color="#7C788A"
                        minWidth="120px"
                      >
                        Total answers: {totalAnswers}
                      </Typography>
                    </Stack>

                    <Stack direction="row" hidden={pageGroups.length === 0}>
                      {!pieCharts
                        ? (
                          <Stack justifyContent="center">
                            {
                              pageGroups.map((groupAnswer, groupIndex) => {
                                const barSize = 220;
                                const progressSize = Math.round((barSize * (groupAnswer.pct || 0)) / 100.0);
                                const groupName = (groupAnswer?.keyword ? 'Keyword: ' : '') + (groupAnswer?.group || '');
                                const errorGroup = get(ERROR_GROUP, groupName);
                                answerPos += 1;

                                return (
                                  <Stack width={pieCharts ? '296px' : '516px'} key={answerPos}>
                                    <Stack direction="row" alignItems="center">
                                      <Box
                                        width="8px"
                                        height="8px"
                                        sx={{
                                          backgroundColor: getGroupColor(groupAnswer, pageIndex, groupIndex),
                                          borderRadius: '2px',
                                        }}
                                      />
                                      <Typography
                                        fontSize="10px"
                                        fontWeight="400"
                                        color="#000020"
                                        noWrap
                                        marginLeft="8px"
                                        width="280px"
                                        lineHeight={1.2}
                                      >
                                        {errorGroup?.label || groupName}
                                      </Typography>
                                      <Stack direction="row" width="220px" justifyContent="space-between">
                                        <Typography
                                          fontSize="10px"
                                          fontWeight="500"
                                          color="#000020"
                                        >
                                          {Math.round(groupAnswer.pct || 0)} %
                                        </Typography>
                                        <Typography
                                          fontSize="10px"
                                          fontWeight="500"
                                          color="#7C788A"
                                        >
                                          {groupAnswer.count || 0}
                                        </Typography>
                                      </Stack>
                                    </Stack>

                                    <Stack direction="row" alignItems="center">
                                      <Typography
                                        fontSize="10px"
                                        fontWeight="400"
                                        color="#7C788A"
                                        noWrap
                                        marginLeft="16px"
                                        width="280px"
                                        lineHeight={1.2}
                                      >
                                        {(groupAnswer?.answers || []).join('; ')}
                                      </Typography>
                                      <Box
                                        width={barSize + 'px'}
                                        height="6px"
                                        position="relative"
                                        sx={{
                                          backgroundColor: '#FFFFFF',
                                          borderRadius: '100px',
                                        }}
                                      >
                                        <Box
                                          width={progressSize + 'px'}
                                          height="6px"
                                          left={0}
                                          position="absolute"
                                          sx={{
                                            backgroundColor: getGroupColor(groupAnswer, pageIndex, groupIndex),
                                            borderRadius: '100px',
                                          }}
                                        />
                                      </Box>
                                    </Stack>
                                  </Stack>
                                );
                              })
                            }
                          </Stack>
                        )
                        : null}


                      {pieCharts
                        ? (
                          <PieChart
                            report
                            skipAnimation
                            size={150}
                            data={pageGroups?.map((groupAnswer, groupIndex) => {
                              const groupName = (groupAnswer?.keyword ? 'Keyword: ' : '') + (groupAnswer?.group || '');
                              const errorGroup = get(ERROR_GROUP, groupName);
                              return {
                                id: groupIndex,
                                value: groupAnswer.count || 0,
                                label: errorGroup?.label || groupName,
                                color: getGroupColor(groupAnswer, pageIndex, groupIndex),
                                answers: (groupAnswer?.answers || []).join('; '),
                              };
                            }) || []}
                          />
                        )
                        : null}
                    </Stack>
                  </Stack>
                </ReportPage>
              );
          });
        })
      }

    </Stack>
  );
}


export default function PollReport() {
  const { id } = useParams();
  const [campaign, setCampaign] = useState<Campaign | null>();
  const [email, setEmail] = useState<string | null>();
  const [lastError, setLastError] = useState<string | null>(null);
  const [lastSuccess, setLastSuccess] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [sending, setSending] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    const load = async () => {
      try {
        if (id) {
          const campaignData = await loadCampaign(parseInt(id, 10));
          setCampaign(campaignData);
        } else {
          setCampaign(null);
        }
      } catch (e: any) {
        setCampaign(null);
        console.log(e);
      }
    };
    load();
  }, [id]);

  const reportData = useMemo(() => {
    return campaign ? (
      <PollReportData
        campaign={campaign}
      />
    ) : null;
  }, [campaign]);

  const downloadReportWithAlert = async () => {
    if (campaign?.id) {
      setLoading(true);
      const res = await downloadReport(campaign);
      if (res.success) {
        setLastError(null);
      } else {
        setLastError(s('Failed to download report, error: :error', { error: res.error }));
        setLastSuccess(null);
      }
      setLoading(false);
    }
  };

  const sendEmailReportWithAlert = async () => {
    if (campaign?.id && email) {
      setSending(true);
      const res = await sendEmailReport(campaign, email);
      if (res.success) {
        setLastSuccess(s('Email sent successfully, status: :status', { status: res.serverStatus }));
        setLastError(null);
      } else {
        setLastError(s('Failed to send email, error: :error', { error: res.error }));
        setLastSuccess(null);
      }
      setSending(false);
    }
  };

  return (
    <Stack width="100vw">
      {lastError ? <Alert
        severity="error"
        onClose={() => setLastError(null)}
      >{lastError}</Alert>
      : null}
      {lastSuccess ? <Alert
        severity="success"
        onClose={() => setLastSuccess(null)}
      >{lastSuccess}</Alert>
      : null}
      <Box sx={{ display: 'block', displayPrint: 'none' }}>
        <Stack direction="row" spacing={2} padding={2}>
          <Button
            variant="contained"
            onClick={() => navigate(-1)}
          >
            {s('Close')}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
                window.print();
              }}
          >
            {s('Print')}
          </Button>
          {loading
                ? <Loading logo={false} size={40} />
                : <Button
                    variant="contained"
                    disabled={sending || loading}
                    onClick={() => downloadReportWithAlert()}
                >
                  {s('Download')}
                </Button>}
          <Stack direction="row" spacing={2}>
            {sending
                ? <Loading logo={false} size={40} />
                : <Button
                    variant="contained"
                    disabled={!validateEmail(email) || sending || loading}
                    onClick={() => sendEmailReportWithAlert()}
                >
                  {s('Send as email')}
                </Button>}
            <TextField
              label={s('Destination email address')}
              value={email}
              disabled={sending}
              onChange={(e) => setEmail(e.target.value)}
              variant="outlined"
              sx={{ width: '300px' }}
              required
            />
          </Stack>
        </Stack>
      </Box>
      {reportData}
    </Stack>
  );
}
