import * as Types from '../../../graphql-api';

import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
export type GetConversationQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sort?: Types.InputMaybe<Types.Scalars['String']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetConversationQuery = { __typename?: 'Query', conversationCount?: number | null, conversation: Array<{ __typename?: 'Conversation', id?: number | null, state?: number | null, createTs?: any | null, chat?: any | null, result?: any | null, phoneNumber?: string | null, campaign?: { __typename?: 'Campaign', id?: number | null } | null }> };

export type GetConversationStatQueryVariables = Types.Exact<{
  campaignId: Types.Scalars['Int']['input'];
}>;


export type GetConversationStatQuery = { __typename?: 'Query', conversationStat?: { __typename?: 'ConversationStat', total?: number | null, attention?: number | null, details?: any | null } | null };

export type GetGroupVoteStatQueryVariables = Types.Exact<{
  campaignId: Types.Scalars['Int']['input'];
}>;


export type GetGroupVoteStatQuery = { __typename?: 'Query', groupVoteStat?: { __typename?: 'GroupVoteStat', questions?: Array<{ __typename?: 'GroupQuestion', question?: string | null, position?: number | null, groups?: Array<{ __typename?: 'GroupAnswer', group?: string | null, answers?: Array<string | null> | null, count?: number | null, pct?: number | null, keyword?: boolean | null } | null> | null } | null> | null } | null };

export type GetRawVoteStatQueryVariables = Types.Exact<{
  campaignId: Types.Scalars['Int']['input'];
}>;


export type GetRawVoteStatQuery = { __typename?: 'Query', rawVoteStat?: { __typename?: 'RawVoteStat', questions?: Array<{ __typename?: 'RawQuestion', question?: string | null, position?: number | null, answers?: Array<{ __typename?: 'RawAnswer', answer?: string | null, count?: number | null, pct?: number | null, keyword?: boolean | null } | null> | null } | null> | null } | null };

export type GetOptInChartQueryVariables = Types.Exact<{
  campaignId: Types.Scalars['Int']['input'];
}>;


export type GetOptInChartQuery = { __typename?: 'Query', optInChart: Array<{ __typename?: 'OptInChart', status: number, count: number, pct: number }> };


export const GetConversationDocument = gql`
    query getConversation($filter: String = null, $sort: String = null, $limit: Int = null, $offset: Int = 0) {
  conversation(filter: $filter, sort: $sort, limit: $limit, offset: $offset) {
    id
    campaign {
      id
    }
    state
    createTs
    chat
    result
    phoneNumber
  }
  conversationCount(filter: $filter)
}
    `;
export const GetConversationStatDocument = gql`
    query getConversationStat($campaignId: Int!) {
  conversationStat(campaignId: $campaignId) {
    total
    attention
    details
  }
}
    `;
export const GetGroupVoteStatDocument = gql`
    query getGroupVoteStat($campaignId: Int!) {
  groupVoteStat(campaignId: $campaignId) {
    questions {
      question
      position
      groups {
        group
        answers
        count
        pct
        keyword
      }
    }
  }
}
    `;
export const GetRawVoteStatDocument = gql`
    query getRawVoteStat($campaignId: Int!) {
  rawVoteStat(campaignId: $campaignId) {
    questions {
      question
      position
      answers {
        answer
        count
        pct
        keyword
      }
    }
  }
}
    `;
export const GetOptInChartDocument = gql`
    query getOptInChart($campaignId: Int!) {
  optInChart(campaignId: $campaignId) {
    status
    count
    pct
  }
}
    `;
export type Requester<C = {}> = <R, V>(doc: DocumentNode, vars?: V, options?: C) => Promise<R> | AsyncIterable<R>
export function getSdk<C>(requester: Requester<C>) {
  return {
    getConversation(variables?: GetConversationQueryVariables, options?: C): Promise<GetConversationQuery> {
      return requester<GetConversationQuery, GetConversationQueryVariables>(GetConversationDocument, variables, options) as Promise<GetConversationQuery>;
    },
    getConversationStat(variables: GetConversationStatQueryVariables, options?: C): Promise<GetConversationStatQuery> {
      return requester<GetConversationStatQuery, GetConversationStatQueryVariables>(GetConversationStatDocument, variables, options) as Promise<GetConversationStatQuery>;
    },
    getGroupVoteStat(variables: GetGroupVoteStatQueryVariables, options?: C): Promise<GetGroupVoteStatQuery> {
      return requester<GetGroupVoteStatQuery, GetGroupVoteStatQueryVariables>(GetGroupVoteStatDocument, variables, options) as Promise<GetGroupVoteStatQuery>;
    },
    getRawVoteStat(variables: GetRawVoteStatQueryVariables, options?: C): Promise<GetRawVoteStatQuery> {
      return requester<GetRawVoteStatQuery, GetRawVoteStatQueryVariables>(GetRawVoteStatDocument, variables, options) as Promise<GetRawVoteStatQuery>;
    },
    getOptInChart(variables: GetOptInChartQueryVariables, options?: C): Promise<GetOptInChartQuery> {
      return requester<GetOptInChartQuery, GetOptInChartQueryVariables>(GetOptInChartDocument, variables, options) as Promise<GetOptInChartQuery>;
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;