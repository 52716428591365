import { NavLink } from 'react-router-dom';
import { ReactNode } from 'react';
import * as CONFIG from 'shared-scope/config';
import { Stack } from '@mui/material';
import { s } from 'i18n';
import { PermissionWrapper, PERMISSION_GENERAL } from 'permission';
import styles from './styles.module.scss';

export default function Menu() {
    return (
      <nav>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={3}
        >
          {CONFIG.get('poll')
            ? <NavItem to="/poll">{s('Polls')}</NavItem>
            : null}

          <PermissionWrapper scopes={[PERMISSION_GENERAL]}>

            {CONFIG.get('campaign')
              ? <NavItem to="/campaign">{s('Campaigns')}</NavItem>
              : null}

            {CONFIG.get('opt-in')
              ? <NavItem to="/opt-in">{s('Opt-in')}</NavItem>
              : null}

            <NavItem to="/stop-list">{s('Stop list')}</NavItem>
            <NavItem to="/templates">{s('Templates')}</NavItem>
            <NavItem to="/phone-book">{s('Phone books')}</NavItem>
          </PermissionWrapper>
        </Stack>
      </nav>
    );
}

function NavItem({
                     to = '',
                     children = null,
                     classNormal = styles.navItemLink,
                     classActive = styles.navItemActive,
                     onClick = () => null,
                 }: NavItemProps) {
    return (
      <NavLink
        to={to}
        className={({ isActive }) => (isActive ? `${classActive} ${classNormal}` : classNormal)}
        onClick={onClick}
      >
        <div className={`position-relative ${styles.navItemContent}`}>{children}</div>
      </NavLink>
    );
}

type NavItemProps = {
    to: string
    children?: ReactNode
    classNormal?: string
    classActive?: string
    onClick?: () => void
};
