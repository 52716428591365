import { s } from 'i18n';
import { FormController, useFormContext } from 'shared-scope/components/Form';
import {
  Stack, TextField,
} from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { fetcher } from 'graphql-api/fetcher';
import { Country, CountryCapability, PhoneBookFolder } from 'graphql-api';
import {
  useRequest, Combobox,
} from '@xeebi/neru';
import { z } from 'zod';
import { sortBy } from 'lodash';
import { getSdk } from 'products/common/queries.generated';
import { getSdk as getCampaignSdk } from '../queries.generated';
import { FormField } from '../types';
import { zText } from '../helpers/validators';

const api = getSdk(fetcher);
const apiCampaign = getCampaignSdk(fetcher);

const getCC = async () => apiCampaign.getCountryCapability({
  filter: JSON.stringify({ 'route.id': { $ne: null } }),
});

export default function StepGeneralPhoneBook({
  folderId = null,
}: StepGeneralPhoneBookProps) {
  const {
    isLoading: isLoadingCC,
    error: errorCC,
    fetch: fetchCC,
    result: resultCC,
  } = useRequest(getCC);
  const {
    isLoading: isLoadingFolder,
    error: errorFolder,
    fetch: fetchFolder,
    result: resultFolder,
  } = useRequest(api.getPhoneBookFolder);

  const {
    form: {
      setValue,
      setError,
    },
    formRow,
  } = useFormContext<StepGeneralPhoneBookRow>();

  const countries: Country[] = useMemo(
    () => {
      const ccCountry: Record<number, Country> = {};
      resultCC?.countryCapability.forEach((cc: CountryCapability) => {
        if (cc?.country?.id) {
          ccCountry[cc.country.id] = cc.country;
        }
      });
      return sortBy(Object.values(ccCountry), ['name']);
    },
    [resultCC],
  );
  const folders: PhoneBookFolder[] = useMemo(() => resultFolder?.phoneBookFolder || [], [resultFolder]);

  /**
   * Fetch init data
   */
  useEffect(() => {
    fetchCC();
    fetchFolder();
  }, [fetchCC, fetchFolder]);

  /**
   * set initial data
   */
  useEffect(() => {
    countries?.length && !formRow.country && setValue(FormField.country, countries[0]);
  }, [countries, formRow.country, setValue]);

  useEffect(() => {
    const initFolder = folders.find((f) => f.id === folderId);
    if (initFolder) {
      setValue(FormField.folder, initFolder);
    }
  }, [folderId, folders, setValue]);
  /**
   * Error handle
   */
  useEffect(() => {
    setError(FormField.country, errorCC ? errorCC.getMessage() : '');
  }, [errorCC, setError]);
  useEffect(() => {
    setError(FormField.folder, errorFolder ? errorFolder.getMessage() : '');
  }, [errorFolder, setError]);

  return (
    <Stack spacing={3}>
      <FormController
        name={FormField.title}
        validateRule={validateRules[FormField.title]}
      >
        {(ctrl) => (
          <TextField
            label={s('Phone book name')}
            value={ctrl.value || ''}
            onChange={(e) => ctrl.onChange(e.target.value)}
            onBlur={() => ctrl.validate()}
            error={!!ctrl.error}
            helperText={ctrl.error && s('Phone book name can\'t be empty')}
          />
        )}
      </FormController>
      <FormController<PhoneBookFolder>
        name={FormField.folder}
        validateRule={validateRules[FormField.folder]}
        renderParams={{ folders, isLoadingFolder }}
      >
        {(ctrl) => ((ctrl.renderParams?.folders?.length || 0) > 0 ? (
          <Combobox<PhoneBookFolder>
            title={s('Folder')}
            options={ctrl.renderParams?.folders || []}
            optionValue="name"
            optionKey="id"
            value={ctrl.value || null}
            onChange={(e, newValue) => ctrl.onChange(newValue)}
            onBlur={() => ctrl.validate()}
            loading={ctrl.renderParams?.isLoadingFolder}
            error={!!ctrl.error}
            helperText={ctrl.error}
          />
        ) : null)}
      </FormController>
      <FormController<Country>
        name={FormField.country}
        validateRule={validateRules[FormField.country]}
        renderParams={{ countries, isLoadingCC }}
      >
        {(ctrl) => ((ctrl.renderParams?.countries?.length || 0) > 1 ? (
          <Combobox<Country>
            title={s('Country')}
            options={ctrl.renderParams?.countries || []}
            optionValue="name"
            optionKey="id"
            value={ctrl.value || null}
            onChange={(e, newValue) => newValue && ctrl.onChange(newValue)}
            onBlur={() => ctrl.validate()}
            loading={ctrl.renderParams?.isLoadingCC}
            error={!!ctrl.error}
            helperText={ctrl.error}
          />
        ) : null)}
      </FormController>
    </Stack>
  );
}

const validateRules: Record<string, z.ZodSchema> = {
  [FormField.title]: zText(),
  [FormField.country]: z.object({}, s('Country can\'t be empty')),
  [FormField.folder]: z.object({}).nullish(),
};

export type StepGeneralPhoneBookRow = {
  title?: string
  country?: Country
  folder?: PhoneBookFolder
};

type StepGeneralPhoneBookProps = {
  folderId?: number | null
};


