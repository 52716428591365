export default function formatFileSize(size: number) {
  const c = ['B', 'KB', 'MB', 'GB'];
  let i = 0;
  while (size > 1000) {
    size /= 1000.0;
    i += 1;
  }
  const f = (i === 0 || size >= 100) ? size.toString() : size.toFixed(3).substring(0, 4);
  return f + c[i];
}
