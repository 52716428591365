import React from 'react';
import { Box } from '@mui/system';
import { Grid, Typography } from '@mui/material';

import styles from './stepper.module.scss';

export function Stepper({ children }: React.PropsWithChildren<StepperProps>) {
  const steps = React.Children.toArray(children).filter(
    (child) => React.isValidElement(child) && child.type === Step,
  );

  let stepNum = 0;
  return (
    <Box>
      {steps.map((step) => {
        stepNum += 1;
        const num = stepNum.toString().padStart(2, '0');
        let icon = null;
        if (React.isValidElement(step)) {
          icon = step?.props?.icon || null;
        }
        return (
          <Box key={num} className={styles.stepper}>
            <Box className={styles.stepNumberWrapper}>
              <Box className={styles.stepNumber}>{icon || num}</Box>
            </Box>
            <Box>{step}</Box>
          </Box>
        );
      })}
    </Box>
  );
}

export function Step({
  label,
  children,
}: React.PropsWithChildren<StepProps>) {
  return <Grid
    container
    direction="row"
    justifyContent="flex-start"
    alignItems="flex-start"
    rowSpacing={3}
  >
    <Grid item xs={12}>
      <Typography variant="h3">{label}</Typography>
    </Grid>
    <Grid item xs={12}>{children}</Grid>
  </Grid>;
}

type StepperProps = {};

type StepProps = {
  label: React.ReactNode
  icon?: React.ReactNode | null
};
