import {
  Box, Grid, IconButton, MenuItem,
  OutlinedInputProps, Select, TextField, Typography,
} from '@mui/material';
import { ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import { BoxProps, SystemStyleObject } from '@mui/system';
import { s } from 'i18n';

export default function MappingParam<T>(
  {
    title,
    type,
    value,
    inputProps,
    inputWidth,
    onChange,
    helperText,
    multiple = false,
    canPaste = false,
    required = false,
    nullable = false,
  }: MappingParamProps<T>,
) {
  let mappingParamControl: ReactNode;
  if (type && Array.isArray(type)) {
    const showCounter = multiple && Array.isArray(value) && value.length > 1;
    mappingParamControl = (
      <Box sx={{ position: 'relative' }}>
        {showCounter
          && <CircleBox
            sx={{
              position: 'absolute',
              zIndex: 1,
              top: '12px',
              left: '10px',
            }}
          >
            {value.length}
          </CircleBox>}
        {canPaste
          && <PasteComponent
            sx={{
              position: 'absolute',
              zIndex: 1,
              top: '12px',
              left: '-22px',
            }}
            options={type}
            onPaste={onChange}
          />}
        <Select
          value={value}
          defaultValue={value}
          multiple={multiple}
          onChange={(e) => onChange(e.target.value as T)}
          sx={{
            width: inputWidth || 'auto',
            paddingLeft: showCounter ? '23px' : '0',
          }}
        >
          {nullable && <MenuItem value="" key="null" sx={{ minHeight: 35 }}>{s('Empty')}</MenuItem>}
          {
            type.map((t: any, i) => ((t.constructor === Object)
              ? <MenuItem value={t.k} key={t.k} sx={{ minHeight: 35 }}>{t.v}</MenuItem>
              : <MenuItem value={i} key={t} sx={{ minHeight: 35 }}>{t}</MenuItem>),
            )
          }
        </Select>
      </Box>
    );
  } else {
    mappingParamControl = (
      <TextField
        value={value}
        type={type}
        onChange={(e) => onChange(e.target.value as unknown as T)}
        variant="outlined"
        InputProps={inputProps}
        sx={{ width: inputWidth || 'auto' }}
        required={required}
        helperText={helperText}
        onFocus={(e) => e.target.select()}
      />
    );
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item xs={12} lg={4}>
        <Typography variant="body1">{title}</Typography>
      </Grid>
      <Grid item xs={12} lg={8}>
        {mappingParamControl}
      </Grid>
    </Grid>
  );
}

const CircleBox = styled(Box)<BoxProps>(({ theme }) => (
  {
    width: '20px',
    height: '20px',
    borderRadius: '100px',
    padding: '2px',
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    fontSize: '12px',
    textAlign: 'center',
    lineHeight: '18px',
    fontWeight: 600,
  }
));

function PasteComponent<T>({ options, onPaste, sx }: PasteComponentProps<T>) {
  return navigator.clipboard
    ? (
      <IconButton
        size="small"
        title={s('Paste from clipboard')}
        sx={({ palette }) => ({
        padding: '3px',
        color: palette.primary.main,
        ...sx,
      })}
        onClick={() => navigator.clipboard
        .readText()
        .then((cliptext: string) => {
          const words = (cliptext.match(/\w+/g) || []) as string[];
          const toPaste: string[] = [];
          options.forEach((opt: any) => {
            if (opt.constructor === Object) {
              words.some(
                (word) => [opt.k.toLowerCase(), opt.v.toLowerCase()].includes(word.toLowerCase()),
              ) && toPaste.push(opt.k);
            } else {
              words.some(
                (word) => opt.toLowerCase() === word.toLowerCase(),
              ) && toPaste.push(opt);
            }
          });
          onPaste(toPaste as T);
        })}
      ><i className="icon-paste" /></IconButton>
  ) : null;
}

export type MappingParamType = {
  k: string | string[]
  v: string
};

type MappingParamProps<T> = {
  title: string
  value: T
  type?: MappingParamType[] | string[] | string
  inputProps?: OutlinedInputProps
  inputWidth?: number | string
  onChange: (value: T) => void
  helperText?: string
  multiple?: boolean
  required?: boolean
  canPaste?: boolean
  nullable?: boolean
};

type PasteComponentProps<T> = {
  options: MappingParamType[] | string[]
  onPaste: (value: T) => void
  sx?: SystemStyleObject
};


