import React, { PropsWithChildren } from 'react';
import { Typography } from '@mui/material';

export default function ErrorText({ children }: PropsWithChildren<unknown>) {
  return <Typography
    variant="subtitle1"
    color={(theme) => theme.palette.error.main}
    sx={{ paddingLeft: '14px' }}
  >
    {children}
  </Typography>;
}
