import { z } from 'zod';
import { s } from 'i18n';
import Scheduler from 'shared-scope/components/Scheduler';
import { Stack, Typography } from '@mui/material';
import React from 'react';
import { FormController } from 'shared-scope/components/Form';
import { FormField } from '../types';
import { DEFAULTS } from '../helpers/const';
import { ErrorText } from '../components';

type StepSchedulerProps = {
  initialvalue?: string | null | undefined
  mask?: string | null | undefined
};

export default function StepScheduler({
  initialvalue,
  mask,
}: StepSchedulerProps) {
  return (
    <Stack spacing={3}>
      <Typography variant="subtitle1">{s('Timezone of message recipient will be used')}</Typography>
      <FormController
        name={FormField.scheduleTimes}
        initial={initialvalue || mask || DEFAULTS.schedule_times}
        validateRule={scheduleRule}
        renderParams={{ mask }}
        render={({
          value, onChange, error, renderParams,
        }) => (
          <>
            <Scheduler value={value} onChange={onChange} mask={renderParams?.mask || ''} />
            {error && <ErrorText>{error}</ErrorText>}
          </>
        )}
      />
    </Stack>
  );
}

const scheduleRule = (row: StepSchedulerRow) => z.custom(
  () => !!row.schedule_times,
  s('Please configure schedule'),
);

export type StepSchedulerRow = {
  schedule_times?: string
};
