import { Segment } from 'shared-scope/types';
import Encoding, { DEFAULT_MAX_OCTETS, Encod } from './Encoding';

export default class UCS2 extends Encoding {
  name = Encod.ucs2;

  can_represent() { return true; }

  split_segments(
    text: string,
    msg_ref_num = 1,
    force_concat = false,
    max_octets = DEFAULT_MAX_OCTETS,
  ): Segment {
    let segments = [''];
    let perSegment = Math.floor(max_octets / 2);

    const lenText = text.length;
    if (force_concat || lenText > Math.floor(max_octets / 2)) {
      let payloadOctets = max_octets;
      if (msg_ref_num <= 0xff) {
        payloadOctets -= 5;
      } else {
        payloadOctets -= 6;
      }
      const segmentChars = Math.floor(payloadOctets / 2);

      for (let i = 0, len = text.length; i < len; i += 1) {
        // Current UTF char
        let char = '';

        // Current byte code
        const c1 = text.charCodeAt(i);
        const c2 = text.charCodeAt(i + 1);

        // CHeck surrogate
        if (
          c1 >= 0xd800 && c1 <= 0xdbff
          && c2 >= 0xdc00 && c2 <= 0xdfff
        ) {
          char = text.substring(i, i + 2);
          i += 1;
        } else {
          char = text.charAt(i);
        }

        // Create new
        if (segments[segments.length - 1].length + char.length > segmentChars) {
          segments.push('');
        }

        // Concat
        segments[segments.length - 1] += char;
      }

      perSegment = segmentChars;
    } else {
      segments = [text];
    }
    return {
      segments,
      per_segment: perSegment,
    };
  }
}
