import {
  Button,
  Icon,
  Stack,
  Typography,
} from '@mui/material';
import { s } from 'i18n';
import { useCallback, useEffect } from 'react';
import {
  AnswerType,
  AttachmentSize,
  QuestionsTemplate,
  QuestionType,
  ValidatorType,
} from 'products/common/types';
import { UID } from 'shared-scope/helpers/functions';
import {
  cloneDeep, isEqual, set,
} from 'lodash';
import GrayBox from 'shared-scope/components/Box/GrayBox';
import TagBox from 'shared-scope/components/TagBox';
import { FormRowsErrors } from 'shared-scope/components/Form/types';
import { getQuestionsError } from '../helpers';
import Question from './Question';


export default function OptInQuestions(
  {
    value,
    onChange,
    maxAttachmentSize,
    isResponder = false,
    error,
  }: QuestionsProps) {
  /**
   * Normalize value
   */
  useEffect(() => {
    const newValue: QuestionsTemplate = {
      ...{
        initialMessage: '',
        initialAnswer: '',
        initialAttachment: { id: null },
        template: {
          questions: [],
          final_message: '',
          final_attachment_id: null,
        },
      },
      ...value,
    };
    !isEqual(value, newValue) && onChange(newValue);
  }, [value, onChange]);

  const handleQuestions = useCallback((f: Field, v: any) => {
    const newValue: any = cloneDeep(value) || {};
    switch (f) {
      case Field.initialAnswer:
        newValue[f] = v as string;
        break;
      case Field.initialAttachment:
        if (v) {
          newValue[f] = cloneDeep(newValue[f]) || {};
          newValue[f].id = v as string;
        } else {
          newValue[f] = v;
        }
        break;
      case Field.doubleVerificationQuestion:
        if (!newValue.template?.questions?.length) {
          set(newValue, 'template.questions[0]', getEmptyQuestion());
        }
        set(newValue, 'template.questions[0].question', v);
        break;
      case Field.doubleVerificationAnswer:
        set(newValue, 'template.questions[0].keywords', v ? v.split(' ') : []);
        break;
      case Field.doubleVerificationAttachmentId:
        set(newValue, 'template.questions[0].attachment_id', v);
        break;
      case Field.finalMessage:
      case Field.finalAttachmentId:
        set(newValue, `template[${f}]`, v);
        break;
      default:
        console.error(`Unknown question field ${f}`);
    }
    onChange(newValue as QuestionsTemplate, true);
  }, [value, onChange]);

  useEffect(() => {
    if (isResponder) {
      handleQuestions(Field.initialMessage, '');
    }
    // eslint-disable-next-line
  }, [isResponder]);

  const questionError = error ? getQuestionsError(error) : null;

  return <Stack spacing={2}>
    <Stack direction="row" spacing={1} justifyContent="flex-start" alignItems="center">
      <Icon
        className="icon-alert"
        fontSize="small"
        sx={{ transform: 'rotate(180deg)', height: '22px' }}
      />
      <Typography variant="body1">
        {s('Enter one or several opt-in keywords')}
      </Typography>
    </Stack>
    <GrayBox
      sx={{ width: '1000px' }}
    >
      <TagBox
        value={value?.initialAnswer || ''}
        onChange={(v) => handleQuestions(Field.initialAnswer, v as string)}
        textField={{
          label: s('Keywords') || '',
          variant: 'outlined',
          sx: { maxWidth: '852px' },
          error: !!questionError?.initial?.reply,
          helperText: questionError?.initial?.reply,
        }}
      />
    </GrayBox>

    {value?.template?.questions?.length
      ? (
        <Question
          key="double_verification"
          title={s('Double opt-in message')}
          answerTitle={s('Confirmation keywords')}
          question={value?.template?.questions?.[0]?.question || ''}
          answer={value?.template?.questions?.[0]?.keywords?.join(' ') || ''}
          attachment={value?.template?.questions?.[0]?.attachment_id}
          maxAttachmentSize={maxAttachmentSize}
          onChange={(v) => handleQuestions(Field.doubleVerificationQuestion, v)}
          onAnswerChange={(v) => handleQuestions(Field.doubleVerificationAnswer, v)}
          onAttachmentChange={(v) => handleQuestions(Field.doubleVerificationAttachmentId, v)}
          onDelete={() => {
            const newValue = value ? cloneDeep(value) : null;
            if (newValue?.template) {
              newValue.template.questions = [];
              onChange(newValue, true);
            }
          }}
          error={questionError?.questions?.[0] || undefined}
        />
      )
      : null}

    {!value?.template?.questions?.length
      ? (
        <Button
          variant="outlined"
          sx={{ width: '300px' }}
          onClick={() => {
            handleQuestions(Field.doubleVerificationQuestion, '');
          }}
        >
          {s('Add double verification')}
        </Button>
      )
      : null}

    <Question
      key="final_message"
      title={s('Confirmation message')}
      question={value?.template?.final_message || ''}
      attachment={value?.template?.final_attachment_id || null}
      maxAttachmentSize={maxAttachmentSize}
      onChange={(v) => handleQuestions(Field.finalMessage, v)}
      onAttachmentChange={(v) => handleQuestions(Field.finalAttachmentId, v)}
      error={questionError?.final}
    />
  </Stack>;
}

const getEmptyQuestion: () => QuestionType = () => ({
  keywords: [],
  position: 1,
  question: '',
  field_name: '1',
  answer_type: AnswerType.OptIn,
  attachment_id: null,
  key: UID(),
  validator: ValidatorType.AnyText,
});

type QuestionsProps = {
  value: QuestionsTemplate | null
  onChange: (v: QuestionsTemplate, templateChanged?: boolean) => void
  isResponder?: boolean
  maxAttachmentSize?: AttachmentSize
  error?: FormRowsErrors
};

enum Field {
  initialMessage = 'initialMessage',
  initialAnswer = 'initialAnswer',
  initialAttachment = 'initialAttachment',
  questions = 'questions',
  finalMessage = 'final_message',
  finalAttachmentId = 'final_attachment_id',
  doubleVerificationAttachmentId = 'double_verification_attachment_id',
  doubleVerificationQuestion = 'double_verification_question',
  doubleVerificationAnswer = 'double_verification_answer',
}
