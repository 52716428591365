import * as CONFIG from './shared-scope/config';

export default function initAnalytics(userId) {
	/* Global site tag (gtag.js) - Google Analytics */
	let ga_tag;
	try {
		ga_tag = CONFIG.get('g_analytics_tag');
		if (ga_tag) {
			const ga_code   = `
window.dataLayer = window.dataLayer || []; 
function gtag(){dataLayer.push(arguments);} 
gtag('js', new Date()); 
gtag('config', '${ga_tag}', {
  cookie_domain: '${window.location.hostname}',
  cookie_prefix: '${BASENAME.replaceAll('/', '_')}',
  send_page_view: false
});
			`;
			const ga_script = document.createElement('script');
			ga_script.type  = 'text/javascript';
			ga_script.appendChild(document.createTextNode(ga_code));
			document.head.insertBefore(ga_script, document.head.firstChild);

			const gm_script = document.createElement('script');
			gm_script.src   = `https://www.googletagmanager.com/gtag/js?id=${ga_tag}`;
			gm_script.async = true;
			gm_script.type  = "text/javascript";
			document.head.insertBefore(gm_script, document.head.firstChild);
		}
	} catch (e) {
		/* Need set ga_tag */
		console.warn('Google Analytics service is not enabled');
	}
	/* Google Analytics */

	/*Google Tag Manager*/
	try {
		const g_tag_manager = CONFIG.get('g_tag_manager');
		if (g_tag_manager) {
			/* Head part*/
			const gtm_code = `
			(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${g_tag_manager}');
			`;

			const gtm_script = document.createElement('script');
			gtm_script.type  = 'text/javascript';
			gtm_script.appendChild(document.createTextNode(gtm_code));
			document.head.insertBefore(gtm_script, document.head.firstChild);

			/*Body part*/
			const gtm_noscript          = document.createElement('noscript');
			const gtm_iframe            = document.createElement('iframe');
			gtm_iframe.src              = `https://www.googletagmanager.com/ns.html?id=${g_tag_manager}`;
			gtm_iframe.height           = '0';
			gtm_iframe.width            = '0';
			gtm_iframe.style.display    = 'none';
			gtm_iframe.style.visibility = 'hidden';

			gtm_noscript.appendChild(gtm_iframe);
			document.body.insertBefore(gtm_noscript, document.body.firstChild);
		}
	} catch (e) {
		/* Need set g_tag_manager */
		console.warn('Google Tag Manager service is not enabled');
	}
	/*Google Tag Manager*/

	/*Hotjar*/
	try {
		const hj_tag = CONFIG.get('hotjar_id');
		if (hj_tag) {
			(function (h, o, t, j, a, r) {
				h.hj          = h.hj || function () {
					(h.hj.q = h.hj.q || []).push(arguments)
				};
				h._hjSettings = {hjid: hj_tag, hjsv: 6};
				a             = o.getElementsByTagName('head')[0];
				r             = o.createElement('script');
				r.async       = 1;
				r.src         = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
				a.appendChild(r);
			})(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
		}
	} catch (e) {
		/* Need set hotjar_id */
		console.warn('HJ Analytics service is not enabled');
	}
	/*Hotjar*/

	/*Yandex.Metrika*/
	try {
		const ym_id = CONFIG.get('ym_id');
		if (ym_id) {
			const ym_code = `
			(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
   m[i].l=1*new Date();
   for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
   k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
   (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

   ym(${ym_id}, "init", {
        clickmap:true,
        trackLinks:true,
        accurateTrackBounce:true,
        webvisor:true
   });
			`;

			const ym_script = document.createElement('script');
			ym_script.type  = 'text/javascript';
			ym_script.appendChild(document.createTextNode(ym_code));
			document.head.insertBefore(ym_script, document.head.firstChild);

			/*Body part*/
			const ym_noscript     = document.createElement('noscript');
			const ym_noscript_div = document.createElement('div');
			const ym_noscript_img = document.createElement('img');
			ym_noscript_img.src   = `https://mc.yandex.ru/watch/${ym_id}`;
			ym_noscript_img.style = 'position:absolute; left:-9999px;';
			ym_noscript_img.alt   = '';

			ym_noscript_div.appendChild(ym_noscript_img);
			ym_noscript.appendChild(ym_noscript_div);
			document.body.insertBefore(ym_noscript, document.body.firstChild);
		}
	} catch (e) {
		/* Need set ym_id */
		console.warn('Yandex.Metrika service is not enabled');
	}
	/*Yandex.Metrika*/

	// Set user id
	ga_tag && gtag('config', ga_tag, {'user_id': userId});
}
