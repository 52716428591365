import makeTheme from './MuiThemeGenerator';

const theme = makeTheme({
  palette: {
    primary: {
      main: '#8cbc43',
      light: '#edffd1',
      // dark: '#202426',
      contrastText: '#fff',
    },
    error: {
      main: '#FF0D2A',
      light: '#fbd6da',
    },
    warning: { main: '#FFBA34' },
    info: { main: '#094AF0' },
    success: {
      main: '#01A76F',
      light: '#d9f9ee',
    },
    normal: {
      main: '#000020',
      light: '#202426',
    },
    muted: { main: '#787878' },
    other: { main: '#7200B1' },
    layout: {
      main: '#FFFFFF',
      light: '#F6F6F6',
      dark: '#F6FAF0',
    },
    header: {
      main: '#0d0d0d',
      light: '#313131',
      contrastText: '#FFFFFF',
    },
  },
  typography: {
    caption: {
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
  components: ({ palette }) => ({
    MuiToggleButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'inherit',
            color: palette.primary.main,
          },
          '&.Mui-selected': {
            backgroundColor: palette.primary.main,
            color: palette.primary.contrastText,
            '&:hover': {
              backgroundColor: palette.primary.main,
            },
          },
        },
      },
    },
  }),
});

export default theme;
