import { Attachment } from 'graphql-api';
import { z } from 'zod';
import { s } from 'i18n';
import {
  zMessageAnswer,
  zMessageAttachment,
  zMessageText,
  zNotRequired,
} from './validators';
import { MessageValidatesList } from './isCorrectMessage';

const isCorrectQuestions = (
  attachmentList: Attachment[],
  maxAttachmentSize?: number,
  validate?: MessageValidatesList,
) => {
  const checkMessage = (errText: string) => (validate?.message !== false ? zMessageText(errText) : zNotRequired);
  const checkAnswer = (errText: string) => (validate?.answer !== false ? zMessageAnswer(errText) : zNotRequired);
  const checkAttachment = validate?.attach !== false
    ? zMessageAttachment(attachmentList, maxAttachmentSize)
    : zNotRequired;

  return z.object({
    initialMessage: checkMessage(s('Engagement question can\'t be empty')),
    initialAnswer: checkAnswer(s('Answer to Engagement question can\'t be empty.')),
    initialAttachment: z.object({
      id: checkAttachment,
    }).nullish(),
    template: z.object({
      final_message: checkMessage(s('You must set a text for final message')),
      final_attachment_id: checkAttachment,
      questions: z.array(z.object({
        question: checkMessage('You must set a text for each question'),
        attachment_id: checkAttachment,
      })).nonempty(),
    }),
  });
};

export default isCorrectQuestions;
