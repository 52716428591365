import { styled } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/system';

const GrayBox = styled(Box)<BoxProps>(({ theme }) => (
  {
    backgroundColor: theme.palette.layout.light,
    borderRadius: '12px',
    padding: '16px',
  }
));

export default GrayBox;
