import { Button, Stack } from '@mui/material';
import { s } from 'i18n';
import ActionsBox from 'shared-scope/components/Box/ActionsBox';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function Actions({
 submitText, cancelText, onCancel, onDraft,
}: ActionsProps) {
  const navigate = useNavigate();

  return (
    <ActionsBox>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="flex-start"
        alignItems="center"
      >
        <Button
          variant="outlined"
          onClick={onCancel || (() => navigate(-1))}
        >{cancelText || s('Cancel')}</Button>

        {onDraft
          ? (
            <Button
              onClick={onDraft || (() => navigate(-1))}
              variant="outlined"
            >
              {s('Save draft')}
            </Button>
          )
          : null}

        <Button
          type="submit"
          variant="contained"
        >{submitText}</Button>
      </Stack>
    </ActionsBox>
  );
}

type ActionsProps = {
  submitText: string
  cancelText?: string
  onCancel?: () => void
  onDraft?: () => void
};
