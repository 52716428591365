import { TextFieldVariants } from '@mui/material/TextField/TextField';
import { Box, TextField, TextFieldProps } from '@mui/material';
import { forwardRef, ReactNode } from 'react';
import styled from '@emotion/styled';

export default function TextFieldHighlight<Variant extends TextFieldVariants>(
  {
    highlight, value, ...props
  }:TextFieldHighlightProps<Variant>,
) {
  const highlighted = highlight(value ? value as string : '');

  return (
    <TextField
      {...props}
      value={value}
      InputProps={{
        inputComponent: CustomInput as any,
        inputProps: {
          highlighted,
        },
      }}
      multiline
    />
  );
}

const TextArea = styled.textarea`
    position: absolute;
    top: 0;
    left: 0;
    height: 100% !important;
    width: 100%;
    overflow-wrap: break-word;
    overflow: hidden;
    -webkit-text-fill-color: transparent;
    min-height: 23px;
    font-family: "Noto Sans Mono", monospace !important;
    font-weight: 400;
    font-optical-sizing: auto;
    font-style: normal;
`;
const Pre = styled.pre`
    position: relative;
    font-family: "Noto Sans Mono", monospace;
    font-size: inherit;
    font-style: normal;
    font-variant-ligatures: inherit;
    font-weight: 400;
    font-optical-sizing: auto;
    letter-spacing: inherit;
    line-height: inherit;
    tab-size: inherit;
    text-indent: inherit;
    text-rendering: inherit;
    text-transform: inherit;
    white-space: pre-wrap;
    word-break: keep-all;
    overflow-wrap: break-word;
    pointer-events: none;
`;

const CustomInput = forwardRef<HTMLTextAreaElement, CustomInputProps>(
  ({ value, highlighted, ...textarea }: CustomInputProps) => {
  return (
    <Box
      sx={{
        position: 'relative',
        textAlign: 'left',
        boxSizing: 'border-box',
        padding: '0px',
        overflow: 'hidden',
        width: '100%',
        lineHeight: '23px',
        minHeight: '23px',
      }}
    >
      <TextArea value={value} {...textarea} />
      <Pre>{highlighted}</Pre>
    </Box>
  );
},
);

type TextFieldHighlightProps<Variant extends TextFieldVariants> = {
    highlight: (v: string) => string | ReactNode
    variant?:Variant
} & Omit<TextFieldProps, 'variant'>;

type CustomInputProps = {
  value: string
  highlighted: string | ReactNode
};
