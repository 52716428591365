import {
  useState, useCallback, ReactNode,
} from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Alert,
  AlertProps,
  Button,
} from '@mui/material';
import { DialogProps } from '@mui/material/Dialog';
import { s } from 'i18n';


interface IConfirmDlgPrm {
  show: boolean
  alert?: string | ReactNode
  content?: string | ReactNode
  onClose: Function // required to close dialog by outer confirm(false) of outer hook useConfirmation()
  onOk?: Function // like Apply action
  onCloseAlert?: AlertProps['onClose']
  alertSeverity?: AlertProps['severity']
}

type ConfirmFunction = (content: string | ReactNode, onClose: Function) => void;

export function useConfirmation() {
  const [confirmParams, setConfirmParams] = useState<IConfirmDlgPrm>({
    show: false, content: '', onClose: () => false,
  });

  const showConfirm: ConfirmFunction = useCallback((content, onClose) => {
    if (!content) {
      setConfirmParams((curParams) => ({ ...curParams, show: false }));
      return;
    }
    setConfirmParams({ show: true, content, onClose });
  }, []);

  const hideConfirm = useCallback(() => {
    setConfirmParams((curParams) => ({ ...curParams, show: false }));
  }, []);

  return [showConfirm, hideConfirm, confirmParams] as const;
}

interface IConfirmDlg {
  confirmParams: IConfirmDlgPrm
  title?: string
  loading?: boolean
  okButton?: boolean
  maxWidth?: DialogProps['maxWidth']
  sx?: DialogProps['sx']
  paperSx?: object
  noLabel?: string
  yesLabel?: string
  contentClass?: string
  disabled?: boolean
  fullWidth?: boolean
  children?: ReactNode
}

// eslint-disable-next-line react/function-component-definition
// export const ConfirmDlg: FC<IConfirmDlg> = ({ confirmParams, loading }) => (
export function ConfirmDlg({
  confirmParams,
  title,
  okButton,
  maxWidth,
  yesLabel,
  noLabel,
  disabled,
  sx,
  fullWidth = false,
  contentClass = '',
  paperSx = {},
  children,
}: IConfirmDlg) {
  const content = children || confirmParams.content || '';

  return (
    <Dialog
      disableEscapeKeyDown
      maxWidth={maxWidth}
      sx={sx}
      open={confirmParams.show}
      fullWidth={fullWidth}
      PaperProps={{ sx: paperSx }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent
        className={contentClass}
        dividers
      >
        {confirmParams.alert && <Alert icon={false} className="mb-2" severity={confirmParams.alertSeverity || 'warning'} onClose={confirmParams.onCloseAlert}>{confirmParams.alert}</Alert>}
        {content}
      </DialogContent>
      <DialogActions
        sx={{
          padding: '8px 24px',
        }}
      >
        {!okButton ? (
          <>
            <Button
              autoFocus
              size="small"
              variant="outlined"
              onClick={() => {
                confirmParams.onClose(false);
              }}
            >
              {noLabel || s('No')}
            </Button>
            <Button
              variant="contained"
              size="small"
              disabled={disabled}
              onClick={() => {
                confirmParams.onOk
                  ? confirmParams.onOk()
                  : confirmParams.onClose(true);
              }}
            >
              {yesLabel || s('Yes')}
            </Button>
          </>
        ) : (
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              confirmParams.onOk
                ? confirmParams.onOk()
                : confirmParams.onClose();
            }}
          >
            {s('Ok')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

ConfirmDlg.defaultProps = {
  loading: false,
  title: s('Please confirm'),
  okButton: false,
  maxWidth: 'xs',
  yesLabel: '',
  noLabel: '',
  sx: undefined,
  disabled: false,
};
