import { FormRowsErrors } from 'shared-scope/components/Form/types';
import { QuestionError } from 'products/common/types';
import { Maybe } from 'shared-scope/types';

const getQuestionsError = (formErr: FormRowsErrors): QuestionsError<QuestionError> => {
  const errors: QuestionsError<QuestionError> = {
    initial: { ...emptyError },
    final: { ...emptyError },
    questions: [],
  };

  formErr.forEach((e) => {
    if (!e.path.length || e.path.includes('initialMessage')) {
      errors.initial.question = e.message;
    } else
    if (e.path.includes('initialAttachment') && !errors.initial.attachment) {
      errors.initial.attachment = e.message;
    } else
    if (e.path.includes('initialAnswer')) {
      errors.initial.reply = e.message;
    } else

    if (!e.path.length || e.path.includes('final_message')) {
      errors.final.question = e.message;
    } else
    if (e.path.includes('final_attachment_id') && !errors.final.attachment) {
      errors.final.attachment = e.message;
    } else

    if (e.path.join('.').includes('template.questions')) {
      const index = e.path[2] as number;
      const target = e.path[3] as string;
      if (!errors.questions[index]) {
        errors.questions[index] = { ...emptyError };
      }
      const q = errors.questions[index] as QuestionError;
      if (target === 'question') {
        q.question = e.message;
      } else
      if (target === 'attachment_id' && !q.attachment) {
        q.attachment = e.message;
      } else
      if (target === 'keywords') {
        q.reply = e.message;
      }
    }
  });

  return errors;
};

export default getQuestionsError;

export type QuestionsError<T> = { initial: T, final: T, questions: Maybe<T>[] };

const emptyError: QuestionError = {
  question: '',
  attachment: '',
  reply: '',
};
