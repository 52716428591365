import { getSdk } from 'products/PhoneBook/query.generated';
import { fetcher } from 'graphql-api/fetcher';
import { ContactInput, Contact } from 'graphql-api';


const api = getSdk(fetcher);

export const phoneNumberValidator = (phoneNumber: string) => {
  let pn = 0;
  try {
    pn = Number(phoneNumber);
  } catch (e) {
    return false;
  }
  return (pn > 12_000_000_000 && pn < 20_000_000_000);
};

export function newContact(id: string) {
  return {
    id,
    phoneNumber: '',
    firstName: '',
    lastName: '',
    gender: '',
    state: '',
    tz: '',
    isNew: true,
  } as Contact;
}

export const createOrModifyContact = async ({ phoneBookId, contact }: CreateParams, config?: any) => {
  const filter = contact.id ? JSON.stringify({ id: contact.id }) : null;
  delete contact.id;
  delete contact.operatorName;
  const response = await api.saveContact({
    phoneBookId,
    filter,
    input: contact as ContactInput,
  }, config);
  return response;
};

export const deleteContact = async ({ phoneBookId, contactIds }: DeleteParams, config?: any) => {
  const filter = JSON.stringify({ id: { $in: contactIds } });
  const response = await api.deleteContact({
    phoneBookId,
    filter,
  }, config);
  return response;
};

type CreateParams = {
  phoneBookId: number
  contact: Contact
};

type DeleteParams = {
  phoneBookId: number
  contactIds: string[]
};
