import { fetcher } from 'graphql-api/fetcher';
import { FormProvider, useForm } from 'shared-scope/components/Form';
import { useNavigate, useParams } from 'react-router-dom';
import useAlert from 'shared-scope/hooks/useAlert';
import { useRequest } from '@xeebi/neru';
import React, { useCallback, useEffect, useState } from 'react';
import { s } from 'i18n';
import Page from 'layout/Page';
import { Stepper, Step } from 'shared-scope/components/Stepper';
import {
  Actions,
  StepGeneralPhoneBook,
  StepGeneralPhoneBookRow,
  StepSettings, StepSettingsRow,
  StepTarget, StepTargetRow,
} from 'products/shared/Campaign/steps';
import { FormField, Sources, request } from 'products/shared/Campaign';
import { omit } from 'lodash';
import { getSdk } from '../query.generated';


const api = getSdk(fetcher);
const back = '/phone-book';

export default function NewPhoneBook() {
  const { folderId } = useParams();
  const fId = !folderId || folderId === 'null' ? null : Number(folderId);
  const [backUrl, setBackUrl] = useState('');

  const form = useForm<NewPhoneBookRow>();
  const navigate = useNavigate();
  const { addSuccess, addError } = useAlert();
  const {
    isLoading: isLoadingSave,
    error: errorSave,
    fetch: createPhoneBook,
  } = useRequest(api.phoneBookSave);
  const {
    isLoading: isLoadingImport,
    error: errorImport,
    fetch: importContacts,
    result: resultImport,
  } = useRequest(request.importContacts);

  const onSubmit = useCallback(async (row: NewPhoneBookRow) => {
    const resultPhoneBook = await createPhoneBook({
      input: {
        name: row.title,
        folder: row.folder?.id,
        country: row.country?.id,
      },
    });
    const phoneBookId = resultPhoneBook?.phoneBookSave[0]?.id;

    if (phoneBookId) {
      const url = `${row.folder?.id || null}/${phoneBookId}`;
      if (row?.source_value) {
        setBackUrl(url);
        const data = {
          ...omit(row.source_value?.data, ['data_lines', 'data_rowset', 'data_sheets', 'file']),
          ...omit(row.source_value, ['data']),
          phone_book_id: phoneBookId,
          phone_book_folder_id: row.folder?.id,
          source_type: row.source_type,
          mapping: row.mapping,
          skip: row.mapping_param?.skipTopRows,
          phone_add: row.mapping_param?.addPrefix,
          phone_cut: row.mapping_param?.cutDigits,
          delimiter: row.mapping_param?.delimiter,
          enclosure: row.mapping_param?.quoteCharacter,
          sheets: row.mapping_param?.sheet,
          guess_state: true,
          guess_timezone: true,
          guess_gender: true,
        };

        await importContacts(data);
      } else {
        addSuccess(s('New phone book added successfully'));
        navigate(`/phone-book/${url}`);
      }
    }
  }, [createPhoneBook, importContacts, addSuccess, navigate]);

  useEffect(() => {
    errorSave && addError(errorSave.getMessage());
    errorImport && addError(errorImport.getMessage());
  }, [errorImport, errorSave, addError]);

  useEffect(() => {
    resultImport && backUrl && addSuccess(s('New phone book added successfully'));
    resultImport && backUrl && navigate(`/phone-book/${backUrl}`);
  }, [resultImport, addSuccess, navigate, backUrl]);

  return (
    <Page title={s('Create phone book')} back={back} loading={isLoadingSave || isLoadingImport}>
      <FormProvider form={form} onSubmit={onSubmit}>
        <Stepper>
          <Step label={s('General')}>
            <StepGeneralPhoneBook folderId={fId} />
          </Step>
          <Step label={s('Source')}>
            <StepTarget avail={[Sources.excel, Sources.text]} targetType="phone-book" noValidate />
          </Step>
          <Step label={s('Settings')}>
            <StepSettings
              sourceType={form.getValue(FormField.sourceType)}
              sourceValue={form.getValue(FormField.sourceValue) || null}
            />
          </Step>
        </Stepper>
        <Actions
          submitText={s('Create phone book')}
          onCancel={() => navigate(back)}
        />
      </FormProvider>
    </Page>
  );
}

type NewPhoneBookRow = StepGeneralPhoneBookRow & StepTargetRow & StepSettingsRow;
