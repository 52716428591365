import {
  Button, Box, Checkbox, FormControlLabel,
  Stack, Typography,
} from '@mui/material';
import { s } from 'i18n';
import { useFormContext } from 'shared-scope/components/Form';
import React, {
  useEffect,
} from 'react';
import useAlert from 'shared-scope/hooks/useAlert';
import { useRequest } from '@xeebi/neru';
import { uploadSource } from 'products/shared/Campaign/request';
import { get } from 'lodash';
import {
  SOURCE_CAMPAIGN, SOURCE_FILE,
  SOURCE_TEXT, SOURCE_PHONE_BOOK,
} from 'products/CampaignList/helpers';
import { StepTargetRow } from 'products/shared/Campaign/steps/types';
import {
  CampaignType,
  FormField,
} from '../types';


const sourceMap = {
  [SOURCE_FILE]: s('File'),
  [SOURCE_TEXT]: s('Text'),
  [SOURCE_PHONE_BOOK]: s('Phone book'),
};


export default function StepTargetReadOnly(
  {
    campaignId,
    onEdit,
    useParticipants = false,
  }: StepTargetProps) {
  const {
    form: { setValue },
    formRow,
  } = useFormContext<StepTargetRow>();

  const {
    error,
    fetch: fetchCampaign,
  } = useRequest(uploadSource);

  const { addError } = useAlert();

  useEffect(() => {
    const uploadCampaign = async () => {
      const result = await fetchCampaign({
        source_type: SOURCE_CAMPAIGN,
        campaign_id: campaignId,
        useParticipants,
      });

      result?.data && setValue(FormField.sourceValue, (prev: any) => ({
        ...prev,
        data: result.data,
      }));
    };
    campaignId && uploadCampaign();
  }, [setValue, campaignId, fetchCampaign, useParticipants]);

  const sourceType = formRow?.source_type
    ? get(sourceMap, formRow?.source_type || '', '')
    : '';

  const sourceName = formRow?.source_type
    ? get(formRow, 'source_name', '')
    : '';

  useEffect(() => {
    error && addError(error.getMessage());
  }, [error, addError]);

  return (
    <Stack spacing={3}>
      <Box sx={{ maxWidth: '600px' }}>
        <Stack spacing={2}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="subtitle1" fontSize={16}>{sourceType}</Typography>
            {formRow?.source_type !== SOURCE_TEXT
              ? <Typography fontSize={16}>{sourceName}</Typography>
              : null}
          </Stack>
          <Box>
            <Button
              variant="outlined"
              size="small"
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                onEdit();
              }}
            >
              {s('Change')}
            </Button>
          </Box>
        </Stack>
      </Box>

      {formRow.type === CampaignType.drip
        ? (
          <FormControlLabel
            label={s('Send messages to contacts already in the phonebook')}
            control={<Checkbox
              disabled
              checked={formRow.send_to_existing_contacts}
            />}
          />
        )
        : null}
    </Stack>
  );
}

type StepTargetProps = {
  useParticipants?: boolean
  campaignId?: number
  onEdit: () => void
};
