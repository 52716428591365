import { s } from 'i18n';
import { isUndefined } from 'lodash';
import { Attachment } from 'graphql-api';


type ValidAttachmentProps = {
  attachmentId: string
  maxAttachmentSize?: number
  attachmentList?: Attachment[]
};

export default function validateAttachment({ attachmentId, maxAttachmentSize, attachmentList }: ValidAttachmentProps) {
  if (isUndefined(maxAttachmentSize)) {
    return '';
  }
  attachmentList = attachmentList || [];
  const attach = attachmentList.find((att) => att.id === attachmentId);
  let errAttach = '';
  if (!maxAttachmentSize) {
    errAttach = s('Attachments are not allowed on your route');
  } else if (!attach?.size) {
    errAttach = s('The file is broken and cannot be attached to the message');
  } else if (attach.size > maxAttachmentSize) {
    errAttach = s('The file is too big. It will be deleted when the campaign is created');
  }
  return errAttach;
}
