import { s } from 'i18n';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { useCallback, useState } from 'react';
import { Campaign } from 'graphql-api';
import { Button } from '@mui/material';
import {
  exportCampaign,
  exportLeads,
  NEW,
  ALL,
} from 'products/Details/helpers';
import Loading from 'shared-scope/components/Loading';
import { GridType } from 'products/CampaignList/types';
import { useNavigate } from 'react-router-dom';


export type ExportProps = {
  data: Campaign | null
  onError: Function
  campaignType: GridType.Poll | GridType.TextCampaign | GridType.AgentPoll | GridType.OptIn
};

export type Option = {
    k: number | null
    v: string
    filter: any
};
export function Export({ data, onError, campaignType }: ExportProps) {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const getExport = useCallback(async (campaignId: number, option: Option | null, reply: boolean) => {
    try {
      setLoading(true);
      setOpen(false);
      await exportCampaign(campaignId, option, reply);
    } catch (e: any) {
      console.log(e);
      onError(e?.message || '');
    } finally {
      setLoading(false);
    }
  }, [setLoading, onError]);

  const getLeads = useCallback(async (campaignId: number, leadType: 'all' | 'new') => {
    try {
      setLoading(true);
      setOpen(false);
      await exportLeads(campaignId, leadType);
    } catch (e: any) {
      console.log(e);
      onError(e?.message || '');
    } finally {
      setLoading(false);
    }
  }, [setLoading, onError]);

  return (
    <>
      {loading
        ? <Loading logo={false} size={38} />
        : (
          <Button
            variant="outlined"
            color="inherit"
            size="small"
            endIcon={<i className="icon-select" />}
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
              setOpen(true);
            }}
            sx={{ fontSize: '1rem' }}
          >
            {campaignType === GridType.Poll ? s('Results') : s('Export')}
          </Button>
        )}
      <Menu
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={anchorEl}
      >
        {campaignType === GridType.Poll
          ? ([
            <MenuItem
              key="report"
              onClick={() => data?.id && navigate(`/report/poll/${data?.id}`)}
            >
              {s('Report')}
            </MenuItem>,
            <MenuItem
              key="all_leads"
              onClick={() => data?.id && getLeads(data.id, ALL)}
            >
              {s('Respondents - all')}
            </MenuItem>,
            <MenuItem
              key="new_leads"
              onClick={() => data?.id && getLeads(data.id, NEW)}
            >
              {s('Respondents - new')}
            </MenuItem>,
          ])
          : null}

        <MenuItem
          key="all"
          onClick={() => data?.id && getExport(data.id, null, false)}
        >
          {s('Messages')}
        </MenuItem>
        <MenuItem
          key="incoming"
          onClick={() => data?.id && getExport(data.id, null, true)}
        >
          {s('Raw answers')}
        </MenuItem>
      </Menu>
    </>
  );
}
