import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { AxiosRequestConfig } from 'axios';
import {
  GridRowSelectionModel,
} from '@mui/x-data-grid-pro';
import { s } from 'i18n';
import { fetcher } from 'graphql-api/fetcher';
import useAlert from 'shared-scope/hooks/useAlert';
import {
  useMountedState,
  useRequest,
  useGridRef,
  DataGrid,
  ApiQueryParams,
  ApiQueryResult,
} from '@xeebi/neru';
import columns from 'products/OptIn/OptInList/Struct';
import { getSdk } from '../query.generated';
import Export from './Export';


const api = getSdk(fetcher);

const deleteOptInRecords = async (selected: GridRowSelectionModel, config?: AxiosRequestConfig) => {
  await api.optInDelete({
    filter: JSON.stringify({ id: { $in: selected } }),
  }, config);
};

export const getRecords = async (params: ApiQueryParams, config: AxiosRequestConfig) => {
  const gridFilter = params.filter === 'null' ? '' : `, ${params.filter}`;
  params.filter = `{"$and":[{"status": {"$in": [1, 4]}}${gridFilter}]}`;
  const { optInList, optInListCount } = await api.getOptInList(params, config);
  return { count: optInListCount, rows: optInList } as ApiQueryResult;
};

export default function OptInList() {
  const navigate = useNavigate();
  const gridRef = useGridRef();
  const isMounted = useMountedState();
  const { addError } = useAlert();
  const [count, setCount] = useState(0);
  const [selected, setSelected] = useState<GridRowSelectionModel>([]);

  const getData = useCallback(async (params: ApiQueryParams, config: AxiosRequestConfig) => {
    const result = await getRecords(params, config);
    isMounted() && setCount(result.count || 0);
    return result;
  }, [isMounted]);

  const {
    isLoading,
    error,
    fetch,
    cancel,
  } = useRequest(deleteOptInRecords);

  const deleteRecords = useCallback(async () => {
    if (isLoading) {
      cancel(s('Duplicate request'));
    }
    await fetch(selected);
    setSelected([]);
    gridRef.current.refresh();
  }, [isLoading, fetch, cancel, selected, gridRef]);

  useEffect(() => {
    if (error) {
      addError(error.getMessage());
      console.error(error.getError());
    }
  }, [error, addError]);

  const ExportTool = useCallback(() => (
    <Export
      count={count}
      selected={selected}
      gridRef={gridRef}
    />
  ), [count, selected, gridRef]);

  return (
    <>
      <DataGrid
        primaryKey="id"
        storageId="StopListGrid"
        refreshTimeout={15_000}
        pageSize={20}
        apiRef={gridRef}
        getRows={getData}
        columns={columns}
        customTools={ExportTool}
        selected={selected}
        onSelect={setSelected}
        onDelete={deleteRecords}
        checkboxSelection
        hideFooterSelectedRowCount={false}
        onRowClick={(row) => {
          navigate(`/opt-in/opt-in-list/${row.id}`);
        }}
      />
      <Outlet />
    </>
  );
}
