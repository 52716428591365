import React from 'react';
import { s } from 'i18n';
import axios from 'axios';
import {
  Box,
  Typography,
  Stack,
  IconButton,
} from '@mui/material';
import * as CONFIG from 'shared-scope/config';
import {
  FilterDateRange,
} from '@xeebi/neru';
import DateTime from 'shared-scope/components/DateTime';
import { GridColDef, GridHeaderFilterCellProps } from '@mui/x-data-grid-pro';


export async function downloadInvoice(id: number) {
  const url = `${CONFIG.get('api')}/customer-document/${id}/download`;

  try {
    const execResp = await axios.get(
      url,
      {
        responseType: 'blob',
      },
    );

    if (execResp?.status !== 200) {
      throw new Error(`Wrong status ${execResp?.status}`);
    }

    const fd = (new Date()).format('dd_mm_yyyy_H2_M2');
    const href = URL.createObjectURL(execResp?.data);
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', `invoice_${id}_${fd}.zip`);
    link.click();
    URL.revokeObjectURL(href);
  } catch (error) {
    console.log('++invoice export failed++ ', error);
  }
}

export default function getColumns({
  currencySign,
}: StructProps) {
  const columns: GridColDef[] = [
    {
      field: 'action',
      headerName: 'Action',
      renderCell: ({ row }) => (
        <Stack justifyContent="center" sx={{ height: '100%' }}>
          <Box>
            <IconButton
              className="icon-download"
              size="medium"
              onClick={(e) => {
                e.stopPropagation();
                downloadInvoice(row.id);
              }}
            />
          </Box>
        </Stack>
      ),
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      width: 90,
      flex: 0,
    },
    {
      field: 'refNumber',
      headerName: 'Reference',
      type: 'string',
      sortable: true,
      filterable: true,
      disableColumnMenu: true,
      minWidth: 80,
      flex: 0,
    },
    {
      field: 'amount',
      type: 'string',
      headerName: s('Amount'),
      disableColumnMenu: true,
      filterable: false,
      sortable: true,
      editable: false,
      minWidth: 150,
      flex: 0,
      renderCell: ({ value }) => (
        <Stack justifyContent="center" alignItems="end" sx={{ height: '100%' }}>
          <Typography>{`${value} ${currencySign || '$'}`}</Typography>
        </Stack>
      ),
    },
    {
      field: 'name',
      type: 'string',
      headerName: s('Name'),
      disableColumnMenu: true,
      filterable: true,
      editable: false,
      minWidth: 200,
      flex: 0,
    },

    {
      field: 'firstDate',
      headerName: s('First date'),
      disableColumnMenu: true,
      type: 'custom',
      renderHeaderFilter: (params: GridHeaderFilterCellProps) => (
        <FilterDateRange
          {...params}
          placeholder={s('First date')}
        />
      ),
      valueGetter: (value) => (value ? new Date(value) : null),
      renderCell: ({ value }) => <DateTime value={value} direction="row" />,
      minWidth: 200,
      flex: 0,
    },
    {
      field: 'lastDate',
      headerName: s('Last date'),
      disableColumnMenu: true,
      type: 'custom',
      renderHeaderFilter: (params: GridHeaderFilterCellProps) => (
        <FilterDateRange
          {...params}
          placeholder={s('Last date')}
        />
      ),
      valueGetter: (value) => (value ? new Date(value) : null),
      renderCell: ({ value }) => <DateTime value={value} direction="row" />,
      minWidth: 200,
      flex: 0,
    },
  ];
  return columns;
}

type StructProps = {
  currencySign: string
};
