import { BrandFiles, getBrandImg, isFileExists } from 'shared-scope/helpers/functions';
import styles from './logo.module.scss';

type LogoHalfProps = {
  width?: number | string
  height?: number | string
  className?: string
  pathClassName?: string
  style?: object
};

export function LogoHalf({
  width,
  height,
  className = '',
  pathClassName = '',
  style = {},
}: LogoHalfProps) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 22" fill="none" className={className} width={width} height={height} style={style}>
        <path
          className={pathClassName}
          d="M14.8856 11.7627C14.7829 11.8951 14.6516 12.0024 14.5014 12.0767C14.3513 12.1509 14.1862 12.19 14.0187 12.1911L9.06514 12.1647C8.85753 12.1661 8.65372 12.1091 8.47699 12.0002C8.30026 11.8912 8.15775 11.7347 8.06574 11.5486C7.97374 11.3625 7.93596 11.1542 7.95672 10.9477C7.97747 10.7411 8.05593 10.5445 8.18312 10.3804L15.9133 0.460676C16.016 0.329555 16.147 0.223321 16.2965 0.149895C16.446 0.0764702 16.6102 0.0377484 16.7768 0.0366211L21.731 0.0621716C21.9386 0.0607647 22.1424 0.1178 22.3192 0.226751C22.4959 0.335702 22.6384 0.492177 22.7304 0.678291C22.8224 0.864405 22.8602 1.07265 22.8394 1.27922C22.8187 1.4858 22.7402 1.68236 22.613 1.84645L14.8856 11.7627Z"
          fill="#EF3F08"
        />
        <path
          className={pathClassName}
          d="M13.8581 15.3086L6.46586 2.50481C6.36158 2.32528 6.2089 2.17872 6.02526 2.08186C5.84163 1.985 5.63445 1.94176 5.4274 1.95709C5.22035 1.97241 5.0218 2.04567 4.85442 2.16851C4.68705 2.29134 4.5576 2.45879 4.48089 2.65172L2.59556 7.23326C2.53402 7.38804 2.50822 7.55472 2.52008 7.72087C2.53194 7.88702 2.58116 8.04834 2.66407 8.19281L10.0586 21.0004C10.1629 21.18 10.3155 21.3265 10.4992 21.4234C10.6828 21.5203 10.89 21.5635 11.097 21.5482C11.3041 21.5328 11.5026 21.4596 11.67 21.3367C11.8374 21.2139 11.9668 21.0465 12.0435 20.8535L13.9294 16.273C13.9913 16.1173 14.0171 15.9496 14.0047 15.7826C13.9924 15.6155 13.9422 15.4535 13.8581 15.3086Z"
          fill="#000020"
        />
        <path
          className={pathClassName}
          d="M16.9637 21.3453C16.8611 21.4776 16.7297 21.585 16.5796 21.6592C16.4294 21.7334 16.2643 21.7725 16.0968 21.7737L11.1433 21.7472C10.9357 21.7486 10.7318 21.6916 10.5551 21.5827C10.3784 21.4737 10.2359 21.3172 10.1439 21.1311C10.0519 20.945 10.0141 20.7368 10.0348 20.5302C10.0556 20.3236 10.1341 20.1271 10.2612 19.963L17.9914 10.0432C18.0941 9.91207 18.2251 9.80584 18.3747 9.73241C18.5242 9.65899 18.6883 9.62027 18.8549 9.61914L23.8091 9.64469C24.0167 9.64328 24.2206 9.70032 24.3973 9.80927C24.574 9.91822 24.7165 10.0747 24.8085 10.2608C24.9005 10.4469 24.9383 10.6552 24.9176 10.8617C24.8968 11.0683 24.8184 11.2649 24.6912 11.429L16.9637 21.3453Z"
          fill="#EF3F08"
        />
      </svg>
    );
}

LogoHalf.defaultProps = {
  width: 0,
  height: 0,
  className: '',
  pathClassName: '',
  style: {},
};

type LogoProps = {
  size?: number
  className?: string
  pathClassName1?: string
  pathClassName2?: string
};

export function Logo({
 size = 100,
 className = '',
 pathClassName1 = '',
 pathClassName2 = '',
}: LogoProps) {
  return !isFileExists(getBrandImg(BrandFiles.LogoSimple))
    ? <div className={`d-flex justify-content-center align-items-center ${className}`}
        style={{
         width: size + 'px',
         height: size + 'px',
       }}
    >
      <LogoHalf width="45%" style={{ marginRight: size * 0.07 + 'px' }} pathClassName={`${styles.logoColor1} ${pathClassName1}`} />
      <LogoHalf width="45%" className={styles.rotate180} pathClassName={`${styles.logoColor2} ${pathClassName2}`} />
    </div>
    : <div className={`d-flex justify-content-center align-items-center ${className}`}>
      <img
        src={getBrandImg(BrandFiles.LogoSimple)}
        alt=""
        style={{
         width: size + 'px',
         height: size + 'px',
       }}
      />
    </div>;
}

Logo.defaultProps = {
  size: 100,
  className: '',
  pathClassName1: '',
  pathClassName2: '',
};
