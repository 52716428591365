import { isUndefined } from 'lodash';

const checkAttachmentSize = (
  maxAttachmentSize?: number,
  size?: number,
): boolean => {
  const maxSize = isUndefined(maxAttachmentSize) ? Infinity : maxAttachmentSize;
  return !!maxSize && (!size || size <= maxSize);
};

export default checkAttachmentSize;
