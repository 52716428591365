
export { default as downloadImportError } from './CampaignShared/helpers/downloadImportError';
export { default as formatFileSize } from './CampaignShared/helpers/formatFileSize';

export const CAMPAIGN_TYPE_DEFAULT = 0;
export const CAMPAIGN_TYPE_API_TEMPLATE = 1;
export const CAMPAIGN_TYPE_STICKY = 2;
export const CAMPAIGN_TYPE_SECOND_STAGE = 3;
export const CAMPAIGN_TYPE_RESPONDER = 4;
export const CAMPAIGN_TYPE_OPT_IN = 5;
export const CAMPAIGN_TYPE_DRIP = 6;
