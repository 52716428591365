import { PropsWithChildren } from 'react';
import styles from './styles.module.scss';

export interface BodyProps {
}

export default function Body({ children }: PropsWithChildren<BodyProps>) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        {children}
      </div>
    </div>
  );
}
