import { Attachment } from 'graphql-api';
import { z } from 'zod';
import { s } from 'i18n';
import { MessageValidatesList } from './isCorrectMessage';
import {
  zMessageAttachment, zMessageKeywords, zMessageText, zNotRequired, zText,
} from './validators';

const isCorrectOptIn = (
  attachmentList: Attachment[],
  maxAttachmentSize?: number,
  validate?: MessageValidatesList,
  ) => {
  const checkMessage = (errText: string) => (validate?.message !== false ? zMessageText(errText) : zNotRequired);
  const checkAnswer = (errText: string) => (validate?.answer !== false ? zMessageKeywords(errText) : z.array(zText(errText)).nullish());
  const checkAttachment = validate?.attach !== false
    ? zMessageAttachment(attachmentList, maxAttachmentSize)
    : zNotRequired;

  return z.object({
    initialAnswer: checkMessage(s('Keywords can\'t be empty.')),
    template: z.object({
      questions: z.array(z.object({
        question: checkMessage(s('Message can\'t be empty. ')),
        keywords: checkAnswer(s('Confirmation keywords can\'t be empty. ')),
        attachment_id: checkAttachment,
      })),
      final_message: checkMessage(s('You must set a text for confirmation message')),
      final_attachment_id: checkAttachment,
    }),
  });
};

export default isCorrectOptIn;
