import { Button } from '@mui/material';
import React, { PropsWithChildren } from 'react';

export default function TagButton({
    children,
    onClick,
    isActive,
    maxWidth = '500px',
    endIcon = null,
    border = 'none',
}: PropsWithChildren<TagButtonProps>) {
  return (
    <Button
      component="label"
      variant="outlined"
      size="small"
      color="inherit"
      onClick={onClick}
      endIcon={endIcon}
      sx={{
        backgroundColor: (theme) => {
          return isActive ? theme.palette.primary.main : theme.palette.layout.light;
        },
        color: (theme) => {
          return isActive ? theme.palette.primary.contrastText : theme.palette.normal.main;
        },
        '&:hover': {
          color: (theme) => theme.palette.primary.light,
          backgroundColor: (theme) => {
            return isActive ? theme.palette.primary.dark : theme.palette.normal.light;
          },
        },
        border,
        borderRadius: '20px',
        fontWeight: 400,
        maxWidth,
      }}
    >
      {children}
    </Button>
  );
}

interface TagButtonProps {
  onClick: () => void
  isActive: boolean
  maxWidth?: string
  endIcon?: React.ReactNode | null
  border?: string
}
