import { PropsWithChildren, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { FormRow, IForm } from './types';
import Context from './FormContext';

export default function FormProvider({ children, form, onSubmit }: PropsWithChildren<FormProviderProps>) {
  const [hasError, setHasError] = useState(false);

  /**
   * Scroll to the first error
   */
  useEffect(() => {
    if (hasError) {
      const errors = form.getErrors();
      const names = Object.keys(errors);
      if (names.length) {
        const el = document.getElementsByClassName(`formController-${names[0]}`);
        el?.[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      setHasError(false);
    }
  }, [hasError, form]);

  return <Context.Provider value={form}>
    <Box
      component="form"
      onSubmit={(e) => {
        e.stopPropagation();
        e.preventDefault();
        if (form.validate()) {
          onSubmit?.(form.getRow());
        } else {
          setHasError(true);
        }
      }}
    >
      {children}
    </Box>
  </Context.Provider>;
}

type FormProviderProps = {
  form: IForm<any>
  onSubmit?: (row: FormRow) => void
};


