import {
  set as s,
  get as g,
} from 'lodash';

let CONFIG: Record<string, any> = {};

export function save(config: Record<string, any> = {}) {
  CONFIG = {
    ...CONFIG,
    ...config,
  };
}

export function get<T>(path?: string, defaultValue?: T): (T | any) {
  if (path === undefined) {
    return CONFIG;
  }

  let v = g(CONFIG, path);
  if (v === undefined) {
    if (defaultValue === undefined) {
      throw new Error(`Unknown config: ${path}`);
    } else {
      return defaultValue;
    }
  }

  // Add EUID for API url
  if (path === 'api') {
    let vv = PUBLIC_PATH.replace(/(\/login)?\/$/, '/');
    if (CONFIG.CID) {
      vv = `/${CONFIG.CID}${vv}`;
    }
    if (CONFIG.EUID) {
      vv = `${vv}${CONFIG.EUID}`;
    }
    v = get(path + '_host') + vv + `${v}`;
  }

  if (path === 'graphql') {
    v = get(path + '_host') + `${v}`;
  }

  return v;
}

export function set(path: string, value: any) {
  s(CONFIG, path, value);
}
