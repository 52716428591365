import { Char } from 'shared-scope/types';
import GSM7 from './GSM7';
import { Encod } from './Encoding';

/**
 * GSM7 ES shift chars
 * @type {*[]}
 */
export const gsm7EsShiftChars: Char[] = [
  null, null, null, null, null, null, null, null,
  null, 'ç', '\f', null, null, null, null, null,
  null, null, null, null, '^', null, null, null,
  null, null, null, null, null, null, null, null,
  null, null, null, null, null, null, null, null,
  '{', '}', null, null, null, null, null, '\\',
  null, null, null, null, null, null, null, null,
  null, null, null, null, '[', '~', ']', null,
  '|', 'Á', null, null, null, null, null, null,
  null, 'Í', null, null, null, null, null, 'Ó',
  null, null, null, null, null, 'Ú', null, null,
  null, null, null, null, null, null, null, null,
  null, 'á', null, null, null, '€', null, null,
  null, 'í', null, null, null, null, null, 'ó',
  null, null, null, null, null, 'ú', null, null,
  null, null, null, null, null, null, null, null,
];

/**
 * GSM7 es
 */
export default class GSM7es extends GSM7 {
  name = Encod.gsm7es;
  shift_code = 0x02;

  /**
   * Init
   */
  init() {
    super.init();
    this.ext_chars = gsm7EsShiftChars;
  }
}
