import { NeruThemeProvider, Theme as NeruTheme } from '@xeebi/neru';
import { ThemeProvider } from '@mui/material';
import { PropsWithChildren } from 'react';
import * as CONFIG from 'shared-scope/config';
import at from 'lodash/at';
import themeVoteSMS from './themes/theme-VoteSMS';
import themeSignalMash from './themes/theme-SignalMash';

export default function Theme({ children = null }: PropsWithChildren<any>) {
  let theme;
  switch (CONFIG.get('color_scheme', '')) {
    case 'green':
    case 'signalMash':
      theme = themeSignalMash;
      break;
    case 'orange':
    case 'voteSms':
    default:
      theme = themeVoteSMS;
      break;
  }
  setThemeColors(theme);

  return <NeruThemeProvider theme={theme as NeruTheme}>
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
  </NeruThemeProvider>;
}

const COLORS_MAP = {
  '--primary-color': 'palette.primary.main',
  '--primary-color-dark': 'palette.primary.dark',
  '--text-color': 'palette.normal.main',
  '--page-layout-bg-color': 'palette.layout.main',
  '--header-text-color': 'palette.header.contrastText',
  '--title-color': 'palette.header.main',
  '--body-bg-color': 'palette.layout.light',
  '--layout-bg-color': 'palette.layout.dark',
  '--header-text-secondary': 'palette.normal.light',
  '--muted-text-secondary': 'palette.muted.main',
  '--link-color': 'palette.info.main',
  '--success-color': 'palette.success.main',
  '--error-color': 'palette.error.main',
  '--scheduler_non_editable_color': 'palette.layout.light',
};
type Color = keyof typeof COLORS_MAP;

function setThemeColors(theme: NeruTheme) {
  (Object.keys(COLORS_MAP) as Color[]).forEach((color) => {
    const paletteColor = at(theme as Record<string, any>, COLORS_MAP[color])?.[0];
    paletteColor && document.documentElement.style.setProperty(color, paletteColor);
  });
}
