import { s } from 'i18n';
import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import { ConversationTemplate } from 'src/graphql-api';
import { getSdk } from 'products/Templates/query.generated';
import { fetcher } from 'graphql-api/fetcher';
import WhiteBox from 'shared-scope/components/Box/WhiteBox';
import TemplateType from 'products/Templates/types';
import {
  Stack,
  TextField,
  IconButton,
  Typography,
  Box,
  useTheme,
} from '@mui/material';
import Drawer from 'shared-scope/components/Drawer';
import { QuestionsTemplate, QuestionType } from 'products/common/types';
import { Questions, Question, OptInQuestions } from 'products/shared/Campaign/components';
import {
 cloneDeep, isEqual, omit, isEmpty,
} from 'lodash';
import useAlert from 'shared-scope/hooks/useAlert';
import { useMountedState } from '@xeebi/neru';
import validateTemplate from 'products/Templates/helpers';
import { parseQuestion, getQuestionFull } from 'products/shared/Campaign/helpers';


const api = getSdk(fetcher);

export type EditTemplateProps = {
  template: ConversationTemplate
  onClose: () => void
  onChange: () => void
  onDelete: () => void
};

export function EditTemplate({
  template,
  onClose,
  onChange,
  onDelete,
}: EditTemplateProps) {
  const { addSuccess } = useAlert();
  const isMounted = useMountedState();
  const { palette } = useTheme();

  const [templateName, setTemplateName] = useState(template.name || '');
  const [initialMessage, setInitialMessage] = useState(template.initialMessage || '');
  const [initialAttachment, setInitialAttachment] = useState<string | null>(template.initialAttachment?.id || null);
  const [questions, setQuestions] = useState<QuestionsTemplate | null>(null);
  const [errors, setErrors] = useState<FormErrors>({});
  const [loading, setLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  const templateType = template.type;

  const validate = useCallback(() => {
    const e: FormErrors = {};
    !templateName.trim() && Object.assign(e, { templateName: s('Template name can\'t be empty') });
    (templateType === TemplateType.Text) && !initialMessage.trim()
    && Object.assign(e, { initialMessage: s('Message text can\'t be empty') });

    if ((templateType === TemplateType.Poll || templateType === TemplateType.OptIn) && questions) {
      const { error, validQuestions } = validateTemplate(templateType, questions);
      !isEqual(questions, validQuestions) && setQuestions(validQuestions);
      Object.assign(e, error);
    }

    return e;
  }, [templateName, initialMessage, templateType, questions]);

  const saveChanges = useCallback(async () => {
    try {
      const err = validate();
      setErrors(err);
      if (!isEmpty(err)) {
        console.error(err);
        return;
      }

      const data: Record<string, any> = templateType === TemplateType.Text ? {
        name: templateName,
        initialMessage,
        initialAttachment,
        initialAnswer: '',
        template: [],
      } : {
        ...omit(questions, ['id', 'createTs', 'questions']),
        ...{
          name: templateName,
          initialAttachment: questions?.initialAttachment?.id || null,
        },
      };

      if (templateType !== TemplateType.Text) {
        delete data.initialError;
        delete data.template.final_error;
        delete data.template.error_error;
        data.template.questions = (data as QuestionsTemplate).template.questions?.map(
          (q: QuestionType) => {
            delete q.error;
            return parseQuestion(q);
          },
        ) || [];
      }

      setLoading(true);
      await api.conversationTemplateSave({ filter: JSON.stringify({ id: template.id }), input: data });
      addSuccess(s(`The template "${templateName}" modified successfully`));
      onChange();
    } catch (e) {
      isMounted() && setErrors({ result: s('Error modifying the template') });
    } finally {
      isMounted() && setLoading(false);
    }
  }, [templateName, initialMessage, initialAttachment, questions,
            templateType, addSuccess, isMounted, validate, template.id, onChange]);

  /**
   * Check if template changed
   */
  useEffect(() => {
    let equals = templateName === template.name;

    if (templateType === TemplateType.Text) {
      equals = equals
        && templateName === template.name
        && initialMessage === template.initialMessage
        && initialAttachment === (template.initialAttachment?.id || null);
    } else {
      equals = equals
        && questions?.initialMessage === template.initialMessage
        && questions?.initialAnswer === template.initialAnswer
        && questions?.initialAttachment?.id === template.initialAttachment?.id
        && isEqual(questions?.template, template.template);
    }

    setIsChanged(!equals);
  }, [templateName, initialMessage, initialAttachment, templateType, questions, template]);

  useEffect(() => {
    const newQuestions = cloneDeep(template) as QuestionsTemplate;
    newQuestions.template.questions = newQuestions.template.questions?.map(
      (q) => getQuestionFull(q),
    ) || [];
    setQuestions(newQuestions);
  }, [template]);

  const hasOptionQuestion = questions?.template.questions?.some(
    (q) => parseQuestion(q).options?.length,
  ) || false;

  return (
    <Drawer
      open
      loading={loading}
      disabled={!isChanged || loading}
      onClose={onClose}
      onSave={saveChanges}
    >
      <Stack sx={{ height: '100%' }} spacing={2}>
        <Box
          sx={{
            backgroundColor: palette.layout.light,
            width: '100%',
            height: '110px',
          }}
        >
          <WhiteBox sx={{ p: 2 }}>
            <Stack direction="row" spacing={2} justifyContent="space-between" height="80px" alignItems="center">
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="h2">{template.name}</Typography>
                <Typography
                  sx={{
                    color: palette.normal.light,
                    fontSize: '16px',
                    fontWeight: 700,
                  }}
                >ID: {template.id}</Typography>
              </Stack>
              <IconButton
                aria-label="Delete template"
                sx={{
                  border: '2px solid',
                  borderRadius: '50%',
                  height: '40px',
                  width: '40px',
                }}
                color="primary"
                onClick={() => onDelete()}
              >
                <div><i className="icon-delete" /></div>
              </IconButton>
            </Stack>
          </WhiteBox>
        </Box>

        <WhiteBox sx={{ p: 2 }}>
          <Stack spacing={2}>
            {errors.result && <Typography variant="h5" color={(theme) => theme.palette.error.main}>{errors.result}</Typography>}
            <Typography variant="h3">{s('Template name')}</Typography>
            <TextField
              label={s('Name')}
              value={templateName}
              onChange={(e) => setTemplateName(e.target.value)}
              variant="outlined"
              sx={{ width: '600px' }}
              required
              error={!!errors?.templateName}
              helperText={errors?.templateName}
            />
            <Typography variant="h3" sx={{ marginTop: '32px !important' }}>
              {templateType === TemplateType.Poll ? s('Poll') : s('Message to send')}
            </Typography>
            {templateType === TemplateType.Text
              ? (
                <>
                  {errors.initialMessage
                      && <Typography variant="subtitle1" color={(theme) => theme.palette.error.main}>
                        {errors.initialMessage}
                      </Typography>}
                  <Question
                    key="message"
                    title={s('Text')}
                    question={initialMessage}
                    attachment={initialAttachment}
                    onChange={(value) => setInitialMessage(value)}
                    onAttachmentChange={(value) => setInitialAttachment(value)}
                  />
                </>
              )
              : null}
            {templateType === TemplateType.Poll
              ? (
                <>
                  {errors.questions?.length
                    && <Typography variant="subtitle1" color={(theme) => theme.palette.error.main}>
                      {errors.questions.map((q) => <div key={q}>{q}</div>)}
                    </Typography>}
                  <Questions
                    isPoll
                    value={questions}
                    onChange={(v) => setQuestions(v)}
                    hasOptionQuestion={hasOptionQuestion}
                  />
                </>
              )
              : null}

            {templateType === TemplateType.OptIn
              ? (
                <OptInQuestions value={questions} onChange={(v) => setQuestions(v)} />
              )
              : null}
          </Stack>
        </WhiteBox>
      </Stack>
    </Drawer>
  );
}

type FormErrors = {
  templateName?: string
  initialMessage?: string
  questions?: string[]
  result?: string
};
