import React, { PropsWithChildren, ReactNode } from 'react';
import {
  Grid, Typography, Icon, Box, Stack, Backdrop,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { isString } from 'lodash';
import Layout from 'layout';
import Loading from 'shared-scope/components/Loading';
import style from './styles.module.scss';

export default function Page({
 title, children, renderHead, headTools, back, loading,
}: PropsWithChildren<PageProps>) {
  return (
    <Layout>
      <Head title={title} headTools={headTools} renderHead={renderHead} back={back} />
      <Content loading={loading}>{children}</Content>
    </Layout>
  );
}

function Head({
 title = '', headTools, renderHead, back,
}: HeadProps) {
  return (
    <Box
      sx={({ palette }) => (
        {
          width: '100%',
          backgroundColor: palette.header.main,
          color: palette.header.contrastText,
          padding: '85px 2% 75px',
        }
      )}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{
          paddingTop: '48px',
        }}
      >
        <Grid item xs={6}>
          <Grid container alignItems="center" justifyContent="flex-start" spacing={0}>
            {back ? <Grid item xs="auto"><Back to={isString(back) ? back : undefined} /></Grid> : null }
            <Grid item xs="auto">
              <Typography
                variant="h1"
                color={(theme) => theme.palette.layout.main}
                sx={{
                  maxWidth: '850px',
                  textOverflow: 'ellipsis',
                  overflowX: 'hidden',
                }}
              >{title}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} textAlign="right" sx={{ paddingRight: '12px' }}>
          <Stack direction="row" spacing={2} justifyContent="flex-end">{headTools}</Stack>
        </Grid>
        <Grid item xs={12}>{renderHead}</Grid>
      </Grid>
    </Box>
  );
}

function Content({ children, loading }: PropsWithChildren<ContentProps>) {
  return (
    <Box className={style.pageBody}>
      <Box className={style.pageContent}>
        {children}
      </Box>
      {loading
        ? <Backdrop
            open={loading}
            sx={{
            color: '#fff',
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
        ><Loading /></Backdrop>
        : null}
    </Box>
  );
}

function Back({ to }: { to?: string }) {
  const navigate = useNavigate();

  return <Box className={style.back} onClick={() => (to ? navigate(to) : navigate(-1))}>
    <Icon sx={{ fontSize: '16px', paddingTop: '2px' }}>arrow_back</Icon>
    <Box component="span">Back</Box>
  </Box>;
}

type HeadProps = {
  title?: string
  headTools?: ReactNode
  renderHead?: ReactNode
  back?: boolean | string
};

type ContentProps = {
  loading?: boolean
};

type PageProps = HeadProps & ContentProps;
