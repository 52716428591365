import * as Types from '../../graphql-api';

import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
export type GetPollChartQueryVariables = Types.Exact<{
  startTs: Types.Scalars['Date']['input'];
  endTs: Types.Scalars['Date']['input'];
  interval: Types.Scalars['String']['input'];
}>;


export type GetPollChartQuery = { __typename?: 'Query', surveyChart: Array<{ __typename?: 'SurveyChart', ts: string, startTs: any, sent: number, replied: number, completed: number, error: number, billedAmount: any, surveyCount: number }> };

export type GetPollStatusQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetPollStatusQuery = { __typename?: 'Query', surveyStatus: Array<{ __typename?: 'SurveyStatus', status: number, details: string, count: number, attentions: number }> };


export const GetPollChartDocument = gql`
    query getPollChart($startTs: Date!, $endTs: Date!, $interval: String!) {
  surveyChart(startTs: $startTs, endTs: $endTs, interval: $interval) {
    ts
    startTs
    sent
    replied
    completed
    error
    billedAmount
    surveyCount
  }
}
    `;
export const GetPollStatusDocument = gql`
    query getPollStatus {
  surveyStatus {
    status
    details
    count
    attentions
  }
}
    `;
export type Requester<C = {}> = <R, V>(doc: DocumentNode, vars?: V, options?: C) => Promise<R> | AsyncIterable<R>
export function getSdk<C>(requester: Requester<C>) {
  return {
    getPollChart(variables: GetPollChartQueryVariables, options?: C): Promise<GetPollChartQuery> {
      return requester<GetPollChartQuery, GetPollChartQueryVariables>(GetPollChartDocument, variables, options) as Promise<GetPollChartQuery>;
    },
    getPollStatus(variables?: GetPollStatusQueryVariables, options?: C): Promise<GetPollStatusQuery> {
      return requester<GetPollStatusQuery, GetPollStatusQueryVariables>(GetPollStatusDocument, variables, options) as Promise<GetPollStatusQuery>;
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;