import React from 'react';
import {
  GridColDef, GridFilterItem,
} from '@mui/x-data-grid-pro';
import Stack from '@mui/material/Stack';
import {
  FilterCombobox,
  FilterDropdownExtra,
} from '@xeebi/neru';
import { s } from 'i18n';
import { SrcHashtag } from 'graphql-api';
import { phoneNumberValidator } from 'products/PhoneBook/PhoneBookGrid/helpers';
import dayjs from 'dayjs';
import { BIRTHDAY_INTERVALS } from 'products/shared/Campaign/components/BirthdayFilter';

export default function Struct(hashtags: SrcHashtag[]) {
  const columns: GridColDef[] = [
    {
      field: 'phoneNumber',
      headerName: s('Phone'),
      type: 'string',
      filterable: true,
      minWidth: 120,
      disableColumnMenu: true,
      flex: 1,
      editable: true,
      preProcessEditCellProps: (params) => {
        return { ...params.props, error: !phoneNumberValidator(params.props.value) };
      },
    },
    {
      field: 'firstName',
      headerName: s('First name'),
      type: 'string',
      filterable: true,
      minWidth: 120,
      disableColumnMenu: true,
      flex: 1,
      editable: true,
    },
    {
      field: 'lastName',
      headerName: s('Last name'),
      type: 'string',
      filterable: true,
      minWidth: 120,
      disableColumnMenu: true,
      flex: 1,
      editable: true,
    },
    {
      field: 'gender',
      headerName: s('Gender'),
      disableColumnMenu: true,
      type: 'string',
      filterable: true,
      minWidth: 120,
      flex: 1,
      editable: true,
    },
    {
      field: 'birthday',
      headerName: s('Birthday'),
      type: 'date',
      filterable: true,
      minWidth: 120,
      disableColumnMenu: true,
      flex: 1,
      editable: true,
      valueGetter: (value) => (value ? dayjs(value).format('YYYY-MM-DD') : null),
      valueSetter: (value, row) => {
        let birthday = value;
        if (value instanceof Date) {
          birthday = dayjs(value).format('YYYY-MM-DD');
        }
        return { ...row, birthday };
      },
      renderHeaderFilter: (params: any) => {
        return (
          <Stack sx={{ width: '100%' }}>
            <FilterCombobox
              {...params}
              options={BIRTHDAY_INTERVALS.filter((i) => !['before', 'after'].includes(i.k))}
              optionKey="k"
              optionValue="v"
              getFilterValue={(v: any) => v.dayCount}
              getFilterOperator={(v) => v.filter}
              filterCompare={(gridFilter: GridFilterItem, option: any) => {
                return gridFilter.value === option.dayCount && gridFilter.operator === option.filter;
              }}
            />
          </Stack>
        );
      },
    },
    {
      field: 'srcHashtags',
      headerName: s('Hashtags'),
      type: 'custom',
      filterable: true,
      minWidth: 200,
      disableColumnMenu: true,
      flex: 1,
      editable: false,
      valueGetter: (value: string[] | null) => (value?.map((v) => `#${v}`).join(', ') || null),
      renderHeaderFilter: (params: any) => {
        return (
          <FilterDropdownExtra
            {...params}
            options={hashtags.map((h) => ({ id: h.hashtag, hashtag: h.hashtag }))}
            keyName="id"
            valueName="hashtag"
            placeholder={s('Hashtag')}
            minWidth={150}
          />
        );
      },
    },
    {
      field: 'state',
      headerName: s('State'),
      disableColumnMenu: true,
      type: 'string',
      filterable: true,
      minWidth: 200,
      flex: 1,
      editable: true,
    },
    {
      field: 'tz',
      headerName: s('Time zone'),
      disableColumnMenu: true,
      type: 'string',
      filterable: true,
      minWidth: 120,
      flex: 1,
      editable: true,
    },
  ];
  return columns;
}
