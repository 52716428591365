import * as Types from '../../graphql-api';

import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
export type GetContactQueryVariables = Types.Exact<{
  phoneBookId: Types.Scalars['Int']['input'];
  filter?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sort?: Types.InputMaybe<Types.Scalars['String']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetContactQuery = { __typename?: 'Query', contactCount?: number | null, contact: Array<{ __typename?: 'Contact', id?: string | null, phoneNumber?: string | null, state?: string | null, tz?: string | null, firstName?: string | null, lastName?: string | null, gender?: string | null, operatorName?: string | null, birthday?: any | null, srcHashtags?: Array<string> | null }> };

export type GetRegionQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sort?: Types.InputMaybe<Types.Scalars['String']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetRegionQuery = { __typename?: 'Query', region: Array<{ __typename?: 'Region', name?: string | null, code?: string | null, tz?: string | null }> };

export type GetImportQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sort?: Types.InputMaybe<Types.Scalars['String']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetImportQuery = { __typename?: 'Query', import: Array<{ __typename?: 'Import', id?: number | null, status?: number | null, rowsError?: number | null, rowsInserted?: number | null, rowsSkipped?: number | null, rowsTotal?: number | null, dstId?: number | null }> };

export type PhoneBookFolderSaveMutationVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.Scalars['String']['input']>;
  input: Types.PhoneBookFolderInput;
}>;


export type PhoneBookFolderSaveMutation = { __typename?: 'Mutation', phoneBookFolderSave: Array<{ __typename?: 'PhoneBookFolder', id?: number | null } | null> };

export type PhoneBookFolderDeleteMutationVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type PhoneBookFolderDeleteMutation = { __typename?: 'Mutation', phoneBookFolderDelete: Array<{ __typename?: 'PhoneBookFolder', id?: number | null } | null> };

export type PhoneBookSaveMutationVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.Scalars['String']['input']>;
  input: Types.PhoneBookInput;
}>;


export type PhoneBookSaveMutation = { __typename?: 'Mutation', phoneBookSave: Array<{ __typename?: 'PhoneBook', id?: number | null } | null> };

export type PhoneBookDeleteMutationVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type PhoneBookDeleteMutation = { __typename?: 'Mutation', phoneBookDelete: Array<{ __typename?: 'PhoneBook', id?: number | null } | null> };

export type ConfirmImportMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type ConfirmImportMutation = { __typename?: 'Mutation', confirmImport?: { __typename?: 'confirmImportResult', success?: boolean | null } | null };

export type SaveContactMutationVariables = Types.Exact<{
  phoneBookId: Types.Scalars['Int']['input'];
  filter?: Types.InputMaybe<Types.Scalars['String']['input']>;
  input: Types.ContactInput;
}>;


export type SaveContactMutation = { __typename?: 'Mutation', contactSave: Array<{ __typename?: 'Contact', id?: string | null } | null> };

export type DeleteContactMutationVariables = Types.Exact<{
  phoneBookId: Types.Scalars['Int']['input'];
  filter?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type DeleteContactMutation = { __typename?: 'Mutation', contactDelete: Array<{ __typename?: 'Contact', id?: string | null } | null> };


export const GetContactDocument = gql`
    query getContact($phoneBookId: Int!, $filter: String = null, $sort: String = null, $offset: Int = null, $limit: Int = 0) {
  contact(
    phoneBookId: $phoneBookId
    filter: $filter
    sort: $sort
    limit: $limit
    offset: $offset
  ) {
    id
    phoneNumber
    state
    tz
    firstName
    lastName
    gender
    operatorName
    birthday
    srcHashtags
  }
  contactCount(phoneBookId: $phoneBookId, filter: $filter)
}
    `;
export const GetRegionDocument = gql`
    query getRegion($filter: String = null, $sort: String = null, $offset: Int = null, $limit: Int = 0) {
  region(filter: $filter, sort: $sort, limit: $limit, offset: $offset) {
    name
    code
    tz
  }
}
    `;
export const GetImportDocument = gql`
    query getImport($filter: String = null, $sort: String = null, $offset: Int = null, $limit: Int = 0) {
  import(filter: $filter, sort: $sort, limit: $limit, offset: $offset) {
    id
    status
    rowsError
    rowsInserted
    rowsSkipped
    rowsTotal
    dstId
  }
}
    `;
export const PhoneBookFolderSaveDocument = gql`
    mutation phoneBookFolderSave($filter: String = null, $input: PhoneBookFolderInput!) {
  phoneBookFolderSave(filter: $filter, input: $input) {
    id
  }
}
    `;
export const PhoneBookFolderDeleteDocument = gql`
    mutation phoneBookFolderDelete($filter: String = null) {
  phoneBookFolderDelete(filter: $filter) {
    id
  }
}
    `;
export const PhoneBookSaveDocument = gql`
    mutation phoneBookSave($filter: String = null, $input: PhoneBookInput!) {
  phoneBookSave(filter: $filter, input: $input) {
    id
  }
}
    `;
export const PhoneBookDeleteDocument = gql`
    mutation phoneBookDelete($filter: String = null) {
  phoneBookDelete(filter: $filter) {
    id
  }
}
    `;
export const ConfirmImportDocument = gql`
    mutation confirmImport($id: Int) {
  confirmImport(id: $id) {
    success
  }
}
    `;
export const SaveContactDocument = gql`
    mutation saveContact($phoneBookId: Int!, $filter: String, $input: ContactInput!) {
  contactSave(phoneBookId: $phoneBookId, filter: $filter, input: $input) {
    id
  }
}
    `;
export const DeleteContactDocument = gql`
    mutation deleteContact($phoneBookId: Int!, $filter: String) {
  contactDelete(phoneBookId: $phoneBookId, filter: $filter) {
    id
  }
}
    `;
export type Requester<C = {}> = <R, V>(doc: DocumentNode, vars?: V, options?: C) => Promise<R> | AsyncIterable<R>
export function getSdk<C>(requester: Requester<C>) {
  return {
    getContact(variables: GetContactQueryVariables, options?: C): Promise<GetContactQuery> {
      return requester<GetContactQuery, GetContactQueryVariables>(GetContactDocument, variables, options) as Promise<GetContactQuery>;
    },
    getRegion(variables?: GetRegionQueryVariables, options?: C): Promise<GetRegionQuery> {
      return requester<GetRegionQuery, GetRegionQueryVariables>(GetRegionDocument, variables, options) as Promise<GetRegionQuery>;
    },
    getImport(variables?: GetImportQueryVariables, options?: C): Promise<GetImportQuery> {
      return requester<GetImportQuery, GetImportQueryVariables>(GetImportDocument, variables, options) as Promise<GetImportQuery>;
    },
    phoneBookFolderSave(variables: PhoneBookFolderSaveMutationVariables, options?: C): Promise<PhoneBookFolderSaveMutation> {
      return requester<PhoneBookFolderSaveMutation, PhoneBookFolderSaveMutationVariables>(PhoneBookFolderSaveDocument, variables, options) as Promise<PhoneBookFolderSaveMutation>;
    },
    phoneBookFolderDelete(variables?: PhoneBookFolderDeleteMutationVariables, options?: C): Promise<PhoneBookFolderDeleteMutation> {
      return requester<PhoneBookFolderDeleteMutation, PhoneBookFolderDeleteMutationVariables>(PhoneBookFolderDeleteDocument, variables, options) as Promise<PhoneBookFolderDeleteMutation>;
    },
    phoneBookSave(variables: PhoneBookSaveMutationVariables, options?: C): Promise<PhoneBookSaveMutation> {
      return requester<PhoneBookSaveMutation, PhoneBookSaveMutationVariables>(PhoneBookSaveDocument, variables, options) as Promise<PhoneBookSaveMutation>;
    },
    phoneBookDelete(variables?: PhoneBookDeleteMutationVariables, options?: C): Promise<PhoneBookDeleteMutation> {
      return requester<PhoneBookDeleteMutation, PhoneBookDeleteMutationVariables>(PhoneBookDeleteDocument, variables, options) as Promise<PhoneBookDeleteMutation>;
    },
    confirmImport(variables?: ConfirmImportMutationVariables, options?: C): Promise<ConfirmImportMutation> {
      return requester<ConfirmImportMutation, ConfirmImportMutationVariables>(ConfirmImportDocument, variables, options) as Promise<ConfirmImportMutation>;
    },
    saveContact(variables: SaveContactMutationVariables, options?: C): Promise<SaveContactMutation> {
      return requester<SaveContactMutation, SaveContactMutationVariables>(SaveContactDocument, variables, options) as Promise<SaveContactMutation>;
    },
    deleteContact(variables: DeleteContactMutationVariables, options?: C): Promise<DeleteContactMutation> {
      return requester<DeleteContactMutation, DeleteContactMutationVariables>(DeleteContactDocument, variables, options) as Promise<DeleteContactMutation>;
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;