import { Attachment } from 'graphql-api';
import { z } from 'zod';
import { s } from 'i18n';
import {
 zMessageAnswer, zMessageAttachment, zMessageText, zNotRequired, messageRequiredError,
} from './validators';

const isCorrectMessage = (
  attachmentList: Attachment[],
  maxAttachmentSize?: number,
  validate?: MessageValidatesList,
) => z
  .object(
    {
      text: validate?.message !== false ? zMessageText() : zNotRequired,
      attachment: validate?.attach !== false
        ? zMessageAttachment(attachmentList, maxAttachmentSize)
        : zNotRequired,
      answer: validate?.answer !== false
        ? zMessageAnswer()
        : zNotRequired,
    },
    { message: validate?.message !== false ? s(messageRequiredError) : '' },
  );

export default isCorrectMessage;

export type MessageValidatesList = { message?: boolean, attach?: boolean, answer?: boolean };
